
import React, {Component} from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {useState,useEffect} from 'react';
import useFetch from '../APIs/RecoverPassword.js';
import CircularProgress from '@mui/material/CircularProgress';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
const RecoverPasswordStepOne = (props) => {





    const [Error,setError] = useState(null)

    function handleUsername(e){
      if(e.target.value.length<=90){
        props.setusername(e.target.value)
      }
      
    
    }

    const [data,setData] = useState(null)
    const [request,setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)


    useEffect(()=>{
      if(FetchedData){
          setData(FetchedData)
      }
    },[FetchedData])

    
    const sendrequest= () =>{

      setRequest(
        {
          'username':props.username
        }
      )
    }

    const onhandle_submit= (e) =>{
  
      e.preventDefault()
      setError(null);
      if(props.username===''){
        document.getElementById('user_name_').focus()
      }else{
       
        sendrequest()
      }
  
    }

    return (
        <>
            <form onSubmit={onhandle_submit} className="myform">
              {data?
              <>
                <div className="email-sent">
                  <MarkEmailReadOutlinedIcon/>
                  <p>We have sucessfully sent you reset link to your email.<br/>
                  <a href={"mailto:"+props.username}>{props.username}</a><br/><br/>
                  If you didnt get it, resend again.</p>
                  <div className="btns">
                      <Button type="button" onClick={()=>setData(null)} className="defaultBTN" color="primary">Resend email</Button>
                  </div> 
                </div>
              </>:
                <div className="fields">
                      <p className="otppar">Enter your email address to proceed.</p>
                      <TextField inputProps={{autoComplete: 'new-password'}} autoComplete='off' variant="filled" onChange={handleUsername} id="user_name_" label="Email Address"  value={props.username}/>
                                  
                      {(isLoading && !error) && <div className="myerr"><CircularProgress/></div>}

                      {(error==="network_err" && !isLoading) &&(
                          <div className="myerr"><p>Check your network connectivity</p></div>
                      )}
                      {(error==="user_not_found" && !isLoading) &&(
                          <div className="myerr"><p>Email provided is not registered</p></div>
                      )}
                      {(error==="unknown_err" && !isLoading) &&(
                          <div className="myerr"><p>Something went wrong, contact developers</p></div>
                      )}
                      {(error==="user_id_empty" && !isLoading) &&(
                          <div className="myerr"><p>Email or username is required</p></div>
                      )}
                      <div className="btns">
                          <Button type="submit" className="defaultBTN" color="primary">RECOVER PASSWORD</Button>
                      </div> 
                    
                </div>
          }
            

            </form> 
        </>
    )
}

export default RecoverPasswordStepOne
