import {React,useEffect,useState} from 'react'
import Card from '../Cards/SpotifySongCard.js';
import useFetch from '../APIs/SpotifySearchSongs.js';
import {Link} from 'react-router-dom';
import LoadingSkeleton from '../LoadingSkeletons/Card05.js';

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import { Navigation } from "swiper/modules";

const TopSongs = (props) => {
 
    //userId,songId,search_string,genre,sort,page,
    
    const [data, setData] = useState(null);
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData){
            setData(FetchedData)
        }
    },[FetchedData])


    const send_request =()=>{
        setData(null)
        setRequest({
            "search_string": props.filters.search_string,
            "sort": props.filters.sort,
            "role":"track",
            "user_id": "",
            "page":"",
        })
    }



    useEffect(()=>{
        send_request()
    },[props.filters])

  return (
    <>
    
        
            {data &&(
                <>
                  <section>
                    <p></p>
                        <div className="row">
                            <div className="col-12 col-lg-12">


                                <div className="htitle">
                                    <div className="lft"><p>TOP SINGLES {props.filters.search_string!=="" &&(<>({data.tracks.total})</>)} <span className="platform">SPOTIFY</span></p></div>
                                    {data.tracks.total > 10 &&(
                                        <div className="rgt"><p><Link to={props.filters.search_string!=''?"/browse?group=singles-on-spotify&search="+props.filters.search_string+"&sort=newest":"/browse?group=singles-on-spotify&sort=newest"}>See more</Link></p></div>
                                    )}
                                    
                                </div>
                                {props.innerWidth<992 &&(
                                    <div className="vertical-container">
                                        {data.tracks.items.slice(0,10).map((song,index)=>(
                                                <Card key={index} data = {song} Data = {data} index={index}/> 
                                            )
                                        )}

                                    </div>
                                )}
                                {props.innerWidth>992 &&(
                                <Swiper
                                        navigation={true}
                                        modules={[Navigation]}
                                        breakpoints={{
                                            640: {
                                                slidesPerView: 2,
                                                spaceBetween: 10,
                                            },
                                            992: {
                                                slidesPerView: 5,
                                                spaceBetween: 20,
                                            },
                                            1600: {
                                                slidesPerView: 7,
                                                spaceBetween: 30,
                                            },
                                        }}
                                        className="mySwiper"
                                    >
                                        {data.tracks.items.slice(0,10).map((song,index)=>(
                                                <SwiperSlide key={index}>
                                                    <Card data = {song} Data={data} index={index}/>
                                                </SwiperSlide>
                                            )
                                        )}
                                    
                                </Swiper>           
                                )}
                            </div>
                        </div>
                    </section>
                </>
            )}
            {(isLoading) &&(
                <>
                    <div className="htitle">
                        <div className="lft"><p>TOP SINGLES {props.filters.search_string!=="" &&(<>(0)</>)} <span className="platform">SPOTIFY</span></p></div>
                        <div className="rgt"><p><Link to={props.filters.search_string!=''?"/browse?group=singles-on-spotify&search="+props.filters.search_string+"&sort=newest":"/browse?group=singles-on-spotify&sort=newest"}>See more</Link></p></div>
                    </div>
                    {props.innerWidth<992 &&(
                        <div className="vertical-container">
                            <LoadingSkeleton/>
                            <LoadingSkeleton/>
                            <LoadingSkeleton/>
                            <LoadingSkeleton/>
                            <LoadingSkeleton/>
                            <LoadingSkeleton/>
                        </div>
                    )}
                    {props.innerWidth>992 &&(
                        <Swiper
                            navigation={true}
                            modules={[Navigation]}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                992: {
                                    slidesPerView: 5,
                                    spaceBetween: 20,
                                },
                                1600: {
                                    slidesPerView: 7,
                                    spaceBetween: 30,
                                },
                            }}
                            className="mySwiper"
                        >
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            
                        </Swiper>           
                    )}
                </>
            )}
                
    </>
  )
}

export default TopSongs