import React, {Component} from "react";
import {useState, useEffect, useContext} from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import {Link, useNavigate  }  from'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { UserStoreContext } from "../Contexts/UserStoreContext.js";
import useFetch from './APIs/UpdateNotificationSettings.js';

import Checkbox from '@mui/material/Checkbox';
import NavigationMenuToggle from '../Navigation/NavigationMenuToggle.js'
import IsDeletingNotifications from './IsDeletingNotifications.js';

export default function NotificationsSettings(props) {
    
    const navigate = useNavigate()
    const [checkedEmail, setCheckedEmail] = useState(false);
    const [checkedApp, setCheckedApp] = useState(false);
    
    
    const {is_logged_in, user_store} = useContext(UserStoreContext)




    useEffect(()=>{
        if(is_logged_in){
            if(user_store.email_notification==="true"){
                setCheckedEmail(true)
            }else{
                setCheckedEmail(false)
            }

            if(user_store.app_notification==="true"){
                setCheckedApp(true)
            }else{
                setCheckedApp(false)
            }
          
        }
    },[user_store])




    
    

    const ToggleEmailNotifications = () => {
        setCheckedEmail((prev) => !prev);
        setRequest(true)
    };

    const ToggleAppNotifications = () => {
        setCheckedApp((prev) => !prev);
        setRequest(true)
    };


    const [request, setRequest] = useState(false);

    const [data, setData] = useState(false);


    const {data:FetchedData,isLoading, error} = useFetch(checkedEmail,checkedApp,request)
    useEffect(()=>{
        if(FetchedData){
            setData(...FetchedData)
        }
        
    },[FetchedData])



    const[isDeleting,setIsDeleting] = useState(false)

    const closeModal = ()=>{
        setIsDeleting(false)
    }
  return (
    <>
    
    {props.innerWidth<992&&(
        <div className="navbar">
            <div className="wrapper">
                <div className="lft">
                    <IconButton onClick={()=>navigate(-1)} className="icon">
                        <ArrowBackIcon />
                    </IconButton> 
                    <div className="titles">
                        <p>Notifications: Settings</p>
                    </div>
                    
                </div>
                <div className="rgt">
                    <NavigationMenuToggle/>
                </div>
            </div>

        </div>   
    )}
    

    <div className="mycontainer">

 
        {/* My  path */}
        {props.innerWidth>992 &&(
            <div className="mypath">
                <div className="wrapper">
                    <div className="lft">
                        {window.history.length>1?
                        <Button onClick={()=>navigate(-1)}>
                            <KeyboardBackspaceIcon/>
                        </Button>  
                        :
                        <Button onClick={()=>navigate(-1)} disabled="true">
                            <KeyboardBackspaceIcon/>
                        </Button>  
                        }
                    </div>
                    <div className="rgt">
                        <p>
                            <Link to="/settings" className="active">Settings</Link>
                            <Link to="/notifications/settings" className="">/ Notification</Link>
                        </p>
                      
                    </div>
                </div>
            </div>
        )}
        <div className="wrapper no-padding-top">
            <div className="notscontain">
                <div className="mynot">
                    <div className="lft">
                        <div className="details">
                            <p>Receive App Notification</p>
                            <p>Get notifications from the app </p>
                        </div>
                    </div>
                    <div className="rgt">
                        <Checkbox checked={checkedApp} onChange={ToggleAppNotifications}  />
                    </div>
                </div>
                <div className="mynot">
                    <div className="lft">
                        <div className="details">
                            <p>Receive Email Notifications</p>
                            <p>Get notifications via email</p>
                        </div>
                    </div>
                    <div className="rgt">
                  
                        <Checkbox  checked={checkedEmail} onChange={ToggleEmailNotifications} />
                    
                    
                    </div>
                </div>
                <div className="mynot">
                    <div className="lft">
                        <div className="details">
                            <p>Clear All Notifications</p>
                            <p>Will be deleted permanently </p>
                        </div>
                    </div>
                    <div className="rgt">
                        <Button variant="contained" onClick={()=>setIsDeleting(true)}>Clear</Button>
                    </div>
                
                </div>
            </div>
        </div>
      </div>
      {isDeleting && (
            <IsDeletingNotifications closeModal={closeModal}/>
        )}
    </>
  );
}

