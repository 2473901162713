import ENVIROMENTS from '../Environment/Configuration.json';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, {Component} from "react";
import {useState, useEffect} from 'react'
import TrackCard from './Card13.js';
const AlbumCard = (props) => {

    const [data,setData] = useState(null)

    useEffect(()=>{
        if(props.data){
            setData(props.data)
        }

    },[props.data])

    const [expand,setExpand] = useState(false)






    return (
        <>
        {data &&(
            <div className={expand?'card14 active':'card14'} >
                <div className="details" onClick={()=>setExpand(!expand)}>
                    <div className="lft">
                        <img className="artcover"aria-label="Art Cover" src={data.album.artcover.length>1 ? ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/arts/smaller/?file_name="+data.album.artcover:"/assets/images/defaultsongart.jpg"}/>
                        <div className="title">
                            {data.album.title}
                        </div>
                    </div>
                    <div className="rgt">
                        <ExpandMoreIcon/>
                    </div>
                </div>
                
                {expand &&(
                 <div className="contain">
                    <p className="tracklist">TRACK LIST</p>
                    {data.songs &&(
                        data.songs.map((song,index)=>(
                            <TrackCard data={song}/>
                        ))
                    )}
                  
                </div>   
                )}
                
                
            </div>

        ) }
            
        </>
        
    )
}

export default AlbumCard
