import ENVIROMENTS from '../Environment/Configuration.json';
import { Toast } from '@capacitor/toast';
import React, {Component} from "react";
import {useState, useEffect, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TimeAgo from 'timeago-react'; 
import { UserStoreContext } from '../Contexts/UserStoreContext';
import useFetch from './APIs/LikeDislikeCommentReply.js';
import CircularProgress from '@mui/material/CircularProgress';
import CommentReplyMenu from './CommentReplyMenu.js';
import LogginDialog from '../Dialogs/LogginDialog.js';
import Linkify from 'linkify-react';
import ConvertNum from '../ConvertNumbers/useConvertLargeNum';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Tooltip from '@mui/material/Tooltip';

const Comments = (props) => {
    const navigate = useNavigate()

   
    const {is_logged_in, user_store} = useContext(UserStoreContext)
    const [data,setData] = useState(null)

    useEffect(()=>{
        if(props.data){
            setData(props.data)
        }
    },[props.data])

    const[isMenuOpened,setisMenuOpened] = useState(false)

    const [showLogIn, setShowLogIn] = useState(false);
    //Like, Dislike, Download
    const [commentId, setCommentId] = useState(null)
    const [request,setRequest] = useState(null);
    const {data:FetchedData,isLoading, error} = useFetch(request)
    const [Error,setError] = useState(null)

 
    useEffect(()=>{
        if(FetchedData){
            if(FetchedData){
                setData(...FetchedData)
            }
        
        }
    },[FetchedData])
 
 
    useEffect(()=>{
        setError(error)
     },[error])

    useEffect(()=>{
        if(Error){
            if(Error==="network_err"){
                Toast.show({
                    text: 'Check your internet connectivity',
                });
            }else if(Error==="unknown_err"){
                Toast.show({
                    text: 'Something went wrong',
                });
            }
         
        }
     
    },[Error])
    const LikeIt =() =>{
        setError(null)
        if(is_logged_in){
           
            setRequest({
                'commentId':data.comment_id,
                'action':'like',
            })


        }else{
        
            setShowLogIn(true)
        
        }
    
    }
    const DislikeIt =() =>{
        setError(null)
        if(is_logged_in){

            setRequest({
                'commentId':data.comment_id,
                'action':'dislike',
            })


        }else{
            
                setShowLogIn(true)
            
        }
    
    }
    const gotouser =() =>{
        if(data){
             navigate('/playing/'+data.song_id);
            
                 navigate('/profile/'+data.user_id)
                
        }
        

    }


    return (
        <>
        {data &&(
            <>
            <div className="CommentCard fadefx">
                <div className="header">
                    <div className="user" onClick={()=>gotouser()}>
                        {data.user_avatar.length>1?
                        
                            <img className="ppimg" src={ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/avatars/original/?file_name="+data.user_avatar}/>
                        :
                            <div className="ppimg">{data.artist_name.substring(0, 1)}</div>            
                        }
                        <div className="details">
                            <p>{data.artist_name}</p>
                            <p>{data.interest}</p>
                        </div>
                    </div>
                    {(is_logged_in && user_store.user_id===data.user_id) &&(
                    <div className="menu">
                         <IconButton aria-label="settings" onClick={()=>setisMenuOpened(true)}>
                            <MoreVertIcon />
                        </IconButton>
                                
                    </div>
                    )}
            

                    
                    
                </div>
            
        
                <div className="comment">
                    <p className="para">
                        <Linkify>
                            {data.message+" "} 
                        </Linkify>
                        
                        <TimeAgo datetime={data.date_added} />
                    </p>
                    <div className="reactbtns">
                        <div className='lft'>
                            <Tooltip title={(Number(data.likes) > 1 ? data.likes + ' people ': Number(data.likes) == 1 ? '1 person ' : 'No one ') + 'liked this comment.'} placement="top">
                                <Button color="secondary" className={data.liked > 0 ? "active" : ""} onClick={()=>LikeIt()}>{(request && request.action==="like") && isLoading ? <CircularProgress/>:<>{data.liked > 0 ? <FavoriteIcon/> : <FavoriteBorderIcon/> }<p><ConvertNum num = {data.likes}/></p></>}</Button>  
                            </Tooltip>
                            <Tooltip title={(Number(data.dislikes) > 1 ? data.dislikes + ' people ': Number(data.dislikes) == 1 ? '1 person ' : 'No one ') + 'disliked this comment.'} placement="top">
                                <Button color="secondary" className={data.disliked > 0 ? "active" : ""} onClick={()=>DislikeIt()}>{(request && request.action==="dislike") && isLoading?<CircularProgress/>:<>{data.disliked > 0 ? <ThumbDownAltIcon/> : <ThumbDownOffAltOutlinedIcon/>}<p><ConvertNum num = {data.dislikes}/></p></>}</Button>      
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            {(isMenuOpened && data) &&(
                <CommentReplyMenu setData={setData} setisMenuOpened={setisMenuOpened} data={data}/>
            )}  
            {showLogIn &&(
                <LogginDialog setShowLogIn={setShowLogIn}/>
            )}
         
        </> 
        )}
        
        </>



    )
}

export default Comments
