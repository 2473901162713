import {useState, useEffect, useContext} from 'react'
import {Helmet} from "react-helmet";
import React, {Component} from "react";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigationMenuToggle from '../../Components/Navigation/NavigationMenuToggle'
import {Link} from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import ValidateUser from '../../Components/UserCredentials/ValidateUser';

import { UserStoreContext } from '../../Components/Contexts/UserStoreContext';
export default function Settings () {
    const {is_logged_in, user_store} = useContext(UserStoreContext)
  

    const navigate = useNavigate();
    const[innerWidth,setinnerWidth] = useState(window.innerWidth)
    useEffect(()=>{
        window.scrollTo(0, 0)
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])


    return (
        <>
        <ValidateUser/>
        <Helmet>
            <title>Settings</title>
            <meta property="og:title" content="Settings" />
            <meta name="title" content="Settings"/>
            <meta name="description" content="Settings"/>
            <meta property="og:image" content="/assets/images/geniux_music_logo.png"/>
        </Helmet>
        
        <>
            {innerWidth<992 &&(
                <div className="navbar">
                    <div className="wrapper">
                        <div className="lft">
                            <IconButton className="icon" onClick={()=>navigate(-1)}>
                                <ArrowBackIcon />
                            </IconButton> 
                            <div className="titles">
                                <p>SETTINGS</p>
                            </div>
                        
                        </div>
                        <div className="rgt">
                            <NavigationMenuToggle/>
                        </div>
                    </div>

                </div>    
            )}
            
            <div className="mycontainer">
                {/* My  path */}
                {innerWidth>992 &&(
                    <div className="mypath">
                        <div className="wrapper">
                            <div className="lft">
                                {window.history.length>1?
                                <Button onClick={()=>navigate(-1)}>
                                    <KeyboardBackspaceIcon/>
                                </Button>  
                                :
                                <Button onClick={()=>navigate(-1)} disabled="true">
                                    <KeyboardBackspaceIcon/>
                                </Button>  
                                }
                            </div>
                            <div className="rgt">
                                <p>
                                    <Link to="/settings" className="active">SETTINGS</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="wrapper">
                    <div className="settingscontainer">
                        <div className="row">
                            {is_logged_in && (
                                <>
                                    <div className="col-12 col-lg-6">
                                        <Link to="/profile/manage/account">
                                            <Button className="card09">
                                            <div className="details">
                                                    <p>My Account</p>
                                                    <p>Update your profile and manage your account effortlessly.</p>
                                                    <p className="action">Manage Account</p>
                                                </div>  
                                            </Button>
                                            
                                        </Link>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <Link to="/uploads">
                                            <Button className="card09">
                                                <div className="details">
                                                    <p>My Uploads</p>
                                                    <p>Update and manage your uploads.</p>
                                                    <p className="action">Manage Uploads </p>
                                                </div>
                                            </Button>
                                        </Link>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        
                                        <Link to="/notifications/settings">
                                            <Button className="card09">
                                                <div className="details">
                                                    <p>Notification</p>
                                                    <p>Manually set how you would like to received notifications.</p>
                                                    <p className="action">Manage Notifications</p>
                                                </div>  
                                            </Button>
                                        </Link>   
                                    </div>
                                </>
                            )}

                            {!is_logged_in && (
                                <>
                                    <div className="col-12 col-lg-6">
                                        <Link to="/login">
                                            <Button className="card09">
                                            <div className="details">
                                                    <p>Join us today</p>
                                                    <p>Access and engage with the app's features.</p>
                                                    <p className="action">Login or sign up</p>
                                                </div>  
                                            </Button>
                                            
                                        </Link>
                                    </div>
                                </>
                            )}
                            
                           
                            <div className="col-12 col-lg-6">
                                <Link to="/about">
                                    <Button className="card09">
                                        <div className="details">
                                            <p>About us</p>
                                            <p>Learn more about our platform</p>
                                            <p className="action">Read more </p>
                                        </div>  
                                    </Button>
                                    
                                </Link>   
                            </div>
                            <div className="col-12 col-lg-6">
                                <Link to="/terms-and-conditions">
                                    <Button className="card09">
                                        <div className="details">
                                            <p>Terms and conditions</p>
                                            <p>Review the privacy policies you should be familiar with.</p>
                                            <p className="action">Review policies</p>
                                        </div>
                                    </Button>
                                </Link>  
                            </div>
                            <div className="col-12 col-lg-6">
                                <a href="mailto:music@geniux.co.za?subject=Geniux Music ZA" target="_blank">
                                    <Button className="card09">
                                        <div className="details">
                                            <p>Email us</p>
                                            <p>Feel free to email us, and we’ll get back to you shortly.</p>
                                            <p className="action">Send us message</p>
                                        </div>
                                    </Button>
                                </a>
                            </div>
                            
                            <div className="col-12 col-lg-6">
                                <a href="https://www.geniux.co.za" target='_blank'>
                                    <Button className="card09">
                                        <div className="details">
                                            <p>Developer</p>
                                            <p>Crafted with pride by Geniux.co.za</p>
                                            <p className="action">Visit Geniux ZA</p>
                                        </div>
                                    </Button>
                                </a>  
                            </div>
                            <div className="col-12 col-lg-6">
                                <Button className="card09" onClick={()=>navigate('/logout',{ replace: true })}>
                                    <div className="details">
                                        <p>Logout</p>
                                        <p>Sign out of the app.</p>
                                        <p className="action">Logout here</p>
                                    </div>
                                </Button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
        
        </>
    )
}



