import {useState, useEffect} from 'react'
const useConvertLargeNum = (props) => {
    const[convertedNum, setConvertedNum] = useState(0);
    const[num, setNumber] = useState(0);
    useEffect(()=>{
        setNumber(props.num)
    },[props.num])


    useEffect(()=>{
        if(num){
            if (num >= 1000000000) {
                setConvertedNum((num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G');
            }else if (num >= 1000000) {
                setConvertedNum ((num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M');
            }else if (num >= 1000) {
                setConvertedNum ((num / 1000).toFixed(1).replace(/\.0$/, '') + 'K');
            }else{
                setConvertedNum(num)
            }
        }else{
            setConvertedNum(0);
        }

    },[num])

     return convertedNum
      
       

   
    
  


  

}

export default useConvertLargeNum
