import React from 'react'
const LoadingSkeleton = () => {
    return (
    <>
         <div className="comment-skeleton anime-skeleton">
            <div className="lft">
                <div className="circle"></div>
                <div className="pars">
                    <p></p>
                    <p></p>
                </div>
            </div>
            <div className="body">
                <p></p>
            </div>
            <div className="foot">
                <div></div>
                <div></div>
            </div>
        </div>
        <div className="comment-skeleton anime-skeleton">
            <div className="lft">
                <div className="circle"></div>
                <div className="pars">
                    <p></p>
                    <p></p>
                </div>
            </div>
            <div className="body">
                <p></p>
            </div>
            <div className="foot">
                <div></div>
                <div></div>
            </div>
        </div>
        <div className="comment-skeleton anime-skeleton">
            <div className="lft">
                <div className="circle"></div>
                <div className="pars">
                    <p></p>
                    <p></p>
                </div>
            </div>
            <div className="body">
                <p></p>
            </div>
            <div className="foot">
                <div></div>
                <div></div>
            </div>
        </div>
        <div className="comment-skeleton anime-skeleton">
            <div className="lft">
                <div className="circle"></div>
                <div className="pars">
                    <p></p>
                    <p></p>
                </div>
            </div>
            <div className="body">
                <p></p>
            </div>
            <div className="foot">
                <div></div>
                <div></div>
            </div>
        </div>
    </>
    )
}

export default LoadingSkeleton
