import React, {Component} from "react";
import {useState, useEffect} from 'react'
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import WarningIcon from '@mui/icons-material//Warning';
import SongDeleted from './SongDeleted.js';
import useFetch from '../APIs/DeleteSong.js';
export default function BasicModal(props) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        props.setIsAvailableOnSpotify(false)
    
    }


    useEffect(()=>{
        handleOpen()
    },[])


    const [data, setData] = useState(null);
    const [request,setRequest] = useState(null)
  
  


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
            <div className="myModal fadefx">
                <div className="container">
                    <div className="errIcon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <path id="Path_3" data-name="Path 3" d="M19.1,10.638C15.23,8.341,8.85,8.13,5.157,9.251A1.122,1.122,0,0,1,4.506,7.1C8.745,5.816,15.791,6.065,20.244,8.708a1.122,1.122,0,1,1-1.146,1.93Zm-.126,3.4a.937.937,0,0,1-1.287.308,15.754,15.754,0,0,0-11.958-1.4.935.935,0,1,1-.543-1.79,17.5,17.5,0,0,1,13.48,1.6A.936.936,0,0,1,18.972,14.041ZM17.5,17.308a.746.746,0,0,1-1.028.249C13.657,15.835,10.11,15.446,5.933,16.4A.748.748,0,0,1,5.6,14.942c4.571-1.045,8.492-.6,11.655,1.338A.747.747,0,0,1,17.5,17.308ZM12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Z" fill="#fff"/>
                      </svg>
                    </div>
                        <form className="myform">
                        <div className="errbody">
                            <p><b>{props.title}</b><br/><br/>
                            This track is available on spotify.</p><br/>
                        
                            <a href={props.url} onClick={()=>handleClose()} target="_blank"><Button className="btn" type="button">Play On Spotify</Button></a>
                            <Button onClick={()=>handleClose()} type="button" className="btn closebtn">Close</Button>
                        </div>
                  
                    
                    
                    </form>
                </div>
            </div>
      </Modal>
   
    </>
  );
}

