import React, {Component} from "react";

import {useState,useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom';
import useFetch from '../APIs/FetchGenreStats.js';
import ConvertNum from '../ConvertNumbers/useConvertLargeNum';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
const HomeGenres = () => {
    const navigate = useNavigate()
    const [data, setData] = useState(null);
    const {data:FetchedData,isLoading, error} = useFetch()


    useEffect(()=>{
        if(FetchedData){
          setData(FetchedData)
           
        }
    },[FetchedData])




    const [FetchGenres,setFetchGenres] = useState([
        {
            "id": "16",
            "orderId": "1",
            "genre": "Hiphop"
        },
        {
            "id": "17",
            "orderId": "2",
            "genre": "House"
        },
        {
            "id": "24",
            "orderId": "3",
            "genre": "RNB"
        },
        {
            "id": "3",
            "orderId": "4",
            "genre": "Classical"
        },
        {
            "id": "39",
            "orderId": "5",
            "genre": "Instrumentals"
        },
        {
            "id": "38",
            "orderId": "6",
            "genre": "Other"
        }
        
    ])


    return (
    <div className="genreshome">
    
        {/* <div className="htitle">
            <div className="lft"><p>TOP GENRES</p></div>
        </div> */}
        <div className="row"> 
            <div className="col-6 col-md-6 col-lg-2">
                <Link to="/browse?group=all" className="genrecard fadefx">
                    {/* <img className="img saturate-filter" src="/assets/images/othersgenre.jpg?c=geniux_music_01"/> */}
                    <div className="title">
                        <p>Browse All</p>
                        <p>Weekly listens: <br/>{data?<ConvertNum num = {data[0].listens+data[1].listens+data[2].listens+data[3].listens+data[4].listens}/> :"N/A"}</p>
                    </div>
                    <KeyboardArrowRightRoundedIcon/>
                </Link>
            </div>
            <div className="col-6 col-md-6 col-lg-2">
                <Link  to="/browse?group=songs&genre=hiphop" className="genrecard fadefx">
                        {/* <img className="img" src="/assets/images/hiphopgenre.jpg?c=geniux_music_01"/> */}
                        <div className="title">
                            <p>Hiphop</p>
                            <p>Weekly listens: <br/>{data?<ConvertNum num = {data[0].listens}/>:"N/A"}</p>
                        </div>
                        <KeyboardArrowRightRoundedIcon/>
                </Link>
            </div>
            <div className="col-6 col-md-6 col-lg-2">
                <Link to="/browse?group=songs&genre=house"  className="genrecard">
                    {/* <img className="img saturate-filter" src="/assets/images/housegenre.jpg?c=geniux_music_01"/> */}
                    <div className="title">
                        <p>House</p>
                        <p>Weekly listens: <br/>{data?<ConvertNum num = {data[1].listens}/>:"N/A"}</p>
                    </div>
                    <KeyboardArrowRightRoundedIcon/>

                </Link>
            </div>
            <div className="col-6 col-md-6 col-lg-2">
                <Link to="/browse?group=songs&genre=rnb" className="genrecard fadefx">
                    {/* <img className="img saturate-filter" src="/assets/images/rnbgenre.jpg?c=geniux_music_01"/> */}
                    <div className="title">
                        <p>RNB</p>
                        <p>Weekly listens: <br/>{data?<ConvertNum num = {data[2].listens}/>:"N/A"}</p>
                    </div>
                    <KeyboardArrowRightRoundedIcon/>
                </Link>
            </div>
            <div className="col-6 col-md-6 col-lg-2">
                <Link to="/browse?group=songs&genre=classical" className="genrecard fadefx">
                    {/* <img className="img" src="/assets/images/classicalgenre.jpg?c=geniux_music_01"/> */}
                    <div className="title">
                        <p>Classical</p>
                        <p>Weekly listens: <br/>{data?<ConvertNum num = {data[3].listens}/>:"N/A"}</p>
                    </div>
                    <KeyboardArrowRightRoundedIcon/>
                </Link>
            </div>
            <div className="col-6 col-md-6 col-lg-2">
                <Link to="/browse?group=songs&genre=instrumentals"   className="genrecard fadefx">
                    {/* <img className="img" src="/assets/images/instrumentalsgenre.jpg?c=geniux_music_01"/> */}
                    <div className="title">
                        <p>Instrumentals</p>
                        <p>Weekly listens: <br/>{data?<ConvertNum num = {data[0].listens}/>:"N/A"}</p>
                    </div>
                    <KeyboardArrowRightRoundedIcon/>
                </Link>
                
            </div>
        </div>
        
        {/* {SongsLoading && <IsLoading/>}
        {SongsError && <div>{SongsError}</div>} */}
    

    </div>  
    )
}

export default HomeGenres
