
import {Link} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React, {Component} from "react";
import {useState,useEffect} from 'react';
import useFetch from '../APIs/ResetPassword.js';
import CircularProgress from '@mui/material/CircularProgress';
const RecoverPasswordStepOne = (props) => {

    const [newpassword, setNewPassword] = useState('')
    const [confirmpassword, setConfirmPassword] = useState('')

    
    const [Error,setError] = useState(null)

  

    function handleNewPassword(e){
        if(e.target.value.length<=90){
          setNewPassword(e.target.value)
        }
    }
    function handleConfirmPassword(e){
      if(e.target.value.length<=90){
        setConfirmPassword(e.target.value)
      }
    
  }


    const [data,setData] = useState(null)
    const [request,setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)


    
    useEffect(()=>{
      if(FetchedData){
       setData(FetchedData)
      }
    },[FetchedData])
    useEffect(()=>{
      setError(error)
    },[error]);

    const sendrequest= () =>{
      setRequest(
        {
          'username':props.username_param? props.username_param:"",
          'v_pin':props.v_pin_param?props.v_pin_param:"",
          'newpassword':newpassword,
        }
      )

    }

    const onhandle_submit= (e) =>{
      e.preventDefault()
      setError(null);
      if(props.v_pin_param===''){
        setError("verification_failed");
      }else if(newpassword===''){
        document.getElementById('new_password_').focus();
        setError("new_password_empty");
      }else if(confirmpassword!==newpassword){
        document.getElementById('confirm_password_').focus();
        setError("password_not_match");
      }else{
          sendrequest()
        
        
      }
  
    }

    const [showpass, setShowPass] = useState(false);
    return (
        <>
            <form onSubmit={onhandle_submit} className="myform">

              <div className="fields">
                  <p className="otppar">You can now create new password. <br/>
                  Remember to create strong password that you will remember.</p>
           
                  <div className="passwordfield">
                    <TextField inputProps={{autoComplete: 'new-password'}} autoComplete='off' variant="filled" onChange={handleNewPassword} type={showpass?"text":"password"} id="new_password_" label="CREATE NEW PASSWORD"  value={newpassword}/>
                    {newpassword!=="" &&(
                      <div className="icon" onClick={()=>setShowPass(!showpass)}>
                          {showpass?"HIDE":"SHOW"}
                      </div>
                    )}   
                 
                  </div>
                  
            
                  <TextField inputProps={{autoComplete: 'new-password'}} autoComplete='off' variant="filled" onChange={handleConfirmPassword} id="confirm_password_" type="password" label="CONFIRM PASSWORD"  value={confirmpassword}/>
                    
                      {(isLoading && !error) && <div className="myerr"><CircularProgress/></div>}
                      
                      {(Error==="user_id_empty" && !isLoading) &&(
                          <div className="myerr"><p>Email address is required.</p></div>
                      )}
                      {(Error==="verification_pin_empty" && !isLoading) &&(
                          <div className="myerr"><p>Verification pin is required.</p></div>
                      )}
                      {(Error==="new_password_empty" && !isLoading) &&(
                        <div className="myerr"><p>New password is required.</p></div>
                      )}
                      {(Error==="password_not_match" && !isLoading) &&(
                          <div className="myerr"><p>Password do not match.</p></div>
                      )}
                      {(Error==="network_err" && !isLoading) &&(
                      <div className="myerr"><p>Check your network connectivity.</p></div>
                      )}
                      {(Error==="user_not_found" && !isLoading) &&(
                          <div className="myerr"><p>Email/Username provided is not registered.</p></div>
                      )}
                      {(Error==="verification_failed" && !isLoading) &&(
                          <div className="myerr"><p>Failed, try to request new link. <Link to="/forgot-password" >Request new link</Link></p></div>
                      )}
                      {(Error==="password_is_short" && !isLoading) &&(
                          <div className="myerr"><p>New password should have 6 characters.</p></div>
                      )}
                      {(Error==="unknown_err" && !isLoading) &&(
                          <div className="myerr"><p>Something went wrong, contact developers.</p></div>
                      )}
                                               
                  <div className="btns">
                      <Button type="submit" className="defaultBTN" color="primary">SAVE CHANGES</Button>
                  </div> 
                  
              </div>

            </form> 
        </>
    )
}

export default RecoverPasswordStepOne
