import React, {Component} from "react";
import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import UserCard from '../Cards/UserCard';
import SpotifyUserCard from '../Cards/SpotifyUserCard';
import useFetch from '../APIs/SpotifySearchArtists.js';
import LoadingSkeleton from '../LoadingSkeletons/UserCard03.js';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import { Navigation } from "swiper/modules";

const TopArtists = (props) => {

    //userId,songId,search_string,genre,sort,page,
    
    const [data, setData] = useState(null);
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData){
            setData(FetchedData)
        }
    },[FetchedData])


    const send_request =()=>{
        setData(null)
        setRequest({
            "user_id":"",
            "search_string":props.filters.search_string,
            "role":"artist",
            "sort":"played",
            "page":"",
        })
    }



    useEffect(()=>{
        send_request()
    },[props.filters])

    return (
    <>
    
      {isLoading &&(
        <>
            <section>
                <div className="row">
                    <div className="col-12 col-lg-12">
                 
                        <div className="htitle">
                            <div className="lft"><p>TOP ARTISTS {props.filters.search_string!=="" &&(<>(0)</>)} <span className="platform">SPOTIFY</span></p></div>
                            <div className="rgt"><p><Link to={props.filters.search_string!=''?"/browse?group=people-on-spotify&search="+props.filters.search_string+"&sort=played":"/browse?group=people-on-spotify&sort=played"}>See more</Link></p></div>
                             
                        </div>
                        
                        {props.innerWidth<992 &&(
                            <div className="vertical-container">
                                <LoadingSkeleton/>
                                <LoadingSkeleton/>
                                <LoadingSkeleton/>
                                <LoadingSkeleton/>
                                <LoadingSkeleton/>
                                <LoadingSkeleton/>
                                <LoadingSkeleton/>
                            </div>
                        )}
                        {props.innerWidth>992 &&(
                            <Swiper
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    992: {
                                        slidesPerView: 5,
                                        spaceBetween: 20,
                                    },
                                    
                                    1600: {
                                        slidesPerView: 7,
                                        spaceBetween: 30,
                                    },
                                }}
                                className="mySwiper"
                            >
                                <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            </Swiper>           
                        )}
                    </div>
                </div>
            </section>

        </>
        )}
        {data && data.artists.total>0 &&(
            <>
            <section>
                <div className="row">
                    <div className="col-12 col-lg-12">
                    
                        <div className="htitle">
                            <div className="lft"><p>TOP ARTISTS {props.filters.search_string!=="" &&(<>({data.artists.total})</>)} <span className="platform">SPOTIFY</span></p></div>
                            {data.artists.total > 10 &&(
                                <div className="rgt"><p><Link to={props.filters.search_string!=''?"/browse?group=people-on-spotify&search="+props.filters.search_string:"/browse?group=people-on-spotify"}>See more</Link></p></div>
                            )}
                            
                        </div>
                        {props.innerWidth<992 &&(
                            <div className="vertical-container">
                                {
                                    data.artists.items.slice(0,10).map((users,index)=>{
                                        return <SpotifyUserCard key = {index} data = {users} />
                                    }
                                )}

                            </div>
                        )}
                        {props.innerWidth>992 &&(
                        <Swiper
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    992: {
                                        slidesPerView: 5,
                                        spaceBetween: 20,
                                    },
                                    1600: {
                                        slidesPerView: 7,
                                        spaceBetween: 30,
                                    },
                                }}
                                className="mySwiper"
                            >
                                {
                                    data.artists.items.slice(0,10).map((users,index)=>{
                                        return <SwiperSlide key = {index}><SpotifyUserCard  data = {users} /></SwiperSlide>
                                    }
                                )}
                            
                            </Swiper>           
                        )}
                     </div>
                </div>
            </section>
            </>
        )}
    </>  
    )
}

export default TopArtists
