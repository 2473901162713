import {useState, useEffect, useContext} from 'react'
import {Helmet} from "react-helmet";
import {ViewContext} from '../../../Components/Contexts/ViewContext.js';
import useFetch from '../../../Components/APIs/FetchUsers.js'; 
import {Link,useNavigate,useSearchParams, useParams} from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import NavigationMenuToggle from '../../../Components/Navigation/NavigationMenuToggle.js';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Uploads from '../../../Components/Profile/Browse/Uploads.js';
import MoreSongs from '../../../Components/Profile/Browse/Sub/Songs.js';
import MoreAlbums from '../../../Components/Profile/Browse/Sub/Albums.js';
import Filter from "../../../Components/Profile/Browse/Filter.js"
import IsLoading from '../../../Components/isLoading.js';
import { prominent } from 'color.js';
import ConvertNum from '../../../Components/ConvertNumbers/useConvertLargeNum';
export default function ProfileContainer ({props}) {
    const {setViewUserData} = useContext(ViewContext)

    const navigate = useNavigate();
   
    const[innerWidth,setinnerWidth] = useState(window.innerWidth);
    const [pagescroll, setPageScroll] = useState(0)
    useEffect(()=>{
        window.scrollTo(0, 0)
        window.onscroll = () => {
            setPageScroll(window.pageYOffset)
        }
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])


    const {user_id} = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const group_param = searchParams.get("group")
    const search_param = searchParams.get("search")
    const sort_param = searchParams.get("sort")
    const genre_param = searchParams.get("genre")
    const role_param = searchParams.get("role")


    const [search_string,setSearchString] = useState('');

    useEffect(()=>{
        if(search_param){
            setSearchString(search_param)
        }else{
            setSearchString('')
        }
        
    },[search_param])
   
    
    const [data, setData] = useState(null);
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData){
            if(FetchedData.total_results>0){
              setData(FetchedData)
              setViewUserData(
                {
                    "id": FetchedData.results[0].user_id,
                    "user_id":FetchedData.results[0].user_id,
                    "artist_name": FetchedData.results[0].artist_name,
                    "img_url": "/geniuxmusic/api/media/avatars/smaller/?file_name="+FetchedData.results[0].user_avatar,
                    "platform": "geniuxmusic"
                }
            );
            }
        }
    },[FetchedData])
    
    const send_request =()=>{
        setData(null)
        setRequest({
            "user_id":user_id,
            "search_string":"",
            "role":"",
            "sort":"topfollowed",
            "page":"",
        })
    }

    useEffect(()=>{
        send_request()
    },[user_id])
  

    const onhandle_search_string = (e) =>{
        if(e.target.value.length<50){
            const isGenre = genre_param?"&genre="+genre_param:"";
            const isSort =  sort_param?"&sort="+sort_param:"";
            const isSearch = e.target.value?"&search="+e.target.value.replace(/\s/g, "%20"):"";
            const isRole =  role_param?"&role="+role_param.replace(/\s/g, "%20"):"";

            if(group_param==="all"){
                navigate("/profile/browse/"+user_id+"?group=all"+isSearch+isSort+isGenre+isRole, { replace: true })
                document.title = data.results[0].artist_name + "'s Library";
            }else if(group_param==="songs"){
                navigate("/profile/browse/"+user_id+"?group=songs"+isSearch+isSort+isGenre+isRole, { replace: true })
                document.title = data.results[0].artist_name + "'s Library - Songs";
            }else if(group_param==="albums"){
                navigate("/profile/browse/"+user_id+"?group=albums"+isSearch+isSort+isGenre+isRole, { replace: true })
                document.title = data.results[0].artist_name + "'s Library - Albums";
            }else{
                navigate("/profile/browse/"+user_id+"?group=all"+isSearch+isSort+isGenre+isRole, { replace: true })
                document.title = data.results[0].artist_name + "'s Library";
            }
        }
       
    }
    const [filters,setFilter] = useState({
        "search_string": search_param?search_param:"",
        "user_id": user_id,
        "search_string": search_param?search_param:"",
        "sort": sort_param?sort_param:"",
        "genre": genre_param?genre_param:"",
    });

    useEffect(()=>{
        setFilter({
            "search_string": search_param?search_param:"",
            "user_id": user_id,
            "search_string": search_param?search_param:"",
            "sort": sort_param?sort_param:"",
            "genre": genre_param?genre_param:"",
        })
    },[group_param,search_param,sort_param,genre_param])


    const[isFilterOpened,  setFilterOpened] = useState(false)


    const removeQueryParam = (param)=>{
        if (searchParams.has(param)) {
            const token = searchParams.get(param);
            if (token) {
              searchParams.delete(param);
              setSearchParams(searchParams);
            }
        }
    }
    const[BGColor,setBGColor] = useState(null)
    
    useEffect(()=>{
        if(data && data.total_results>0){
            if(data.results[0].user_avatar.length>2){
                prominent("/geniuxmusic/api/media/avatars/smaller/?file_name="+data.results[0].user_avatar,{format: 'hex',group: 30}).then(colors => {
                    setBGColor(colors[1]) 
                })
                setViewUserData(
                    {
                        "id": data.results[0].user_id,
                        "user_id":data.results[0].user_id,
                        "artist_name": data.results[0].artist_name,
                        "img_url": "/geniuxmusic/api/media/avatars/smaller/?file_name="+data.results[0].user_avatar,
                        "platform": "geniuxmusic"
                    }
                );
            }else{
                setBGColor('#212121') 
            }
          
              
        }else{
            setBGColor('#212121') 
        }
        
    },[data])

    const goto=(d)=>{
        const isSort =  sort_param?"&sort="+sort_param:"";
        const isSearch = search_param?"&search="+search_param.replace(/\s/g, "%20"):"";
        const isGenre = genre_param?"&genre="+genre_param:"";
        const isRole =  role_param?"&role="+role_param.replace(/\s/g, "%20"):"";

        if(d==="All"){
            return "/profile/browse/"+user_id+"?group=all"+isSearch+isSort+isGenre+isRole;
        }else if(d==="Singles"){
            return "/profile/browse/"+user_id+"?group=songs"+isSearch+isSort+isGenre+isRole;
        }else if(d==="Albums"){
            return "/profile/browse/"+user_id+"?group=albums"+isSearch+isSort+isGenre+isRole;
        }else{
            return "/profile/browse/"+user_id+"?group=all"+isSearch+isSort+isGenre+isRole;
        }
    }
    return (
        <>
        {data && data.total_results>0?
        <>
       
            <Helmet>
                <title>{data.results[0].artist_name+"'s Library"}</title>
                <meta property="og:title" content={data.results[0].artist_name+"'s Library"}/>
                <meta name="title" content={data.results[0].artist_name+"'s Library"}/>
                <meta name="description" content={data.results[0].bio.length>1?data.results[0].bio.length<90? data.results[0].bio:data.results[0].bio.substring(0, 90) + "...":"Check out this profile on Geniux Music."}/>
                {data.results[0].user_avatar.length>2?
                <meta property="og:image" content={"/geniuxmusic/data/avatars/"+data.results[0].user_avatar}/>
                :<meta property="og:image" content="/assets/images/geniuxbanner.jpeg"/>
                }
                
            </Helmet>
            {innerWidth<992 &&(
                <div className="navbar" style={{"--userbannerbg": BGColor}}>
                    <div className="wrapper">
                        <div className="lft">
                            <IconButton className="icon" onClick={()=>navigate(-1)}>
                                <ArrowBackIcon />
                            </IconButton> 
                            <div className="titles">
                                <p>PROFILE</p>
                                {pagescroll>80 &&( <p>{data.results[0].artist_name}</p>)}
                            </div>
                            
                        </div>
                        <div className="rgt">
                        {/* {is_logged_in &&
                            <IconButton className="icon" onClick={()=>goto('/settings/notifications')} aria-label="Notification Settings" color="inherit">
                                <SettingsIcon />
                            </IconButton>
                        
                        } */}
                        <NavigationMenuToggle/>
                    
                        </div>  
                    </div>
                    
                </div>
            )}
            <div className="mycontainer">
                <div className="userbanner02">
                    <div className="background" style={{backgroundColor: BGColor}}></div>
                    
                    <div className="user">
                        <div className='wrapper'>
                        <Link to={"/profile/"+user_id}>
                            <div className="lf" onClick={()=>navigate("/profile/"+user_id)}>
                                {data.results[0].user_avatar.length>2?
                                    <img className="ppimg" src={"/geniuxmusic/data/avatars/"+data.results[0].user_avatar}/>
                                :
                                    <div className="ppimg">
                                        {data.results[0].artist_name.substring(0, 1)}
                                    </div>
                                }
                                <div className="details">
                                    <p>{data.results[0].artist_name}</p>
                                    <p>{data.results[0].interest}</p>
                                </div>
                            </div>
                        </Link>

                        <div className="rt">
                            <div className="details">
                                <p onClick={()=>navigate("/profile/browse/"+user_id+"?group=songs")}>Singles <span><ConvertNum num = {data.results[0].songs}/></span></p>
                                <p onClick={()=>navigate("/profile/browse/"+user_id+"?group=albums")}>Albums <span><ConvertNum num = {data.results[0].albums}/></span></p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    
                    <div className="FilterPanel">
                            <div className="searchbar">
                                <SearchIcon/>
                                <input placeholder={"Search "+data.results[0].artist_name}  value={search_param?search_param:""}  onChange={onhandle_search_string}/>
                            </div>
                
                            <div className="filter">
                                <div className="lft">
                                    <Link to={goto("All")}>
                                        <Button color="secondary" className={group_param==="all" || !group_param?"active":""}><p>All</p></Button>
                                    </Link>
                                    <Link to={goto("Singles")}>
                                        <Button color="secondary" className={group_param==="songs"?"active":""}><p>Singles</p></Button>   
                                    </Link>
                                    <Link to={goto("Albums")}>
                                        <Button color="secondary" className={group_param==="albums"?"active":""}><p>Albums</p></Button>
                                    </Link>
                                    
                                </div>
                                <div className="rgt">
                                    {innerWidth<992?<IconButton color="secondary" className="" onClick={()=>setFilterOpened(true)}><MoreVertRoundedIcon/></IconButton>:
                                    <><div className="separator"></div><Button color="secondary" onClick={()=>setFilterOpened(true)}>Filter</Button></>}
                                </div>
                            </div>
                            <div className="keywords">
                                {filters.search_string!==""&&(
                                    <Button color="secondary"><p>SEARCH: {filters.search_string.length>15?filters.search_string.substring(0,15)+"...":filters.search_string}</p><CancelIcon onClick={()=>removeQueryParam('search')}/></Button>
                                )}
                                {filters.sort!==""&&(
                                    <Button color="secondary"><p onClick={()=>setFilterOpened(true)}>SORT: {filters.sort==="played"?"Popular":filters.sort==="random"?"Shuffle":filters.sort}</p><CancelIcon onClick={()=>removeQueryParam('sort')}/></Button>
                                )}
                                {filters.genre!==""&&(
                                    <Button color="secondary"><p>GENRE: {filters.genre}</p><CancelIcon  onClick={()=>removeQueryParam('genre')}/></Button>
                                )}
                            </div>
                    </div>
                    <br/>
                    {group_param=="songs"?
                        <>
                        <Helmet>
                            <title>{data.results[0].artist_name+"'s Library - Songs"}</title>
                            <meta property="og:title" content={data.results[0].artist_name+"'s Library - Songs"}/>
                            <meta name="title" content={data.results[0].artist_name+"'s Library - Songs"}/>
                        </Helmet>
                        {data.results[0].songs<1?
                            <div className='wrapper'>
                                <div className="no_data_msg">
                                    <div className="container">
                                        <div className="icon">
                                            <img src="/assets/images/no_music_found.svg" />
                                        </div>
                                        <p>{data.results[0].artist_name} have no singles</p> 
                                        <Button onClick={()=>navigate(-1)}>
                                            Go Back
                                        </Button>
                                    </div>

                                </div>
                            </div> 
                            :
                            <MoreSongs filters={filters} innerWidth={innerWidth}/>
                            }

                        </>:
                        group_param=="albums"?
                        <>
                            <Helmet>
                                <title>{data.results[0].artist_name+"'s Library - Albums"}</title>
                                <meta property="og:title" content={data.results[0].artist_name+"'s Library - Albums"}/>
                                <meta name="title" content={data.results[0].artist_name+"'s Library - Albums"}/>
                            </Helmet>
                            {data.results[0].albums<1?
                                <div className='wrapper'>
                                    <div className="no_data_msg">
                                        <div className="container">
                                            <div className="icon">
                                                <img src="/assets/images/no_album_results.svg" />
                                            </div>
                                            <p>{data.results[0].artist_name} have no albums</p> 
                                            <Button onClick={()=>navigate(-1)}>
                                                Go Back
                                            </Button>
                                        </div>

                                    </div>
                                </div> 
                            :
                            <MoreAlbums filters={filters} innerWidth={innerWidth}/>
                            }

                        
                        </>:
                        <>
                            <Helmet>
                                <title>{data.results[0].artist_name+"'s Library"}</title>
                                <meta property="og:title" content={data.results[0].artist_name+"'s Library"}/>
                                <meta name="title" content={data.results[0].artist_name+"'s Library"}/>
                            </Helmet>

                            {(data.results[0].songs<1 && data.results[0].albums<1)?
                            <div className='wrapper'>
                                    <div className="no_data_msg">
                                        <div className="container">
                                            <div className="icon">
                                                <img src="/assets/images/no_album_results.svg" />
                                            </div>
                                            <p>{data.results[0].artist_name} have no uploads</p> 
                                            <Button onClick={()=>navigate(-1)}>
                                                Go Back
                                            </Button>
                                        </div>

                                    </div>
                                </div> 
                            :
                            <>
                            <Uploads filters={filters} innerWidth={innerWidth}/>
                            </>}
                        </>
                    }
                    
                    
                </div>
            </div>
            {isFilterOpened && (
                <Filter user_data = {data.results[0]} setFilterOpened={setFilterOpened} filters = {filters} group_param={group_param} />
            )}
            
        </>:
        data && data.total_results<1?
        <>
            <Helmet>
                <title>User not found</title>
                <meta property="og:title" content="User not found" />
                <meta name="title" content="User not found"/>
                <meta name="description" content="Link might be invalid or user has been removed"/>
                <meta property="og:image" content="/assets/images/geniuxbanner.jpeg"/>
            </Helmet>
            {window.innerWidth<992 &&(
                <div className="navbar">
                    <div className="wrapper">
                        <div className="lft">
                            <IconButton className="icon" onClick={()=>navigate(-1)}>
                                <ArrowBackIcon />
                            </IconButton> 
                            <div className="titles">
                                <p>PROFILE</p>
                                {pagescroll> 80 &&(
                                    <>
                                     <p>{user_id}</p>
                                    </>
                                )}
                            </div>
                            
                        </div>
                        <div className="rgt">
                        {/* {is_logged_in &&
                            <IconButton className="icon" onClick={()=>goto('/settings/notifications')} aria-label="Notification Settings" color="inherit">
                                <SettingsIcon />
                            </IconButton>
                        
                        } */}
                        <NavigationMenuToggle/>
                    
                        </div>  
                    </div>
                    
                </div>
            )}
        </>:
        error==="network_err"?
        <>
        <Helmet>
            <title>No Internet</title>
            <meta property="og:title" content="No Internet"/>
            <meta name="title" content="No Internet"/>
            <meta name="description" content="Check your internet connectivity" />
            <meta property="og:image" content="/assets/images/geniuxbanner.jpeg"/>
                
           
        </Helmet>
        {innerWidth<992 &&(
            <div className="navbar">
                <div className="wrapper">
                    <div className="lft">
                        <IconButton className="icon" onClick={()=>navigate(-1)}>
                            <ArrowBackIcon />
                        </IconButton> 
                    
                        <div className="titles">
                            <p>Browse</p>
                            {/* <p>{user_id}</p> */}
                        </div>
                    
        
                    </div>
                    <div className="rgt">
                        <NavigationMenuToggle/>
                    </div>
                </div>
            </div>
        )}
        <div className="mycontainer">
            {innerWidth>992 &&(
                <div className="mypath">
                    <div className="wrapper ">
                        <div className="lft">
                        {window.history.length>1?
                            <Button onClick={()=>navigate(-1)}>
                            <KeyboardBackspaceIcon/>
                            </Button>  
                            :
                            <Button onClick={()=>navigate(-1)} disabled="true">
                                <KeyboardBackspaceIcon/>
                            </Button>  
                        }
                        </div>
                        <div className="rgt">
                            <p>
                                <Link to="" className="active">Browse</Link>
                                <Link to="" className="">/ {user_id}</Link>
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {(!isLoading) &&(
                <div className='wrapper'>
                    <div className="no_data_msg">
                        <div className="container">
                            <div className="icon">
                                <img src="/assets/images/no_user_found.svg" />
                            </div>
                            <p>Check your internet connectivity</p> 
                            <Button onClick={()=>send_request()}>
                                Retry
                            </Button>
                        </div>
                    </div>
                </div> 
            )}  
        </div>
        </>:
        isLoading?
        <>
        {innerWidth<992 &&(
            <div className="navbar">
                <div className="wrapper">
                    <div className="lft">
                        <IconButton className="icon" onClick={()=>navigate(-1)}>
                            <ArrowBackIcon />
                        </IconButton> 
                    
                        <div className="titles">
                            <p>Profile</p>
                            {/* {pagescroll> 80 &&(<p>{user_id}</p>)} */}
                        </div>
                    
        
                    </div>
                    <div className="rgt">
                        <NavigationMenuToggle/>
                    </div>
                </div>
            </div>
        )}
        <div className="mycontainer">
            {innerWidth>992 &&(
                <div className="mypath">
                    <div className="wrapper ">
                        <div className="lft">
                        {window.history.length>1?
                            <Button onClick={()=>navigate(-1)}>
                            <KeyboardBackspaceIcon/>
                            </Button>  
                            :
                            <Button onClick={()=>navigate(-1)} disabled="true">
                                <KeyboardBackspaceIcon/>
                            </Button>  
                        }
                        </div>
                        <div className="rgt">
                            <p>
                                <Link to="" className="active">Browse</Link>
                                <Link to="" className="">/ {user_id}</Link>
                            </p>
                        </div>
                    </div>
                </div>
            )}
          
            <div className='wrapper'>
                <IsLoading/>
            </div> 
            
        </div>
        </>:<></>
        }
        </>
    )
}

