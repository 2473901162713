import React, { useEffect } from 'react';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import {useNavigate} from 'react-router-dom';
const AppUrlListener: React.FC<any> = () => {
    const navigate = useNavigate()
    useEffect(() => {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split('https://music.geniux.co.za').pop();
        if (slug) {
            navigate(slug)
        }else{
           navigate('/')
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    }, []);


  
    return null;
  };
  
  export default AppUrlListener;