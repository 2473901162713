
import {useState, useEffect, createContext} from 'react';
import React, {Component} from "react";

import { EncryptStorage } from 'encrypt-storage';

export const UserStoreContext = createContext();

export const encryptStorage = new EncryptStorage('secret-key-value', {
    doNotParseValues: true,
});


export function UserStoreProvider ({children})  {

    const [is_logged_in,set_is_logged_in] = useState(null);
    const [user_store, set_user_store] = useState(null);

    useEffect(()=>{
        try {
            if(encryptStorage.getItem('user_store')){
                const data = JSON.parse(encryptStorage.getItem('user_store'));
               
                if(data.user_id && data.user_token){
                    set_is_logged_in(true)
                    set_user_store(data)
                }else{
                    set_is_logged_in(false)
                    set_user_store(null)
                }
            }else{
                set_is_logged_in(false)
                set_user_store(null)
            }
        } catch (e) {
            localStorage.removeItem('user_store')
        }
        
    },[])

    useEffect(()=>{
        if(user_store){
            const data = JSON.stringify(user_store);
            encryptStorage.setItem('user_store', data);
        }
    },[user_store])

    return (
        <UserStoreContext.Provider value={{is_logged_in,set_is_logged_in, user_store, set_user_store}}>
               {children} 
        </UserStoreContext.Provider>
    )
}

