import React, {Component} from "react";
import {useState,useEffect } from 'react';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';

const ImageCrop = (props) => {
      const [fileList, setFileList] = useState([
        // {
        //   uid: '-1',
        //   url: defaultdisk,
        // },
      ]);

      useEffect(()=>{
        if(props.data){
          setFileList([]);
        }
      },[props.data])

    
      const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
      };
    
      const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
          src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
  
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);

      };
      useEffect(() => {
     
        if(fileList.length>0){
         
          props.setArtCover(fileList[0])
        }else{
          props.setArtCover(null)
        }
        
      
      }, [fileList])
    
    return (
        <ImgCrop showGrid modalTitle="Crop Image">
            <Upload
            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
            >
            {fileList.length < 1 && '+ ADD IMAGE'}
            </Upload>
        </ImgCrop>
    )
}

export default ImageCrop
