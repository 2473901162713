import React, {Component} from "react";
import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import useFetch from '../APIs/SpotifyGetArtistTopTracks.js';
import LoadingSkeleton from '../LoadingSkeletons/card005.js';
import TrackCard from '../Cards/Spotifycard005.js'

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper/modules";
const Songs = (props) => {
    
    //userId,songId,search_string,genre,sort,page,
    
    const [data, setData] = useState(null);
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData){
            setData(FetchedData)
        }
    },[FetchedData])


    const send_request =()=>{
        setData(null)
        setRequest({
            "user_id":props.data.id,
            "song_id":"",
            "search_string":"",
            "genre":"",
            "sort":"played",
            "type":"",
            "page":"",
        })
    }



    useEffect(()=>{
        send_request()
    },[])

   
    return (
        <>
            {data && data.tracks.length>0 &&(
                <>
                <div className="col-12 col-lg-6">
                    <section>
                        <div className="htitle">
                            <div className="lft"><p>Popular </p></div>
                            {data.tracks.length > 3 &&(
                                <div className="rgt"><p><Link to={"/spotify/profile/browse/"+props.data.id+"?group=songs&sort=played"}>See more</Link></p></div>
                            )}
                            
                        </div>
                    
                        {data.tracks.slice(0,3).map((song,index)=>{
                                return <TrackCard key={index} data={song} Data={data.tracks} index={index}/>
                            }
                        )}
                    </section>
                </div>
                </>
            )}
            {isLoading &&(
                <>
                 <div className="col-12 col-lg-6">
                    <section>
                    <div className="htitle">
                        <div className="lft"><p>SINGLES</p></div>
                    </div>
                    
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    </section>
                </div>
                </>
            )}
        </>
    )
}

export default Songs
