import React from 'react'
const LoadingSkeleton = () => {
    return (
    <>
   
        <div className="noticard-skeleton anime-skeleton">
            <div className="icon"></div>
            <div className="details">
                <p></p>
                <p></p>
            </div>
        </div>
        
       
    </>
    )
}

export default LoadingSkeleton
