import {useState, useEffect, useContext} from 'react'
import { UserStoreContext } from '../Contexts/UserStoreContext';
import Axios from 'axios';
import {useNavigate} from 'react-router-dom'; 
import ENVIROMENTS from '../Environment/Configuration.json';

const useFetch = (request) => {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null); 
    

    const[UploadPercentage, setUploadPercentage] = useState(null); 

    const {is_logged_in, set_is_logged_in,set_user_store, user_store} = useContext(UserStoreContext)
    




    useEffect(()=>{ 
        
        setIsLoading(true)
        setError(null)
        if(is_logged_in && request){
     
            const url = ENVIROMENTS.baseUrl + '/geniuxmusic/api/upload_song.php';
            var formData = new FormData()
            formData.append("songfile", request.songfile)
            if(request.artcover){
                formData.append("artcover", request.artcover.originFileObj)
            }
   
            formData.append('song_title', request.song_title)
            formData.append('genre', request.genre)
            formData.append('description', request.description)
            formData.append('lyrics', request.lyrics)
            formData.append('myuserid', user_store.user_id)
            formData.append('mytoken', user_store.user_token)
 
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: ProgressEvent =>{
                    setUploadPercentage(
                        parseInt(
                            Math.round((ProgressEvent.loaded * 100)/ProgressEvent.total)
                            )
                    )
                    // 
                    //     setUploadPercentage(null)
                    // },[10000])
                },
                
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                if(response.data.length>0 && response.data[0].song_id){
                     navigate('/uploads/upload/single/complete/'+response.data[0].song_id)
                }else{
                    if(response.data && response.data.response==="song_title_empty"){
                        setError('song_title_empty'); 
                    }else if(response.data && response.data.response==="song_title_too_long"){
                        setError('song_title_too_long'); 
                    }else if(response.data && response.data.response==="user_not_found"){
                        setError('user_not_found'); 
                        set_is_logged_in(false)
                        set_user_store(null)
                    }else if(response.data && response.data.response==="credentials_not_provided"){
                        setError('credentials_not_provided'); 
                        set_is_logged_in(false)
                        set_user_store(null)
                    }else if(response.data && response.data.response==="song_file_not_attached"){
                        setError('song_file_not_attached'); 
                    }else if(response.data && response.data.response==="song_file_format_error"){
                        setError('song_file_format_error'); 
                    }else if(response.data && response.data.response==="song_file_error_occured"){
                        setError('song_file_error_occured'); 
                    }else if(response.data && response.data.response==="song_file_file_is_huge"){
                        setError('song_file_file_is_huge'); 
                    }else if(response.data && response.data.response==="failed_to_save_file"){
                        setError('failed_to_save_file'); 
                    }else if(response.data && response.data.response==="failed_to_query"){
                        setError('failed_to_query'); 
                    }else{
                        setError('unknown_error'); 
                    }
                    
                    setData(null)
                    setIsLoading(false)
                    setUploadPercentage(null)
                }
           
               
            })
            .catch(err=>{
                if(!Axios.isCancel(err)){
                    setError('network_err');
                    setIsLoading(false)
                    setUploadPercentage(null)
                }
            })
            return () => cancel()
        }else{
            setIsLoading(false)
        }
    
    
    },[is_logged_in,request])
 
    return {data, isLoading, error, UploadPercentage}
}

export default useFetch
