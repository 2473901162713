import React, {Component} from "react";
import {useState,useEffect,useContext} from 'react'
import Button from '@mui/material/Button';
import useFetch from '../APIs/SpotifyGetAlbum.js';
import {Link  }  from'react-router-dom';
import TrackCard from '../Cards/SpotifyAlbumCard005.js';
import LoadingSkeleton from '../LoadingSkeletons/AlbumDetails.js';
import DownloadZip from './DownloadZip.js';
import ShareLink from '../ShareLink.js'
import ViewImageModal from '../viewImageModal.js'
import TimeAgo from 'timeago-react'; 
const MoreFromAlbum = (props) => {

    const[page, setPage] = useState(1) 


   




    //Fetch Album Details
    const[data,setData] = useState(null)
    const[request,setRequest] = useState(null)
    const {data:FetchedData,isLoading, error, hasmore} = useFetch(request)

    const send_request = ()=>{
        setRequest({
            "album_id": props.data.album.id,
            "sort":"newest",
            "page": page,
        })
    }
    useEffect(()=>{
        if(props.data){
            send_request()
        }
    },[props.data.album.id])


    useEffect(()=>{
        if(FetchedData){
            setData(FetchedData)
           
        }
    },[FetchedData])

 
    const[isMenuOpened,setisMenuOpened] = useState(false)
 
    
    const [isViewingImage,setIsViewingImage] = useState(false);


    return (
        <>
        
        
       {data &&(
        <>
        <div className="albumdetails"> 
 
        
         <div className="htitle"><div className="lft"><p>ALBUM DETAILS</p></div></div>
            <div className="albumactions">
                <img src={(data.images.length>0 && data.images[0])?data.images[0].url:"/assets/images/defaultsongart.jpg"} onClick={()=>setIsViewingImage(true)}/>
                <div className="btns">
                    <Button onClick={()=>setisMenuOpened(true)} variant="contained" color="primary" >Share Link</Button>
                    <Button variant="contained" color="primary" >  <a href={data.external_urls.spotify} target="_blank">Play on spotify</a></Button>
                   
                </div>
            </div>
            <div className="songdetails">
           
                <div className="par">
                    <p>Album Title:</p>
                    <p>{data.name}</p>
                </div>
                {
                    data.genres.length>0 &&(
                        <div className="par">
                        <p>Genres:</p>
                        <p>
                        {
                            data.genres.map((genre,index)=>{
                                    return <>{genre}{(index+1)<data.artists.length?",":""}&nbsp;</>
                                
                            })
                        }
                        </p>
                    </div>
                    )
                }

                <div className="par">
                    <p>Artist{data.artists.length>1?"s":""}:</p>
                    <p>
                    {
                        data.artists.map((artist,index)=>{
                                return <Link className="active" to={"/spotify/profile/"+artist.id}>{artist.name}{(index+1)<data.artists.length?",":""}&nbsp;</Link>
                            
                        })
                        
                    }
                    </p>
                </div>
                <div className="par">
                    <p>Tracks:</p>
                    <p>{data.total_tracks}</p>
                </div>
                <div className="par">
                    <p>Popularity:</p>
                    <p className="">{data.popularity}%</p>
                </div>
                <div className="par">
                    <p>Date Released:</p>
                    <p>{data.release_date}</p>
                </div>
                <div className="par">
                    <p>Label:</p>
                    <p>{data.label}</p>
                </div>
                {props.innerWidth<992 &&(
                    <div className="par">
                        <p>Description:</p>
                        <p>
                            This was published&nbsp;<TimeAgo datetime={data.release_date} />&nbsp;on&nbsp;<a className="active" href={data.external_urls.spotify} target="_blank">Spotify.</a>
                        </p>
                    </div>
                )}
                {data.copyrights.length>0 &&(
                    <div className="par">
                        <p>Copyrights:</p>
                        <p>
                            {
                                data.copyrights.map((copyright,index)=>{
                                        return <>{copyright.text}{(index+1)<data.copyrights.length?",":""}&nbsp;</>
                                    
                                })
                            }
                        </p>
                    </div>
                )}
                
                
            </div>
            <div className="moretracks">
            
            {data.tracks.items.map((songs,index)=>( 
                    <TrackCard key={index} data={songs} Data={data.tracks.items} album_info={data} index={index}/>
                ))
            }
            </div>
        </div>
        <ViewImageModal isViewingImage={isViewingImage} setIsViewingImage={setIsViewingImage} data={data} type="spotify-artcover"/>
        {(isMenuOpened && data) &&(
            <ShareLink setisMenuOpened={setisMenuOpened} data={data} type="Spotify Album"/>
        )}
        </>
        
       )}
       {isLoading &&(
            <div className="albumdetails"> 
                <div className="htitle"><div className="lft"><p>ALBUM DETAILS</p></div></div>
                <LoadingSkeleton/>
            </div>
        )}
       {(error=="network_err") &&(
         <div className="albumdetails"> 
            <div className="htitle"><div className="lft"><p>ALBUM DETAILS</p></div></div>
                <div className="no_data_msg">
                    <div className="container">
                        <div className="icon">
                            <img src="/assets/images/no_album_results.svg" />
                        </div>
                        <p>Check your internet connectivity</p> 
                        <Button onClick={()=>send_request()}>
                            Retry
                        </Button>
                    </div>
                </div>
            </div>
        )} 
        {(error=="no_results") &&(
            <div className="albumdetails"> 
                <div className="htitle"><div className="lft"><p>ALBUM DETAILS</p></div></div>
                <div className="no_data_msg">
                    <div className="container">
                        <div className="icon">
                            <img src="/assets/images/no_album_results.svg" />
                        </div>
                        <p>No results, link might broken or is been removed</p> 
                        <Button onClick={()=>send_request()}>
                            Retry
                        </Button>
                    </div>

                </div>
            </div>
        )}
        
        
       
            
      
    </>
    )
}

export default MoreFromAlbum
