import React from 'react'
import ReactDOM from 'react-dom/client';
import './Assets/Styles/index.css'
import './Assets/Styles/colors.css'
import './Assets/Styles/bootstrap.grid.css'
import App from './App';
import { createTheme,ThemeProvider } from '@mui/material';
const theme = createTheme({
    palette:{
        primary: {
            light: '#df1233',
            main: '#df1233',
            dark: '#ad0923',
            contrastText: '#fff',
          },
          secondary: {
            light: '#A5A1A1',
            main: '#A5A1A1',
            dark: '#000000',
            contrastText: '#fff',
          },
    }
})

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}><App/></ThemeProvider>
  </React.StrictMode>
);

// const container = document.getElementById('app');
// const root = hydrateRoot(container, <ThemeProvider theme={theme}><App/></ThemeProvider>);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
