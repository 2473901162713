
import {useState, useEffect, createContext} from 'react';
import React, {Component} from "react";
export const ViewContext = createContext()
export function ViewProvider ({children})  {

    // Play Id
    const [ViewUserData,setViewUserData] = useState(null);
    const [ViewSongData,setViewSongData] = useState(null);



    return (
        <ViewContext.Provider value={{ViewUserData,setViewUserData, ViewSongData, setViewSongData}}>
               {children} 
        </ViewContext.Provider>
    )
}

