import React, {Component} from "react";
import {useState,useEffect} from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ErrorIcon from '@mui/icons-material/Error';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageCrop from './ImageCrop.js';
import Button from '@mui/material/Button';
import {Link, useNavigate  }  from'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import useFetch from '../APIs/UploadAlbum.js';
import NoInternet from '../Dialogs/NoInternet.js';
import UploadingProgress from './UploadingProgress.js';
import NavigationMenuToggle from '../Navigation/NavigationMenuToggle.js'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
export default function UploadAlbum(props) {
    useEffect(()=>{
        window.scrollTo(0, 0)
        document.body.style.overflow = "unset"
        document.title = "Upload Music - Album";
    },[])
    const navigate = useNavigate()
   
    const [FetchGenres, setFetchGenres] = useState([{
        "id": "16",
        "orderId": "1",
        "genre": "Hiphop"
    },
    {
        "id": "17",
        "orderId": "2",
        "genre": "House"
    },
    {
        "id": "24",
        "orderId": "3",
        "genre": "RNB"
    },
    {
        "id": "3",
        "orderId": "4",
        "genre": "Classical"
    },
    {
        "id": "39",
        "orderId": "5",
        "genre": "Instrumentals"
    },
    {
        "id": "38",
        "orderId": "6",
        "genre": "Others"
    }])

    //Convert Bytes
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }






    //Song Art Cover
    const [artcover, setArtCover] = useState(null);
    const [songs, setSongs] = useState([]);



    const [album_title, setAlbumTitle] = useState('');
    const [genre, setGenre] = useState('');
    const [description, setDescription] = useState('');

 
    function handleAlbumTitle(e){
        if(e.target.value.length<=90){
            setAlbumTitle(e.target.value)
        }
    
    }




    // Update song titles
  
    const handleFile =(e)=>{
        setMyError(null)
        if(e.target.files){
           const Files = Array.from(e.target.files)
            setSongs([...songs, ...Files])
        }
        e.target.value = "";
    }

    
    const EditSongName =(name, index)=>{
        if(name.length<=90){
            const newState = [...songs];
            newState[index] = new File([songs[index]], name, {
                type: songs[index].type,
                lastModified: songs[index].lastModified,
            });
    
            setSongs(newState);
        }
       
        
        
    }
    const removeSong = (index) =>{
        const newState = [...songs];
        newState.splice(index, 1); 
        setSongs(newState);
    }   


    //POST DATA
    const [myError, setMyError] = useState(null);
    const [data, setData] = useState(null);
    const [request,setRequest] = useState(null)
    const [UploadProgressValue,setUploadProgressValue] = useState(null)
    const {data:FetchedData,isLoading, error,UploadPercentage} = useFetch(request)



    useEffect(() => {
        if(UploadPercentage){
            setUploadProgressValue(UploadPercentage)
        }
    }, [UploadPercentage])
    


    const sendrequest = () =>{
        setData(null)
        setUploadProgressValue(null)
        setRequest({
          'album_title':album_title,
          'genre':genre,
          'description':description,
          'songs':songs,
          'artcover':artcover,
        })
      }


    const onhandle_submit= (e) =>{
        e.preventDefault()
        setMyError(null)
       
        if(album_title===""){
            document.getElementById('album_title').focus()
        }else if(genre===""){
            setMyError('genre_not_selected');
            window.scrollTo({top: 0, behavior: 'smooth'});
        }else if(songs.length<1){
            setMyError('files_not_attached');
        }else if(songs.find(o => o.type !== 'audio/mpeg')){
            const invalidFile = songs.find(o => o.type !== 'audio/mpeg')
            document.getElementById("song"+invalidFile.size).classList.add("active");
        }else if(songs.find(o => o.name === '')){
            const invalidFile = songs.find(o => o.name === '')
            document.querySelector("#song"+invalidFile.size+" input").focus();
        }else if(songs.find(o => o.size > 100000000)){
            const invalidFile = songs.find(o => o.size > 100000000)
            document.getElementById("song"+invalidFile.size).classList.add("active");
        }else{
            sendrequest()
        }
        
    
    }



  

  return (
    <>
    {props.innerWidth<992&&(
    <div className="navbar">
        <div className="wrapper">
            <div className="lft">
                <IconButton className="icon"  onClick={()=> navigate('/uploads/upload')}>
                    <ArrowBackIcon />
                </IconButton> 
                <div className="titles">
                    <p>UPLOAD ALBUM</p>
                </div>
            
            </div>
            <div className="rgt">
                <NavigationMenuToggle/>
            </div>
        </div>
    </div>
    )}
    <div className="mycontainer">
        {props.innerWidth>992 &&(
          <div className="mypath">
              <div className="wrapper">
                <div className="lft">
                  {window.history.length>1?
                    <Button onClick={()=>navigate(-1)}>
                      <KeyboardBackspaceIcon/>
                    </Button>  
                    :
                    <Button disabled="true">
                        <KeyboardBackspaceIcon/>
                    </Button>  
                  }
                </div>
                <div className="rgt">
                  <p>
                    <Link to="/uploads/upload" className="active">Upload Music</Link>
                    <Link to="/uploads/upload/album" className="">/ Album</Link>
                  </p>
                </div>
            </div>
          </div>
        )}
        <div className="wrapper">
            <div className="uploadmusic">
                <form noValidate autoComplete="off" className="myform" onSubmit={onhandle_submit} >
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <div className="sections">
                                {/* <div className="uploadstitle">You are uploading an Album</div>
                                */}
                                <div className="uploadcover">
                                    <div className="imgcrop">
                                        <ImageCrop data = {data} setArtCover={setArtCover}/>
                                    </div>
                                    <div className="titles">
                                        <p>Choose</p>
                                        <p>Album Art Cover</p>
                                    </div>
                                </div> 
                            
                            </div>
                            <div className="fields"> 
                                <TextField variant="filled" id="album_title" label="Album Title"  onChange={handleAlbumTitle}  value={album_title}/>
                                {myError==="genre_not_selected"?<p className="mylabel error">Select Genre <div className="icon"><ErrorIcon/></div></p>:
                                <p className="mylabel">Select Genre </p>
                                }
                                <div className="selectoptions">
                                    {FetchGenres.map((data,index)=>(
                                        <p key={index} onClick={()=>setGenre(data.genre)} className={genre===data.genre?"active":""}>{data.genre}</p>
                                    ))}
                                </div>
                            </div>
                            <hr/>
                            <div className="sections">
                                
                        
                                {songs.map((data,index)=>(
                                    <div className="trackupload" key={index} id={"song"+data.size} >
                                        <div className={data.name.length>0?"upload":"upload active"}>
                                            <p className="num">{index + 1}</p>
                                            <input type="text" placeholder="Song Title" onChange={(e)=>EditSongName(e.target.value, index)} value={data.name.slice(0, 90).replaceAll(".mp3","")}/>
                                        </div>
                                        <div className="uploaded">
                                            <div className="lft">
                                                <span className="filename">File Size: {formatBytes(data.size)}</span>
                                                {data.size>100000000?<span className="error">File huge</span>:""}
                                                {data.type!=="audio/mpeg"?<span className="error">Unsupported File</span>:""}
                                          
                                            </div>
                                            <div className="rgt">
                                                <Button onClick={()=>removeSong(index)}><DeleteIcon/></Button>     
                                            </div>
                                            
                                        </div>
                                    </div>
                               
                                ))}
                               
                                
                            </div>
                            {myError==="files_not_attached"?<p className="mylabel error">Add Tracks To Your Album <div className="icon">Required<ErrorIcon/></div> </p>:
                            <p className="mylabel">Add Tracks To Your Album</p>}
                                <Button color="secondary"   className={(myError==="files_not_attached" || myError==="files_not_allowed" )?"upload-file active":"upload-file"}>
                                    <label htmlFor="UploadInput">
                                    <div className="lft">
                                        <p>Add Tracks +</p>
                                    </div>
                                    <div className="rgt">
                                    <p>Click to upload (Audio/MP3)</p>
                                    </div>
                                    <input type="file" accept=".mp3,audio/*" id="UploadInput" multiple onChange={handleFile}/>
                                    </label>
                                </Button>
                        
                            <div className="myerr">  
                            
                                {(error==="album_title_is_required" && !isLoading) &&(
                                    <p>Album title is required</p>
                                )}
                                {(error==="user_not_found" && !isLoading) &&(
                                    <p>Something went wrong, try to logout and login again</p>
                                )}
                                {(error==="songs_not_attached" && !isLoading) &&(
                                    <p>Songs are not attached</p>
                                )}
                                {(error==="credentials_not_provided" && !isLoading) &&(
                                    <p>Something went wrong, try to logout and login again</p>
                                )}
                                {(error==="failed_to_query" && !isLoading) &&(
                                    <p>Something went wrong, contact developers</p>
                                )}
                                {(error==="unknown_error" && !isLoading) &&(
                                    <p>Something went wrong, contact developers</p>
                                )}
                                {(error==="network_err" && !isLoading) &&(
                                    <p>Check your network connectivity</p>
                                )}
                                {myError==="songs_are_less" && !error && !isLoading &&(
                                    <p>Songs are less, an album requires minimum of 3 Tracks</p>
                                )}
                                {myError==="fields_required" && !error && !isLoading &&(
                                    <p>Title is missing</p>
                                )}
                                {error==="files_contains_error" && !isLoading &&(
                                    <p>Your files have issues</p>
                                )}

                            </div>  
                            {(FetchedData && FetchedData.response==="files_contains_error") &&(
                                <>
                                {(FetchedData && FetchedData.tracks) &&(
                                        FetchedData.tracks.map((data,index)=>( 
                                            <div key={index} className="card006">
                                                <div className="lft">
                                                    <p>{data.title}</p>
                                                    <p>
                                                        {data.status==="format_err"?"Format not allowed":
                                                            data.status==="unsupported_err"?"Unsupported":
                                                            data.status==="file_huge_err"?"File is huge (Max 200MB)":
                                                            data.status==="server_err"?"Server issue":
                                                            data.status==="server_err"?"No error":
                                                            ""
                                                        }
                                                    </p>
                                                </div>
                                                <div className="rgt">
                                                    {data.status==="success"?<CheckCircleOutlineIcon/>:<ErrorIcon className="error"/>}\
                                                </div>
                                            </div>
                                        ))
                                        
                                    )}

                                
                                </>
                            )}

                            
                            <div className="btns">
                                <Button  type="submit" className="defaultBTN" color="primary">UPLOAD ALBUM</Button>
                            </div> 
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="rightmenu">
                                <div className="content-container">
                                    <div className="htitle">
                                        <div className="lft"><p>YOUR MUSIC, MATERIALS AND INFORMATION</p></div>
                                    </div>
                                    <p>
                                    When you upload your Recordings through our Services, you are asked to submit Metadata as well as cover artwork for use on the Stores. You submit the Recordings, cover artwork and any other information and material (jointly “Material”) at your own expense and in formats required for use on the Stores.
                                    <br/><br/>
                                    You are fully responsible for everything you submit to us. If we find it unsuitable, we reserve the right to, in our sole discretion, remove the information and/or prevent you from using our Services and/or any or all Stores.
                                    <br/><br/>
                                    You submit the Recordings to us in a pre-agreed format and for a pre-agreed release date. The release date will be locked and you may not change it.
                                    <br/><br/>
                                    </p>
                                    <div className="htitle">
                                        <div className="lft"><p>Grands of rights</p></div>
                                    </div>
                                    <p>
                                    You grant us all necessary consents and rights, without limitation, to sell and make use of your recordings for digital downloads, interactive and non-interactive streaming, cloud services and streaming-on-demand services. This grant of rights does not, however, constitute a transfer of ownership.
                                    <br/>
                                    <br/>
                                    <b>By using our Services you grant us, during the Term and throughout the Territory,</b><br/><br/>
                                        The non-exclusive right to use your className, photographs, likenesses, cover artwork, biographical and other information attributable to you, which you have submitted to Geniux Music.<br/><br/>
                                        The right for Geniux Music to sublicense or otherwise transfer the above rights to any and all Stores.<br/><br/>
                                        The right for Geniux Music to perform Metadata corrections (where necessary).<br/><br/>
                                        The right for Geniux Music to synchronize and authorize others to synchronize your Recordings with visual images, to combine portions of your Recordings with still or moving images or as a live stream.<br/>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>      
                </form>
                
            </div>
        </div>
    </div>
        {isLoading &&(
            <UploadingProgress UploadProgressValue = {UploadProgressValue} artcover={artcover}/>
        
        )}
        {/* {error==="network_err" &&(
            <NoInternet setError={setError} closeModal={closeModal} handleError={handleError}/>
            
        )} */}

    </>
  );
}

