import {useState, useEffect, useContext} from 'react'
import { UserStoreContext } from '../Contexts/UserStoreContext';
import { Browser } from '@capacitor/browser';
import Axios from 'axios';
import ENVIROMENTS from '../Environment/Configuration.json';

const useFetch = (request) => {
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null);
    
    
    const {is_logged_in, set_is_logged_in,set_user_store, user_store} = useContext(UserStoreContext)
    

    useEffect(()=>{ 
        setError(null)
        setIsLoading(true)
        if(request){
                const url = ENVIROMENTS.baseUrl + '/geniuxmusic/api/like_dislike_download.php';
                let cancel
                Axios({
                    method: 'post',
                    url: url,
                    data: {
                        songId: request.song_id,
                        action: request.action,
                        myuserid: is_logged_in?user_store.user_id:"",
                        mytoken: is_logged_in?user_store.user_token:"",
                    },
                    cancelToken: new Axios.CancelToken(c => cancel = c),

                })
                
                .then(response=>{
                    setIsLoading(false)
                    if(response.data && response.data.response==="success"){
                        setData(response.data)
                        
                    }else{
                        if(response.data.response){
                            if(response.data.response==="user_not_found" || response.data.response==="credentials_not_provided" ){
                                set_is_logged_in(false)
                                set_user_store(null)
                            }
                        }
                        setIsLoading(true)
                        setData(null)
                        setError('unknown_err');  
                    }
            
                
                })
                .catch(err=>{
                    if(!Axios.isCancel(err)){
                        setError('network_err');
                        setIsLoading(false)
                    }  
                })
                return () => cancel()
           
        }else{
            setIsLoading(false)
        }
    
    
    },[request])
 
    return {data, isLoading, error}
}

export default useFetch
