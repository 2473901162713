import React, {Component} from "react";
import {useState} from 'react'
import Button from '@mui/material/Button';

import useFetch from './APIs/DownloadAlbum.js';

const DownloadZip = (props) => {

    

    //Fetch Album Details
    const [request, setRequest] = useState(null)


    const {data:FetchedData,isLoading, error} = useFetch(request)


    const download = () =>{
           
        if(props.album_id){
            setRequest({
                'album_id':props.album_id
            })

        }


        
    }
    


    return (
        <>
             <Button variant="contained" onClick={()=>download()} color="primary" >{isLoading?"Downloading...":"Download Zip"} </Button>
        </>
    )
}

export default DownloadZip
