import {useState, useEffect, useContext} from 'react'
import { UserStoreContext } from '../Contexts/UserStoreContext';
import Axios from 'axios';
import ENVIROMENTS from '../Environment/Configuration.json';
const useFetch = (request) => {
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null);
    
    const {is_logged_in, set_is_logged_in,set_user_store, user_store} = useContext(UserStoreContext)
    

    useEffect(()=>{ 
        setError(null);  
        setIsLoading(true)
        if(request){
                const url = ENVIROMENTS.baseUrl + '/geniuxmusic/api/fetch_liked_songs.php';
                //const url = 'api/fetch_songs.php';
                let cancel
                Axios({
                    method: 'post',
                    url: url,
                    data: {
                        user_id: request.user_id,
                        myuserid: is_logged_in?user_store.user_id : "",
                        mytoken: is_logged_in?user_store.user_token : "",
                        sort: request.sort ? request.sort : "",
                        page: request.page ? request.page : 1
                    },
                    cancelToken: new Axios.CancelToken(c => cancel = c),

                })
                
                .then(response=>{
                    setIsLoading(false)
                    if(response.data && response.data.status==="success"){
                        setData(response.data)
                    }else if(response.data && response.data.status==="no_results"){
                        setError("no_results");  
                    }else{
                        setError("network_err");  
                    }
            
                
                })
                .catch(err=>{
                    if(!Axios.isCancel(err)){
                        setError('network_err');
                        setIsLoading(false)
                    }  
                })
                return () => cancel()
          
        }else{
            setIsLoading(false)
        }
    
    
    },[request])
 
    return {data, isLoading, error}
}

export default useFetch
