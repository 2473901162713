import React from 'react'
const Card13 = () => {
    return (
        <div className="card13-skeleton anime-skeleton">
            <div className="lft">
                <p></p>
            </div>
            <div className="rgt">
                <p></p>
            </div>
        </div>
    )
}

export default Card13
