import {useState, useEffect,useContext} from 'react'
import { UserStoreContext } from '../Contexts/UserStoreContext';
import Axios from 'axios';
import ENVIROMENTS from '../Environment/Configuration.json';

const useFetch = (request) => {
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(null);
    const[error, setError] = useState(null);
    
    const {is_logged_in, user_store} = useContext(UserStoreContext)
    

    useEffect(()=>{ 
        if(request){
            setData(null)
            setError(null)
            setIsLoading(true)
            const url = ENVIROMENTS.baseUrl + '/geniuxmusic/api/fetch_songs.php';
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: {
                    user_id: request.user_id,
                    song_id: request.song_id,
                    search: request.search_string,
                    genre: request.genre,
                    sort: request.sort,
                    type: request.type,
                    page: request.page,
                    listens: request.listens?request.listens:'',
                    myuserid: is_logged_in?user_store.user_id:"",
                    mytoken: is_logged_in?user_store.user_token:"",
                },
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                setIsLoading(false)
                if(response.data && response.data.status==="success"){
                    setData(response.data)
                }else{
                    setError("network_err");  
                }
           
               
            })
            .catch(err=>{
                if(!Axios.isCancel(err)){
                    setError('network_err');
                    setIsLoading(false)
                }
            })
            return () => cancel()
        }
    
    
    },[request])
 
    return {data, isLoading, error}
}

export default useFetch
