import {useState, useEffect, useContext} from 'react'
import { UserStoreContext } from '../Contexts/UserStoreContext';
import Axios from 'axios';
import ENVIROMENTS from '../Environment/Configuration.json';
const useFetch = (request) => {
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null);
    
    
    const {is_logged_in, set_is_logged_in,set_user_store, user_store} = useContext(UserStoreContext)
    

    useEffect(()=>{ 
        setError(null)
        setIsLoading(true)
        if(request){
            const url = ENVIROMENTS.baseUrl + '/geniuxmusic/api/delete_song.php';
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: {
                    songId: request.song_id,
                    myuserid: is_logged_in?user_store.user_id:"",
                    mytoken: is_logged_in?user_store.user_token:"",
                },
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                if(response.data[0] && response.data.length>0){
                    setData(response.data)
                    setIsLoading(false)
                    setError(null);  

                    
                }else{

                    setData(null)
                    setIsLoading(false)
                    if(response.data.response){
                        if(response.data.response==="credentials_not_provided" || response.data.response==="username_not_found"){
                            setError('not_logged_in');  
                            set_is_logged_in(false)
                            set_user_store(null)
                        }else if(response.data.response==="song_not_specified"){
                            setError('song_not_specified');  
                        }else if(response.data.response==="song_not_found"){
                            setError('song_not_found');  
                        }else{
                            setError('unknown_err');  
                        }
                    }else{
                        setError('unknown_err');  
                    }
                    
                
                }
        
            
            })
            .catch(err=>{
                if(!Axios.isCancel(err)){
                    setError('network_err');
                    setIsLoading(false)
                }
            })
            return () => cancel()
          
        }else{
            setIsLoading(false)
        }
    
    
    },[request,is_logged_in])
 
    return {data, isLoading, error}
}

export default useFetch
