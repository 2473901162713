import React, {Component} from "react";
import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import useFetch from '../APIs/FetchLikedSongs.js';
import SongCard from '../Cards/SongCard.js'
import LoadingSkeleton from '../LoadingSkeletons/Card05.js';

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper/modules";
const Songs = (props) => {
    //userId,songId,search_string,genre,sort,page,
    
    const [data, setData] = useState(null);
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData){
            if(FetchedData.total_results>0){
                setData(FetchedData)
            }
            
        }
    },[FetchedData])


    const send_request =()=>{
        setData(null)
        setRequest({
            "user_id":props.user_id,
            "page":"",
        })
    }



    useEffect(()=>{
        send_request()
    },[])


    return (
        <>
            {data &&(
                <>
                    <div className="htitle">
                        <div className="lft"><p>Tracks you liked</p></div>
                        {data.results.length > 10 &&(
                            <div className="rgt"><p><Link to={"/library/liked"}>See more</Link></p></div>
                        )}
                    </div>
                        {props.innerWidth<992 &&(
                            <div className="vertical-container">
                                {data.results.map((song,index)=>{
                                        return <SongCard key={index} data = {song} Data = {data.results} index={index}/> 
                                    }
                                )}

                            </div>
                        )}
                        {props.innerWidth>992 &&(
                        <Swiper
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    992: {
                                        slidesPerView: 5,
                                        spaceBetween: 20,
                                    },
                                    1600: {
                                        slidesPerView: 6,
                                        spaceBetween: 30,
                                    },
                                }}
                                className="mySwiper"
                            >
                                {data.results.map((song,index)=>{
                                        return <SwiperSlide key={index}><SongCard  data = {song} Data={data.results} index={index}/></SwiperSlide>
                                    }
                                )}
                            
                            </Swiper>           
                        )}
                </>
            )}
            {(isLoading) &&(
                <>
                    <div className="htitle">
                        <div className="lft"><p>Tracks you liked</p></div>
                    </div>
                    {props.innerWidth<992 &&(
                        <div className="vertical-container">
                            <LoadingSkeleton/>
                            <LoadingSkeleton/>
                            <LoadingSkeleton/>
                            <LoadingSkeleton/>
                            <LoadingSkeleton/>
                            <LoadingSkeleton/>
                        </div>
                    )}
                    {props.innerWidth>992 &&(
                        <Swiper
                            navigation={true}
                            modules={[Navigation]}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                992: {
                                    slidesPerView: 5,
                                    spaceBetween: 20,
                                },
                                1600: {
                                    slidesPerView: 8,
                                    spaceBetween: 30,
                                },
                            }}
                            className="mySwiper"
                        >
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            
                        </Swiper>           
                    )}
                </>
            )}
        </>
    )
}

export default Songs
