import React, {Component} from "react";
import {useState, useEffect, useContext} from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import { UserStoreContext } from '../Contexts/UserStoreContext';
import Button from '@mui/material/Button';
import {Link, useNavigate  }  from'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LogginDialog from '../Dialogs/LogginDialog.js';
import NavigationMenuToggle from '../Navigation/NavigationMenuToggle.js'

export default function UploadMusic(props) {
  const navigate = useNavigate()
  const {is_logged_in, user_store} = useContext(UserStoreContext)
  

  const [showLogIn, setShowLogIn] = useState(false);


  return (
    <>
       {props.innerWidth<992&&(
        <div className="navbar">
            <div className="wrapper">
                <div className="lft">
                    <IconButton className="icon" onClick={()=> navigate('/')}>
                        <ArrowBackIcon />
                    </IconButton> 
                    <div className="titles">
                        <p>UPLOAD MUSIC</p>
                    </div>
                
                </div>
                <div className="rgt">
                    <NavigationMenuToggle/>
                </div>
            </div>
            
        </div>
       )}
        
      
      <div className="mycontainer">
        {/* My  path */}
        {props.innerWidth>992 &&(
            <div className="mypath">
                <div className="wrapper">
                    <div className="lft">
                        {window.history.length>1?
                        <Button onClick={()=>navigate(-1)}>
                            <KeyboardBackspaceIcon/>
                        </Button>  
                        :
                        <Button disabled="true">
                            <KeyboardBackspaceIcon/>
                        </Button>  
                        }
                    </div>
                    <div className="rgt">
                        <p>
                            <Link to="/uploads" className="active">Upload Music</Link>
                        </p>
                    </div>
                </div>
            </div>
        )}
        <div className="wrapper">
          <div className="uploadmusic" >
              <form>
                <div className="row">

                  <div className="col-12 col-lg-7">
                      <div className="htitle">
                          <div className="lft"><p> What would you like to upload?</p></div>
                      </div>
                      <div className="chooseupload">
                        {!is_logged_in &&(
                          <>
                            <Button onClick={()=>setShowLogIn(true)}  color="primary">
                                <MusicNoteIcon/>
                                <p>Song</p>
                            </Button>
                            <Button onClick={()=>setShowLogIn(true)} variant="" color="primary">
                                <LibraryMusicIcon/>
                                <p>Album</p>
                            </Button>
                          </>
                        )}
                          {is_logged_in &&(
                          <>
                              <Button color="primary" onClick={()=> navigate('/uploads/upload/single')}>
                                  <MusicNoteIcon/>
                                  <p>Song</p>
                              </Button>
                              <Button  color="primary" onClick={()=> navigate('/uploads/upload/album')}>
                                  <LibraryMusicIcon/>
                                  <p>Album</p>
                              </Button>
                          </>
                        )}
                      </div>

                      <div className="htitle">
                          <div className="lft"><p> Tips to get your music heard:</p></div>
                      </div>
                      <div className="uploadtips">
                          <div className="list">
                            <div className="num">1</div>
                            <p>Regularly share your links on platforms like Instagram, Twitter, Facebook, and TikTok.</p>
                          </div>
                          <div className="list">
                            <div className="num">2</div>
                            <p>Ensure your profiles on Geniux Music, are complete with a bio, photos, and links to your social media.</p>
                          </div>
                          <div className="list">
                            <div className="num">3</div>
                            <p>Create remixes or covers of popular songs to draw attention to your work.</p>
                          </div>
                          <div className="list">
                            <div className="num">4</div>
                            <p>Submit your songs to established, popular podcasts.</p>
                          </div>
                          <div className="list">
                            <div className="num">5</div>
                            <p>Promote your music by paying ads placeholder.</p>
                          </div>
                          <div className="list">
                            <div className="num">6</div>
                            <p>Release new content regularly to keep your audience engaged.</p>
                          </div>
                          <div className="list">
                            <div className="num">7</div>
                            <p> Don’t ignore existing fans while trying to make new ones.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-12 col-lg-5">
                    <div className="rightmenu">
                        <div className="content-container">
                            <div className="htitle">
                                <div className="lft"><p>YOUR MUSIC, MATERIALS AND INFORMATION</p></div>
                            </div>
                            <p>
                            When you upload your Recordings through our Services, you are asked to submit Metadata as well as cover artwork for use on the Stores. You submit the Recordings, cover artwork and any other information and material (jointly “Material”) at your own expense and in formats required for use on the Stores.
                            <br/><br/>
                            You are fully responsible for everything you submit to us. If we find it unsuitable, we reserve the right to, in our sole discretion, remove the information and/or prevent you from using our Services and/or any or all Stores.
                            <br/><br/>
                            You submit the Recordings to us in a pre-agreed format and for a pre-agreed release date. The release date will be locked and you may not change it.
                            <br/><br/>
                            </p>
                            <div className="htitle">
                                <div className="lft"><p>Grands of rights</p></div>
                            </div>
                            <p>
                            You grant us all necessary consents and rights, without limitation, to sell and make use of your recordings for digital downloads, interactive and non-interactive streaming, cloud services and streaming-on-demand services. This grant of rights does not, however, constitute a transfer of ownership.
                            <br/>
                            <br/>
                            <b>By using our Services you grant us, during the Term and throughout the Territory,</b><br/><br/>
                              The non-exclusive right to use your className, photographs, likenesses, cover artwork, biographical and other information attributable to you, which you have submitted to Geniux Music.<br/><br/>
                              The right for Geniux Music to sublicense or otherwise transfer the above rights to any and all Stores.<br/><br/>
                              The right for Geniux Music to perform Metadata corrections (where necessary).<br/><br/>
                              The right for Geniux Music to synchronize and authorize others to synchronize your Recordings with visual images, to combine portions of your Recordings with still or moving images or as a live stream.<br/>
                            </p>
                        </div>
                      </div>
                  </div>
                </div>
              </form>
          </div>
        </div>
      </div>
      {(!is_logged_in && showLogIn) &&(
        <LogginDialog setShowLogIn={setShowLogIn}/>
      )}


 
    </>
  );
}

