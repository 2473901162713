//Native Features
import StatusBar from './Components/Capacitor/StatusBar';
import DownloadsDirectory from './Components/Capacitor/DownloadsDirectory';
import PushNotification from './Components/Capacitor/PushNotification';

//
import React, {Component} from "react";
import AppUrlListener from './Components/AppUrlListener.tsx';
import Home from './Pages'
import UploadMusic from './Pages/uploads/upload'
import UploadSingle from './Pages/uploads/upload/single'
import UploadAlbum from './Pages/uploads/upload/album'
import UploadCompleteSong from './Pages/uploads/upload/single/complete'
import UploadCompleteAlbum from './Pages/uploads/upload/album/complete'

import Profile from './Pages/profile/geniux-music-profile';
import SpotifyProfile from './Pages/profile/spotify-profile';

import UpdateAccount from './Pages/profile/manage/account';
import Followers from './Pages/profile/followers';
import Following from './Pages/profile/following';
import Notitications from './Pages/notifications';
import NotiticationsSettings from './Pages/notifications/settings';
import Settings from './Pages/settings';
import Logout from './Pages/logout';
import {BrowserRouter as Router, Routes, Route}  from'react-router-dom';
import Login from './Pages/login/'
import ValidateUser from './Components/UserCredentials/ValidateUser.js'
import ManageUploads from './Pages/uploads'
import UpdateSong from './Pages/uploads/update';
import GeniuxMusicPlayer from './Pages/playing/geniuxmusic-player';
import GetSongPostId from './Pages/playing/postId/postId';
import SpotifyPlayer from './Pages/playing/spotify-player.js';
import MinPlayer from './Components/MusicPlayer/MinPlayer';
import Downloads from './Pages/profile/downloads'
import Favourites from './Pages/profile/liked'
import GeniuxMusicAlbum from './Pages/playing/album/geniuxmusic'
import SpotifyAlbum from './Pages/playing/album/spotify'

import BrowsePage from './Pages/browse/index'
import GeniuxMusicProfileBrowse from './Pages/profile/browse/geniuxmusic.js'
import SpotifyProfileBrowse from './Pages/profile/browse/spotify.js'

import { ViewProvider } from './Components/Contexts/ViewContext.js';
import { PlayProvider } from './Components/Contexts/PlayContext.js';
import DesktopNavigation from './Components/Navigation/DesktopNavigation.js'

import { UserStoreProvider } from './Components/Contexts/UserStoreContext';
import TermsAndConditons from './Pages/terms-of-conditions';
import About from './Pages/about';
const App = () => {
  const appversion = '1.0.3'
  
  return (
    <>
    <UserStoreProvider>
      <StatusBar/>
      <DownloadsDirectory/>
      
      <ValidateUser/>
      <PushNotification/>

        <Router>
        <AppUrlListener/>
        
        <ViewProvider>
        <PlayProvider>
          <DesktopNavigation/>
          <Routes>
                <Route path='/login'  element={<Login/>}/>
                <Route path='/logout'  element={<Logout/>}/>
                <Route path='/forgot-password'  element={<Login/>}/>
                <Route path='/about'  element={<About/>}/>
                <Route path='/terms-and-conditions'  element={<TermsAndConditons/>}/>

                <Route path='/uploads/'  element={<ManageUploads/>}/>
            
                <Route path='/uploads/update/:song_id'  element={<UpdateSong/>}/>

                <Route path='/uploads/upload' element={<UploadMusic/>}/>
                <Route path='/uploads/upload/single' element={<UploadSingle/>}/>
                <Route path='/uploads/upload/album' element={<UploadAlbum/>}/>
                <Route path='/uploads/upload/single/complete/:song_id'  element={<UploadCompleteSong/>}/>
                <Route path='/uploads/upload/album/complete/:album_id'  element={<UploadCompleteAlbum/>}/>

                <Route path='/spotify/profile/:user_id'  element={<SpotifyProfile/>}/>

                <Route path='/profile/:user_id'  element={<Profile/>}/>
                <Route path='/profile/followers/:user_id'  element={<Followers/>}/>
                <Route path='/profile/following/:user_id'  element={<Following/>}/>

                <Route path='/profile/browse/:user_id'  element={<GeniuxMusicProfileBrowse/>}/>
                <Route path='/spotify/profile/browse/:user_id'  element={<SpotifyProfileBrowse/>}/>

                <Route path='/profile/manage/account'  element={<UpdateAccount/>}/>


                <Route path='/settings'  element={<Settings/>}/>
                <Route path='/notifications'  element={<Notitications/>}/>
                <Route path='/notifications/settings'  element={<NotiticationsSettings/>}/>
      
                <Route path='/browse' element={<BrowsePage/>}/>
                {/* <Route path='/browse/:genre_param/'element={<DiscoverSongs/>} />
                <Route path='/browse/:genre_param/:sort_param'  element={<DiscoverSongs/>}/> */}

                <Route path='/playing/:song_id/'  element={<GeniuxMusicPlayer/>}/>
                <Route path='/post.php'  element={<GetSongPostId/>}/>
                <Route path='/spotify/playing/:song_id/'  element={<SpotifyPlayer/>}/>
                <Route path='/spotify/playing/album/:album_id'  element={<SpotifyAlbum/>}/>

                <Route path='/playing/album/:album_id'  element={<GeniuxMusicAlbum/>}/>

                <Route path='/library/downloads/' element={<Downloads/>}/>
                <Route path='/library/liked/' element={<Favourites/>}/>
                <Route path='/'element={<Home/>}/>
        
                
          </Routes>
          <MinPlayer/>
          </PlayProvider>
          </ViewProvider>
        </Router>
    </UserStoreProvider>
    
    </>
    
  )}

  export default App
  
  


