

import Button from '@mui/material/Button';
import React, {Component} from "react";
import {useState, useEffect} from 'react'
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from 'react-router-dom';


export default function BasicModal(props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
      setOpen(true)
  }
  const handleClose = () => {
        setOpen(false)
        props.setFilterOpened(false)
  }
  useEffect(()=>{
    handleOpen()
  },[])

  const fetchRoles = [
    {
        "id": "0",
        "role": "All"
    },
    {
        "id": "15",
        "role": "Amateur"
    },
    {
        "id": "2",
        "role": "Artist"
    },
    {
        "id": "7",
        "role": "Beat Maker"
    },
    {
        "id": "8",
        "role": "Composer"
    },
    {
        "id": "14",
        "role": "Dancer"
    },
    {
        "id": "16",
        "role": "Director"
    },
    {
        "id": "11",
        "role": "Ghost Writer"
    },
    {
        "id": "13",
        "role": "Instrumentalist"
    },
    {
        "id": "9",
        "role": "Lyricist"
    },
    {
        "id": "18",
        "role": "Manager"
    },
    {
        "id": "3",
        "role": "Musician"
    },
    {
        "id": "1",
        "role": "Producer"
    },
    {
        "id": "6",
        "role": "Rapper"
    },
    {
        "id": "20",
        "role": "Record Label"
    },
    {
        "id": "19",
        "role": "Regular User"
    },
    {
        "id": "5",
        "role": "Singer"
    },
    {
        "id": "12",
        "role": "Song Writer"
    },
    {
        "id": "4",
        "role": "Sound Engineer"
    },
    {
        "id": "17",
        "role": "Trapper"
    },
    {
        "id": "10",
        "role": "Writer"
    }
]

    const [genre, setGenre] = useState(props.filters.genre);
    const [sort, setSort] = useState(props.filters.sort);
    const [role, setInterest] = useState(props.filters.role);


    const navigate = useNavigate();
    const apply_filter =()=>{
        const isGenre = genre?"&genre="+genre:"";
        const isSort =  sort?"&sort="+sort:"";
        const isRole =  role?"&role="+role:"";
        const isSearch = props.filters.search_string?"&search="+props.filters.search_string.replace(/\s/g, "%20"):"";

        if(props.group_param==="all"){
             navigate('/browse?group=all'+isSearch+isSort+isGenre+isRole)
            document.title = "Browse";
        }else if(props.group_param==="people"){
             navigate('/browse?group=people'+isSearch+isSort+isGenre+isRole)
            document.title = "Browse - People";
        }else if(props.group_param==="songs"){
             navigate('/browse?group=songs'+isSearch+isSort+isGenre+isRole)
            document.title = "Browse - Songs";
        }else if(props.group_param==="albums"){
             navigate('/browse?group=albums'+isSearch+isSort+isGenre+isRole)
            document.title = "Browse - Albums";
        }else if(props.group_param==="people-on-spotify"){
            navigate('/browse?group=people-on-spotify'+isSearch+isSort+isGenre+isRole)
           document.title = "Browse - Artists On Spotify";
        }else if(props.group_param==="singles-on-spotify"){
            navigate('/browse?group=singles-on-spotify'+isSearch+isSort+isGenre+isRole)
            document.title = "Browse - Singles On Spotify";
        }else if(props.group_param==="albums-on-spotify"){
            navigate('/browse?group=singles-on-spotify'+isSearch+isSort+isGenre+isRole)
            document.title = "Browse - Albums On Spotify";
        }else{
             navigate('/browse?group=all'+isSearch+isSort+isGenre+isRole)
            document.title = "Browse";
        }
        handleClose();
    }



  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
            <div className="myModal">
                <div className="container">
                    <div className="header">
                        <p>Filter Results</p>
                        <Button color="secondary" onClick={()=>handleClose()}><CloseIcon/></Button>
                    </div>
                    <div className="filter_container">
                        {props.group_param==="all" &&(
                            <>  
                                <div className="filter">
                                    <p>Sort By</p>
                                    <div className="options">
                                        <p onClick={()=>setSort('played')} className={sort==='played'?"active":""}>Popular</p>
                                        <p onClick={()=>setSort('newest')} className={sort==='newest'?"active":""}>Newest</p>
                                        <p onClick={()=>setSort('oldest')} className={sort==='oldest'?"active":""}>Oldest</p>
                                        <p onClick={()=>setSort('random')} className={sort==='random'?"active":""}>Shuffle</p>
                                    </div>
                                </div>    
                            </>
                        )}
                        {props.group_param==="people" &&(
                            <>
                                <div className="filter">
                                    <p>Roles</p>
                                    <div className="options">
                                    {
                                        fetchRoles.map((data,index)=>(
                                            <p key={index} onClick={()=>setInterest(data.role)} className={role===data.role?"active":""}>{data.role}</p>
                                        ))
                                    
                                    }
                                    </div>
                                </div>
                                <div className="filter">
                                    <p>Sort By</p>
                                    <div className="options">
                                        <p onClick={()=>setSort('played')} className={sort==='played'?"active":""}>Popular</p>
                                        <p onClick={()=>setSort('newest')} className={sort==='newest'?"active":""}>Newest</p>
                                        <p onClick={()=>setSort('oldest')} className={sort==='oldest'?"active":""}>Oldest</p>
                                        <p onClick={()=>setSort('topfollowed')} className={sort==='topfollowed'?"active":""}>Top Followed</p>
                                        <p onClick={()=>setSort('random')} className={sort==='random'?"active":""}>Shuffle</p>
                                    </div>
                                </div>    
                            </>
                        )}
                        {props.group_param==="songs" &&(
                            <>
                                <div className="filter">
                                    <p>Genre</p>
                                    <div className="options">
                                        <p onClick={()=>setGenre('all')} className={genre==='all'?"active":""}>All</p>
                                        <p onClick={()=>setGenre('hiphop')} className={genre==='hiphop'?"active":""}>Hiphop</p>
                                        <p onClick={()=>setGenre('house')} className={genre==='house'?"active":""}>House</p>
                                        <p onClick={()=>setGenre('rnb')} className={genre==='rnb'?"active":""}>RNB</p>
                                        <p onClick={()=>setGenre('classical')} className={genre==='classical'?"active":""}>Classical</p>
                                        <p onClick={()=>setGenre('instrumentals')} className={genre==='instrumentals'?"active":""}>Instrumentals</p>
                                        <p onClick={()=>setGenre('other')} className={genre==='other'?"active":""}>Others</p>
                                    </div>
                                </div>
                                <div className="filter">
                                    <p>Sort By</p>
                                    <div className="options">
                                        <p onClick={()=>setSort('played')} className={sort==='played'?"active":""}>Popular</p>
                                        <p onClick={()=>setSort('newest')} className={sort==='newest'?"active":""}>Newest</p>
                                        <p onClick={()=>setSort('oldest')} className={sort==='oldest'?"active":""}>Oldest</p>
                                        <p onClick={()=>setSort('liked')} className={sort==='liked'?"active":""}>Most Liked</p>
                                        <p onClick={()=>setSort('commented')} className={sort==='commented'?"active":""}>Most Commented</p>
                                        <p onClick={()=>setSort('downloaded')} className={sort==='downloaded'?"active":""}>Most Download</p>
                                        <p onClick={()=>setSort('random')} className={sort==='random'?"active":""}>Shuffle</p>
                                    </div>
                                </div>    
                            </>
                        )}
                        {props.group_param==="albums" &&(
                            <>
                                <div className="filter">
                                    <p>Genre</p>
                                    <div className="options">
                                        <p onClick={()=>setGenre('all')} className={genre==='all'?"active":""}>All</p>
                                        <p onClick={()=>setGenre('hiphop')} className={genre==='hiphop'?"active":""}>Hiphop</p>
                                        <p onClick={()=>setGenre('house')} className={genre==='house'?"active":""}>House</p>
                                        <p onClick={()=>setGenre('rnb')} className={genre==='rnb'?"active":""}>RNB</p>
                                        <p onClick={()=>setGenre('classical')} className={genre==='classical'?"active":""}>Classical</p>
                                        <p onClick={()=>setGenre('instrumentals')} className={genre==='instrumentals'?"active":""}>Instrumentals</p>
                                        <p onClick={()=>setGenre('other')} className={genre==='other'?"active":""}>Others</p>
                                    </div>
                                </div>
                                <div className="filter">
                                    <p>Sort By</p>
                                    <div className="options">
                                        <p onClick={()=>setSort('played')} className={sort==='played'?"active":""}>Popular</p>
                                        <p onClick={()=>setSort('newest')} className={sort==='newest'?"active":""}>Newest</p>
                                        <p onClick={()=>setSort('oldest')} className={sort==='oldest'?"active":""}>Oldest</p>
                                        <p onClick={()=>setSort('liked')} className={sort==='mostliked'?"active":""}>Most Liked</p>
                                        <p onClick={()=>setSort('commented')} className={sort==='commented'?"active":""}>Most Commented</p>
                                        <p onClick={()=>setSort('downloaded')} className={sort==='downloaded'?"active":""}>Most Download</p>
                                    </div>
                                </div>    
                            </>
                        )}
                        {(props.group_param==="people-on-spotify" || props.group_param==="singles-on-spotify" || props.group_param==="albums-on-spotify") &&(
                            <>
                                <div className="filter">
                                    <p>Genre</p>
                                    <div className="options">
                                        <p onClick={()=>setGenre('all')} className={genre==='all'?"active":""}>All</p>
                                        <p onClick={()=>setGenre('hiphop')} className={genre==='hiphop'?"active":""}>Hiphop</p>
                                        <p onClick={()=>setGenre('house')} className={genre==='house'?"active":""}>House</p>
                                        <p onClick={()=>setGenre('rnb')} className={genre==='rnb'?"active":""}>RNB</p>
                                        <p onClick={()=>setGenre('classical')} className={genre==='classical'?"active":""}>Classical</p>
                                        <p onClick={()=>setGenre('instrumentals')} className={genre==='instrumentals'?"active":""}>Instrumentals</p>
                                        <p onClick={()=>setGenre('other')} className={genre==='other'?"active":""}>Others</p>
                                    </div>
                                </div>
                                <div className="filter">
                                    <p>Sort By</p>
                                    <div className="options">
                                        <p onClick={()=>setSort('played')} className={sort==='played'?"active":""}>Popular</p>
                                        <p onClick={()=>setSort('newest')} className={sort==='newest'?"active":""}>Newest</p>
                                        <p onClick={()=>setSort('oldest')} className={sort==='oldest'?"active":""}>Oldest</p>
                                        <p onClick={()=>setSort('liked')} className={sort==='mostliked'?"active":""}>Most Liked</p>
                                        <p onClick={()=>setSort('commented')} className={sort==='commented'?"active":""}>Most Commented</p>
                                        <p onClick={()=>setSort('downloaded')} className={sort==='downloaded'?"active":""}>Most Download</p>
                                    </div>
                                </div>    
                            </>
                        )}
                        <Button className="btn" onClick={()=>apply_filter()}>Apply</Button>
                    </div>
                
                </div>
            </div>
         
       
      </Modal>
      
  );
}

