import React, {Component} from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import {useState,useEffect} from 'react'
const ReportComment = (props) => {
    const[feedBack, setFeedBack] = useState(false)

    const [type,setType] = useState(null)
    useEffect(()=>{
        if(props.type){
            setType(props.type)

        }
    },[props.type])
    return (
        <>

        
            <div className="editcomment">
                <div className="wrapper">
                    <div className="navmenu">
                        <div className="lft">
                                <IconButton onClick={()=>props.showIntialMenu()}>
                                    <ArrowBackIcon/>
                                </IconButton>
                            
                            <div className="title">
                                {type==="Song" &&(
                                    <>Report Song</>
                                )} 
                                {type==="Profile" &&(
                                    <>Report Account</>
                                )} 
                            </div>
                        </div>
                    
                    </div>
                    <form className="myform">
                        <textarea placeholder="Briefly explain your concerns"/>
                        <div className="btns">
                        
                            <div className="myerr">  
                                {feedBack &&(<span className="noerr">Your report was sent</span>)}
                            </div>
                        
                            
                    
                        <Button  type="button" className="defaultBTN" onClick={()=>setFeedBack(true)}color="primary">SUBMIT</Button>
                        </div> 
                    </form>

                
                </div>
              

            </div>
            
        </>
    )
}

export default ReportComment
