
import React, {Component} from "react";
import {useState, useEffect} from 'react'
import useFetch from '../APIs/FetchAlbums.js';
import AlbumCard from '../Cards/AlbumCard.js';
import LoadingSkeleton from '../LoadingSkeletons/AlbumCard.js';
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import { Navigation } from "swiper/modules";
const Albums = (props) => {
  

    const [data, setData] = useState(null)
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    const send_request =()=>{
        setRequest({
            "user_id":'',
            "sort":'played',
            "page":1,
        })
    }
  
    useEffect(()=>{
        send_request()
    },[])

    useEffect(()=>{
        if(FetchedData && FetchedData.total_results>0){
            setData(FetchedData)
        }
    },[FetchedData])



    return (
        <>
        {data &&(
            <>
                <div className="htitle">
                    <div className="lft"><p>POPULAR ALBUMS</p></div>
                    {data.results.length > 10 && (
                        <div className="rgt"><p><Link to="/browse?group=albums">See more</Link></p></div>
                    )}
                   
                   
                </div>
                {props.innerWidth<992 &&(
                    <div className="vertical-container">
                        {data.results.slice(0,10).map((album,index)=>( 
                            <AlbumCard data={album} key={index}/>
                        ))}
                    </div>
                )}
                {props.innerWidth>992 &&(
                    <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        breakpoints={{
                            992: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            1200: {
                                slidesPerView: 5,
                                spaceBetween: 20,
                            },
                            1600: {
                                slidesPerView: 6,
                                spaceBetween: 30,
                            },
                        }}
                        className="mySwiper"
                    >
                          {data.results.slice(0,10).map((album,index)=>( 
                              <SwiperSlide key={index}><AlbumCard data={album} key={index}/></SwiperSlide>
                        ))}
                        
                    </Swiper>           
                )}
            
            </>
        )}
        {(isLoading || error=="network_err") &&(
            <>
                <div className="htitle">
                    <div className="lft"><p>POPULAR ALBUMS</p></div>
                    <div className="rgt"><p><Link to="/browse?group=albums">See more</Link></p></div>
                </div>

                {props.innerWidth<992 &&(
                    <div className="vertical-container">
                        <LoadingSkeleton/>
                        <LoadingSkeleton/>
                        <LoadingSkeleton/>
                        <LoadingSkeleton/>
                    </div>
                )}
                {props.innerWidth>992 &&(
                    <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        breakpoints={{
                            992: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            1200: {
                                slidesPerView: 5,
                                spaceBetween: 20,
                            },
                            1600: {
                                slidesPerView: 6,
                                spaceBetween: 30,
                            },
                        }}
                        className="mySwiper"
                    >
                        <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                        <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                        <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                        <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                        <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                        
                    </Swiper>           
                )}

             
            
            </>
        )}
        </>
    )
}

export default Albums
