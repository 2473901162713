import React, {Component} from "react";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Button from '@mui/material/Button';
import {useEffect, useState,useContext } from 'react';
import {PlayContext} from '../Contexts/PlayContext.js'
import { UserStoreContext } from '../Contexts/UserStoreContext';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {Link}  from'react-router-dom'; 
import TimeAgo from 'timeago-react'; 
import Tooltip from '@mui/material/Tooltip';
import PlayOnSpotify from "../Dialogs/PlayOnSpotify.js";

import LogginDialog from '../Dialogs/LogginDialog.js';

const SongCard = (props) => {
    const [showLogIn, setShowLogIn] = useState(false);
    
    const {is_logged_in, user_store} = useContext(UserStoreContext)

    const {PlayData,setPlayData,PlayStatus,setPlayIndex,setPlayList} = useContext(PlayContext)
   

    const [data, setData] = useState(null)
    useEffect(()=>{
        if(props.data){
            setData(props.data)
        }
    },[props.data]) 


    const [isAvailableOnSpotify,setIsAvailableOnSpotify] = useState(false);



    const PlaySong = () =>{
        if(is_logged_in){
            setIsAvailableOnSpotify(true)
        }else{
            setShowLogIn(true)
        }
    }

  return (
    <>
        {data &&(
        <div className="card001">
                
            <div className="placeholder">
                {data.album.album_type=="album" && (<div className="type"><p> <Link to={"/spotify/playing/album/"+data.album.id}>{data.album.name}</Link></p></div>)}
                
                {(data.album.images.length > 0 && data.album.images[1])?
                    <img className="artcover" src={data.album.images[1].url}/>
                    :
                    <img className="artcover" src={"/assets/images/defaultsongart.jpg"}/>
                }
                <div className={PlayData && PlayData.data.song.song_id===data.id ? "playbtn active" : "playbtn" }  onClick={()=>PlaySong()}>
                    <Button>
                        {PlayData && PlayData.data.song.song_id===data.id && PlayStatus==='playing'?
                        <PauseIcon/>
                        :
                        PlayData && PlayData.data.song.song_id===data.id && PlayStatus==='paused'?
                        <PlayArrowIcon/>
                        : 
                        <PlayArrowIcon/>
                        }
                    </Button>
                </div>
            </div>
            <div className="details">
                
          
                <div className="titles">
                    <Tooltip title={data.name} placement="top">
                        <p>
                            <Link to={"/spotify/playing/"+data.id}>
                                {data.name}
                            </Link>
                        </p>
                    </Tooltip>
                    <Tooltip title={"Artists: "+data.artists.map((artist,index)=>{ return " "+artist.name}) +". Released date: "+data.album.release_date} placement="bottom">
                    <p>
                        {
                            data.artists.slice(0,2).map((artist,index)=>{
                                return <Link key={index} to={"/spotify/profile/"+artist.id}>{artist.name}{(index+1)<data.artists.slice(0,2).length?", ":""}</Link>
                                
                            })
                            
                        }{data.artists.length>2?"...":""}<TimeAgo datetime={data.album.release_date} /></p>
                    </Tooltip>
                   
                </div>
           
                <div className="stats">
                    <Button className="myicon" color="secondary"  onClick={()=>PlaySong()}>
                        <PlayCircleIcon/><p>Play On Spotify</p>
                    </Button>
                </div>
            </div>
        </div>
        
        )}
        {isAvailableOnSpotify &&(
            <PlayOnSpotify setIsAvailableOnSpotify={setIsAvailableOnSpotify} title={data.name} url={"https://open.spotify.com/track/"+data.id}/>
        )}
         
        {showLogIn &&(
            <LogginDialog setShowLogIn={setShowLogIn}/>
        )}
    </>
   
    
  )
}

export default SongCard