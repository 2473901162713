import React, {Component} from "react";
import {useState,useEffect,useContext} from 'react'
import {PlayContext} from '../Contexts/PlayContext.js'
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {Link, useNavigate} from 'react-router-dom';
import TimeAgo from 'timeago-react'; 
import Tooltip from '@mui/material/Tooltip';
const AlbumCard = (props) => {
    const navigate = useNavigate();
    const[data,setData] = useState(null)

    useEffect(()=>{
        if(props.data){
            setData(props.data)
        }
    },[props.data])


    const {PlayData,setPlayData,PlayStatus, setPlayIndex,setPlayList} = useContext(PlayContext)

    return (
        <>
            {data && (
                <div className={PlayData && PlayData.data.song.song_id===data.id?"albumcard active": "albumcard"} >
                    <div className="contents">
                        <div className="songcover" onClick={()=>navigate("/spotify/playing/album/"+data.id)}>
                            <img className="artcover" aria-label="Art Cover" src={(data.images.length>1 && data.images[1])?data.images[1].url:"/assets/images/defaultsongart.jpg"}/>
                            {PlayData && PlayData.data.song.song_id===data.id &&(
                                <>
                                    {PlayStatus==="playing"?<PauseIcon className="active"/>:<PlayArrowIcon/>}
                                </>
                            )
                            }

                        </div>
                        
                        <div className="details">
                            <Tooltip title={data.name} placement="top">
                                <p onClick={()=>navigate("/spotify/playing/album/"+data.id)}>{data.name}</p>
                            </Tooltip>
                            <Tooltip title={"Artists: "+data.artists.map((artist,index)=>{ return " "+artist.name}) +". Released date: "+data.release_date} placement="bottom">
                            <p>{
                                data.artists.slice(0,2).map((artist,index)=>{
                                        return <Link key={index} to={"/spotify/profile/"+artist.id}>{artist.name}{(index+1)<data.artists.slice(0,2).length?", ":""}</Link>
                                    
                                })
                                
                            }{data.artists.length>2?"...":""}<TimeAgo datetime={data.release_date} /></p>
                            </Tooltip>
                            
                        </div>
                    </div>
                </div>     
            )}
        </>
         
    )
}

export default AlbumCard
