import React from 'react'
const LoadingSkeleton = () => {
    return (
    <>
  
        <div className="usercard03-skeleton anime-skeleton">
            <div className="circle"></div>
            <div className="details">
                <p></p>
                <p></p> 
            </div>
            <div className="box"></div>
        </div>
        
       
    </>
    )
}

export default LoadingSkeleton
