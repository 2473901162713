import ENVIROMENTS from '../Environment/Configuration.json';
import {useState, useEffect, createContext} from 'react';
import { useGlobalAudioPlayer } from "react-use-audio-player"
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import React, {Component} from "react";
import useFetch from '../APIs/FetchSongs'; 
export const PlayContext = createContext()
export function PlayProvider ({children})  {
    const navigate = useNavigate();
    const location = useLocation();

    const [searchParams, setSearchParams] = useSearchParams();
    const tab_param = searchParams.get("tab")
    //
    const [IsOnRepeat,setRepeat] = useState(false);
    // Play Id
    const [PlayData,setPlayData] = useState(null);
  

    //Set Playing Status: playing
    const [PlayStatus,setPlayStatus] = useState('');
  
    //Set Current Playing Index
    const [PlayIndex,setPlayIndex] = useState(0);

    //Set Playlist
    const [PlayList, setPlayList] = useState([]);


    const { load, loop, playing, paused, play, pause } = useGlobalAudioPlayer()

    useEffect(()=>{
        if(playing){
            setPlayStatus("playing")
        }
        if(paused){
            setPlayStatus("paused")
        }
    },[playing, paused])
    useEffect(()=>{
        if(IsOnRepeat){
            loop(true)
        }else{
            loop(false)
        }
    },[IsOnRepeat])

    useEffect(()=>{
        if(IsOnRepeat){
            loop(true)
        }else{
            loop(false)
        }
    },[IsOnRepeat])

    const NextSong = () =>{
          
            if(PlayList[PlayIndex+1] !== 'undefined'){
                const data = PlayList[PlayIndex+1];
                setPlayData({"platform": "geniuxmusic",data});
                setPlayIndex(PlayIndex+1)
                const isTab = tab_param==="2"?"?tab=2":"";
                if(location.pathname.includes("/playing/")){
                    navigate('/playing/'+data.song.song_id+isTab, { replace: true })
                }
            }
            
    }
    const PrevSong = () =>{
        if(PlayList[PlayIndex-1] !== 'undefined'){
            const data = PlayList[PlayIndex-1];
            setPlayData({"platform": "geniuxmusic",data});
            setPlayIndex(PlayIndex-1)
            const isTab = tab_param==="2"?"?tab=2":"";
            if(location.pathname.includes("/playing/")){
                 navigate('/playing/'+data.song.song_id+isTab, { replace: true })
            }
        }
        
    }


    // Default PlayList
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData && FetchedData.total_results>0){
            setPlayList([...PlayList, ...FetchedData.results])
        }
    },[FetchedData])




    useEffect(()=>{
        if(PlayList.length<1){
            setRequest({
                "user_id":"",
                "song_id":"",
                "search_string":"",
                "genre":"",
                "sort":"newest",
                "page":1,
            })
        }
        
    },[])


 

  
    useEffect(()=>{
        if(PlayData){
          
            if(PlayData.platform==="geniuxmusic"){
                load(ENVIROMENTS.baseUrl + '/geniuxmusic/data/songs/'+PlayData.data.song.filename, {autoplay: true,  onend: () => NextSong()})


                if ('mediaSession' in navigator) {
                    navigator.mediaSession.metadata = new MediaMetadata({
                        title: PlayData.data.song.title,
                        artist: PlayData.data.user.artist_name,
                        album: PlayData.data.song.type==="album"?PlayData.data.song.album_title:"Single",
                        artwork: [{src: PlayData.data.song.artcover.length>2?ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/arts/original/?file_name="+PlayData.data.song.artcover:"/assets/images/defaultsongart.jpg"}]
                    });
                    navigator.mediaSession.setActionHandler("previoustrack", () => {
                        PrevSong();
                    });
                    navigator.mediaSession.setActionHandler("nexttrack", () => {
                        NextSong();
                    });

                    navigator.mediaSession.setActionHandler("play", () => {
                        play()
                    });
                    navigator.mediaSession.setActionHandler("pause", () => {
                        pause()
                    });
                    navigator.mediaSession.setActionHandler("stop", () => {
                        pause()
                    });

                    // navigator.mediaSession.setActionHandler("seekto", (details)=> {
                    //     if (details.action == 'seekto') {
                    //       alert(details.seekto.seekTime);
                    //     }
                    //     alert(details.seekto);
                    // });

                }
            }
            if(PlayData.platform==="spotify"){
                if(PlayData.data.song.preview_url){
                    load(PlayData.data.song.preview_url, {autoplay: true,  onend: () => NextSong()})
                }else{
                    
                }
                


                // if ('mediaSession' in navigator) {
                //     navigator.mediaSession.metadata = new MediaMetadata({
                //         title: PlayData.data.song.title,
                //         artist: PlayData.user.artist_name,
                //         album: PlayData.data.song.type==="album"?PlayData.data.song.album_title:"Single release",
                //         artwork: [{src: PlayData.data.song.artcover.length>2?ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/arts/smaller/?file_name="+PlayData.data.song.artcover:"/assets/images/defaultsongart.jpg"}]
                //     });
                //     navigator.mediaSession.setActionHandler("previoustrack", () => {
                //         PrevSong();
                //     });
                //     navigator.mediaSession.setActionHandler("nexttrack", () => {
                //         NextSong();
                //     });

                //     navigator.mediaSession.setActionHandler("play", () => {
                //         setPlayStatus("playing")
                //         play()
                //     });
                //     navigator.mediaSession.setActionHandler("pause", () => {
                //         setPlayStatus("paused")
                //         pause()
                //     });
                // }
            }
            
        }
    },[PlayData])


    return (
        <PlayContext.Provider value={{setRepeat,IsOnRepeat, NextSong, PrevSong, PlayData,setPlayData,PlayStatus,setPlayStatus,PlayIndex,setPlayIndex,PlayList, setPlayList}}>
               {children} 
        
        </PlayContext.Provider>
    )
}

