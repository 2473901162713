import ENVIROMENTS from '../Environment/Configuration.json';
import Button from '@mui/material/Button';
import React, {Component} from "react";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import Menu from '@mui/material/Menu';
import {useEffect,useState,useContext} from 'react'
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import UploadIcon from '@mui/icons-material/Upload';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {PlayContext} from '../Contexts/PlayContext.js';
import {ViewContext} from '../Contexts/ViewContext.js';
import { UserStoreContext } from '../Contexts/UserStoreContext.js';
import { prominent } from 'color.js';
const SideMenu = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const[innerWidth,setinnerWidth] = useState(window.innerWidth)
    useEffect(()=>{
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])

    const {PlayData} = useContext(PlayContext)
    const {ViewUserData,ViewSongData, setViewUserData, setViewSongData} = useContext(ViewContext)
    
    //Check Login Credentials
    const {is_logged_in, user_store} = useContext(UserStoreContext)
    //Check Login Credentials

        



    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const searchHint = () => {
       if(location.pathname.includes("/browse")){
            if(document.querySelector(".FilterPanel .searchbar input")!== null){
                document.querySelector('.FilterPanel .searchbar input').focus()
            }
       }
    };

    const[BGColor,setBGColor] = useState(null)
    useEffect(()=>{
        if(ViewUserData && location.pathname.includes("/profile")){
 
            if(ViewUserData.img_url!==""){
                prominent(ViewUserData.img_url,{format: 'hex',group: 30}).then(colors => {
                    setBGColor(colors[1]) 
                })
                
            }else{
                setBGColor('#212121') 
            }
                    
              
        }else if(ViewSongData && location.pathname.includes("/playing")){
 
            if(ViewSongData.img_url!==""){
                prominent(ViewSongData.img_url,{format: 'hex',group: 30}).then(colors => {
                    setBGColor(colors[1]) 
                })
                
            }else{
                setBGColor('#212121') 
            }
                    
              
        }else{
            setBGColor('#212121') 
            setViewSongData(null)
            setViewUserData(null)
        }
        
    },[ViewUserData,ViewSongData])
  return (
    <>
    {(innerWidth>992)&&(
        <>
        
        {(location.pathname!="/login" && location.pathname!="/logout" && location.pathname!="/getting-started" && location.pathname!="/forgot-password")  &&(
            <>
                <div className="navbar" style={{"--userbannerbg": ((location.pathname.includes("/playing") || (location.pathname.includes("/profile")) && !location.pathname.includes("/profile/manage")))? BGColor:"#212121"}}>
                    <div className="wrapper">
                        <div className="lft">
                        <Link to={'/browse?group=all'}>
                            <IconButton onClick={()=>searchHint()}>
                                <SearchIcon/>
                            </IconButton>
                        </Link>
                        </div>
                        <div className="rgt">
                    
                            {is_logged_in?
                            <>
                                <Button className='useraccount loggedin' onClick={handleClick}>
                                    <div className="usericon" style={{}}>
                                        {user_store.user_avatar.length>1?
                                            <img className="userpic" src={ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/avatars/smaller/?file_name="+user_store.user_avatar}  />
                                            :
                                            <p>{user_store.artist_name.substring(0, 1)}</p>
                                        }
                                    </div>
                                    <div className="details">
                                        <p>{user_store.artist_name}</p>
                                    </div>
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    className="myaccountmenu"
                                    MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <Link to={'/profile/'+user_store.user_id}>
                                        <MenuItem onClick={handleClose}>
                                            <p>Profile</p>
                                        </MenuItem>
                                    </Link>
                                    <Link to={'/profile/manage/account'}>
                                        <MenuItem onClick={handleClose}>
                                            <p>My Account</p>
                                        </MenuItem>
                                    </Link>
                                    
                                    <Link to={'/settings'}>
                                        <MenuItem onClick={handleClose}>
                                            <p>Settings</p>
                                        </MenuItem>
                                    </Link>
                                
                                    <Divider />
                                    <MenuItem onClick={()=>{handleClose();navigate('/logout',{ replace: true })}}>
                                        <p>Logout</p>
                                    </MenuItem>
                                    
                                </Menu>

                            </>:
                            <>
                                <Link to="/login">
                                    <Button className='useraccount notloggedin'>
                                        <div className='details'>
                                            <p>Login/Sign up</p>
                                        </div>
                                    </Button>
                                </Link>
                                
                            </>

                            }
                            
                        </div>  
                    </div>
                </div>
                <div className="sidebar">
                    <div className="contain">
            
                    
                            <div className="header">
                                <Link to="/"><img className="brandlogo" src="/assets/images/logo.svg"/></Link>
                                {/* <p>Welcome back,<br/>
                                    <b>{user_store.artist_name}</b></p>  */}
                            </div>
                        
                            <div className="menu">
                                <div className="tabs">
                                    {(ViewUserData && location.pathname.includes("/profile")) &&(
                                        <>
                                            {ViewUserData.platform ==="geniuxmusic" &&(
                                                <div className='currentview'>
                                                    <Link to={"/profile/"+ViewUserData.user_id}>
                                                        <Button className={location.pathname.includes("/profile")?"active":""}><p>{ViewUserData.artist_name}</p>
                                                            <div className='imgicon'>
                                                                {ViewUserData.img_url!==""?
                                                                    <img src={ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/avatars/smaller/?file_name=" + ViewUserData.img_url.split("=")[1]}/>:
                                                                    <p>{ViewUserData.artist_name.substring(0, 1)}</p>
                                                                }
                                                                                    
                                                            </div> 
                                                        </Button>
                                                    </Link>
                                                </div>
                                            )}
                                            {ViewUserData.platform ==="spotify" &&(
                                                <div className='currentview'>
                                                    <Link to={"/spotify/profile/"+ViewUserData.user_id}>
                                                        <Button className={location.pathname.includes("/profile")?"active":""}><p>{ViewUserData.artist_name}</p>
                                                            <div className='imgicon'>
                                                                {ViewUserData.img_url!==""?
                                                                    <img src={ViewUserData.img_url}/>:
                                                                    <p>{ViewUserData.artist_name.substring(0, 1)}</p>
                                                                }
                                                                                    
                                                            </div> 
                                                        </Button>
                                                    </Link>
                                                </div>
                                            )}
                                        </>
                                            
                                    )}
                                    {(ViewSongData  && location.pathname.includes("/playing"))&&(
                                        <div className='currentview'>
                                            {ViewSongData.platform ==="geniuxmusic" &&(
                                                <Link to={"/playing/"+ViewSongData.song_id}>
                                                    <Button className={location.pathname.includes("/playing")?"active":""}><p>{ViewSongData.title}</p>
                                                        <div className='imgicon song'>
                                                            {ViewSongData.img_url!==""?
                                                                <img src={ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/arts/smaller/?file_name=" + ViewSongData.img_url.split("=")[1]}/>:
                                                                <img src="/assets/images/defaultsongart.jpg"/>
                                                            }
                                                            
                                                        </div> 
                                                    </Button>
                                                </Link>
                                            )}
                                            {ViewSongData.platform ==="spotify" &&(
                                                <Link to={"/spotify/playing/"+ViewSongData.song_id}>
                                                    <Button className={location.pathname.includes("/playing")?"active":""}><p>{ViewSongData.title}</p>
                                                        <div className='imgicon song'>
                                                            {ViewSongData.img_url!==""?
                                                                <img src={ViewSongData.img_url}/>:
                                                                <img src="/assets/images/defaultsongart.jpg"/>
                                                            }
                                                            
                                                        </div> 
                                                    </Button>
                                                </Link>
                                            )}
                                        </div>
                                    )}
                                    {(!location.pathname.includes("/playing") && !location.pathname.includes("/profile")  && PlayData)&&(
                                        <div className='currentview'>
                                            
                                            {(PlayData.platform==="geniuxmusic") &&(
                                                <Link to={"/playing/"+PlayData.data.song.song_id}>
                                                    <Button className={(location.pathname.includes("/playing") || location.pathname.includes(PlayData.data.song.song_id)) && (!location.pathname.includes('/uploads'))?"active":""}><p>{PlayData.data.song.title}</p>
                                                        <div className='imgicon song'>
                                                            {PlayData.data.song.artcover.length>2 ?
                                                                <img src={ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/arts/smaller/?file_name="+PlayData.data.song.artcover}/>:
                                                                <img src="/assets/images/defaultsongart.jpg"/>
                                                            }
                                                        </div> 
                                                    </Button>
                                                </Link>
                                             )}
                                              {(PlayData.platform==="spotify") &&(
                                                <>
                                                    <Link to={"/spotify/playing/"+PlayData.data.song.song_id}>
                                                        <Button className={location.pathname.includes("/playing") || location.pathname.includes(PlayData.data.song.song_id)?"active":""}><p>{PlayData.data.song.title}</p>
                                                            <div className='imgicon song'>
                                                                {PlayData.data.song.artcover!=="" ?
                                                                    <img src={PlayData.data.song.artcover}/>:
                                                                    <img src="/assets/images/defaultsongart.jpg"/>
                                                                }
                                                            </div> 
                                                        </Button>
                                                    </Link>
                                                </>
                                            )}
                                            
                                    </div>
                                    )}
                                    <Link to="/"><Button className={location.pathname=="/"?"active":""}><p>Home</p>{location.pathname=="/"?<HomeRoundedIcon/>:<HomeOutlinedIcon/>} </Button></Link>
                                    {!is_logged_in &&(
                                        <>
                                        <Link to="/login"><Button><p>Login/Sign</p> <AccountCircleIcon/></Button></Link>
                                        </>
                                        
                                    )}
                                    
                                    <Link to="/browse?group=all"><Button className={!location.pathname.includes("/profile/") && location.pathname.includes("/browse")?"active":""}><p>Search</p> <SearchIcon/></Button></Link>
                                
                                
                                    {is_logged_in &&(
                                        <Link to="/notifications"><Button className={location.pathname=="/notifications" ? "active":""}><p>Notifications</p>{location.pathname=="/notifications" ? <NotificationsIcon/> : <NotificationsOutlinedIcon/>}{user_store.unseen_notification>0 &&(<div className="mybadge"><p>{user_store.unseen_notification>99?"99":user_store.unseen_notification}</p></div>)}</Button></Link>    
                                    )}
                                     <Link to="/uploads/upload"><Button className={location.pathname.includes("/uploads") ? "active":""}><p>Upload Music</p>{location.pathname.includes("/uploads") ? <UploadIcon/> : <UploadOutlinedIcon/>}</Button></Link>
                                    <Link to="/library/liked"><Button className={location.pathname.includes("/library/liked") ? "active":""}><p>Liked Music</p> {location.pathname.includes("/library/liked") ? <FavoriteIcon/> : <FavoriteBorderIcon/>}</Button></Link>
                                    <Link to="/library/downloads"><Button className={location.pathname.includes("/library/downloads") ? "active":""}><p>Downloads</p> {location.pathname.includes("/library/downloads") ? <DownloadForOfflineIcon/> : <DownloadForOfflineOutlinedIcon/>}</Button></Link>
                                    <Link to="/settings"><Button className={location.pathname.includes("/settings") ? "active":""}><p>Settings</p>{location.pathname.includes("/settings") ? <SettingsRoundedIcon/> : <SettingsOutlinedIcon/>}</Button></Link>
                                    
                                
                                </div>
                            
                            </div>
            
                    
                        
                        <div className="footer">
                            <p>Need Help? Talk to us</p>
                            <p><a href="mailto:music@geniux.co.za" target="_blank">music@geniux.co.za</a></p>
                        </div>
                    </div>
                    {ViewUserData && location.pathname.includes("/profile") &&(
                        <>
                        <div className='background'>
                            {ViewUserData.img_url!=="" &&(
                                <>
                                    <div className='fademask'></div>
                                    <img src={ViewUserData.img_url}/>
                                </>
                            )}
                        </div>
                           
                        </>
                        
                    )}
            
                    {(ViewSongData && location.pathname.includes("/playing")) &&(
                        <div className='background'>
                            {ViewSongData.img_url!==""?
                            <>
                            <div className='fademask'></div>
                            <img src={ViewSongData.img_url}/>
                            </>:
                            <>
                                <div className='fademask'></div>
                                <img src="/assets/images/geniuxbanner.jpg"/>
                            </>
                                
                            }
                            
                        </div>
                    )}
                    {(!location.pathname.includes("/playing") && !location.pathname.includes("/profile")  && PlayData)&&(
                        
                        <div className='background'>
                            <div className='fademask'></div>
                            {(PlayData.platform==="geniuxmusic") &&(
                                <>
                                
                                    {PlayData.data.song.artcover.length>2 ?
                                        <img src={ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/arts/smaller/?file_name="+PlayData.data.song.artcover}/>:
                                        <img src="/assets/images/geniuxbanner.jpg"/>
                                    }
                                </>
                            )}
                            {(PlayData.platform==="spotify") &&(
                                <>
                                {PlayData.data.song.artcover!=="" ?
                                    <img src={PlayData.data.song.artcover}/>:
                                    <img src="/assets/images/geniuxbanner.jpg"/>
                                }
                                </>
                            )}
                            
                        </div>
                    )}
                </div>   
            </>
        )}
        
        </>
    )}
        
    </>
  )
}

export default SideMenu