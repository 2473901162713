import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FacebookIcon from '@mui/icons-material/Facebook';
import React, {Component} from "react";
import {useState,useEffect} from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Share } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';
import {Link} from 'react-router-dom';
const ReportComment = (props) => {
    const[data, setData] = useState(null)
    const[type, setType] = useState(null)
    useEffect(()=>{
        if(props.data){
            setData(props.data)
        }
    },[props.data])

    useEffect(()=>{
        if(props.type){
            setType(props.type)
        }
    },[props.type])
 

    const[url, setUrl] = useState(null)
    const[caption, setCaption] = useState(null)
    
    useEffect(()=>{
        if(data){

            if(type==="Profile"){
                setUrl('https://music.geniux.co.za/profile/'+data.user_id+"/")
                setCaption('Check out the profile: '+data.artist_name+' on Geniux Music. ')
            }else if(type==="Song"){
                setUrl('https://music.geniux.co.za/playing/'+data.song.song_id+"/")
                setCaption('Check out the song: '+data.song.title+' on Geniux Music. ')
            }else if(type==="Comment"){
                setUrl('https://music.geniux.co.za/playing/'+data.song_id+'/comment/'+data.comment_id+"/")
                setCaption('Check out the comment on Geniux Music. ')
            }else if(type==="Album"){
                setUrl('https://music.geniux.co.za/playing/album/'+data.results[0].album.album_id+"/")
                setCaption('Check out the album on Geniux Music. ')
            }else if(type==="Spotify Profile"){
                setUrl('https://music.geniux.co.za/spotify/profile/'+data.id+"/")
                setCaption('Check out the profile on Geniux Music. ')
            }else if(type==="Spotify Song"){
                setUrl('https://music.geniux.co.za/spotify/playing/'+data.id+"/")
                setCaption('Check out the song on Geniux Music. ')
            }else if(type==="Spotify Album"){
                setUrl('https://music.geniux.co.za/spotify/playing/album/'+data.id+"/")
                setCaption('Check out the album on Geniux Music. ')
            }
            
           
       
        }
    },[data])




    //copy link
    const [copySuccess, setCopySuccess] = useState(null);
    
    function copyToClipboard() {
        Clipboard.write({
            string: url
        });
        setCopySuccess(true);
        setTimeout(() => {
            setCopySuccess(null);
        }, [1000]);
    };

    const sharemore=()=>{
      
        Share.share({
            title: type,
            text: caption,
            url: url,
            dialogTitle: 'Share with buddies',
        });
    }


    return (
        <>
            {data &&(
            <div className="editcomment">
                <div className="navmenu">
                    <div className="lft">
                            <IconButton onClick={()=>props.showIntialMenu()} color="secondary">
                                <ArrowBackIcon/>
                            </IconButton>
                     
                        <div className="title">
                        { type==="Profile" &&(
                            <>
                                You are sharing profile link
                            </>
                        )}
                        {type==="Song" &&(
                            <>
                                You are sharing song link
                            </>
                        )}  
                        {type==="Album" &&(
                            <>
                                You are sharing album link
                            </>
                        )}  
                         {type==="Comment" &&(
                            <>
                                You are sharing comment link
                            </>
                        )}  
                        {type==="Spotify Profile" &&(
                            <>
                                You are sharing profile link
                            </>
                        )}  
                        {type==="Spotify Song" &&(
                            <>
                                You are sharing song link
                            </>
                        )}
                        {type==="Spotify Album" &&(
                            <>
                                You are sharing album link
                            </>
                        )}  
                        </div>
                    </div>
                    <div className="rgt">
                        <IconButton onClick={copyToClipboard} color="secondary">
                            <ContentCopyIcon/>
                        </IconButton>
                    </div>
                    
                </div>
                {type==="Profile" &&(
                    <>
                        <p className="linktitle">{data.artist_name}</p>
                        <p className="linkpar" onClick={copyToClipboard}><Link to={"/profile/"+data.user_id}>{url}</Link> {copySuccess && (<span>Link copied</span>)} </p>
                    </>
                )}  
           
                {type==="Song" &&(
                    <>
                        <p className="linktitle">{data.song.title}</p>
                        <p className="linkpar" onClick={copyToClipboard}><Link to={"/playing/"+data.song.song_id}>{url}</Link> {copySuccess && (<span>Link copied</span>)}</p>
                    </>
             
                )}  
                {type==="Album" &&(
                    <>
                        <p className="linktitle">{data.results[0].user.artist_name +" - "+data.results[0].album.title}</p>
                        <p className="linkpar" onClick={copyToClipboard}><Link to={"/playing/album/"+data.results[0].album.album_id}>{url}</Link> {copySuccess && (<span>Link copied</span>)}</p>
                    </>
                )}  
                 {type==="Spotify Profile" &&(
                    <>
                        <p className="linktitle">{data.name}</p>
                        <p className="linkpar" onClick={copyToClipboard}><Link to={"/spotify/profile/"+data.id}>{url}</Link> {copySuccess && (<span>Link copied</span>)} </p>
                    </>
                )}  
                {type==="Spotify Song" &&(
                    <>
                        <p className="linktitle">{data.name}</p>
                        <p className="linkpar" onClick={copyToClipboard}><Link to={"/spotify/playing/"+data.id}>{url}</Link> {copySuccess && (<span>Link copied</span>)} </p>
                    </>
                )} 
                {type==="Spotify Album" &&(
                    <>
                        <p className="linktitle">{data.name}</p>
                        <p className="linkpar" onClick={copyToClipboard}><Link to={"/spotify/playing/album/"+data.id}>{url}</Link> {copySuccess && (<span>Link copied</span>)}</p>
                    </>
                )}  
                <div className="sharelinkcontainer">
                    <div className="contain">
                    
                
                        <a href={"https://www.facebook.com/sharer/sharer.php?u="+url} target="_blank">
                            <Button color="secondary">
                                <div className="icon"> <FacebookIcon/></div>
                                <span>FACEBOOK</span>
                            </Button>
                        </a>
                
                        <a href={props.innerWidth<992?"whatsapp://send?text="+caption+url:"https://api.whatsapp.com/send?text="+caption+url} data-action="share/whatsapp/share" target="_blank">
                            <Button color="secondary">
                                <div className="icon"> <WhatsAppIcon/></div>
                                <span>WHATSAPP</span>
                            </Button>
                        </a>
                    
                        <Button color="secondary" onClick={()=>sharemore()}>
                            <div className="icon"> <MoreHorizIcon/></div>
                            <span>MORE</span>
                        </Button>
           
                        
                    </div>
                    <br/>
                    
                    
                </div>
              
            </div>
            )}
        


             
                
              

       
            
        </>
    )
}

export default ReportComment
