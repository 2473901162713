import {useState,useEffect, useContext} from 'react'
import Container from '../../../Components/Notifications/NotiticationsSettings'
import {Helmet} from "react-helmet";
import ValidateUser from '../../../Components/UserCredentials/ValidateUser';
import { UserStoreContext } from '../../../Components/Contexts/UserStoreContext';
import {useNavigate} from "react-router-dom"; 
const url = '/geniuxmusic/api/fetch_songs.php'

export default function MaxPlayer () {
    const navigate = useNavigate();
    const[innerWidth,setinnerWidth] = useState(window.innerWidth)
    useEffect(()=>{
        window.scrollTo(0, 0)
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])

    const {is_logged_in} = useContext(UserStoreContext)
    useEffect(()=>{
        if(is_logged_in===true || is_logged_in===false){
            if(is_logged_in===false){
                navigate('/login')
            }
        }
    },[is_logged_in])
    return (
        <>
        
        <Helmet>
            <title>Notifications - Settings</title>
            <meta property="og:title" content="Notifications"/>
            <meta name="title" content="Notifications"/>
            <meta name="description" content="Manage your notification settings"/>
           
            
            <meta property="og:image" content="/assets/images/geniuxbanner.jpeg"/>
                
        </Helmet>
        <ValidateUser/>
         <Container innerWidth={innerWidth}/> 
            
        </>
    )
}

