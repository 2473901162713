import {useState,useEffect, useRef, useCallback} from 'react';
import Card from '../../Cards/SongCard';
import useFetch from '../../APIs/FetchSongs.js';
import LoadingSkeleton from '../../LoadingSkeletons/Card05.js';
import NoInternet from '../../Dialogs/NoInternet.js';
import Button from '@mui/material/Button';

const Uploads = (props) => {
 
   

    const [page,setPage] = useState(1);

    const [data, setData] = useState(null);
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData && FetchedData.total_results>0){
            if(data && data.length>0){
              setData([...data, ...FetchedData.results])
            }else{
                setData(FetchedData.results)
            }
        }
    },[FetchedData])


    const send_request =()=>{
        setRequest({
            "user_id":props.filters.user_id,
            "song_id":"",
            "search_string":props.filters.search_string,
            "genre":props.filters.genre,
            "sort":props.filters.sort===""?"newest":props.filters.sort,
            "type":"",
            "page":page,
        })
    }



    useEffect(()=>{
        send_request()
    },[page])

    useEffect(()=>{
        setData(null)
        setPage(1)
        send_request()
    },[props.filters])

    const LoadMore = ()=>{
        const currpage = page;
        setPage(currpage+1)
    }
  
    const observer = useRef()
    const lastBookElementRef = useCallback(node=>{
  
        if(observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries=>{
            if(entries[0].isIntersecting){
  
              if(FetchedData && FetchedData.results.length>0){
                LoadMore()
              }
                
            }
        })
        if(node) observer.current.observe(node)
       
    },[FetchedData])

    //Handle Error Onclick
    const handleError = ()=>{
        send_request()
    }
    const[showNoInternet,setShowInternet] = useState(false)
    useEffect(()=>{
        if(error){
            setShowInternet(true)
        }
    },[error])

    const closeModal = ()=>{
        setShowInternet(false);
    }
  return (
    <>
    <div className="row">
        {data && (
            data.map((song,index)=>{
                return <div key={index} className="col-6 col-md-4 col-lg-3 col-xxl-2"><Card data = {song} Data = {data} index={index}/></div>
            }
        ))}
        {(isLoading) &&(
            <>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
                <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><LoadingSkeleton/></div>
            </>
        )}
    </div>
    <div ref={lastBookElementRef}></div>
    {/* Feedbacks */}
    {(!data && error=="network_err") &&(
        <div className='wrapper'>
            <div className="no_data_msg">
                <div className="container">
                    <div className="icon">
                        <img src="/assets/images/no_music_found.svg" />
                    </div>
                    <p>Check your internet connectivity</p> 
                    <Button onClick={()=>send_request()}>
                        Retry
                    </Button>
                </div>

            </div>
        </div> 
    )}  

    {(FetchedData && FetchedData.total_results<1) &&(
        <div className='wrapper'>
            <div className="no_data_msg">
                <div className="container">
                    <div className="icon">
                        <img src="/assets/images/no_music_found.svg" />
                    </div>
                    <p>No results found</p> 
                    <Button onClick={()=>send_request()}>
                        Retry
                    </Button>
                </div>

            </div>
        </div> 
    )}   
    {(data && showNoInternet)&&(
        <NoInternet closeModal={closeModal} handleError={handleError}/>
    )}   
                
    </>
  )
}

export default Uploads