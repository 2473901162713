import {useState, useEffect, useContext} from 'react'
import { UserStoreContext } from '../../Contexts/UserStoreContext';
import Axios from 'axios';
const useFetch = (deleterequest) => {
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null);
    
    
    const {is_logged_in, user_store} = useContext(UserStoreContext)
    

    useEffect(()=>{ 

        setIsLoading(true)
        if(is_logged_in && deleterequest){
            const url = '/geniuxmusic/api/delete_comment.php';
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: {
                    songId: deleterequest.songId,
                    commentId: deleterequest.commentId,
                    myuserid: is_logged_in?user_store.user_id:"",
                    mytoken: is_logged_in?user_store.user_token:"",
                },
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                if(response.data[0] && response.data[0].status==="success"){
                    setData(response.data)
                    setIsLoading(false)
                    setError(null);  
                 

                    
                }else{
                    setError('unknown_error'); 
                    setData(null)
                    setIsLoading(false) 
                }
           
               
            })
            .catch(err=>{
                setIsLoading(false)
                setError('network_err');   
            })
            return () => cancel()
        }else{
            setIsLoading(false)
        }
    
    
    },[deleterequest, is_logged_in])
 
    return {data, isLoading, error}
}

export default useFetch
