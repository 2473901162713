import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import React, {Component} from "react";
import {useState, useEffect} from 'react'
import Modal from '@mui/material/Modal';
import {useNavigate} from 'react-router-dom';


export default function BasicModal(props) {
const navigate = useNavigate()
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
      setOpen(true)
  }
  const handleClose = () => {
      
        setOpen(false)
        props.setFilterOpened(false)
      
    
  }


  useEffect(()=>{
    handleOpen()
  },[])


    const [sort, setSort] = useState('newest')

    const apply_filter =()=>{
        navigate('/library/liked?sort='+sort, { replace: true })
        props.setFilterOpened(false)
    }
    useEffect(()=>{
        if(props.sort){
            setSort(props.sort)
        }
    },[props.sort])


  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
            <div className="myModal">
                <div className="container">
                    <div className="header">
                        <p>Filter Results</p>
                        <Button color="secondary" onClick={()=>handleClose()}><CloseIcon/></Button>
                    </div>
                    <div className="filter_container">
                        <div className="filter">
                            <label>Sort By</label>
                            <div className="options">
                                <p onClick={()=>setSort('newest')} className={sort==='newest'?"active":""}>Newest</p>
                                <p onClick={()=>setSort('oldest')} className={sort==='oldest'?"active":""}>Oldest</p>
                            </div>
                        </div>
                        <Button className="btn" onClick={()=>apply_filter()}>Apply</Button>
                    </div>
                
                </div>
            </div>
      </Modal>
  );
}


