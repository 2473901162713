import Button from '@mui/material/Button';
import WarningIcon from '@mui/icons-material//Warning';
import React, {Component} from "react";
import {useState, useEffect} from 'react'
import Modal from '@mui/material/Modal';
import useFetch from './APIs/ClearNotifications.js';
import {useNavigate} from 'react-router-dom';


export default function BasicModal(props) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

  
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        
        setOpen(false)
        props.closeModal()
        

    }
    const handleError = () => {
        
            props.handleError()
        

    }

    useEffect(()=>{
        handleOpen()
    },[])

    const [request, setRequest] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [data, setData] = useState(false)
    const {data:FetchedData,isLoading, error} = useFetch(confirm,request)


    useEffect(()=>{
        if(FetchedData){
            setData(FetchedData)
        }
    },[FetchedData])
    const yesDelete =()=>{
        setRequest(!request)
        setConfirm(true)
    }

  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
            <div className="myModal fadefx">
                <div className="container">
                    <div className="errIcon">
                        <WarningIcon/>
                    </div>
                    <div className="errbody">
                        {isLoading &&(
                            <>
                                <p>Please wait while deleting...</p>
                                <Button className="btn" onClick={()=>window.location.reload()}>Cancel</Button>
                            </>
                        )}
                        {data &&(
                            <>
                                <p>You have successfully deleted your notifications.</p>
                                <Button className="btn" onClick={()=>handleClose()}>Okay</Button>
                            </>
                        )}
                        {error==="network_err"?
                            <>
                                <p>Something went wrong.<br/>Check your network connectivity.</p>
                                <Button className="btn" onClick={()=>yesDelete()}>Retry</Button>
                            </>:
                        error==="unknown_err"?
                            <>
                                <p>Something went wrong.<br/>Try again later.</p>
                                <Button className="btn" onClick={()=>yesDelete()}>Retry</Button>
                            </>:
                        error==="failed_to_query"?
                            <>
                                <p>Something went wrong.<br/>Try again later.</p>
                                <Button className="btn" onClick={()=>yesDelete()}>Retry</Button>
                            </>:
                        error==="not_confirmed"?
                        <>
                            <p>Something went wrong.<br/>Try again later.</p>
                            <Button className="btn" onClick={()=>yesDelete()}>Retry</Button>
                        </>:
                        error==="user_not_found"?
                        <>
                            <p>Something went wrong.<br/>Try to logout and login again.</p>
                            <Button className="btn" onClick={()=>navigate('/logout',{ replace: true })}>Logout</Button>
                        </>:
                        error==="credentials_not_provided"?
                        <>
                            <p>Something went wrong.<br/>Try to logout and login again.</p>
                            <Button className="btn" onClick={()=>navigate('/logout',{ replace: true })}>Logout</Button>
                        </>
                            :
                        <></>
                        }
                        {(!data && !isLoading && !error) &&(
                            <>
                                <p>You are deleting all your notifications<br/>
                                This action cannot be undone, are you sure?</p>
                                <Button className="btn" onClick={()=>yesDelete()}>Yes, Delete</Button>
                            </>
                        )}
                        
                       
                        <Button className="btn closebtn" onClick={()=>handleClose()}>Close</Button>
                    </div>
                </div>
            </div>
         
      </Modal>
  );
}

