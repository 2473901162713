import {useState,useEffect, useContext} from 'react';
import React, {Component} from "react";
import {Helmet} from "react-helmet";
import Popular from '../../Components/SpotifyProfile/Popular.js'
import LikedSongs from '../../Components/Profile/LikedSongs.js'
import Albums from '../../Components/SpotifyProfile/Albums.js'
import RelatedArtists from '../../Components/SpotifyProfile/RelatedArtists.js'
import NewestSongs from '../../Components/SpotifyProfile/NewestSongs.js'
import Button from '@mui/material/Button';
import Engagement from '../../Components/SpotifyProfile/Engagement.js';
import useFetch from '../../Components/APIs/SpotifyGetArtist.js'; 
import { UserStoreContext } from '../../Components/Contexts/UserStoreContext.js';
import {ViewContext} from '../../Components/Contexts/ViewContext.js';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ViewImageModal from '../../Components/viewImageModal.js'
import ShareLink from '../../Components/ShareLink.js'
import Linkify from 'linkify-react';
import NavigationMenuToggle from '../../Components/Navigation/NavigationMenuToggle.js'
import Tooltip from '@mui/material/Tooltip';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {useParams,Link,useNavigate  }  from'react-router-dom';
import IsLoading from '../../Components/isLoading.js';
import { prominent } from 'color.js';
import LogginDialog from '../../Components/Dialogs/LogginDialog.js';
import ConvertNum from '../../Components/ConvertNumbers/useConvertLargeNum.js';

export default function ProfileContainer ({props}) {
    const navigate = useNavigate();
    const {is_logged_in, user_store} = useContext(UserStoreContext)
    const {setViewUserData} = useContext(ViewContext)
    const[innerWidth,setinnerWidth] = useState(window.innerWidth);
    const [pagescroll, setPageScroll] = useState(0);
    useEffect(()=>{
        window.scrollTo(0, 0)
        window.onscroll = () => {
            setPageScroll(window.pageYOffset)
        }
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])


    const {user_id} = useParams();
    
    const[data,setData] = useState(null)
    const [user_request, set_user_request] = useState(null)
    const {data:_user_fetched_data, isLoading:_user_is_loading, error:_user_fetch_error} = useFetch(user_request)

    useEffect(()=>{
        if(_user_fetched_data){
            setData(_user_fetched_data);
          
        }
    },[_user_fetched_data])


    const send_user_request =()=>{
        set_user_request({
            "user_id":user_id,
            "search_string":"",
            "role":"",
            "sort":"topfollowed",
            "page":"",
        })
    }

    const [showLogIn, setShowLogIn] = useState(false);
 



    const [isViewingImage,setIsViewingImage] = useState(false);
    const[isMenuOpened,setisMenuOpened] = useState(false)


    const[BGColor,setBGColor] = useState(null)
    useEffect(()=>{
        if(data){
            setViewUserData(
                {
                    "id": data.id,
                    "user_id": data.id,
                    "artist_name": data.name,
                    "img_url": data.images.length>0?data.images[1].url:"",
                    "platform": "spotify"
                }
            );
            if(data.images.length>2){
                
                prominent(data.images[1].url,{format: 'hex',group: 30}).then(colors => {
                    setBGColor(colors[1]) 
                })
            }else{
                setBGColor('#212121') 
            }
            
        }
    },[data])

    useEffect(()=>{
        window.scrollTo(0, 0)
        setData(null)
        setViewUserData(null)
        send_user_request()
    },[user_id])
    return (
        <>
        {data?
            <>
            <Helmet>
                <title>{"Profile: "+data.name}</title>
                <meta property="og:title" content={"Profile: "+data.name}/>
                <meta name="title" content={"Profile: "+data.name}/>
                <meta name="description" content="Check out this profile on Geniux Music." key="3"/>
                {(data.images.length>0 && data.images[1].url)?
                <meta property="og:image" content={data.images[1].url}/>:
                <meta property="og:image" content="/assets/images/geniuxbanner.jpeg"/>
                }
            </Helmet>
            <div className="mycontainer">

                {innerWidth<992 &&(
                <>
                    <div className="navbar"  style={{"--userbannerbg": BGColor}}>
                        <div className="wrapper">
                            <div className="lft">
                                <IconButton className="icon" onClick={()=>navigate(-1)}>
                                    <ArrowBackIcon />
                                </IconButton> 
                                <div className="titles">
                                    <p>PROFILE</p>
                                    {pagescroll> 80 &&(
                                        <>
                                        {data.name.length<40?
                                            <p>{data.name}</p>:
                                            <p>{data.name.substring(0, 40) + "..."}</p> 
                                        }
                                        </>
                                    )}
                                </div>
                        
                            
                            </div>
                            <div className="rgt">
                            {/*
                                {(data && is_logged_in && user_store.user_id===data.results[0].user_id) &&(
                                    <IconButton className="icon" onClick={()=>navigate('/profile/manage/account')}>
                                        <CreateIcon />
                                    </IconButton>  
                                )}
                            */}
                            <NavigationMenuToggle/>
                            </div>  
                        </div>
                        
                    </div>
                </>
                )}
                

              

                 <div className="userbanner">
                    {innerWidth>992 &&(
                    <div className="mypath">
                        <div className="wrapper">
                            <div className="lft">
                            {window.history.length>1?
                                <Button onClick={()=>navigate(-1)}>
                                <KeyboardBackspaceIcon/>
                                </Button>  
                                :
                                <Button onClick={()=>navigate(-1)} disabled="true">
                                    <KeyboardBackspaceIcon/>
                                </Button>  
                            }
                            </div>
                            <div className="rgt">
                                <p>
                                    <Link to="" className="active">Profile</Link>
                                    {data && (
                                        <Link to="" className="">/ {data.name}</Link>
                                    )}
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                    )}
               
                   <div className="background" style={{backgroundColor: BGColor}}></div> 
                    <div className='contain'>
                        {innerWidth>992 &&(
                            <div className="usericon desktop">
                                {data.images.length>0?
                                    <img className="ppimg" onClick={()=>setIsViewingImage(true)} src={data.images[1].url}/>
                                :
                                    <div className="ppimg">
                                    {data.name.substring(0, 1)}
                                    </div>
                                }
                            </div>  
                        )}
                    
                        <div className="flexdv">
                            <div className="lft">
                                <div className="titles01">
                                    <p>{data.name}</p>
                                    <p>{data.type}</p>
                                </div>
                                <p className="titles02">
                                    <Linkify>
                                    {
                                         data.genres.map((genre,index)=>{
                                            return <span className="genres">{genre} {(index+1)<data.genres.length?", ":" "}</span>;
                                        })
                                    }
                                    </Linkify>
                                </p>
                                
                                
                                
                            </div>
                            {innerWidth<992 &&(
                              <div className="rgt">
                                    {data.images.length>0?
                                        <img className="ppimg" onClick={()=>setIsViewingImage(true)}src={data.images[1].url}/>
                                    :
                                        <div className="ppimg">
                                        {data.name.substring(0, 1)}
                                        </div>
                                    }
                                </div>  
                            )}
                            
                        </div>
                        {innerWidth<992 &&(
                         <div className="icons">
                            
                               {is_logged_in?<Button className="active"><a href={data.external_urls.spotify} target="_blank">Follow on spotify</a></Button>:<Button className="active" onClick={()=>setShowLogIn(true)}>Follow</Button>} 
                                <Button onClick={()=>setisMenuOpened(true)}><ShareIcon/></Button> 
                                <Button onClick={()=>setisMenuOpened(true)}><MoreVertIcon/></Button> 
                            </div>   
                        )}
                        
                    </div>

                    {innerWidth<992 &&(
                        <div className="kpis">
                            <div className="kpi">
                                <Link to={'/spotify/profile/browse/'+data.id+'?group=all'}>
                                    <Button>
                                        <p>&lt;1000</p>
                                        <p>Uploads</p>  
                                    </Button>
                                </Link>
                            </div>
                            <div className="kpi">
                                <Link to={'/spotify/profile/'+data.id}>
                                    <Button >
                                        <p><ConvertNum num = {data.followers.total}/></p>
                                        <p>Followers</p>
                                    </Button>
                                </Link>   
                            </div>
                            <div className="kpi">
                                <Button >
                                    <p>{data.popularity}%</p>
                                    <p>Popularity</p>
                                </Button>
                            </div>
                        </div>
                    )}
                    {innerWidth>992 &&(
                        <div className="kpisactionbtns">
                            <div className="wrapper">

                           
                                <div className="lft">
                                    <div className="kpis">
                                        <div className="kpi">
                                        <Tooltip title={0} placement="top">
                                            <Link to={'/spotify/profile/browse/'+data.id+'?group=all'}>
                                                <Button>
                                                    <p>&lt;1000</p>
                                                    <p>Uploads</p>  
                                                </Button>
                                            </Link>
                                        </Tooltip>
                                            
                                        </div>
                                        <div className="kpi">
                                        <Tooltip title={data.followers.total + " followers"} placement="top">
                                            <Link to={'/spotify/profile/'+data.id}>
                                                <Button>
                                                    <p><ConvertNum num = {data.followers.total}/></p>
                                                    <p>Followers</p>
                                                </Button>
                                            </Link>
                                        </Tooltip>
                                        </div>
                                        <div className="kpi">
                                        <Tooltip title={"The popularity of the artist. The value will be between 0 and 100, with 100 being the most popular. The artist's popularity is calculated from the popularity of all the artist's tracks"} placement="top">
                                            <Button>
                                                <p>{data.popularity}%</p>
                                                <p>Popularity</p>
                                            </Button>
                                        </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                <div className="rgt">
                                    <div className="icons">
                                        {is_logged_in?<Tooltip title="Follow on spotify" placement="top"><Button className="active"><a href={data.external_urls.spotify} target="_blank">Follow on spotify</a></Button></Tooltip>:<Button className="active" onClick={()=>setShowLogIn(true)}>Follow</Button>} 
                                        <Tooltip title="Share this profile" placement="top">
                                            <Button onClick={()=>setisMenuOpened(true)}><ShareIcon/></Button> 
                                        </Tooltip>
                                        <Tooltip title="More options" placement="top">
                                            <Button onClick={()=>setisMenuOpened(true)}><MoreVertIcon/></Button> 
                                        </Tooltip>
                                        
                                    </div>   
                                </div>
                            </div>
                        </div>
                    )}
                
                </div>
                {(data.images.length>0 && data.images[0]) &&(
                    <ViewImageModal isViewingImage={isViewingImage} setIsViewingImage={setIsViewingImage} data={data} type="spotify-profile"/>

                )}
                
                {(isMenuOpened && data) &&(
                    <ShareLink setisMenuOpened={setisMenuOpened} data={data} type="Spotify Profile"/>
                )}
       
               
       

                
                {showLogIn &&(
                    <LogginDialog setShowLogIn={setShowLogIn}/>
                )}
      

                <div className="wrapper usercontainer">
                        <div className="row">
                            <Engagement data={data}/>
                            <Popular data={data} innerWidth={innerWidth}/>
                        </div>
                 
                        <NewestSongs data={data} innerWidth={innerWidth}/>
                        <Albums data={data} innerWidth={innerWidth}/>
                        <RelatedArtists data={data} innerWidth={innerWidth}/>
                         {/* 
                        <div className="row">
                            {data.results[0].followers>0 &&(
                                <div className="col-12 col-lg-6">
                                    <section>
                                        <Followers user_id={data.results[0].user_id} innerWidth={innerWidth}/>
                                    </section>
                                </div>
                            )}
                            {data.results[0].following>0 &&(
                                <div className="col-12 col-lg-6">
                                    <section>
                                        <Following user_id={data.results[0].user_id} innerWidth={innerWidth}/>
                                    </section>
                                </div>
                            )}         
                        </div>
                        {data.results[0].total_liked_songs>0 &&(
                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <section>
                                        <LikedSongs user_id={data.results[0].user_id} innerWidth={innerWidth}/>
                                    </section>
                                </div>
                            </div>
                        )} */}
                        
            
                </div> 
            </div>






        </>
         :data && !data.name?
         <>
            <Helmet>
                <title>User not found</title>
                <meta property="og:title" content="User not found" key="1" />
                <meta name="title" content="User not found" key="2"/>
                <meta name="description" content="Link might be invalid or user has been removed" key="3"/>
                <meta property="og:image" content="/assets/images/geniuxbanner.jpeg" key="4"/>
            </Helmet>
            {innerWidth<992 &&(
                <>
                    <div className="navbar">
                        <div className="wrapper">
                            <div className="lft">
                                <IconButton className="icon" onClick={()=>navigate(-1)}>
                                    <ArrowBackIcon />
                                </IconButton> 
                                <div className="titles">
                                    <p>PROFILE</p>
                                    {pagescroll> 80 &&(
                                        <p>{user_id}</p>
                                    )}
                                </div>
                            </div>
                            <div className="rgt">
                            <NavigationMenuToggle/>
                            </div>  
                        </div>
                    </div>
                </>
               ) }
            <div className="mycontainer">
                <div className="wrapper">
                    <div className="no_data_msg">
                        <div className="container">
                            <div className="icon">
                                <img src="/assets/images/no_user_found.svg" />
                            </div>
                            <p>User is not found or link might be broken</p> 
                            <Button onClick={()=>send_user_request()}>Retry</Button>
                        </div>
                    </div>
                </div>
            </div>
         
        </>
        :
        _user_fetch_error==="network_err"?
        <>
        <Helmet>
            <title>No Internet</title>
            <meta property="og:title" content="No Internet"/>
            <meta name="title" content="No Internet"/>
            <meta name="description" content="Check your internet connectivity" />
            <meta property="og:image" content="/assets/images/geniuxbanner.jpeg"/>
                
           
        </Helmet>
        {innerWidth<992 &&(
            <div className="navbar">
                <div className="wrapper">
                    <div className="lft">
                        <IconButton className="icon" onClick={()=>navigate(-1)}>
                            <ArrowBackIcon />
                        </IconButton> 
                    
                        <div className="titles">
                            <p>PROFILE</p>
                        </div>
                    
        
                    </div>
                    <div className="rgt">
                        <NavigationMenuToggle/>
                    </div>
                </div>
            </div>
        )}
        <div className="mycontainer">
            {innerWidth>992 &&(
                <div className="mypath">
                    <div className="wrapper ">
                        <div className="lft">
                        {window.history.length>1?
                            <Button onClick={()=>navigate(-1)}>
                            <KeyboardBackspaceIcon/>
                            </Button>  
                            :
                            <Button onClick={()=>navigate(-1)} disabled="true">
                                <KeyboardBackspaceIcon/>
                            </Button>  
                        }
                        </div>
                        <div className="rgt">
                            <p>
                                <Link to="" className="active">PROFILE</Link>
                                <Link to="" className=""></Link>
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {(!_user_is_loading) &&(
                <div className='wrapper'>
                    <div className="no_data_msg">
                        <div className="container">
                            <div className="icon">
                                <img src="/assets/images/no_user_found.svg" />
                            </div>
                            <p>Check your internet connectivity</p> 
                            <Button onClick={()=>send_user_request()}>
                                Retry
                            </Button>
                        </div>
                    </div>
                </div> 
            )}  
        </div>
        </>:
        _user_is_loading?
        <>
        {innerWidth<992 &&(
            <div className="navbar">
                <div className="wrapper">
                    <div className="lft">
                        <IconButton className="icon" onClick={()=>navigate(-1)}>
                            <ArrowBackIcon />
                        </IconButton> 
                    
                        <div className="titles">
                            <p>PROFILE</p>
                        </div>
                    
        
                    </div>
                    <div className="rgt">
                        <NavigationMenuToggle/>
                    </div>
                </div>
            </div>
        )}
        <div className="mycontainer">
            {innerWidth>992 &&(
                <div className="mypath">
                    <div className="wrapper ">
                        <div className="lft">
                        {window.history.length>1?
                            <Button onClick={()=>navigate(-1)}>
                            <KeyboardBackspaceIcon/>
                            </Button>  
                            :
                            <Button onClick={()=>navigate(-1)} disabled="true">
                                <KeyboardBackspaceIcon/>
                            </Button>  
                        }
                        </div>
                        <div className="rgt">
                            <p>
                                <Link to="" className="active">Profile</Link>
                                <Link to="" className=""></Link>
                            </p>
                        </div>
                    </div>
                </div>
            )}
            
            <div className='wrapper'>
                <IsLoading/>
            </div> 
            
        </div>
        </>:
        <></>
        }
 
        
        </>
    )
}



