import ENVIROMENTS from '../Environment/Configuration.json';
import React, {Component} from "react";
import {useState, useEffect, useContext} from 'react'
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';
import { UserStoreContext } from '../Contexts/UserStoreContext';
import useFetch from '../APIs/FollowUser.js';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import LogginDialog from '../Dialogs/LogginDialog.js';
const UserCard = (props) => {
    const {is_logged_in, user_store} = useContext(UserStoreContext)
    const [showLogIn, setShowLogIn] = useState(false);

 

    const [data, setData] = useState(props.data)

    //Follow
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)
    const [Error,setError] = useState(null)

    
    useEffect(()=>{
        if(FetchedData){
            if(FetchedData.length>0){
                setData(...FetchedData)
            }
        }
    },[FetchedData])


    const send_request=()=>{
        setRequest({
          "user_id": data.user_id
        })
    
      }

    const follow_user =()=>{
        if(!is_logged_in){
            setShowLogIn(true)
        }else{
            if(data){
                send_request()
            }
        }
    }
   

    return (
        <div className="card002">
               {data.user_avatar.length>2?
                <Link className="ppimg" to={'/profile/'+data.user_id}>
                    <img src={ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/avatars/smaller/?file_name="+data.user_avatar}/>
                </Link>
                :
                <Link className="ppimg" to={'/profile/'+data.user_id}>
                    {data.artist_name.substring(0, 1)}
                </Link> 
                }
            <div className="details">
                <Tooltip title={data.artist_name +" ("+data.interest+")"} placement="top" >
                    <div className="titles">
                        <p><Link to={'/profile/'+data.user_id}>{data.artist_name}</Link></p>
                        <p><Link to={'/profile/'+data.user_id}>{data.interest}</Link></p>
                    </div>   
                </Tooltip>
                
                {data.followed>0 &&(
                    <Link to={'/profile/'+data.user_id}><Button >VIEW PROFILE</Button> </Link>
                )}
                {data.followed<1 &&(
                    <Button  className="active" onClick={()=>follow_user()}>{data.followed>0?"Unfollow": "Follow"} {isLoading && <CircularProgress/>}</Button>
                )}
            </div>
            {showLogIn &&(
                <LogginDialog setShowLogIn={setShowLogIn}/>
            )}
        </div>
    )
}

export default UserCard
