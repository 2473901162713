import React, {Component} from "react";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PersonIcon from '@mui/icons-material/Person';
import {Link, useNavigate} from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const CompleteProfile = (props) => {
    const navigate = useNavigate();


    return (
    <>
   

    
        <div className="wrapper ">
            <div className="htitle">
                <div className="lft">
                    <p>COMPLETE PROFILE
                        {(props.data.user_avatar.length<1 && props.data.bio.length<1)?
                        "(0/2)"
                        :
                        "(1/2)"
                        }
                    </p>
                </div>
            </div>
        </div>
        
        <div className="completeprofile">
            <Link to={'/profile/manage/account'} className="card04">
                <div>
                   
                    {props.data.user_avatar.length<1?
                    <>
                        <div className="icon"><AddAPhotoIcon/></div>
                        <p>Add profile avatar </p>   
                    </>
                     
                    :
                    <>
                        <div className="icon"><CheckCircleIcon/></div>
                        <p>Profile avatar added</p> 
                    </>
                    
                    }
                    
                </div>

                
            </Link>
            <Link to={'/profile/manage/account'} className="card04" >
                <div>
                    
                    {props.data.bio.length<1?
                    <>
                        <div className="icon"><PersonIcon/></div>
                        <p>Add biography</p>      
                    </>
                  
                    :
                    <>
                        <div className="icon"><CheckCircleIcon/></div>
                        <p>Biography added</p> 
                    </>
                   
                    }
                </div>
            </Link>

                

        </div>
 
    </>  
    )
}

export default CompleteProfile
