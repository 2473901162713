import {useState, useEffect, useContext} from 'react'
import { UserStoreContext } from '../../Contexts/UserStoreContext';
import Axios from 'axios';
const useFetch = (updatecommentrequest) => {
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null);
    
    
    const {is_logged_in, user_store} = useContext(UserStoreContext)
    

    useEffect(()=>{ 

        setIsLoading(true)
        if(is_logged_in && updatecommentrequest){
            const url = '/geniuxmusic/api/edit_comment.php';
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: {
                    songId: updatecommentrequest.songId,
                    commentId: updatecommentrequest.commentId,
                    comment: updatecommentrequest.comment,
                    myuserid: is_logged_in?user_store.user_id:"",
                    mytoken: is_logged_in?user_store.user_token:"",
                },
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                if(response.data[0] && response.data[0].user_id){
                    setData(response.data)
                    setIsLoading(false)
                    setError(null);  
                 

                    
                }else{
                    if(response.data.response){
                        if(response.data.response==="song_not_specified"){
                            setError(response.data.response); 
                        }else if(response.data.response==="comment_id_not_specified"){
                            setError(response.data.response); 
                        }else if(response.data.response==="comment_empty"){
                            setError(response.data.response); 
                        }else if(response.data.response==="credentials_not_provided"){
                            setError(response.data.response); 
                        }else if(response.data.response==="user_not_found"){
                            setError(response.data.response); 
                        }else if(response.data.response==="failed_to_update_comment"){
                            setError(response.data.response); 
                        }else if(response.data.response==="comment_not_found"){
                            setError(response.data.response); 
                        }else{
                            setError('unknown_error'); 
                        }
                       
                    }else{
                        setError('unknown_error'); 
                    }
                    setData(null)
                    setIsLoading(false) 
                }
           
               
            })
            .catch(err=>{
                setIsLoading(false)
                setError('network_err');   
            })
            return () => cancel()
        }else{
            setIsLoading(false)
        }
    
    
    },[updatecommentrequest, is_logged_in])
 
    return {data, isLoading, error}
}

export default useFetch
