

import Button from '@mui/material/Button';
import React, {Component} from "react";
import {useState, useEffect} from 'react'
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from 'react-router-dom';


export default function BasicModal(props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
      setOpen(true)
  }
  const handleClose = () => {
        setOpen(false)
        props.setFilterOpened(false)
  }
  useEffect(()=>{
    handleOpen()
  },[])

  const fetchInterests = [
    {
        "id": "0",
        "interests": "All"
    },
    {
        "id": "15",
        "interests": "Amateur"
    },
    {
        "id": "2",
        "interests": "Artist"
    },
    {
        "id": "7",
        "interests": "Beat Maker"
    },
    {
        "id": "8",
        "interests": "Composer"
    },
    {
        "id": "14",
        "interests": "Dancer"
    },
    {
        "id": "16",
        "interests": "Director"
    },
    {
        "id": "11",
        "interests": "Ghost Writer"
    },
    {
        "id": "13",
        "interests": "Instrumentalist"
    },
    {
        "id": "9",
        "interests": "Lyricist"
    },
    {
        "id": "18",
        "interests": "Manager"
    },
    {
        "id": "3",
        "interests": "Musician"
    },
    {
        "id": "1",
        "interests": "Producer"
    },
    {
        "id": "6",
        "interests": "Rapper"
    },
    {
        "id": "20",
        "interests": "Record Label"
    },
    {
        "id": "19",
        "interests": "Regular User"
    },
    {
        "id": "5",
        "interests": "Singer"
    },
    {
        "id": "12",
        "interests": "Song Writer"
    },
    {
        "id": "4",
        "interests": "Sound Engineer"
    },
    {
        "id": "17",
        "interests": "Trapper"
    },
    {
        "id": "10",
        "interests": "Writer"
    }
]
    const [data, setData] = useState(props.user_data)
    const [genre, setGenre] = useState(props.filters.genre);
    const [sort, setSort] = useState(props.filters.sort);
    const [interest, setInterest] = useState(props.filters.interest);


    const navigate = useNavigate();
    const apply_filter =()=>{
        const isGenre = genre?"&genre="+genre:"";
        const isSort =  sort?"&sort="+sort:"";
        const isInterest =  interest?"&interest="+interest:"";
        const isSearch = props.filters.search_string?"&search="+props.filters.search_string:"";

        if(props.group_param==="all"){
             navigate('/spotify/profile/browse/'+props.filters.user_id+'?group=all'+isSearch.replace(/\s/g, "%20")+isSort)
        }else if(props.group_param==="songs"){
             navigate('/spotify/profile/browse/'+props.filters.user_id+'?group=songs'+isSearch.replace(/\s/g, "%20")+isSort+isGenre)
        }else if(props.group_param==="albums"){
             navigate('/spotify/profile/browse/'+props.filters.user_id+'?group=albums'+isSearch.replace(/\s/g, "%20")+isSort+isGenre)
        }else{
             navigate('/spotify/profile/browse/'+props.filters.user_id+'?group=all'+isSearch.replace(/\s/g, "%20")+isSort)
        }
        handleClose();
    }



  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
            <div className="myModal">
                <div className="container">
                    <div className="header">
                        <p>Filter Results</p>
                        <Button color="secondary" onClick={()=>handleClose()}><CloseIcon/></Button>
                    </div>
                    <div className="filter_container">
                        {props.group_param==="all" &&(
                            <>  
                                <div className="filter">
                                    <p>Sort By</p>
                                    <div className="options">
                                        <p onClick={()=>setSort('played')} className={sort==='played'?"active":""}>Popular</p>
                                        <p onClick={()=>setSort('newest')} className={sort==='newest'?"active":""}>Newest</p>
                                        <p onClick={()=>setSort('oldest')} className={sort==='oldest'?"active":""}>Oldest</p>
                                        <p onClick={()=>setSort('random')} className={sort==='random'?"active":""}>Shuffle</p>
                                    </div>
                                </div>    
                            </>
                        )}
                        {props.group_param==="songs" &&( 
                            <>
                                <div className="filter">
                                    <p>Genre</p>
                                    <div className="options">
                                        <p onClick={()=>setGenre('all')} className={genre==='all'?"active":""}>All</p>
                                        <p onClick={()=>setGenre('hiphop')} className={genre==='hiphop'?"active":""}>Hiphop</p>
                                        <p onClick={()=>setGenre('house')} className={genre==='house'?"active":""}>House</p>
                                        <p onClick={()=>setGenre('rnb')} className={genre==='rnb'?"active":""}>RNB</p>
                                        <p onClick={()=>setGenre('classical')} className={genre==='classical'?"active":""}>Classical</p>
                                        <p onClick={()=>setGenre('instrumentals')} className={genre==='instrumentals'?"active":""}>Instrumentals</p>
                                        <p onClick={()=>setGenre('other')} className={genre==='other'?"active":""}>Others</p>
                                    </div>
                                </div>
                                <div className="filter">
                                    <p>Sort By</p>
                                    <div className="options">
                                        <p onClick={()=>setSort('played')} className={sort==='played'?"active":""}>Popular</p>
                                        <p onClick={()=>setSort('newest')} className={sort==='newest'?"active":""}>Newest</p>
                                        <p onClick={()=>setSort('oldest')} className={sort==='oldest'?"active":""}>Oldest</p>
                                        <p onClick={()=>setSort('liked')} className={sort==='liked'?"active":""}>Most Liked</p>
                                        <p onClick={()=>setSort('commented')} className={sort==='commented'?"active":""}>Most Commented</p>
                                        <p onClick={()=>setSort('downloaded')} className={sort==='downloaded'?"active":""}>Most Download</p>
                                        <p onClick={()=>setSort('random')} className={sort==='random'?"active":""}>Shuffle</p>
                                    </div>
                                </div>    
                            </>
                        )}
                        {props.group_param==="albums" &&(
                            <>
                                <div className="filter">
                                    <p>Genre</p>
                                    <div className="options">
                                        <p onClick={()=>setGenre('all')} className={genre==='all'?"active":""}>All</p>
                                        <p onClick={()=>setGenre('hiphop')} className={genre==='hiphop'?"active":""}>Hiphop</p>
                                        <p onClick={()=>setGenre('house')} className={genre==='house'?"active":""}>House</p>
                                        <p onClick={()=>setGenre('rnb')} className={genre==='rnb'?"active":""}>RNB</p>
                                        <p onClick={()=>setGenre('classical')} className={genre==='classical'?"active":""}>Classical</p>
                                        <p onClick={()=>setGenre('instrumentals')} className={genre==='instrumentals'?"active":""}>Instrumentals</p>
                                        <p onClick={()=>setGenre('other')} className={genre==='other'?"active":""}>Others</p>
                                    </div>
                                </div>
                                <div className="filter">
                                    <p>Sort By</p>
                                    <div className="options">
                                        <p onClick={()=>setSort('played')} className={sort==='played'?"active":""}>Popular</p>
                                        <p onClick={()=>setSort('newest')} className={sort==='newest'?"active":""}>Newest</p>
                                        <p onClick={()=>setSort('oldest')} className={sort==='oldest'?"active":""}>Oldest</p>
                                        <p onClick={()=>setSort('liked')} className={sort==='mostliked'?"active":""}>Most Liked</p>
                                        <p onClick={()=>setSort('commented')} className={sort==='commented'?"active":""}>Most Commented</p>
                                        <p onClick={()=>setSort('downloaded')} className={sort==='downloaded'?"active":""}>Most Download</p>
                                        <p onClick={()=>setSort('random')} className={sort==='random'?"active":""}>Shuffle</p>
                                    </div>
                                </div>    
                            </>
                        )}
                        <Button className="btn" onClick={()=>apply_filter()}>Apply</Button>
                    </div>
                
                </div>
            </div>
         
       
      </Modal>
      
  );
}

