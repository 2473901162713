
import ENVIROMENTS from '../Environment/Configuration.json';
import React, {Component, useEffect, useRef, useState, useContext} from "react";
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Button from '@mui/material/Button';
import {Link, useNavigate, useLocation}  from'react-router-dom';
import {Helmet} from "react-helmet";
import {PlayContext} from '../Contexts/PlayContext.js';
import { useGlobalAudioPlayer } from "react-use-audio-player";
import useFetch from '../APIs/FetchSongs.js';
import { UserStoreContext } from '../Contexts/UserStoreContext.js';
import LogginDialog from '../Dialogs/LogginDialog.js';
export default function () {
    const [showLogIn, setShowLogIn] = useState(false);
    const {is_logged_in, user_store} = useContext(UserStoreContext)
    const navigate = useNavigate();
    const location = useLocation();
    const {setPlayData, PlayData,PlayStatus,NextSong} = useContext(PlayContext)

       
    /////////////////////////////Get Data
    const [data, setData]= useState(null)
    const [request,setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    
    useEffect(()=>{
        if(PlayData){
            if(PlayData.platform==="geniuxmusic"){
                setRequest({
                    "user_id":"",
                    "song_id":PlayData?PlayData.data.song.song_id:"",
                    "search_string":"",
                    "genre":"",
                    "sort":"",
                    "page":1,
                    "listens": "allowed"
                })
            }
            
            
        }
    },[PlayData])




    
    const { getPosition, duration,play, pause } = useGlobalAudioPlayer({ highRefreshRate: false })
    const [pos, setPos] = useState(0)
    const frameRef = useRef()
    useEffect(() => {
        const animate = () => {
            setPos(getPosition())
            frameRef.current = requestAnimationFrame(animate)
        }

        frameRef.current = window.requestAnimationFrame(animate)

        return () => {
            if (frameRef.current) {
                cancelAnimationFrame(frameRef.current)
            }
        }
    }, [])

    const PlaySong = () =>{
        if(PlayStatus==='playing'){
            pause()
        }else{
            play()
        } 
    }

    useEffect(() => {
        if((pos / duration)*100>10){
            if(!is_logged_in){
                if(PlayStatus==='playing'){
                    setShowLogIn(true)
                    pause()
                }
            }
        }
    }, [pos])
    return ( 
        <>
        {((PlayData && PlayData.platform==="geniuxmusic") && (!location.pathname.includes("/playing/"+PlayData.data.song.song_id))) && (
        
        <>
            <Helmet>
                <title>{PlayData.data.song.title}</title>
                <meta property="og:title" content={PlayData.data.song.title} key="1" />
                <meta name="title" content={PlayData.data.song.title} key="2"/>
                <meta name="description" content={PlayData.data.user.artist_name} key="3"/>
                {PlayData.data.song.artcover.length>2?
                <meta property="og:image" content={ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/arts/original/?file_name="+PlayData.data.song.artcover} key="4"/>
                :
                <meta property="og:image" content="/assets/images/defaultsongart.jpg" key="4"/>
                }
            </Helmet>
            <div className="minPlayer" style={{"--minprogress": ((pos / duration)*100)+'%'}}>
            
                <div className="min-container">

                    
                    <div className="details">
                        <div className="btns">
                        
                            <Button className="playbtn" color={PlayStatus==="playing"?"primary": "secondary"} variant="contained" onClick={()=>PlaySong()} >
                                {PlayStatus==="playing"?<PauseIcon/>:<PlayArrowIcon/>}
                            </Button>
                            <Button className="skipBtn"   onClick={()=>NextSong()}>
                                <SkipNextIcon/>
                            </Button>
                        </div>
                    
                    
                        <Link to={'/playing/'+PlayData.data.song.song_id} className="titles">
                            <div>
                                <p>{PlayData.data.song.title}</p>
                                <p>{PlayData.data.user.artist_name}</p>
                            </div>
                        </Link>
                        
                    </div>
                    <div className="rgt">
              
                        {PlayData.data.song.artcover.length>1?
                        <Link to={'/playing/'+PlayData.data.song.song_id}>
                            <img src={ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/arts/smaller/?file_name="+PlayData.data.song.artcover.toString()}/>
                        </Link>
                        :
                        <Link to={'/playing/'+PlayData.data.song.song_id}>
                            <img  src="/assets/images/defaultsongart.jpg"/>
                        </Link>
                                            
                        }
                    
                    </div>
                </div>      
                
            </div>
        </>
        
        )}

        {showLogIn &&(
            <LogginDialog setShowLogIn={setShowLogIn}/>
        )}
      
        </>
    )
}