import React from 'react'
const Card05 = () => {
    return (
        <>
            <div className="card05-skeleton anime-skeleton"> 
                <div className="details">
                    <p></p>
                    <p></p>
                    <p></p>
                </div>
            </div>  
           
        </>
    
    )
}

export default Card05
