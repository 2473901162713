import Button from '@mui/material/Button';
import React, {Component} from "react";
import {useState, useEffect} from 'react'
import {Link, useNavigate  }  from'react-router-dom';
const TrackCard = (props) => {
    const navigate = useNavigate()
    const [data,setData] = useState(null)

    useEffect(()=>{
        if(props.data){
            setData(props.data)
        }
    },[props.data])

    return (
        <>
   
            {data &&(
                <div className="card13">
                    <div className="lft">
                        <Link className="title" to={"/playing/"+data.song.song_id}>
                            {data.song.title}
                        </Link>
                    </div>
                    <div className="rgt">
                       <Link to={'/uploads/update/'+data.song.song_id}><Button color="secondary">UPDATE</Button></Link>
                    </div>
                </div>
            )}

        </>
        
    )
}

export default TrackCard
