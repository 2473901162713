import {useState,useEffect} from 'react'
import Container from '../../Components/Auth/Container'
import {Helmet} from "react-helmet";

export default function Uploads () {
    const[innerWidth,setinnerWidth] = useState(window.innerWidth)
    useEffect(()=>{
        window.scrollTo(0, 0)
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])
    return (
        <>
        
        <Helmet>
            <title>Login/Register</title>
            <meta property="og:title" content="Login/Register" />
            <meta name="title" content="Login/Register" />
            <meta name="description" content="Login/Register on geniux music"/>
            <meta property="og:image" content="/assets/images/geniux_music_logo.png" />
        </Helmet>
         <Container innerWidth={innerWidth} /> 
            
        </>
    )
}


