import {useState,useEffect} from 'react'
import Container from '../../Components/Auth/Logout'
import {Helmet} from "react-helmet";

export default function Logout () {
    const[innerWidth,setinnerWidth] = useState(window.innerWidth)
    useEffect(()=>{
        window.scrollTo(0, 0)
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])
    return (
        <>
        
        <Helmet>
            <title>Logout</title>
            <meta property="og:title" content="Logout"/>
            <meta name="title" content="Logout"/>
            <meta name="description" content="Logout of geniux music"/>
            <meta property="og:image" content="/assets/images/geniux_music_logo.png"/>
        </Helmet>
         <Container innerWidth={innerWidth} /> 
            
        </>
    )
}


