import {useState,useEffect, useContext} from 'react'
import Container from '../../../../Components/ManageAccount/UpdateAccount'
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom"; 
import { UserStoreContext } from '../../../../Components/Contexts/UserStoreContext';
import ValidateUser from '../../../../Components/UserCredentials/ValidateUser';
export default function MaxPlayer () {
    const navigate = useNavigate();
    const[innerWidth,setinnerWidth] = useState(window.innerWidth)
    useEffect(()=>{
        window.scrollTo(0, 0)
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])
    const {is_logged_in} = useContext(UserStoreContext)
    useEffect(()=>{
        if(is_logged_in===true || is_logged_in===false){
            if(is_logged_in===false){
                navigate('/login')
            }
        }
    },[is_logged_in])

    return (
        <>
          <ValidateUser/>
            <Helmet>
                <title>Profile - Manage Account</title>
                <meta property="og:title" content="Profile - Manage Account" />
                <meta name="title" content="Profile - Manage Account"/>
                <meta name="description" content="Manage your account"/>
                <meta property="og:image" content="/assets/images/geniuxbanner.jpeg"/>
            </Helmet>
            <Container innerWidth={innerWidth}/> 
        </>
    )
}


