
import React, {Component} from "react";
import {useState, useEffect,useContext} from 'react'
import useFetch from '../APIs/FetchAlbums.js';
import LoadingSkeleton from '../LoadingSkeletons/AlbumCard.js';
import AlbumCard from '../Cards/AlbumCard.js';
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import { Navigation } from "swiper/modules";
const Albums = (props) => {

    const [data, setData] = useState(null)
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    const send_request =()=>{
        setRequest({
            "search_string":"",
            "genre": "",
            "album_id": "",
            "user_id": props.user_id,
            "genre": "",
            "sort": "newest",
            "page":1,
        })
    }
  
    useEffect(()=>{
        send_request()
    },[props.user_id])

    useEffect(()=>{
        if(FetchedData){
            setData(FetchedData)
        }
    },[FetchedData])


    return (
        <>
        {data &&(
        <>
            <div className="htitle">
                <div className="lft"><p>ALBUMS</p></div>
                {data.total_results > 3 && (<div className="rgt"><p><Link to={"/profile/browse/"+props.user_id+"?group=albums"}>See more</Link></p></div> )}
            </div>
            {props.innerWidth<992 &&(
                <div className="vertical-container">
                    {data.results.slice(0,10).map((album,index)=>( 
                        <AlbumCard data={album} key={index}/>
                    ))}
                </div>
            )}
            {props.innerWidth>992 &&(
                <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                        992: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        1200: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1600: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                    }}
                    className="mySwiper"
                >
                        {data.results.slice(0,10).map((album,index)=>( 
                            <SwiperSlide key={index}><AlbumCard data={album} key={index}/></SwiperSlide>
                    ))}
                    
                </Swiper>           
            )}  
          
        </>
        )}
         {(isLoading) &&(
            <>
                <div className="htitle">
                    <div className="lft"><p>ALBUMS</p></div>
                </div>
                {props.innerWidth<992 &&(
                    <div className="vertical-container">
                        <LoadingSkeleton/>
                        <LoadingSkeleton/>
                        <LoadingSkeleton/>
                        <LoadingSkeleton/>
                        <LoadingSkeleton/>
                        <LoadingSkeleton/>
                        <LoadingSkeleton/>
                    </div>
                )}
                {props.innerWidth>992 &&(
                    <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        breakpoints={{
                            992: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1600: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                            },
                        }}
                        className="mySwiper"
                    >
                        <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                        <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                        <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                        <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                        <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                        
                    </Swiper>           
                )}
            </>
        )}
        </>
    )
}

export default Albums
