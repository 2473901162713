import React, {Component} from "react";
import {useState, useEffect} from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';



export default function BasicModal(props) {
  
    const navigate = useNavigate()

    const [open, setOpen] = useState(false);

  
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        
            setOpen(false)
            props.setisDialogOpened(false)
        

    }


    useEffect(()=>{
        handleOpen()
    },[])



  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
            <div className="myModal fadefx">
                <div className="container">
                    <div className="errIcon">
                        <CheckCircleIcon/>
                    </div>
                    <div className="errbody">
                        <p><b>Your song has been updated successfully.</b><br/><br/>
                            {props.song_title}</p><br/>


                        <Button onClick={()=>  navigate('/playing/'+props.song_id)} className="btn">Go To Song</Button>
                        <Button onClick={()=>handleClose()} className="btn closebtn">Close</Button>
                    </div>
                </div>
            </div>
            
      </Modal>
  );
}



