//Native Feature
import { LocalNotifications } from '@capacitor/local-notifications';
import useFetch from '../APIs/FetchNotifications.js';
import React, {Component} from "react";
import {useState, useEffect, useContext} from 'react';
import { UserStoreContext } from '../Contexts/UserStoreContext';

const PushNotification = () => {
    const {is_logged_in, user_store} = useContext(UserStoreContext)

    const [request, setRequest]= useState(false)
    const [status, setStatus] = useState('unseen')
    const [sort, setSort] = useState('newest')
    const [page, setPage] = useState(1)
    const [data, setData]= useState(null)



    const {data:FetchedData,isLoading, error,hasmore} = useFetch(status,sort,page,request)

    useEffect(()=>{
        if(is_logged_in && user_store){
            setRequest(!request)
        }
    },[is_logged_in])

        useEffect(()=>{
            
            if(FetchedData){
                setData(FetchedData)
            }
        },[FetchedData])

        
      useEffect(()=>{
        if(data){
        
            LocalNotifications.schedule({
                notifications: [
                    {
                    title: "Notification",
                    body: data.length>1?"You have "+data.length+" new notitications":"You have new notification",
                    id: 1,
                    schedule: { at: new Date(Date.now() + 1000 * 5) },
                    sound: null,
                    attachments: null,
                    actionTypeId: "",
                    extra: null
                }
                
                ]
            });
            
        }

        
    },[data])

   
}

export default PushNotification
