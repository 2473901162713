import React, {Component} from "react";
import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import UserCard from '../Cards/UserCard';
import useFetch from '../APIs/FetchUsers.js';
import LoadingSkeleton from '../LoadingSkeletons/UserCard03.js';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import { Navigation } from "swiper/modules";

const TopArtists = (props) => {

    //userId,songId,search_string,genre,sort,page,
    
    const [data, setData] = useState(null);
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData){
            setData(FetchedData)
        }
    },[FetchedData])


    const send_request =()=>{
        setData(null)
        setRequest({
            "user_id":"",
            "search_string":props.filters.search_string,
            "role":props.filters.role,
            "sort":"played",
            "page":"",
        })
    }



    useEffect(()=>{
        send_request()
    },[props.filters])

    return (
    <>
    
      {isLoading &&(
        <>
            <section>
                <div className="row">
                    <div className="col-12 col-lg-12">
                 
                        <div className="htitle">
                            <div className="lft"><p>POPULAR ARTISTS {props.filters.search_string!=="" &&(<>(0)</>)} <span className="platform">GENIUX MUSIC</span></p></div>
                            <div className="rgt"><p><Link to={props.filters.search_string!=''?"/browse?group=people&search="+props.filters.search_string+"&sort=played":"/browse?group=people&sort=played"}>See more</Link></p></div>
                        </div>
                        
                        {props.innerWidth<992 &&(
                            <div className="vertical-container">
                                <LoadingSkeleton/>
                                <LoadingSkeleton/>
                                <LoadingSkeleton/>
                                <LoadingSkeleton/>
                                <LoadingSkeleton/>
                                <LoadingSkeleton/>
                                <LoadingSkeleton/>
                            </div>
                        )}
                        {props.innerWidth>992 &&(
                            <Swiper
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    992: {
                                        slidesPerView: 5,
                                        spaceBetween: 20,
                                    },
                                    
                                    1600: {
                                        slidesPerView: 7,
                                        spaceBetween: 30,
                                    },
                                }}
                                className="mySwiper"
                            >
                                <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            </Swiper>           
                        )}
                    </div>
                </div>
            </section>

        </>
        )}
        {data && data.total_results>1 &&(
            <>
            <section>
                <div className="row">
                    <div className="col-12 col-lg-12">
                    
                        <div className="htitle">
                            <div className="lft"><p>POPULAR ARTISTS {props.filters.search_string!=="" &&(<>({data.total_results})</>)} <span className="platform">GENIUX MUSIC</span></p></div>
                            {data.total_results > 10 &&(
                                <div className="rgt"><p><Link to={props.filters.search_string!=''?"/browse?group=people&search="+props.filters.search_string:"/browse?group=people"}>See more</Link></p></div>
                            )}
                             
                        </div>
                        {props.innerWidth<992 &&(
                            <div className="vertical-container">
                                {data && (
                                    data.results.slice(1,10).map((users,index)=>{
                                        return <UserCard key = {index} data = {users} />
                                    }
                                ))}

                            </div>
                        )}
                        {props.innerWidth>992 &&(
                        <Swiper
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    992: {
                                        slidesPerView: 5,
                                        spaceBetween: 20,
                                    },
                                    1600: {
                                        slidesPerView: 7,
                                        spaceBetween: 30,
                                    },
                                }}
                                className="mySwiper"
                            >
                                {data && (
                                    data.results.slice(1,10).map((users,index)=>{
                                        return <SwiperSlide key = {index}><UserCard  data = {users} /></SwiperSlide>
                                    }
                                ))}
                            
                            </Swiper>           
                        )}
                     </div>
                </div>
            </section>
            </>
        )}
    </>  
    )
}

export default TopArtists
