import ENVIROMENTS from '../Environment/Configuration.json';
import React, {Component} from "react";
import {useState,useEffect,useContext} from 'react'
import NavigationMenuToggle from '../Navigation/NavigationMenuToggle.js'

import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {Link, useNavigate  }  from'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { UserStoreContext } from '../Contexts/UserStoreContext.js';
import useFetch from '../APIs/UpdateAccount.js';
import ErrorIcon from '@mui/icons-material/Error';
import ImageCrop from './ImageCrop.js';
import ProfileUpdatedDialog from '../Dialogs/ProfileUpdated.js';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import DeleteAccount from '../Dialogs/DeleteAccount.js'
import {ViewContext} from '../Contexts/ViewContext.js';
const ManageAccount = (props) => {
    const {setViewUserData} = useContext(ViewContext)
    
    const navigate = useNavigate()
    const {is_logged_in, user_store} = useContext(UserStoreContext)
    const fetchInterests = [
        {
            "id": "15",
            "interests": "Amateur"
        },
        {
            "id": "2",
            "interests": "Artist"
        },
        {
            "id": "7",
            "interests": "Beat Maker"
        },
        {
            "id": "8",
            "interests": "Composer"
        },
        {
            "id": "14",
            "interests": "Dancer"
        },
        {
            "id": "16",
            "interests": "Director"
        },
        {
            "id": "11",
            "interests": "Ghost Writer"
        },
        {
            "id": "13",
            "interests": "Instrumentalist"
        },
        {
            "id": "9",
            "interests": "Lyricist"
        },
        {
            "id": "18",
            "interests": "Manager"
        },
        {
            "id": "3",
            "interests": "Musician"
        },
        {
            "id": "1",
            "interests": "Producer"
        },
        {
            "id": "6",
            "interests": "Rapper"
        },
        {
            "id": "20",
            "interests": "Record Label"
        },
        {
            "id": "19",
            "interests": "Regular User"
        },
        {
            "id": "5",
            "interests": "Singer"
        },
        {
            "id": "12",
            "interests": "Song Writer"
        },
        {
            "id": "4",
            "interests": "Sound Engineer"
        },
        {
            "id": "17",
            "interests": "Trapper"
        },
        {
            "id": "10",
            "interests": "Writer"
        }
    ]
    const[isDialogOpened, setisDialogOpened] = useState(false)
    const[userId, setUserId] = useState(null)

    const[isUploadingAvatar, setIsUploadingAvatar] = useState(false)

    const[current_user_avatar, setCurrentUserAvatar] = useState(null)
    const[new_user_avatar, setNewUserAvatar] = useState(null)
    const[fullname, setFullName] = useState('')
    const[interest, setInterest] = useState('')
    const[email, setEmail] = useState('')
    const[bio, setBio] = useState('')
    const[showemail, setShowEmail] = useState(false)
    const[showphone, setShowPhone] = useState(false)

    const[newpassword, setNewPassword] = useState('')
    const[currentpassword, setCurrentPassword] = useState('')
    
    useEffect(()=>{
        if(is_logged_in && user_store){
            setFullName(user_store.artist_name)
            setInterest(user_store.interest)
            setEmail(user_store.email)
            setBio(user_store.bio)
            setCurrentUserAvatar(user_store.user_avatar)
            setUserId(user_store.user_id)
            if(user_store.show_cellphone==="true"){
                setShowPhone(true)
            }else{
                setShowPhone(false)
            }
            if(user_store.show_email==="true"){
                setShowEmail(true)
            }else{
                setShowEmail(false)
            }
            setViewUserData(
                {
                    "id": user_store.user_id,
                    "user_id":user_store.user_id,
                    "artist_name": user_store.artist_name,
                    "img_url": user_store.user_avatar.length>1?"/geniuxmusic/api/media/avatars/smaller/?file_name="+user_store.user_avatar:"",
                    "platform": "geniuxmusic"
                }
            );

            // Scroll to current selected interest 
            setTimeout(()=>{
                if(document.querySelector(".selectoptions p.active")!== null){
                    document.querySelector(".selectoptions p.active").scrollIntoView({
                        block: 'center',
                        inline: 'center',
                    }); 
                    window.scrollTo(0, 0);
                }
            },[1000])

          
            
        }
    },[is_logged_in])

    const onhandle_fullname = (e) =>{
        if(e.target.value.length<=60){
            setFullName(e.target.value)
        }
     
    }
   
    const onhandle_email = (e) =>{
        if(e.target.value.length<=60){
            setEmail(e.target.value)
        }
       
    }
    const onhandle_bio = (e) =>{
        if(e.target.value.length<=150){
            setBio(e.target.value)
        }
        
    }
    const onhandle_newpassword = (e) =>{
        if(e.target.value.length<=60){
            setNewPassword(e.target.value)
        }
     
    }
    const onhandle_current_password = (e) =>{
        if(e.target.value.length<=60){
            setCurrentPassword(e.target.value)
        }
       
    }

    
  
    const [fields, setFields] = useState({
        'current_user_avatar':'',
        'fullname':'',
        'interest':'',
        'email':'',
        'bio':'',
        'showemail':'',
        'showphone':'',
        'newpassword':'',
        'currentpassword':''
      })
    //Validate Fields
    const [myError, setMyError] = useState(null);

    const [data,setData] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(fields)


    useEffect(()=>{
        if(FetchedData){
            setisDialogOpened(true)
        }
    },[FetchedData])

    useEffect(()=>{
        setMyError(error)
       
        if(error==="email_already_registered" || error==="email_empty"){
            document.getElementById('email').focus()
        }else if(error==="fullname_empty"){
            document.getElementById('fullname').focus()
        }else if(error==="newpassword_is_short"){
            document.getElementById('newpassword').focus()
        }else if(error==="incorrect_current_password"){
            document.getElementById('currentpassword').focus()
        }
        
    },[error])
    const sendrequest = () =>{
        setData(null)
        setFields({
            'new_user_avatar':new_user_avatar,
            'fullname':fullname,
            'interest':interest,
            'email':email,
            'bio':bio,
            'showemail':showemail,
            'showphone':showphone,
            'newpassword':newpassword,
            'currentpassword':currentpassword

          })
      }

      
    const onhandle_submit= (e) =>{
        e.preventDefault()
        setMyError(null)
        if(fullname===""){
            setMyError('fullname_empty')
            document.getElementById('fullname').focus()
        }else if(interest===""){
            setMyError('interest_empty')
            document.getElementById('currentpassword').focus()
        }else if(email===""){
            setMyError('email_empty')
            document.getElementById('email').focus()
        }else if(currentpassword===""){
            setMyError('current_password_empty')
            document.getElementById('currentpassword').focus()
        }else if(newpassword!=="" && newpassword.length<6 ){
            setMyError('newpassword_is_short')
            document.getElementById('newpassword').focus()
        }else{
            if(new_user_avatar){
                if(new_user_avatar.size>10094588){
                    setMyError('user_avater_huge')
                }else{
                    if(new_user_avatar.type ==="image/jpeg" || new_user_avatar.type ==="image/png" || new_user_avatar.type ==="image/webp"){
                        sendrequest()
                    }else{
                        setMyError('user_avater_format_error')
                    }
                 
                }
            }else{
               sendrequest() 
            }
        
            
        }
        
    
    }


    const [showpass, setShowPass] = useState(false);

    const [showpass_n, setShowPass_n] = useState(false);
    
    const[isShowDelete,setShowDelete] = useState(false)
    return (
        <>
        {props.innerWidth<992 &&(
            <div className="navbar">
                <div className="wrapper">
                    <div className="lft">
                        <IconButton className="icon" onClick={()=>navigate(-1)}>
                            <ArrowBackIcon />
                        </IconButton> 
                        <div className="titles">
                            <p>UPDATE ACCOUNT</p>
                        </div>
                    
                    </div>
                    <div className="rgt">
                        <NavigationMenuToggle/>
                    </div>
                </div>
                
            </div>
        )}

        <div className="mycontainer">
            {props.innerWidth>992 &&(
                <div className="mypath">
                    <div className="wrapper">
                        <div className="lft">
                        {window.history.length>1?
                            <Button onClick={()=>navigate(-1)}>
                            <KeyboardBackspaceIcon/>
                            </Button>  
                            :
                            <Button onClick={()=>navigate(-1)} disabled="true">
                                <KeyboardBackspaceIcon/>
                            </Button>  
                        }
                        </div>
                        <div className="rgt">
                            <p>
                                <Link to="/settings" className="active">Settings</Link><Link to="/profile/manage/account" className="">/ Manage Account</Link>
                            </p>
                        </div>
                    </div>
                </div>
            )}
            <div className="manageaccount">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <div className="profilebanner" onClick={()=>setIsUploadingAvatar(true)}>
                                
                                {!isUploadingAvatar && (
                                    
                                    current_user_avatar?
                                    <>
                                    <div className="userpp">
                                        <img className="ppimage" src={ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/avatars/medium/?file_name="+current_user_avatar}/>
                                        <div className="icon"><CameraAltOutlinedIcon/></div>
                                    </div>
                                        
                                    </>
                                        
                                    :  
                                    <div className="imgcrop">
                                        <ImageCrop className="" data = {data} setNewUserAvatar={setNewUserAvatar}/>
                                    </div>           
                                    
                                
            
                                
                                )}
                                {isUploadingAvatar &&(
                                    <div className="imgcrop">
                                        <ImageCrop className="" data = {data} setNewUserAvatar={setNewUserAvatar}/>
                                    </div>   
                            
                                    
                                )}
                                
                            </div> 
                        <form onSubmit={onhandle_submit} className="myform">
                                <div className="fields">
                                    
                                    <TextField variant="filled" id="fullname" onChange={onhandle_fullname} value={fullname} label="Full Name"  />
                                    {myError==="interest_empty"?<p className="mylabel error">Select Interest<div className="icon">Required<ErrorIcon/></div></p>:
                                    <p className="mylabel">Select Interest</p>}
                                    
                                    <div className="selectoptions">
                                        {fetchInterests.map((data,index)=>(
                                            <p key={index} onClick={()=>setInterest(data.interests)} className={interest===data.interests?"active":""}>{data.interests}</p>
                                        ))}
                                    </div>
                                    <TextField variant="filled" id="email" type="email" onChange={onhandle_email}  value={email}  label="Email"  />
                                    <TextField variant="filled" label={"Brief Biography ("+bio.length+"/150)"} onChange={onhandle_bio}  value={bio} multiline rows={4} />
                                    
                                    <div className="passwordfield">
                                        <TextField variant="filled" id="newpassword" inputProps={{autoComplete: 'new-password'}} onChange={onhandle_newpassword} value={newpassword} type={showpass_n?"text":"password"}  label="Change Password(Optional)"  />
                                        {newpassword!=="" &&(
                                            <div className="icon" onClick={()=>setShowPass_n(!showpass_n)}>
                                                {showpass_n?"HIDE":"SHOW"}
                                            </div> 
                                        )}
                                    </div>

                                    <div className="mycheckboxes">
                                        <p className="mylabel">Your Privacy</p>
                                        <div className={showemail?"mycheck active": "mycheck"} onClick={()=>setShowEmail(!showemail)}>
                                            <div className="circle"><span></span></div>
                                            <p>Show my email on the profile</p>
                                        </div>
                                        <div className={showphone?"mycheck active": "mycheck"} onClick={()=>setShowPhone(!showphone)}>
                                            <div className="circle"><span></span></div>
                                            <p>Show my cell phone on the profile</p>
                                        </div>
                                    </div>
                                    <p className="mylabel">For security purpose you must enter your current password to update your changes.</p>
                    
                                    <div className="passwordfield">
                                        <TextField variant="filled" id="currentpassword" type={showpass?"text":"password"} onChange={onhandle_current_password} value={currentpassword} label="Current Password"  />
                                        {currentpassword!=="" &&(
                                            <div className="icon" onClick={()=>setShowPass(!showpass)}>
                                                {showpass?"HIDE":"SHOW"}
                                            </div>     
                                        )}
                                    </div>
                                    
                                    <div className="myerr">  
                                        {myError==="fullname_empty"?<p>Fullname is required</p>:""}
                                        {myError==="email_empty"?<p>Email is required</p>:""}
                                        {myError==="current_password_empty"?<p>Current password is required</p>:""}
                                        {myError==="interest_empty"?<p>Current password is required</p>:""}
                                        {myError==="newpassword_is_short"?<p>New password must have mininum of 6 characters</p>:""}
                                        {myError==="incorrect_current_password"?<p>Current password is incorrect</p>:""}
                                        {myError==="network_err"?<p>Check your internet connectivity</p>:""}
                                        {myError==="email_already_registered"?<p>Email is already registered</p>:""}
                                        {myError==="unknow_error"?<p>Something went wrong, try again later</p>:""}
                                        {myError==="user_avater_huge"?<p>Image is huge, max size is 10MB</p>:""}
                                        {myError==="user_avater_format_error"?<p>Image is not supported, only png/jpeg</p>:""}
                                    </div>
                                    <div className="btns">
                                        <Button type="submit" className="defaultBTN" color="primary">Update Account {isLoading && <CircularProgress/>}</Button>
                                    </div> 
                                </div>
                            </form>  
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="rightmenu">
                                <div className="content-container">
                                    <div className="htitle">
                                        <div className="lft"><p> Your Geniux Music Account</p></div>
                                    </div>
                                    <p>
                                    In order to access certain features of the Service, you must create and/or sign into a user account (“Geniux Music Account”) of your own. Creating an account is completely free. Use of another’s account is not permitted. When creating your Geniux Music Account, you must provide accurate and complete information.
                                    <br/><br/>
                                    You are solely responsible for the activity that occurs on your Geniux Music Account. You are also responsible for maintaining the security of your account password, as well as the passwords of any third-party services that you may have elected to link to your account.
                                    <br/><br/>
                                    Please review our fully transparent Privacy Policy for information regarding security, confidentiality, and what we do with the data you provide us.
                                    <br/><br/>
                                    We will always make a reasonable effort in ensuring that our Services are available. Should the Services be interrupted in any way, we will make a reasonable effort to correct the interruptions without delay. We are, however, not liable for any errors, delays, or interruptions that might occur.
                                    <br/><br/>
                                    </p>
                                    <div className="htitle">
                                        <div className="lft"><p> YOUR USE OF THE Geniux Music SERVICE</p></div>
                                    </div>
                                    <p>
                                        The Geniux Music Service may be used and accessed solely for lawful purposes. You agree to abide by all applicable laws and regulations in connection with your use of the Service. You agree and warrant that you will not use the Geniux Music Service to transmit, distribute, route, provide connections to or store any material that infringes copyrighted works or otherwise violates or promotes the violation of the intellectual property rights of any third party. You also agree to not threaten, harass, abuse, slander, defame or otherwise violate the legal rights of any employee of Geniux Music. Geniux Music may remove your Recordings and terminate your Account if you are abusive or rude or provide false or intentionally misleading information to any Geniux Music employees or agents.
                                        <br/><br/>       
                                        Your Geniux Music Account shall be used solely by you and may not be transferred or shared with any third party. You acknowledge that you are exclusively responsible for all usage or activity on your Geniux Music Account. You shall immediately notify Geniux Music of any breach of security or unauthorized use of your Geniux Music account. Any fraudulent, abusive, or otherwise illegal account activity, including manipulated streams, shall constitute a basis to terminate your account. You agree to indemnify Geniux Music against any liability and costs arising from such improper use of your Geniux Music Account.
                                        <br/><br/>
                                    </p>
                                    <div className="htitle">
                                        <div className="lft"><p>REMOVE ACCOUNT</p></div>
                                    </div>
                                    <p>
                                        We will not be responsible for your loss, and there's no turning back.
                                    </p>
                                    <div className="delete-account">
                                        <Button variant="outlined" onClick={()=>setShowDelete(true)}>Delete Account</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
        {(isDialogOpened && is_logged_in) &&(
            <ProfileUpdatedDialog user_store={user_store} is_logged_in={is_logged_in} setisDialogOpened={setisDialogOpened}/>  
        )}
        {(is_logged_in && isShowDelete) &&(
            <DeleteAccount setShowDelete={setShowDelete} user_store={user_store} is_logged_in={is_logged_in}/>   
        )}
        

        </>
    )
}

export default ManageAccount
