import React, {Component} from "react";
import {useState, useEffect, useContext} from 'react'
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';
import { UserStoreContext } from '../Contexts/UserStoreContext';
import Tooltip from '@mui/material/Tooltip';
const UserCard = (props) => {
    const {is_logged_in, user_store} = useContext(UserStoreContext)
    const [showLogIn, setShowLogIn] = useState(false);

 

    const [data, setData] = useState(props.data)


   

    return (
        <div className="card002">
            {(data.images.length>0 && data.images[2])?
            <Link className="ppimg" to={"/spotify/profile/"+data.id}>
                <img src={data.images[2].url}/>
            </Link>
            :
            <Link className="ppimg" to={"/spotify/profile/"+data.id}>
                {data.name.substring(0, 1)}
            </Link> 
            }
            <div className="details">
                <Tooltip title={data.name +" ("+data.type+")"} placement="top">
                    <div className="titles">
                        <p><Link to={"/spotify/profile/"+data.id}>{data.name}</Link></p>
                        <p><Link to={"/spotify/profile/"+data.id}>{data.type}</Link></p>
                    </div>   
                </Tooltip>
                <Link to={"/spotify/profile/"+data.id}><Button >VIEW PROFILE</Button> </Link>
            </div>
        </div>
    )
}

export default UserCard
