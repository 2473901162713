import {useState, useEffect} from 'react'
import Axios from 'axios';
import ENVIROMENTS from '../Environment/Configuration.json';
const useFetch = (request) => {
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(false);
    const[error, setError] = useState(null);

    useEffect(()=>{ 
       
        if(request){
            setIsLoading(true)
            const url = ENVIROMENTS.baseUrl + '/geniuxmusic/api/recover_password.php';
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: {
                    username: request.username
                },
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                setIsLoading(false)
                if(response.data[0] && response.data.length>0){
                    setData(response.data)
                    setError(null)

                }else{
                    if(response.data.response && response.data.response==="user_not_found"){
                        setData(null)
                        setError('user_not_found');  
                    }else if(response.data.response && response.data.response==="user_id_empty"){
                        setData(null)
                        setError('user_id_empty');  
                    }else{
                        setData(null)
                        setError('unknown_err');  
                    }
                }
           
               
            })
            .catch(err=>{
                if(!Axios.isCancel(err)){
                    setError('network_err');
                    setIsLoading(false)
                } 
            })
            return () => cancel()
        }
     
        
    },[request])

    return {data, isLoading, error}
}

export default useFetch
