import React, {Component} from "react";
import {useState,useEffect, useContext} from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Create';
import ShareIcon from '@mui/icons-material/Share';
import ReportIcon from '@mui/icons-material/Report';
import EditComment from './EditComment.js';
import ReportComment from './ReportComment.js';
import { UserStoreContext } from '../Contexts/UserStoreContext';
import ShareLink from '../ShareLinkContainer.js';

import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

export default function TemporaryDrawer(props) {

  const[myuserid, setMyuserid] = useState(null);
  const {is_logged_in, user_store} = useContext(UserStoreContext)
  useEffect(()=>{
      if(is_logged_in){
          setMyuserid(user_store.user_id)
      }
  },[is_logged_in])




  const[initialOptions, setInitialOptions] = useState(true)
  const[isEditing, setIsEditing] = useState(false)
  const[isSharingLink, setIsSharingLink] = useState(false)
  const[isReportingComment, setIsReportingComment] = useState(false)

  const showEditCommentMenu = ()=>{
    setInitialOptions(false)
    setIsReportingComment(false)
    setIsEditing(true)
    setIsSharingLink(false)
  }
  const showIntialMenu = ()=>{
   
    setIsEditing(false)
    setIsReportingComment(false)
    setInitialOptions(true)
    setIsSharingLink(false)
  }
  const showReportMenu = ()=>{
   
    setIsEditing(false)
    setIsReportingComment(true)
    setInitialOptions(false)
    setIsSharingLink(false)
  }


  const showSharingLink = ()=>{
    setInitialOptions(false)
    setIsReportingComment(false)
    setInitialOptions(false)
    setIsSharingLink(true)
  }


  return (
    <>    
          {window.innerWidth<992?
          <Drawer anchor="bottom" open={true} onClose={()=>props.setisMenuOpened(false)}>
            <div className="drawercontainer">

            
              {isEditing && is_logged_in && myuserid===props.data.user_id &&(
                <EditComment showIntialMenu={showIntialMenu} setData={props.setData} data={props.data}/>
              )}

              {initialOptions &&(
                <div className="mymenucontainer">
                    <div className="mymenu">
                        <Button onClick={showSharingLink}>
                            <div>
                                <div className="icon"> <ShareIcon/></div>
                                <span>Share</span>
                            </div>
                        
                        </Button>
                        <Button onClick={showReportMenu}>
                            <div>
                                <div className="icon"> <ReportIcon/></div>
                                <span>Report</span>
                            </div>
                        </Button>
                        {(is_logged_in && myuserid===props.data.user_id) &&(
                          <Button onClick={showEditCommentMenu}>
                              <div>
                                  <div className="icon"> <CreateIcon/></div>
                                  <span>Edit</span>
                              </div>
                          </Button>
                        )}
                        
                        
                    </div>
                    
                </div>
              )}
              {isReportingComment &&(
                <ReportComment  showIntialMenu={showIntialMenu} data={props.data}/>
              )}
              {isSharingLink &&(
                <ShareLink  type = "Comment" showIntialMenu={showIntialMenu} data={props.data}/>
              )}
            </div>

          </Drawer>:
          <Modal
            open={true}
            onClose={()=>props.setisMenuOpened(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            
                <div className="myModal">
                    <div className="container">
                        <div className="header">
                            <p>MORE OPTIONS</p>
                            <Button color="secondary" onClick={()=>props.setisMenuOpened(false)}><CloseIcon/></Button>
                        </div>
                        <div className="drawercontainer">

                                      
                          {isEditing && is_logged_in && myuserid===props.data.user_id &&(
                            <EditComment showIntialMenu={showIntialMenu} setData={props.setData} data={props.data}/>
                          )}

                          {initialOptions &&(
                            <div className="mymenucontainer">
                                <div className="mymenu">
                                    <Button onClick={showSharingLink} color="secondary">
                                        <div>
                                            <div className="icon"> <ShareIcon/></div>
                                            <span>Share</span>
                                        </div>
                                    
                                    </Button>
                                    <Button onClick={showReportMenu} color="secondary">
                                        <div>
                                            <div className="icon"> <ReportIcon/></div>
                                            <span>Report</span>
                                        </div>
                                    </Button>
                                    {(is_logged_in && myuserid===props.data.user_id) &&(
                                      <Button onClick={showEditCommentMenu} color="secondary">
                                          <div>
                                              <div className="icon"> <CreateIcon/></div>
                                              <span>Edit</span>
                                          </div>
                                      </Button>
                                    )}
                                    
                                    
                                </div>
                                
                            </div>
                          )}
                          {isReportingComment &&(
                            <ReportComment  showIntialMenu={showIntialMenu} data={props.data}/>
                          )}
                          {isSharingLink &&(
                            <ShareLink  type = "Comment" showIntialMenu={showIntialMenu} data={props.data}/>
                          )}
                        </div>
                    
                    </div>
                </div>
            
          
          </Modal>
          }
          

          
    </>
  );
}
