import {useState, useEffect, useContext} from 'react'
import {useNavigate, useLocation} from 'react-router-dom';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Button from '@mui/material/Button';
import { UserStoreContext } from '../Contexts/UserStoreContext';

import Tooltip from '@mui/material/Tooltip';
import ConvertNum from '../ConvertNumbers/useConvertLargeNum';
import LogginDialog from '../Dialogs/LogginDialog.js';
import PlayOnSpotify from "../Dialogs/PlayOnSpotify.js"
const ActionBtns = (props) => {
    const navigate = useNavigate()
    const location = useLocation();
    const {is_logged_in, user_store} = useContext(UserStoreContext)

    const [data, setData] = useState(null)

    useEffect(()=>{
        setData(props.data)
    },[props.data])

    const scrolltocomments =()=>{
        if(data){
            if(!location.search.includes("tab=2")){
                setTimeout(()=>{
                    document.querySelector(".comments .CommentBox").scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'end',
                    }); 
                },[400])
            }else{
                navigate("/spotify/playing/"+data.id)
                setTimeout(()=>{
                    if(document.querySelector(".comments .CommentBox")!== null){
                      document.querySelector(".comments .CommentBox").scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'end',
                    });   
                }
                    
                },[1000])
            }
        }
        
      
    }
    const [showLogIn, setShowLogIn] = useState(false);
    const [isAvailableOnSpotify,setIsAvailableOnSpotify] = useState(false);

    const DownloadIt =() =>{
        if(is_logged_in){
            setIsAvailableOnSpotify(true)
        }else{
            
                setShowLogIn(true)
            
              
        }

      
    }
    const LikeIt =() =>{
        if(is_logged_in){
            setIsAvailableOnSpotify(true)
        }else{
            
                setShowLogIn(true)
            
              
        }

      
    }


   
    return (
        <>
        {data &&(
            <>
                <div className="">
                    <Tooltip title={"0 Plays"} placement="top"><Button className={"action-btn"}  variant="contained"><PlayCircleFilledWhiteOutlinedIcon/><p>{data.popularity}%</p></Button></Tooltip>
                    <Tooltip title={"0 Likes"} placement="top"><Button  className={"action-btn"}  onClick={()=>LikeIt()} variant="contained"><FavoriteBorderIcon/><p>10+</p></Button></Tooltip>
                    <Tooltip title={"0 Comments"} placement="top"><Button className="action-btn" onClick={()=>scrolltocomments()} variant="contained"><ModeCommentOutlinedIcon/><p>10+</p></Button></Tooltip>         
                </div>
                <div className="">
                  <Button className={"download-btn"}  onClick={()=>DownloadIt()} variant="contained"><p>Download</p><div className="downIcon"><DownloadOutlinedIcon/><p>10+</p></div></Button>                
                </div> 
                {showLogIn &&(
                    <LogginDialog setShowLogIn={setShowLogIn}/>
                )}
                {isAvailableOnSpotify &&(
                    <PlayOnSpotify setIsAvailableOnSpotify={setIsAvailableOnSpotify} title={data.name} url={"https://open.spotify.com/track/"+data.id}/>
                )}
              
              
              
                
            </>
           
          

        )}
            
         
        </>
    )
}

export default ActionBtns
