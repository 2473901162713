import React from 'react';
import TrackSkeleton from './card005.js';
const LoadingSkeleton = () => {
    return (
    <>
        <div className="loading-album-details anime-skeleton">
            <div className="p1">
                <div><p></p></div>
                <div>
                    <p></p>
                    <p></p>
                </div>
            </div>
            <div className="p2">
                <div>
                    <p></p>
                    <p style={{width: "40%"}}></p>
                </div>
                <div>
                    <p style={{width: "60%"}}></p>
                    <p></p>
                </div>
                <div>
                    <p></p>
                    <p style={{width: "20%"}}></p>
                </div>
                <div>
                    <p></p>
                    <p></p>
                </div>
                <div>
                    <p></p>
                    <p style={{width: "70%"}}></p>
                </div>
            </div>
        </div>
        <TrackSkeleton/>
        <TrackSkeleton/>
        <TrackSkeleton/>
        <TrackSkeleton/>
        <TrackSkeleton/>
        <TrackSkeleton/>
        <TrackSkeleton/>
      
            
    </>
    )
}

export default LoadingSkeleton
