
import React, {Component} from "react";
import {useState, useEffect,useContext} from 'react'
import useFetch from '../APIs/SpotifyGetArtistAlbums.js';
import LoadingSkeleton from '../LoadingSkeletons/AlbumCard.js';
import AlbumCard from '../Cards/SpotifyArtistAlbumCard.js';
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import { Navigation } from "swiper/modules";
const Albums = (props) => {

    const [data, setData] = useState(null)
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    const send_request =()=>{
        setRequest({
            "search_string":"",
            "genre": "",
            "album_id": "",
            "user_id": props.data.id,
            "genre": "",
            "sort": "newest",
            "page":1,
        })
    }
  
    useEffect(()=>{
        send_request()
    },[props.user_id])

    useEffect(()=>{
        if(FetchedData){
            setData(FetchedData)
        }
    },[FetchedData])


    return (
        <>
        {(data && data.items.length>0) &&(
            <div className="row">
                <div className="col-12 col-lg-12">
                    <section>
                        <div className="htitle">
                            <div className="lft"><p>ALBUMS</p></div>
                            {data.total > 10 &&(
                                <div className="rgt"><p><Link to={"/spotify/profile/browse/"+props.data.id+"?group=albums"}>See more</Link></p></div>
                            )}
                        </div>
                        {props.innerWidth<992 &&(
                            <div className="vertical-container">
                                {data.items.slice(0,10).map((album,index)=>( 
                                    <AlbumCard data={album} key={index}/>
                                ))}
                            </div>
                        )}
                        {props.innerWidth>992 &&(
                            <Swiper
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    992: {
                                        slidesPerView: 5,
                                        spaceBetween: 20,
                                    },
                                    1600: {
                                        slidesPerView: 6,
                                        spaceBetween: 30,
                                    },
                                }}
                                className="mySwiper"
                            >
                                    {data.items.slice(0,10).map((album,index)=>( 
                                        <SwiperSlide key={index}><AlbumCard data={album} key={index}/></SwiperSlide>
                                ))}
                                
                            </Swiper>           
                        )}  
                    </section>
                </div>
            </div>
          
        )}
         {(isLoading) &&(
            <div className="row">
                <div className="col-12 col-lg-12">
                    <section>
                            <div className="htitle">
                                <div className="lft"><p>ALBUMS</p></div>
                            </div>
                            {props.innerWidth<992 &&(
                                <div className="vertical-container">
                                    <LoadingSkeleton/>
                                    <LoadingSkeleton/>
                                    <LoadingSkeleton/>
                                    <LoadingSkeleton/>
                                    <LoadingSkeleton/>
                                    <LoadingSkeleton/>
                                    <LoadingSkeleton/>
                                </div>
                            )}
                            {props.innerWidth>992 &&(
                                <Swiper
                                    navigation={true}
                                    modules={[Navigation]}
                                    breakpoints={{
                                        640: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        992: {
                                            slidesPerView: 5,
                                            spaceBetween: 20,
                                        },
                                        1600: {
                                            slidesPerView: 6,
                                            spaceBetween: 30,
                                        },
                                    }}
                                    className="mySwiper"
                                >
                                    <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                    <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                    <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                    <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                    <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                    
                                </Swiper>           
                            )}
                            
                    </section>
                </div>
            </div>
           
        )}
        </>
    )
}

export default Albums
