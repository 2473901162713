
import React, {Component} from "react";
import {useState,useEffect,useContext} from 'react'
import {PlayContext} from '../Contexts/PlayContext.js'
import { UserStoreContext } from '../Contexts/UserStoreContext.js';
import LogginDialog from '../Dialogs/LogginDialog.js';
import {Link} from 'react-router-dom';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import TimeAgo from 'timeago-react'; 
import Tooltip from '@mui/material/Tooltip';
import PlayOnSpotify from "../Dialogs/PlayOnSpotify.js"
const TrackCard = (props) => {
    const [showLogIn, setShowLogIn] = useState(false);
    
    const {is_logged_in, user_store} = useContext(UserStoreContext)
    const {PlayData,setPlayData,PlayStatus,setPlayIndex,setPlayList} = useContext(PlayContext)


    const [data, setData] = useState(null)
    useEffect(()=>{
        if(props.data){
            setData(props.data)
        }
    },[props.data]) 

    const [isAvailableOnSpotify,setIsAvailableOnSpotify] = useState(false);
    
    const PlaySong = () =>{
        if(is_logged_in){
            setIsAvailableOnSpotify(true)
        }else{
            setShowLogIn(true)
        }
    }

 

    return (
        <>
        {data &&(
            <div className={PlayData && PlayData.data.song.song_id===data.id?"card005 active": "card005"}  >
                <div className="lft">
                    
                    <div className="trackdetails">
                        <div className="tracktitle">
                            <Tooltip title={data.name} placement="top">
                                <p><Link to={"/spotify/playing/"+data.id}>{data.track_number<10 && ("0")}{data.track_number}. {data.name}</Link></p>
                            </Tooltip>
                            <Tooltip title={"Artists: "+data.artists.map((artist,index)=>{ return " "+artist.name}) +". Released date: "+props.album_info.release_date} placement="bottom">
                            <p>
                                <b>
                                    {
                                        data.artists.slice(0,2).map((artist,index)=>{
                                            return <Link to={"/spotify/profile/"+artist.id}>{artist.name}{(index+1)<data.artists.slice(0,2).length?", ":""}</Link>
                                        })
                                    }

                                </b>{data.artists.length>2?"...":""}<TimeAgo datetime={props.album_info.release_date} />
                            </p>
                            </Tooltip>
                        </div>

                        <div className="reacticons">
                            <Link  to={"/spotify/playing/"+data.id} className="icon">
                                <PlayCircleIcon/><p>Play on spotify</p>
                            </Link>
                        </div>
                    </div>
                    
                </div> 
                <div className="rgt">
                    <div className="playbtn" onClick={()=>PlaySong()}>
                       <img src={(props.album_info.images.length>0 && props.album_info.images[1])?props.album_info.images[1].url:"/assets/images/defaultsongart.jpg"} className="artcover"/>
                       {(PlayData && PlayData.data.song.song_id===data.id && PlayStatus==='playing')?
                            <PauseIcon/>:
                            PlayData && PlayData.data.song.song_id===data.id && PlayStatus==='paused'?
                            <PlayArrowIcon/>:""
                        }
                    </div>
                </div>
            </div>
        )}
        {isAvailableOnSpotify &&(
            <PlayOnSpotify setIsAvailableOnSpotify={setIsAvailableOnSpotify} title={data.name} url={"https://open.spotify.com/track/"+data.id}/>
        )}
        {showLogIn &&(
            <LogginDialog setShowLogIn={setShowLogIn}/>
        )}
         
        </>
    )
}

export default TrackCard
