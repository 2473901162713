import React, {Component} from "react";
import {useState, useEffect, useContext} from 'react'
import UserCard from '../Cards/UserCard';
import useFetch from '../APIs/FetchFollowers';
import LoadingSkeleton from '../LoadingSkeletons/UserCard03.js';
import { Swiper, SwiperSlide } from "swiper/react";
import {Link} from 'react-router-dom';
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import { Navigation } from "swiper/modules";

const RecommendedUsers = (props) => {
    

    //userId,songId,search_string,genre,sort,page,
    
    const [data, setData] = useState(null);
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData && FetchedData.total_results>0){
            setData(FetchedData)
        }
    },[FetchedData])


    const send_request =()=>{
        setData(null)
        if(props.is_logged_in){
            setRequest({
                "user_id":props.is_logged_in ? props.user_store.user_id:"",
                "type":"following",
                "page":1,
            })
        }
        
    }



    useEffect(()=>{
        send_request()
    },[props.is_logged_in])

    return (
    <>
    
      {(isLoading || error=="network_err") &&(
        <>
        <div className="col-12 col-lg-6">
            
        </div>
            <div className="htitle">
                <div className="lft"><p>Your favourite artists</p></div>
                <div className="rgt"><p><Link to={"/profile/following/"+props.user_store.user_id}>See more</Link></p></div>
            </div>
            
            {props.innerWidth<992 &&(
                <div className="vertical-container">
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                </div>
            )}
            {props.innerWidth>992 &&(
                <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                        992: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        1200: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        
                        1600: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                    }}
                    className="mySwiper"
                >
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                </Swiper>           
            )}

        </>
        )}
        {data &&(
            <>
                <div className="htitle">
                    <div className="lft"><p>Your favourite artists</p></div>
                    {data.results.length > 10 && (
                        <div className="rgt"><p><Link to={"/profile/following/"+props.user_store.user_id}>See more</Link></p></div>
                    )}
                    
                </div>
                {props.innerWidth<992 &&(
                    <div className="vertical-container">
                        {data.results.map((users,index)=>{
                                return <UserCard key = {index} data = {users} />
                            }
                        )}

                    </div>
                )}
                {props.innerWidth>992 &&(
                   <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        breakpoints={{
                            992: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1600: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                            },
                        }}
                        className="mySwiper"
                    >
                        {data && (
                            data.results.map((users,index)=>{
                                return <SwiperSlide key = {index}><UserCard  data = {users} /></SwiperSlide>
                            }
                        ))}
                       
                    </Swiper>           
                )}
            </>
        )}
    </>  
    )
}

export default RecommendedUsers
