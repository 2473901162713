import React, { useEffect,useState } from 'react';

import { prominent } from 'color.js'
const Engagement = (props) => {
    const[dominantColor,setDominantColor] = useState(null)
    
    useEffect(()=>{
        if(props.data){
            if(props.data.images.length>2){
                prominent(props.data.images[1].url,{format: 'hex', group: 30}).then(colors => {
                    setDominantColor(colors[1]) 
                })
            }else{
                setDominantColor('#212121') 
            }
          
              
        }
        
    },[props.data])
  return (
    <>
    <div className="col-12 col-lg-6">
            <section>
                <div className="htitle">
                    <div className="lft"><p>Platform</p></div>
                </div>
                                            
                <div className="card004">
                    <div className="background" style={{"background":dominantColor?dominantColor:"#212121"}}></div>
                    <a className="icon" href="https://www.spotify.com/"  target='_blank'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path id="Path_3" data-name="Path 3" d="M19.1,10.638C15.23,8.341,8.85,8.13,5.157,9.251A1.122,1.122,0,0,1,4.506,7.1C8.745,5.816,15.791,6.065,20.244,8.708a1.122,1.122,0,1,1-1.146,1.93Zm-.126,3.4a.937.937,0,0,1-1.287.308,15.754,15.754,0,0,0-11.958-1.4.935.935,0,1,1-.543-1.79,17.5,17.5,0,0,1,13.48,1.6A.936.936,0,0,1,18.972,14.041ZM17.5,17.308a.746.746,0,0,1-1.028.249C13.657,15.835,10.11,15.446,5.933,16.4A.748.748,0,0,1,5.6,14.942c4.571-1.045,8.492-.6,11.655,1.338A.747.747,0,0,1,17.5,17.308ZM12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Z" fill="#fff"/>
                        </svg>
                    </a>
                    <div className="contain">
                        <div className="caption"> 
                            <p>Spotify</p>
                            <p>There are millions of tracks and episodes on Spotify. So whether you’re behind the wheel, working out, partying or relaxing, the right music or podcast is always at your fingertips.
                            <br/><br/>
                            <a href="https://www.spotify.com/" target='_blank'>Learn more</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
  )
}

export default Engagement