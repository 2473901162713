import {useState,useEffect} from 'react'
import {Helmet} from "react-helmet";
import React, {Component} from "react";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigationMenuToggle from '../../Components/Navigation/NavigationMenuToggle'
import {Link} from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import ValidateUser from '../../Components/UserCredentials/ValidateUser';
export default function TermsAndConditons () {
    const navigate = useNavigate();
    const[innerWidth,setinnerWidth] = useState(window.innerWidth)
    useEffect(()=>{
        window.scrollTo(0, 0)
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])


    return (
        <>
        <ValidateUser/>
        <Helmet>
            <title>Settings</title>
            <meta property="og:title" content="Settings" />
            <meta name="title" content="Settings"/>
            <meta name="description" content="Settings"/>
            <meta property="og:image" content="/assets/images/geniux_music_logo.png"/>
        </Helmet>
        
        <>
            {innerWidth<992 &&(
                <div className="navbar">
                    <div className="wrapper">
                        <div className="lft">
                            <IconButton className="icon" onClick={()=>navigate(-1)}>
                                <ArrowBackIcon />
                            </IconButton> 
                            <div className="titles">
                                <p>TERMS AND CONDITIONS</p>
                            </div>
                        
                        </div>
                        <div className="rgt">
                            <NavigationMenuToggle/>
                        </div>
                    </div>

                </div>    
            )}
            
            <div className="mycontainer">
                {/* My  path */}
                {innerWidth>992 &&(
                    <div className="mypath">
                        <div className="wrapper">
                            <div className="lft">
                                {window.history.length>1?
                                <Button onClick={()=>navigate(-1)}>
                                    <KeyboardBackspaceIcon/>
                                </Button>  
                                :
                                <Button onClick={()=>navigate(-1)} disabled="true">
                                    <KeyboardBackspaceIcon/>
                                </Button>  
                                }
                            </div>
                            <div className="rgt">
                                <p>
                                    <Link to="/settings" className="active">TERMS AND CONDITIONS</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="wrapper">
                    <br/><br/>
                    <div className="htitle">
                        <div className="lft"><p>Welcome to Geniux Music</p></div>
                    </div>
                    
                    <p>
                        Please read these Terms and Conditions ("Terms") carefully before using our mobile application (the "Service").<br/><br/>
                       <b> 1. Acceptance of Terms</b><br/>
                        By downloading, accessing, or using Geniux Music, you agree to be bound by these Terms and Conditions and our Privacy Policy. If you do not agree with these Terms, you should not use the Service.<br/><br/>

                        <b>2. Eligibility</b><br/>
                        You must be at least 13 years old to use Geniux Music. By using the Service, you represent and warrant that you meet this age requirement.<br/><br/>

                        <b>3. User Accounts</b><br/>
                        Registration: To access certain features, you may need to create an account. You agree to provide accurate, complete, and up-to-date information during registration.
                        Account Security: You are responsible for safeguarding your password and any activities or actions under your account. Notify us immediately of any unauthorized use.<br/><br/>
                        <b>4. Subscription and Payments</b><br/>
                        Free and Paid Plans: Geniux Music offers both free and paid subscription plans. Details of features and pricing can be found in the app.
                        Billing: Paid subscriptions are billed on a recurring basis, as specified during the subscription process. By subscribing, you authorize us to charge your payment method on a recurring basis.
                        Cancellations: You may cancel your subscription at any time. The cancellation will take effect at the end of the current billing cycle.<br/><br/>
                        <b>5. License and Access</b><br/>
                        License: We grant you a limited, non-exclusive, non-transferable license to use the Service for personal, non-commercial use.
                        Restrictions: You may not copy, modify, distribute, sell, or lease any part of our Service, nor may you reverse engineer or attempt to extract the source code.<br/><br/>
                        <b>6. User Content</b><br/>
                        Responsibility: You are responsible for any content you upload, post, or otherwise transmit through the Service. This includes ensuring that you have the necessary rights to share such content.
                        Prohibited Content: You agree not to upload any content that is unlawful, harmful, defamatory, obscene, or otherwise objectionable.<br/><br/>
                        <b>7. Intellectual Property</b><br/>
                        Ownership: All intellectual property rights in the Service, including but not limited to software, design, and content, are owned by Geniux Music or our licensors.
                        Trademarks: The trademarks, logos, and service marks displayed on the Service are the property of Geniux Music or third parties. You are not permitted to use them without our prior written consent.<br/><br/>
                        <b>8. Privacy</b><br/>
                        Your use of the Service is also governed by our Privacy Policy, which can be found here.<br/><br/>

                        <b>9. Termination</b><br/>
                        We may suspend or terminate your access to the Service at any time, with or without cause or notice, if you breach these Terms or if we believe you have violated any laws or regulations.<br/><br/>

                        <b>10. Disclaimers and Limitation of Liability</b><br/>
                        As Is: The Service is provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the Service.
                        Limitation of Liability: To the maximum extent permitted by law, Geniux Music will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.<br/><br/>
                        <b>11. Indemnification</b><br/>
                        You agree to indemnify and hold harmless Geniux Music, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, and expenses, including legal fees, arising out of or in any way connected with your use of the Service or violation of these Terms.<br/><br/>

                        <b>12. Governing Law</b><br/>
                        These Terms shall be governed and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law provisions.<br/><br/>

                        <b>13. Changes to Terms</b><br/>
                        We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.<br/><br/>

                        <b>14. Contact Us</b><br/>
                        If you have any questions about these Terms, please contact us at <a href="mailto:music@geniux.co.za"></a><br/><br/>

                        By using Geniux Music, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
                    </p>
                </div>
            </div>
        </>
        
        </>
    )
}



