import React, {Component} from "react";
import {useState, useEffect, useRef,useCallback} from 'react';
import useFetch from '../APIs/FetchCommentsReplies.js';
import CommentReplyCard from './CommentReplyCard.js';
import LoadingSkeleton from '../LoadingSkeletons/CommentsReplies.js';
import NoInternet from '../Dialogs/NoInternet.js';
import CommentBoxReply from './CommentBoxReply.js';
const FetchCommentReplies = (props) => {


    const [page,setPage] = useState(1);

    const [data, setData] = useState(null);
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData && FetchedData.total_results>0){
            if(data && data.length>0){
              setData([...data, ...FetchedData.results])
            }else{
                setData(FetchedData.results)
            }
        }
    },[FetchedData])


    const send_request =()=>{
        setRequest({
            "user_id":"",
            "song_id":props.song_id,
            "commented_id": props.comment_id,
            "search_string":"",
            "sort":"newest",
            "page":page,
        })
    }



    useEffect(()=>{
        send_request()
    },[page])

    useEffect(()=>{
        setData(null)
        setPage(1)
        send_request()
    },[props.song_id,props.comment_id])

    const LoadMore = ()=>{
        const currpage = page;
        setPage(currpage+1)
    }
  
    const observer = useRef()
    const lastBookElementRef = useCallback(node=>{
  
        if(observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries=>{
            if(entries[0].isIntersecting){
  
              if(FetchedData && FetchedData.results.length>0){
                LoadMore()
              }
                
            }
        })
        if(node) observer.current.observe(node)
       
    },[FetchedData])


    //Handle Error Onclick
    const handleError = ()=>{
        send_request()
    }
    const[showNoInternet,setShowInternet] = useState(false)
    useEffect(()=>{
        if(error){
            setShowInternet(true)
        }
    },[error])

    const closeModal = ()=>{
        setShowInternet(false);
    }


    return (
        <>
            <CommentBoxReply comment_id = {props.comment_id} song_id = {props.song_id} send_request={send_request} setData={setData} setPage={setPage}/>
            <div className="replies">
                    {data && (
                        data.map((info,index)=>{
                            
                            return <CommentReplyCard 
                                key = {index}
                                data = {info}
                            />
                        })
                    )}
                    <div ref={lastBookElementRef}></div>
            </div>
          
             
            {isLoading &&(
                <LoadingSkeleton/>
            )}
          
            {(data && showNoInternet)&&(
                <NoInternet closeModal={closeModal} handleError={handleError}/>
            )}
        </>
    )
}

export default FetchCommentReplies
