import {useState,useEffect} from 'react'
import Container from '../../Components/Notifications/Container'
import ValidateUser from '../../Components/UserCredentials/ValidateUser';
import {Helmet} from "react-helmet";
const url = '/geniuxmusic/api/fetch_songs.php'

export default function MaxPlayer ({props}) {
    const[innerWidth,setinnerWidth] = useState(window.innerWidth)
    useEffect(()=>{
        window.scrollTo(0, 0)
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])
    return (
        <>
        <ValidateUser/>
        <Helmet>
            <title>Notifications</title>
            <meta property="og:title" content="Notifications"/>
            <meta name="title" content="Notifications"/>
            <meta name="description" content="Stay up-to-date with geniux music."/>
            <meta property="og:image" content="/assets/images/geniux_music_logo.png"/>
        </Helmet>
         <Container innerWidth={innerWidth}/> 
      
        </>
    )
}



