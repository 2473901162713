import {useState, useEffect,useContext} from 'react'
import { UserStoreContext } from '../Contexts/UserStoreContext';
import Axios from 'axios';
import ENVIROMENTS from '../Environment/Configuration.json';
const useFetch = (request) => {
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null);
    
    
    const {is_logged_in, user_store} = useContext(UserStoreContext)

    useEffect(()=>{ 
        if(request){
            setData(null)
            setError(null);  
            setIsLoading(true)
            const url = ENVIROMENTS.baseUrl + '/geniuxmusic/api/spotify/get_track.php';
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: {
                    song_id: request.song_id
                },
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                setIsLoading(false)
                if(response.data && response.data.name){
                    setData(response.data)
                }else{
                    setError('no_results')
                }
           
               
            })
            .catch(err=>{
                if(!Axios.isCancel(err)){
                    setError('network_err');
                    setIsLoading(false)
                }
            })
            return () => cancel()
     
        }
    },[request])
 
    return {data, isLoading, error}
}

export default useFetch
