import ENVIROMENTS from '../Environment/Configuration.json';
import React, {Component} from "react";
import {useState, useEffect, useContext} from 'react'
import {useNavigate, useLocation} from 'react-router-dom';

import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import Button from '@mui/material/Button';
import useFetch from '../APIs/LikeDislikeDownloadSong.js';
import { UserStoreContext } from '../Contexts/UserStoreContext';
import LogginDialog from '../Dialogs/LogginDialog.js';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

import Tooltip from '@mui/material/Tooltip';
import ConvertNum from '../ConvertNumbers/useConvertLargeNum';

const ActionBtns = (props) => {
    const navigate = useNavigate()
    const location = useLocation();
    const {is_logged_in, user_store} = useContext(UserStoreContext)

    const [data, setData] = useState(null)

    useEffect(()=>{
        setData(props.data)
    },[props.data])

    const scrolltocomments =()=>{
        if(data){
            if(!location.search.includes("tab=2")){
                setTimeout(()=>{
                    if(document.querySelector("#commets_section")!== null){
                        document.querySelector("#commets_section").scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                            inline: 'end',
                        }); 
                    }
                },[400])
            }else{
                navigate("/playing/"+data.song.song_id)
                setTimeout(()=>{
                    if(document.querySelector("#commets_section")!== null){
                        document.querySelector("#commets_section").scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                            inline: 'end',
                        }); 
                    }
                },[1000])
            }
        }
    }
    
    const [showLogIn, setShowLogIn] = useState(false);

    //Like, Dislike, Download
    const [Error,setError] = useState(null)
    const [song_id, setSong_id] = useState(null)
    const [request,setRequest] = useState(null);
    const {data:FetchedData,isLoading, error} = useFetch(request)
   
    useEffect(()=>{
        setError(error)
    },[error])

    useEffect(()=>{
    
        if(FetchedData && FetchedData.results.length>0){
            setData(FetchedData.results[0])

            if(request.action == "download"){
                const link = document.createElement('a');
                link.href = ENVIROMENTS.baseUrl + '/geniuxmusic/api/download.php?song='+ request.song_id;
                link.setAttribute(
                'download',
                "Download File"
                );
            
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                  
            }
        }
         
        
    },[FetchedData])


 
    const LikeIt =() =>{
        setError(null)
        if(is_logged_in){
            setRequest({
                'song_id':data.song.song_id,
                'action':'like',
            })
        }else{
            setShowLogIn(true)
            
        }
    }
 
    const DislikeIt =() =>{
        setError(null)
        if(is_logged_in){
            setRequest({
                'song_id':data.song.song_id,
                'action':'dislike',
            })

        }else{
            setShowLogIn(true)
        }
    
    }


    const DownloadFile =() =>{
        if(is_logged_in){
            setRequest({
                'song_id':data.song.song_id,
                'action':'download',
            })
        }else{
             setShowLogIn(true)
        }
    }

   

   
    return (
        <>
        {data &&(
            <>
                <div className="">
                    <Tooltip title={data.song.plays+" Plays"} placement="top"><Button className={data.song.played>0?"action-btn active":"action-btn"} variant="contained">{data.song.played > 0 ? <PlayCircleFilledIcon/> : <PlayCircleFilledWhiteOutlinedIcon/>}<p><ConvertNum num = {data.song.plays}/></p></Button></Tooltip>
                    <Tooltip title={data.song.likes+" Likes"} placement="top"><Button onClick={()=>LikeIt()} className={data.song.liked>0?"action-btn active":"action-btn"} variant="contained">{(request && request.action==="like") && isLoading ? <CircularProgress/> : <> {data.song.liked>0? <FavoriteIcon/> : <FavoriteBorderIcon/>}<p><ConvertNum num = {data.song.likes}/></p></>}</Button></Tooltip>
                    {/* <Button onClick={()=>DislikeIt()} className={data.song.disliked>0?"action-btn active":"action-btn"} variant="contained">{(request && request.action==="dislike") && isLoading?<CircularProgress/>:<><ThumbDownAltIcon/><span>{data.song.dislikes}</span></>}</Button>               */}
                    <Tooltip title={data.song.comments+" Comments"} placement="top"><Button className="action-btn" onClick={()=>scrolltocomments()} variant="contained"><ModeCommentOutlinedIcon/><p><ConvertNum num = {data.song.comments}/></p></Button></Tooltip>         
                </div>
                <div className="">
                  <Button className="download-btn" onClick={()=>DownloadFile()} variant="contained"><p>Download</p><div className="downIcon">{((request && isLoading) && request.action==="download" ) ? <CircularProgress/> : <><DownloadOutlinedIcon/><p><ConvertNum num = {data.song.downloads}/></p></>}</div></Button>                
                </div> 
                {showLogIn &&(
                    <LogginDialog setShowLogIn={setShowLogIn}/>
                )}
                
            </>
           
          

        )}
            
         
        </>
    )
}

export default ActionBtns
