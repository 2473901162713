import {useState,useEffect, useContext} from 'react'
import Container from '../../../../Components/UploadMusic/UploadSingle'
import { UserStoreContext } from '../../../../Components/Contexts/UserStoreContext';
import {useNavigate} from "react-router-dom"; 
import {Helmet} from "react-helmet";

export default function Uploads () {
    const navigate = useNavigate();
    const[innerWidth,setinnerWidth] = useState(window.innerWidth)
    useEffect(()=>{
        window.scrollTo(0, 0)
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])
    
    const {is_logged_in} = useContext(UserStoreContext)
    useEffect(()=>{
        if(is_logged_in===true || is_logged_in===false){
            if(is_logged_in===false){
                navigate('/login')
            }
        }
    },[is_logged_in])
    return (
        <>
        
        <Helmet>
            <title>Upload Single</title>
            <meta property="og:title" content="Upload Single" key="1" />
            <meta name="title" content="Upload Single" key="2"/>
            <meta name="description" content="Upload your single songs to geniux music." key="3"/>
            <meta property="og:image" content="/assets/images/geniux_music_logo.png" key="4"/>
        </Helmet>
        {is_logged_in&&(<Container innerWidth={innerWidth} /> )}
         
            
        </>
    )
}


