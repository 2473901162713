import React, {Component} from "react";
import {useState, useEffect, useContext} from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Create';
import ShareIcon from '@mui/icons-material/Share';
import ReportIcon from '@mui/icons-material/Report';
import EditCommentReply from './EditCommentReply.js';
import ReportComment from './ReportComment.js';
import { UserStoreContext } from '../Contexts/UserStoreContext';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';

export default function TemporaryDrawer(props) {

  const[myuserid, setMyuserid] = useState(null);
  const {is_logged_in, user_store} = useContext(UserStoreContext)
  useEffect(()=>{
      if(is_logged_in){
          setMyuserid(user_store.user_id)
      }
  },[is_logged_in])


  const [state, setState] = useState({
    bottom: true,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

    if(!open){
      props.setisMenuOpened(false)
    }else{
      props.setisMenuOpened(true)
    }
  };



  const[initialOptions, setInitialOptions] = useState(true)
  const[isEditing, setIsEditing] = useState(false)
  const[isReportingComment, setIsReportingComment] = useState(false)

  const showEditCommentMenu = ()=>{
    setInitialOptions(false)
    setIsReportingComment(false)
    setIsEditing(true)
  }
  const showIntialMenu = ()=>{
   
    setIsEditing(false)
    setIsReportingComment(false)
    setInitialOptions(true)
  }
  const showReportMenu = ()=>{
   
    setIsEditing(false)
    setIsReportingComment(true)
    setInitialOptions(false)
  }


  return (
    <>
        {window.innerWidth<992?
          <Drawer anchor="bottom" open={state['bottom']} onClose={toggleDrawer("bottom", false)}>
            <div className="drawercontainer">

            
            {(isEditing && is_logged_in && myuserid===props.data.user_id)&&(
              <EditCommentReply showIntialMenu={showIntialMenu} setData={props.setData} data={props.data}/>
            )}
          
            {initialOptions &&(
              <div className="mymenucontainer">
                  <div className="mymenu">
                      <Button color="secondary">
                          <div>
                              <div className="icon"> <ShareIcon/></div>
                              <span>Share</span>
                          </div>
                      
                      </Button>
                      <Button color="secondary" onClick={showReportMenu}>
                          <div>
                              <div className="icon"> <ReportIcon/></div>
                              <span>Report</span>
                          </div>
                      </Button>
                      {(is_logged_in && myuserid===props.data.user_id) &&(
                        <Button color="secondary" onClick={showEditCommentMenu}>
                            <div>
                                <div className="icon"> <CreateIcon/></div>
                                <span>Edit</span>
                            </div>
                        </Button>
                      )}
                  </div>
                  
              </div>
            )}
            {isReportingComment &&(
              <ReportComment  showIntialMenu={showIntialMenu} data={props.data}/>
            )}
           </div>
         
          </Drawer>:
          <Modal
            open={true}
            onClose={()=>props.setisMenuOpened(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            
                <div className="myModal">
                    <div className="container">
                        <div className="header">
                            <p>MORE OPTIONS</p>
                            <Button color="secondary" onClick={()=>props.setisMenuOpened(false)}><CloseIcon/></Button>
                        </div>
                        <div className="drawercontainer">
                                      
                          {(isEditing && is_logged_in && myuserid===props.data.user_id)&&(
                            <EditCommentReply showIntialMenu={showIntialMenu} setData={props.setData} data={props.data}/>
                          )}
                        
                          {initialOptions &&(
                            <div className="mymenucontainer">
                                <div className="mymenu">
                                    <Button color="secondary">
                                        <div>
                                            <div className="icon"> <ShareIcon/></div>
                                            <span>Share</span>
                                        </div>
                                    
                                    </Button>
                                    <Button color="secondary" onClick={showReportMenu}>
                                        <div>
                                            <div className="icon"> <ReportIcon/></div>
                                            <span>Report</span>
                                        </div>
                                    </Button>
                                    {(is_logged_in && myuserid===props.data.user_id) &&(
                                      <Button color="secondary" onClick={showEditCommentMenu}>
                                          <div>
                                              <div className="icon"> <CreateIcon/></div>
                                              <span>Edit</span>
                                          </div>
                                      </Button>
                                    )}
                                </div>
                                
                            </div>
                          )}
                          {isReportingComment &&(
                            <ReportComment  showIntialMenu={showIntialMenu} data={props.data}/>
                          )}  
                        </div>
                    
                    </div>
                </div>
            
          
          </Modal>
          }
        </>
  );
}
