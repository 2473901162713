
import React, {Component} from "react";
import {useState,useEffect} from 'react';
import LinearProgress from '@mui/material/LinearProgress';


import Button from '@mui/material/Button';
const UploadingProgress = (props) => {
    const [ProgressValue, setProgress] = useState(0);
    const [artcover, setArtCover] = useState(null);

    useEffect(()=>{
        if(props.UploadProgressValue){
            setProgress(props.UploadProgressValue)
        }
    
    },[props.UploadProgressValue])

    useEffect(()=>{
        if(props.artcover){
            setArtCover(props.artcover)
        }
    },[props.artcover])

    return (
        <>
            <div className="uploading-container">
                {artcover?
                  <img className="bgimg blurfx" src={artcover.thumbUrl}/>  
                  :
                  <img className="bgimg" src="/assets/images/geniuxbanner.jpeg"/>
                }
                
                <div className="fademask"> </div>
                <div className="contain">
                    <div className="songart"><img src={artcover?artcover.thumbUrl:"/assets/images/defaultsongart.jpg"}/></div>
                    <LinearProgress  variant="buffer" valueBuffer={ProgressValue} value={ProgressValue}  /> 
                    <p>Uploading in progress, be patient ({ProgressValue}%)</p>
                    <Button onClick={()=>window.location.reload()}>CANCEL</Button>
                </div>
            </div>   
        </>
    )
}

export default UploadingProgress
