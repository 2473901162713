import {useState, useEffect, useContext} from 'react'
import { UserStoreContext } from '../../Contexts/UserStoreContext';
import Axios from 'axios';
const useFetch = (checkedEmail, checkedApp,request) => {
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null); 
    

    const {is_logged_in, user_store} = useContext(UserStoreContext)
    




    useEffect(()=>{ 

        setIsLoading(true)
        if(is_logged_in && request){
            const url = '/geniuxmusic/api/update_notification_settings.php';
            
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: {
                    email_notification: checkedEmail,
                    app_notification: checkedApp,
                    myuserid: is_logged_in?user_store.user_id:"",
                    mytoken: is_logged_in?user_store.user_token:"",
                },
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
        
  
                if(response.data && response.data.length>0){
                    setData(response.data)
                    setIsLoading(false)
                    setError(null);  
                    
                }else{
                    setData(null)
                    setIsLoading(false)
                    if(response.response && response.response==="user_not_found"){
                        setError('user_not_found');  
                    }else if(response.response && response.response==="credentials_not_provided"){
                        setError('credentials_not_provided');  
                    }else{
                        setError('unknown_error');  
                    }
               
             
                }
           
               
            })
            .catch(err=>{
                setIsLoading(false)
                setError('network_err');  
            })
            return () => cancel()
        }else{
            setIsLoading(false)
        }
    
    
    },[is_logged_in,checkedEmail,checkedApp,request])
 
    return {data, isLoading, error}
}

export default useFetch
