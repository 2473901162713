import {useState, useEffect,useContext} from 'react'
import { UserStoreContext } from '../Contexts/UserStoreContext';
import Axios from 'axios';
import ENVIROMENTS from '../Environment/Configuration.json';

const useFetch = (request) => {
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null);

    const {is_logged_in, user_store} = useContext(UserStoreContext);
    
    
    useEffect(()=>{ 

        console.log(request)
        setError(null);  
        setIsLoading(true)
        if(request){
            

           
            const url = ENVIROMENTS.baseUrl + '/geniuxmusic/api/fetch_followers.php';
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: {
                    user_id: request.user_id,
                    type: request.type,
                    page: request.page,
                    sort: request.sort ? request.sort : "",
                    myuserid: is_logged_in ? user_store.user_id : "",
                    mytoken: is_logged_in ? user_store.user_token : "",
                },
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                
                setIsLoading(false)
                if(response.data && response.data.status==="success"){
                    setData(response.data)
                }else if(response.data && response.data.status==="target_user_not_found"){
                    setError('target_user_not_found')
                }else if(response.data && response.data.status==="target_user_not_specified"){
                    setError('type_not_specified')
                }else if(response.data && response.data.status==="type_not_specified"){
                    setError('type_not_specified')
                }else {
                    setError('unknown_error')
                }
        
            
            })
            .catch(err=>{
                

                if(!Axios.isCancel(err)){
                    setError('network_err');
                    setIsLoading(false)
                }  
            })
            return () => cancel()
           
        }else{
            setIsLoading(false)
        }
    
    
    },[request])
 
    return {data, isLoading, error}
}

export default useFetch
