import { StatusBar } from '@capacitor/status-bar';
import { Capacitor } from "@capacitor/core";
import {useEffect} from 'react'

const Statusbar = () => {

    useEffect(() => {
        if(Capacitor.isNativePlatform()){
            StatusBar.setBackgroundColor({ color: '#212121' });
        }
    }, [])
}

export default Statusbar
