import React, {Component} from "react";
import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Modal from '@mui/material/Modal';


export default function BasicModal(props) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        props.setShowLogIn(false)
    
    }


    useEffect(()=>{
        handleOpen()
    },[])

  
    const navigate = useNavigate()

    const gotologin = ()=>{
        navigate('/login')
        handleClose()

    }


  return (
    
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
            <div className="myModal fadefx">
                <div className="container">
                    <div className="errIcon">
                        <PersonIcon/>
                    </div>
                    <div className="errbody">
                        <p><b>You are not logged in</b><br/><br/>
                        Please log in or create an account to access and engage with the app's features.</p><br/>

                        <Button onClick={()=>gotologin()} className="btn">Login or sign up</Button>
                        <Button onClick={()=>handleClose()} className="btn closebtn">Close</Button>
                    </div>
                </div>
            </div>
      </Modal>
      
  );
}

