import React, {Component} from "react";
import {useState, useEffect} from 'react';
import TrackCard from '../Cards/card005.js';

const PlayList = (props) => {

    const [data, setData] = useState(null);


    useEffect(()=>{
        if(props.PlayList){
            setData(props.PlayList)
        }
    },[props.PlayList])


    


    return (
    <>
        <div className="moretracks">
            <div className="htitle">
                <div className="lft">
                  <p>Up next</p>
                </div>
            </div>

            {data &&(
                data.map((song,index)=>( 
                    <TrackCard key={index} data={song} Data ={data} index={index}/>
                ))
            )}
        </div>
    </>  
    )
}

export default PlayList
