import React, {Component} from "react";
import {useState,useEffect, useRef, useCallback} from 'react';
import useFetch from '../APIs/FetchComments.js';
import Button from '@mui/material/Button';
import CommentCard from './CommentCard.js';
import LoadingSkeleton from '../LoadingSkeletons/Comments.js';
import SortIcon from '@mui/icons-material/Sort';
import CommentBox from './CommentBox.js';
import NoInternet from '../Dialogs/NoInternet.js';
import {useNavigate, useLocation} from 'react-router-dom';
export default function (props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [sort,setSort] = useState("newest");
    const [page,setPage] = useState(1);

    const [data, setData] = useState(null);
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData && FetchedData.total_results>0){
            if(data && data.length>0){
              setData([...data, ...FetchedData.results])
            }else{
                setData(FetchedData.results)
            }
        }
    },[FetchedData])

    useEffect(()=>{
        if(data){
            setTimeout(()=>{
                if(location.pathname.includes("#comments")){
                    document.getElementById("commets_section").scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'end',
                    }); 
                }
            },500)
        }
        
    },[data])

    const send_request =()=>{
        setRequest({
            "page": page,
            "user_id": "",
            "song_id": props.song_id,
            "search": "",
            "sort": sort
        })
    }



    useEffect(()=>{
        send_request()
    },[page])

    useEffect(()=>{
        setData(null)
        setPage(1)
        send_request()

        
    },[props.song_id,sort])

    
    const LoadMore = ()=>{
        const currpage = page;
        setPage(currpage+1)
    }
  
    const observer = useRef()
    const lastBookElementRef = useCallback(node=>{
  
        if(observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries=>{
            if(entries[0].isIntersecting){
  
              if(FetchedData && FetchedData.results.length>0){
                LoadMore()
              }
                
            }
        })
        if(node) observer.current.observe(node)
       
    },[FetchedData])

    //Handle Error Onclick
    const handleError = ()=>{
        send_request()
    }
    const[showNoInternet,setShowInternet] = useState(false)
    useEffect(()=>{
        if(error){
            setShowInternet(true)
        }
    },[error])
    
    const closeModal = ()=>{
        setShowInternet(false);
    }
    
    return (
        <>
        <div className="title">
            <p>COMMENTS {FetchedData && ("("+FetchedData.total_results+")")}</p> 
            {(FetchedData && FetchedData.total_results)>0&&(
               <>
                    {sort==="newest"?
                    <Button aria-label="Sort Comments" onClick={()=>setSort('oldest')}>
                        Newest <SortIcon />
                    </Button>
                    :
                    <Button aria-label="Sort Comments" onClick={()=>setSort('newest')}>
                        Oldest <SortIcon />
                    </Button>
                    }

               </> 
            )}  
            
            
        </div>
       
        <div className="cards">
        <CommentBox  song_id={props.song_id} setData={setData} setPage={setPage} send_request={send_request}/>
            { data && (
                <>
           
                   {
                    data.slice(0,1).map((comment,index)=>{
                        return <CommentCard 
                            key = {index}
                            data = {comment}
                        />
                    }) 
                   }
                    <div id="commets_section"></div>
                    {data.slice(1).map((comment,index)=>{
                        return <CommentCard 
                            key = {index}
                            data = {comment}
                        />
                    }) 
                   }
                </>
                
            )}
           
            {isLoading && (
                <>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                </>
            )}<div ref={lastBookElementRef}></div>
            {/* Feedbacks */}

            {(!data && error=="network_err") &&(
                <div className="no_data_msg">
                    <div className="container">
                        <div className="icon">
                            <img src="/assets/images/no_comments_found.svg" />
                        </div>
                        <p>Check your internet connectivity</p> 
                        <Button onClick={()=>send_request()}>
                            Retry
                        </Button>
                    </div>

                </div>
            )}  

           
            {(data && showNoInternet)&&(
                <NoInternet closeModal={closeModal} handleError={handleError}/>
            )}
           
        </div>
        
       </>
    )
}


