import { Filesystem, Directory } from '@capacitor/filesystem';
import React, {Component} from "react";
import {useEffect} from 'react'
const DownloadDirectory = () => {
    //Directory
    const AppDirectory = Directory.External
    const CurrentFolder = 'Downloads'

    

    useEffect(() => {
        Filesystem.mkdir({
            directory: AppDirectory,
            path: CurrentFolder,
         
        }).then(response=>{
   
        })
        .catch(err=>{
            
        })
    }, [])

    return "";
}

export default DownloadDirectory
