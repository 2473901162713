import React from 'react'
const Skeleton = () => {
    return (
        <div className="banner01-skeleton anime-skeleton">
            <div className="lft">
                <p></p>
                <p></p>
                <p></p>
            </div>
            <div className="rgt"></div>
        </div>
    )
}

export default Skeleton
