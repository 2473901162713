import React from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
const Form = () => {
    const onhandle_submit= (e) =>{
        e.preventDefault()
     
    
    }
    return (
        <>
             <form onSubmit={onhandle_submit} className="myform">

                <TextField variant="filled" label="Description (Optional)" multiline rows={4} />


                <div className="btns">
                <Button  type="submit" className="defaultBTN" color="primary">Submit</Button>
                </div> 
            </form>
        </>
    )
}

export default Form
