import {useState,useEffect} from 'react'
import {Helmet} from "react-helmet";
import React, {Component} from "react";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigationMenuToggle from '../../Components/Navigation/NavigationMenuToggle'
import {Link} from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import ValidateUser from '../../Components/UserCredentials/ValidateUser';
export default function About () {
    const navigate = useNavigate();
    const[innerWidth,setinnerWidth] = useState(window.innerWidth)
    useEffect(()=>{
        window.scrollTo(0, 0)
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])


    return (
        <>
        <ValidateUser/>
        <Helmet>
            <title>About</title>
            <meta property="og:title" content="Settings" />
            <meta name="title" content="Settings"/>
            <meta name="description" content="Settings"/>
            <meta property="og:image" content="/assets/images/geniux_music_logo.png"/>
        </Helmet>
        
        <>
            {innerWidth<992 &&(
                <div className="navbar">
                    <div className="wrapper">
                        <div className="lft">
                            <IconButton className="icon" onClick={()=>navigate(-1)}>
                                <ArrowBackIcon />
                            </IconButton> 
                            <div className="titles">
                                <p>ABOUT</p>
                            </div>
                        
                        </div>
                        <div className="rgt">
                            <NavigationMenuToggle/>
                        </div>
                    </div>

                </div>    
            )}
            
            <div className="mycontainer">
                {/* My  path */}
                {innerWidth>992 &&(
                    <div className="mypath">
                        <div className="wrapper">
                            <div className="lft">
                                {window.history.length>1?
                                <Button onClick={()=>navigate(-1)}>
                                    <KeyboardBackspaceIcon/>
                                </Button>  
                                :
                                <Button onClick={()=>navigate(-1)} disabled="true">
                                    <KeyboardBackspaceIcon/>
                                </Button>  
                                }
                            </div>
                            <div className="rgt">
                                <p>
                                    <Link to="/settings" className="active">ABOUT</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="wrapper">
                    <div className="about-us">
                        <div className="contain">
                            <div className="lft">
                               <p>
                                Geniux Music is a vibrant platform dedicated to showcasing the talents of emerging artists, providing them with the necessary tools and resources to thrive in the competitive music industry. Our mission is to discover, nurture, and promote new musical talent, offering a dynamic space where artists can connect with a global audience, collaborate with industry professionals, and gain the exposure they need to advance their careers.<br/><br/>
                                For more info visit: <a href="https://geniux.co.za">www.geniux.co.za</a>
                               </p>
                            </div>
                            <div className='rgt'>
                                <div className='logo'>
                                    <img src="/assets/images/logo.svg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        
        </>
    )
}



