import React, { useEffect,useState } from 'react';

import { prominent } from 'color.js';
import ConvertNum from '../ConvertNumbers/useConvertLargeNum.js';
const Engagement = (props) => {
    const[dominantColor,setDominantColor] = useState(null)
    
    useEffect(()=>{
        if(props.data){
            if(props.data.user_avatar.length>2){
                prominent("/geniuxmusic/api/media/avatars/smaller/?file_name="+props.data.user_avatar,{format: 'hex',group: 30}).then(colors => {
                    setDominantColor(colors[1]) 
                })
            }else{
                setDominantColor('#212121') 
            }
        }
        
    },[props.data])
  return (
    <>
        <div className="htitle">
            <div className="lft"><p>Engagement</p></div>
        </div>           
        <div className="card004">
            <div className="background" style={{"background":dominantColor?dominantColor:"#212121"}}> </div>
            <div className="contain">
                <div className="kpi"> 
                    <p><ConvertNum num = {props.data.total_plays}/></p>
                    <p>Streams</p>
                </div>
                <div className="kpi"> 
                    <p><ConvertNum num = {props.data.total_likes}/></p>
                    <p>Likes</p>
                </div>
                <div className="kpi"> 
                    <p><ConvertNum num = {props.data.total_downloads}/></p>
                    <p>Downloads</p>
                </div>
            </div>
            
        </div>
        
    </>
  )
}

export default Engagement