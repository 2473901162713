import {useState,useEffect, useContext} from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Create';
import ShareIcon from '@mui/icons-material/Share';
import ReportIcon from '@mui/icons-material/Report';
import Report from './Report.js';
import ShareLink from './ShareLinkContainer.js';
import { UserStoreContext } from './Contexts/UserStoreContext.js';
import React, { Component }  from 'react';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from 'react-router-dom';
export default function TemporaryDrawer(props) {
  const[innerWidth,setinnerWidth] = useState(window.innerWidth)
  useEffect(()=>{
    const updateInnerWidth = () => {
        setinnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateInnerWidth);
    return () => window.removeEventListener("resize", updateInnerWidth) 
  },[])
  const navigate = useNavigate();

  const {is_logged_in, user_store} = useContext(UserStoreContext)



  const[initialOptions, setInitialOptions] = useState(true)
  const[isSharingLink, setIsSharingLink] = useState(false)
  const[isReporting, setisReporting] = useState(false)


  const showIntialMenu = ()=>{
   
    setisReporting(false)
    setInitialOptions(true)
    setIsSharingLink(false)
  }
  const showReportMenu = ()=>{
   
    setisReporting(true)
    setInitialOptions(false)
    setIsSharingLink(false)
  }

  const showSharingLink = ()=>{
   
    setisReporting(false)
    setInitialOptions(false)
    setIsSharingLink(true)
  }



  const [data,setData] = useState(null)
  const [type,setType] = useState(null)
  useEffect(()=>{
    if(props.data){
      setData(props.data)

    }
  },[props.data])


  useEffect(()=>{
      if(props.type){
        setType(props.type)
      }
  },[props.type])






  return (
        <>
         {innerWidth<992?
          <Drawer anchor="bottom" open={true} onClose={()=>props.setisMenuOpened(false)}>
            <div className="drawercontainer">
              {(initialOptions && data) &&(
                <div className="mymenucontainer">
                    <div className="mymenu">
                      <Button onClick={showSharingLink} color="secondary">
                          <div>
                              <div className="icon"> <ShareIcon/></div>
                              <span>Share</span>
                          </div>
                      
                      </Button>
                      <Button onClick={showReportMenu} color="secondary">
                          <div>
                              <div className="icon"> <ReportIcon/></div>
                              <span>Report</span>
                          </div>
                      </Button>
                      {(is_logged_in && type==="Song" && data.user.user_id===user_store.user_id ) &&(
                        <Button color="secondary" onClick={()=> navigate('/uploads/update/'+data.song.song_id)}>
                            <div>
                                <div className="icon"> <CreateIcon/></div>
                                <span>Update</span>
                            </div>
                        </Button>
                      )}
                      {(is_logged_in && type==="Profile" && data.user_id===user_store.user_id) &&(
                        <Button onClick={()=> navigate('/profile/manage/account')} color="secondary">
                            <div>
                                <div className="icon"> <CreateIcon/></div>
                                <span>Update</span>
                            </div>
                        </Button>
                      )}

                      
                      {(type==="Spotify Profile") &&(
                        <Button color="secondary">
                            <div>
                                <div className="icon"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill-rule="evenodd" clip-rule="evenodd"><path d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z"/></svg></div>
                                <span>Go to Spotify</span>
                            </div>
                        </Button>
                      )}
                      {(type==="Spotify Song") &&(
                        <Button color="secondary">
                            <div>
                                <div className="icon"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill-rule="evenodd" clip-rule="evenodd"><path d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z"/></svg></div>
                                <span>Go to Spotify</span>
                            </div>
                        </Button>
                      )}
                      
                      
                        
                      
                    </div>
                    
                </div>
              )}
              {isReporting  &&(
                <Report showIntialMenu={showIntialMenu} type={type} data={props.data}/>
              )}
              {isSharingLink &&(
                <ShareLink type = {type} showIntialMenu={showIntialMenu} data={props.data} innerWidth={innerWidth}/>
              )}
           </div>
         
          </Drawer>:
          <Modal
          open={true}
          onClose={()=>props.setisMenuOpened(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          
              <div className="myModal">
                  <div className="container">
                      <div className="header">
                          <p>MORE OPTIONS</p>
                          <Button color="secondary" onClick={()=>props.setisMenuOpened(false)}><CloseIcon/></Button>
                      </div>
                      <div className="drawercontainer">
                        {(initialOptions && data) &&(
                          <div className="mymenucontainer">
                              <div className="mymenu">
                                <Button onClick={showSharingLink} color="secondary">
                                    <div>
                                        <div className="icon"> <ShareIcon/></div>
                                        <span>Share</span>
                                    </div>
                                
                                </Button>
                                <Button onClick={showReportMenu} color="secondary">
                                    <div>
                                        <div className="icon"> <ReportIcon/></div>
                                        <span>Report</span>
                                    </div>
                                </Button>
                                {(is_logged_in && type==="Song" && data.user.user_id===user_store.user_id) &&(
                                  <Button onClick={()=> navigate('/uploads/update/'+data.song.song_id)} color="secondary">
                                      <div>
                                          <div className="icon"> <CreateIcon/></div>
                                          <span>Update</span>
                                      </div>
                                  </Button>
                                )}
                                {(is_logged_in && type==="Profile" && data.user_id===user_store.user_id) &&(
                                  <Button onClick={()=> navigate('/profile/manage/account')} color="secondary">
                                      <div>
                                          <div className="icon"> <CreateIcon/></div>
                                          <span>Update</span>
                                      </div>
                                  </Button>
                                )}
                                
                                
                                {(type==="Spotify Profile") &&(
                                  <Button color="secondary">
                                      <div>
                                          <div className="icon"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill-rule="evenodd" clip-rule="evenodd"><path d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z"/></svg></div>
                                          <span>Go to Spotify</span>
                                      </div>
                                  </Button>
                                )}
                                
                                {(type==="Spotify Song") &&(
                                  <Button color="secondary">
                                      <div>
                                          <div className="icon"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill-rule="evenodd" clip-rule="evenodd"><path d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z"/></svg></div>
                                          <span>Go to Spotify</span>
                                      </div>
                                  </Button>
                                )}
                                
                              </div>
                              
                          </div>
                        )}
                        {isReporting  &&(
                          <Report showIntialMenu={showIntialMenu} type={type} data={props.data}/>
                        )}
                        {isSharingLink &&(
                          <ShareLink  type = {type} showIntialMenu={showIntialMenu} data={props.data} innerWidth={innerWidth}/>
                        )}
                    </div>
                  
                  </div>
              </div>
          
        
        </Modal>
        }
        </>
  );
}
