import {useState, useEffect, useContext} from 'react'
import Axios from 'axios';
import {useNavigate} from 'react-router-dom';
import { UserStoreContext } from '../Contexts/UserStoreContext';
import ENVIROMENTS from '../Environment/Configuration.json';

const useFetch = (credentials,request) => {
    const navigate = useNavigate();
    const {set_is_logged_in, set_user_store} = useContext(UserStoreContext)

    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(false);
    const[error, setError] = useState(null);

    useEffect(()=>{ 

        const url = ENVIROMENTS.baseUrl + '/geniuxmusic/api/login.php';

        if(credentials.username!=="" && credentials.password!==""){
            setIsLoading(true)
            setError(null)

            let cancel
            Axios({
                method: 'post',
                url: url,
                data: {
                    username: credentials.username,
                    password: credentials.password
                },
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                setIsLoading(false)
                if(response.data[0] && response.data[0].response==="success"){
                    // localStorage.setItem('user_store', JSON.stringify(...response.data))
                     set_is_logged_in(true)
                     set_user_store(...response.data)
                     
                     navigate('/')

                }else{
                    if(response.data.response && response.data.response==="password_incorrect"){
                        setData(null)
                        setError('password_incorrect');  
                    }else if(response.data.response && response.data.response==="username_not_found"){
                        setData(null)
                        setError('username_not_found');  
                    }else{
                        setData(null)
                        setError('unknown_error');  
                    }
                }
           
               
            })
            .catch(err=>{
                if(!Axios.isCancel(err)){
                    setError('network_err');
                    setIsLoading(false)
                    setData(null)
                }
            })
            return () => cancel()
        }
     
        
    },[credentials,request])

    return {data, isLoading, error}
}

export default useFetch
