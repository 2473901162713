import React, {Component} from "react";
import {useState} from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

const ReportComment = (props) => {
    const[feedBack, setFeedBack] = useState(false)
    return (
        <>

        
            <div className="editcomment">
                <div className="wrapper">
                    <div className="navmenu">
                        <div className="lft">
                                <IconButton color="secondary" onClick={()=>props.showIntialMenu()}>
                                    <ArrowBackIcon/>
                                </IconButton>
                            
                            <div className="title">
                                Report Comment
                            </div>
                        </div>
                    
                    </div>
                    <form className="myform">
                        <textarea placeholder="Briefly explain your concerns"/>
                        <div className="btns">
                            {feedBack &&(
                                <div className="myerr">  
                                    <span className="noerr">Your report was sent</span>
                                </div>
                            )}
                            
                            <div className="myerr"></div>
                        <Button  type="button" className="defaultBTN" onClick={()=>setFeedBack(true)}color="primary">SUBMIT</Button>
                        </div> 
                    </form>
                </div>

            </div>
            
        </>
    )
}

export default ReportComment
