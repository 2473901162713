import {useState, useEffect, useContext, useRef, useCallback} from 'react';
import {Helmet} from "react-helmet";
import React, {Component} from "react";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigationMenuToggle from '../../../Components/Navigation/NavigationMenuToggle';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import ValidateUser from '../../../Components/UserCredentials/ValidateUser';

import { UserStoreContext } from '../../../Components/Contexts/UserStoreContext';
import DesktopLoadingSkeleton from '../../../Components/LoadingSkeletons/Card05.js';
import MobileLoadingSkeleton from '../../../Components/LoadingSkeletons/card005.js';
import DesktopCard from '../../../Components/Cards/SongCard.js'
import MobileCard from '../../../Components/Cards/card005.js';
import useFetch from '../../../Components/APIs/FetchLikedSongs.js';

import FilerDialog from './FilterDialog.js';

import NoInternet from '../../../Components/Dialogs/NoInternet.js';

export default function Favourites () {
    const {is_logged_in, user_store} = useContext(UserStoreContext);
    const navigate = useNavigate();
    const[innerWidth,setinnerWidth] = useState(window.innerWidth)
    useEffect(()=>{
        window.scrollTo(0, 0)
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])

    const [searchParams, setSearchParams] = useSearchParams();
    const sort_param = searchParams.get("sort")

  //userId,songId,search_string,genre,sort,pageNumber,
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState(null);
  const [request, setRequest] = useState(null);
  const {data:FetchedData,isLoading, error} = useFetch(request)

  useEffect(()=>{
      if(FetchedData){
        if(FetchedData.total_results>0){
          if(data && data.length>0){
            setData([...data, ...FetchedData.results])
          }else{
              setData(FetchedData.results)
          }
        }
      }
  },[FetchedData])


  const send_request =()=>{
    if(is_logged_in){
        setRequest({
            "sort":sort_param?sort_param:"newest",
            "user_id": user_store.user_id,
            "page":pageNumber,
        })
    }
  }

  useEffect(()=>{
      send_request()
  },[pageNumber, is_logged_in])


  const LoadMore = ()=>{
      const currpage = pageNumber+1;
      setPageNumber(currpage)
  }

  const observer = useRef()
  const lastBookElementRef = useCallback(node=>{

      if(observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries=>{
          if(entries[0].isIntersecting){
            if(FetchedData && FetchedData.results.length>0){
              LoadMore()
            }
              
          }
      })
      if(node) observer.current.observe(node)
     
  },[FetchedData])

  useEffect(()=>{
    setData(null)
    setPageNumber(1)
    send_request()
},[sort_param])

  //Handle Error Onclick
  const handleError = ()=>{
      send_request()
  }
  const[showNoInternet,setShowInternet] = useState(false)
  useEffect(()=>{
      if(error){
          setShowInternet(true)
      }
  },[error])

  const closeModal = ()=>{
      setShowInternet(false);
  }

    
    //
    const [isFilterOpened, setFilterOpened] = useState(false)


    return (
        <>
        <ValidateUser/>
        <Helmet>
            <title>Liked Music</title>
            <meta property="og:title" content="Liked Music" />
            <meta name="title" content="Liked Music"/>
            <meta name="description" content="Liked Music"/>
            <meta property="og:image" content="/assets/images/geniux_music_logo.png"/>
        </Helmet>
        
        <>
            {innerWidth<992 &&(
                <div className="navbar">
                    <div className="wrapper">
                        <div className="lft">
                            <IconButton className="icon" onClick={()=>navigate(-1)}>
                                <ArrowBackIcon />
                            </IconButton> 
                            <div className="titles">
                                <p>Liked Music</p>
                            </div>
                        
                        </div>
                        <div className="rgt">
                            <NavigationMenuToggle/>
                        </div>
                    </div>

                </div>    
            )}
            
            <div className="mycontainer">
                {/* My  path */}
                {innerWidth>992 &&(
                    <div className="mypath">
                        <div className="wrapper">
                            <div className="lft">
                                {window.history.length>1?
                                <Button onClick={()=>navigate(-1)}>
                                    <KeyboardBackspaceIcon/>
                                </Button>  
                                :
                                <Button onClick={()=>navigate(-1)} disabled="true">
                                    <KeyboardBackspaceIcon/>
                                </Button>  
                                }
                            </div>
                            <div className="rgt">
                                <p>
                                    <Link to="/settings" className="active">Liked Music</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                 
                {is_logged_in &&(
                <>
                    <div className="wrapper">
                        <div className="myfilter">
                            <div className="lft">
                                <p>All Tracks {FetchedData && ("("+FetchedData.total_results+")")}</p>
                                <p className='subtitle'>Filtered by: {sort_param==="oldest"?"Oldest":"Newest"}</p>
                            </div>
                            <div className="rgt">
                                <Button className="loadmorebtn" color="primary" onClick={()=>setFilterOpened(true)}>Filter</Button>
                            </div>
                        </div>
                        <div className="row">
                        {innerWidth < 992 ?
                            <>
                                {data && (
                                    data.map((song,index)=>{
                                        return <MobileCard key={index} data = {song} Data = {data} index={index}/>
                                    }
                                ))}
                                {(isLoading) &&(
                                    <>
                                        <MobileLoadingSkeleton/>
                                        <MobileLoadingSkeleton/>
                                        <MobileLoadingSkeleton/>
                                        <MobileLoadingSkeleton/>
                                        <MobileLoadingSkeleton/>
                                        <MobileLoadingSkeleton/>
                                        <MobileLoadingSkeleton/>
                                        <MobileLoadingSkeleton/>
                                        <MobileLoadingSkeleton/>
                                    </>
                                )}
                            </>:
                            <>
                                <div className="row">
                                    {data && (
                                        data.map((song,index)=>{
                                            return <div className="col-6 col-md-4 col-lg-3 col-xxl-2" key={index}><DesktopCard data = {song} Data = {data} index={index}/></div>
                                        }
                                    ))}
                                    {(isLoading) &&(
                                        <>
                                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><DesktopLoadingSkeleton/></div>
                                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><DesktopLoadingSkeleton/></div>
                                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><DesktopLoadingSkeleton/></div>
                                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><DesktopLoadingSkeleton/></div>
                                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><DesktopLoadingSkeleton/></div>
                                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><DesktopLoadingSkeleton/></div>
                                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><DesktopLoadingSkeleton/></div>
                                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><DesktopLoadingSkeleton/></div>
                                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><DesktopLoadingSkeleton/></div>
                                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><DesktopLoadingSkeleton/></div>
                                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><DesktopLoadingSkeleton/></div>
                                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><DesktopLoadingSkeleton/></div>
                                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><DesktopLoadingSkeleton/></div>
                                            <div className="col-6 col-md-4 col-lg-3 col-xxl-2"><DesktopLoadingSkeleton/></div>
                                        </>
                                    )}
                                </div>
                            </>
                        }
                        </div>
                    </div>
                </>
                )}

                {!is_logged_in &&(
                    <div className='wrapper'>
                        <div className="no_data_msg">
                            <div className="container">
                                <div className="icon">
                                    <img src="/assets/images/no_music_found.svg" />
                                </div>
                                <p>Please log in or create an account to access and engage with the app's features.</p> 
                                <Link to="/login">
                                    <Button>
                                        Login or sign up
                                    </Button>
                                </Link>
                            </div>

                        </div>
                    </div> 
                )}
                <div ref={lastBookElementRef}></div>
                    
            
                {/* Feedbacks */}
                {(!data && error=="network_err") &&(
                    <div className='wrapper'>
                        <div className="no_data_msg">
                            <div className="container">
                                <div className="icon">
                                    <img src="/assets/images/no_music_found.svg" />
                                </div>
                                <p>Check your internet connectivity</p> 
                                <Button onClick={()=>send_request()}>
                                    Retry
                                </Button>
                            </div>

                        </div>
                    </div> 
                )}  

                {(!isLoading && FetchedData && FetchedData.total_results<1) &&(
                    <div className='wrapper'>
                        <div className="no_data_msg">
                            <div className="container">
                                <div className="icon">
                                    <img src="/assets/images/no_music_found.svg" />
                                </div>
                                <p>You have not liked any song</p> 
                                <Button onClick={()=>navigate(-1)}>
                                    Go Back
                                </Button>
                            </div>

                        </div>
                    </div> 
                )}   
                {(!data && error=="network_err")&&(
                    <NoInternet closeModal={closeModal} handleError={handleError}/>
                )}  
                </div>
            {isFilterOpened &&(
                <FilerDialog setFilterOpened={setFilterOpened} sort={sort_param}/>
            )}
        </>
        
        </>
    )
}



