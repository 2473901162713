import {useState,useEffect, useContext} from 'react'
import Container from '../../../../Components/UploadMusic/UploadAlbum'
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom"; 
import { UserStoreContext } from '../../../../Components/Contexts/UserStoreContext';
export default function Uploads () {
    const navigate = useNavigate();
    const[innerWidth,setinnerWidth] = useState(window.innerWidth)
    useEffect(()=>{
        window.scrollTo(0, 0)
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])

    const {is_logged_in} = useContext(UserStoreContext)
    useEffect(()=>{
        if(is_logged_in===true || is_logged_in===false){
            if(is_logged_in===false){
                navigate('/login')
            }
        }
    },[is_logged_in])
    return (
        <>
        
        <Helmet>
            <title>Upload Album</title>
            <meta property="og:title" content="Upload Album" key="1" />
            <meta name="title" content="Upload Album" key="2"/>
            <meta name="description" content="Upload your albums to geniux music." key="3"/>
            <meta property="og:image" content="/assets/images/geniux_music_logo.png" key="4"/>
        </Helmet>
        {is_logged_in&&(<Container innerWidth={innerWidth} /> )}
            
        </>
    )
}


