import ENVIROMENTS from '../Environment/Configuration.json';
import Button from '@mui/material/Button';
import LogginDialog from '../Dialogs/LogginDialog.js';
import {useState, useEffect, useContext} from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import {Link} from 'react-router-dom';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import {EffectFade, Autoplay } from "swiper/modules";
const HomeGenres = (props) => {
    const [showLogIn, setShowLogIn] = useState(false);
    const [color, setColor] = useState('');
    return (
        <>
        <div className="home-banner">
        {(!props.is_logged_in || props.is_logged_in && props.user_store.user_avatar.length < 2) && (
            <Swiper
                loop={true}
                autoplay={{
                    delay: 8000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, EffectFade]}
                className="mySwiper"
            >
                    <SwiperSlide>
                        <div className="background"></div>
                        {props.innerWidth>992?
                        <img src="/assets/images/geniux-music-banner-desktop-slide-2.jpg?geniuxmusic"/>
                        :
                        <img src="/assets/images/geniux-music-banner-mobile-slide-2.jpg?geniuxmusic"/>
                        }
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="background"></div>
                
                        {props.innerWidth>992?
                        <img src="/assets/images/geniux-music-banner-desktop-slide-1.jpg?geniuxmusic"/>
                        :
                        <img src="/assets/images/geniux-music-banner-mobile-slide-1.jpg?geniuxmusic"/>
                        }
                    </SwiperSlide>
                   
                    <SwiperSlide>
                        <div className="background"></div>
                        {props.innerWidth>992?
                        <img src="/assets/images/geniux-music-banner-desktop-slide-3.jpg?geniuxmusic"/>
                        :
                        <img src="/assets/images/geniux-music-banner-mobile-slide-3.jpg?geniuxmusic"/>
                        }
                    </SwiperSlide>
            </Swiper>
        )}
        {(props.is_logged_in && props.user_store.user_avatar.length > 2) && (
            <>
                <div className="background"></div>
                <img className="blurFx" src={ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/avatars/medium/?file_name="+ props.user_store.user_avatar}/>
            </>
        )}
        
        <div className="wrapper">
            {props.is_logged_in?
            <>
                <div className="lft">
                    <p>Welcome back <b>{props.user_store.artist_name}</b>, it is nice to see you again.</p>
                </div>
                <div className="rgt">
                    <div className="btns"  >
                        <Link to={"/library/liked"}><Button variant="outlined" onClick={()=>setShowLogIn(true)}>{props.innerWidth<992?<><span>{props.user_store.total_liked_songs}</span>Liked Music</>:<><span>{props.user_store.total_liked_songs}</span>Liked Music</>}</Button></Link>
                        <div className="separator"></div>
                        <Link to={"/profile/following/"+props.user_store.user_id}><Button variant="outlined" onClick={()=>setShowLogIn(true)}>{props.innerWidth<992?<><span>{props.user_store.following}</span>Fav Artists</>:<><span>{props.user_store.following}</span>Fav Artists</>}</Button></Link>
                        <div className="separator"></div>
                        <Link to={"/library/downloads"}><Button variant="outlined" onClick={()=>setShowLogIn(true)}>{props.innerWidth<992?<><span>{props.user_store.total_downloads}</span>Downloads</>:<><span>{props.user_store.total_downloads}</span>Downloads</>}</Button></Link>
                    </div>
                </div>
            </>:
            <>
                <div className="lft">
                   <p>One of the best ways to keep the connection between you and your fans alive.</p>
                </div>
                <div className="rgt">
                    <div className="btns"  >
                    <Button variant="outlined" onClick={()=>setShowLogIn(true)}>{props.innerWidth<992?<><span>0</span>Liked Music</>:<><span>0</span> Liked Music</>}</Button>
                        <div className="separator"></div>
                        <Button variant="outlined" onClick={()=>setShowLogIn(true)}>{props.innerWidth<992?<><span>0</span>Fav Artists</>:<><span>0</span>Fav Artists</>}</Button>
                        <div className="separator"></div>
                        <Button variant="outlined" onClick={()=>setShowLogIn(true)}>{props.innerWidth<992?<><span>0</span>Downloads</>:<><span>0</span>Downloads</>}</Button>
                    </div>
                    
                </div>
            </>}
                
            </div>

        </div>
        
        {showLogIn &&(
            <LogginDialog setShowLogIn={setShowLogIn}/>
        )}
        </>
    )
}

export default HomeGenres
