import ENVIROMENTS from '../Environment/Configuration.json';
import React, {Component} from "react";
import {useState,useEffect,useContext} from 'react'
import {PlayContext} from '../Contexts/PlayContext.js'
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CircularProgress from '@mui/material/CircularProgress';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useGlobalAudioPlayer } from "react-use-audio-player"
import useFetch from '../APIs/LikeDislikeDownloadSong.js';
import { UserStoreContext } from '../Contexts/UserStoreContext';
import LogginDialog from '../Dialogs/LogginDialog.js';
import TimeAgo from 'timeago-react'; 
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';

import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';


import ConvertNum from '../ConvertNumbers/useConvertLargeNum';
import Tooltip from '@mui/material/Tooltip';
const TrackCard = (props) => {
    
    const {PlayData,setPlayData,PlayStatus,setPlayIndex,setPlayList} = useContext(PlayContext)
    const { play, pause } = useGlobalAudioPlayer({ highRefreshRate: false })

    const {is_logged_in, user_store} = useContext(UserStoreContext)


    const [data, setData] = useState(null)
    useEffect(()=>{
        if(props.data){
            setData(props.data)
        }
    },[props.data]) 

    const PlaySong = () =>{
 
        if(PlayData && data.song.song_id===PlayData.data.song.song_id){
            if(PlayStatus==='playing'){
                pause()
            }else{
                play()
            }  
        }else{
            setPlayData({"platform": "geniuxmusic",data});
        }
        if(props.Data && props.Data.length>0){
            setPlayList(props.Data)
            setPlayIndex(props.index)
        }
      
    }


    const [showLogIn, setShowLogIn] = useState(false);
    //Like, Dislike, Download
    const [request,setRequest] = useState(null);
    const {data:FetchedData,isLoading, error} = useFetch(request)


    useEffect(()=>{
        if(FetchedData && FetchedData.results.length>0){
            setData(FetchedData.results[0])
        }
        
    },[FetchedData])


 
    const LikeIt =() =>{
        if(is_logged_in){
            setRequest({
                'song_id':data.song.song_id,
                'action':'like',
            })

        }else{
      
            setShowLogIn(true)
       
        }
    
    }


 
    const DislikeIt =() =>{
        if(is_logged_in){
            setRequest({
                'song_id':data.song.song_id,
                'action':'dislike',
            })

        }else{
            setShowLogIn(true)
        }
    
    }
    const DownloadIt =() =>{
        if(is_logged_in){
            setRequest({
                'song_id':data.song.song_id,
                'action':'download',
            })
            

        }else{
            setShowLogIn(true)
        }
    
    }
    
 

    return (
        <>
        {data &&(
            <div className={PlayData && PlayData.data.song.song_id===data.song.song_id?"card005 active": "card005"} >
                <div className="lft">
                    
                    <div className="trackdetails">
                        <div className="tracktitle">
                            <Tooltip title={data.song.title} placement="top">
                                <p><Link to={"/playing/"+data.song.song_id}>{data.song.title}</Link></p>
                            </Tooltip>
                            <Tooltip title={"Published by "+data.user.artist_name +". Released date: "+data.song.date_added.substr(0,11)} placement="bottom">
                                <p><b><Link to={"/profile/"+data.user.user_id}>{data.user.artist_name}</Link></b><TimeAgo datetime={data.song.date_added} /></p>
                            </Tooltip>
                        </div>
                        <div className="reacticons">
                            
                            <Link  to={"/playing/"+data.song.song_id} className={data.song.played > 0 ? "icon active":"icon"}>
                                {data.song.played > 0 ? <PlayCircleFilledIcon/> : <PlayCircleFilledWhiteOutlinedIcon/>}<p><ConvertNum num = {data.song.plays}/></p>
                            </Link>
                            <div className={data.song.liked >0 ? "icon active" : "icon"}   onClick={()=>LikeIt()}>
                                {isLoading?<CircularProgress/>:<>{data.song.liked >0 ? <FavoriteIcon/> : <FavoriteBorderIcon/> }<p><ConvertNum num = {data.song.likes}/></p></>}
                            </div>
                            <Link className="icon" to={"/playing/"+data.song.song_id+"?#comments"}>
                               <ModeCommentOutlinedIcon/><p><ConvertNum num = {data.song.comments}/></p>
                            </Link>
                        </div>
                    </div>
                    
                </div>
                <div className="rgt">
                    <div className="playbtn" onClick={()=>PlaySong()}>

                       <img src={data.song.artcover.length>1?ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/arts/smaller/?file_name="+data.song.artcover:"/assets/images/defaultsongart.jpg"} className="artcover"/>
                
                       {(PlayData && PlayData.data.song.song_id===data.song.song_id && PlayStatus==='playing')?
                            <PauseIcon/>:
                            PlayData && PlayData.data.song.song_id===data.song.song_id && PlayStatus==='paused'?
                            <PlayArrowIcon/>:""
                        }
                    </div>
                        
                     
                </div>
            </div>
        )}
        {showLogIn &&(
            <LogginDialog setShowLogIn={setShowLogIn}/>
        )}
         
        </>
    )
}

export default TrackCard
