import ENVIROMENTS from '../Environment/Configuration.json';
import React, {Component} from "react";
import {useState,useEffect,useContext} from 'react'
import Button from '@mui/material/Button';
import useFetch from '../APIs/FetchAlbums.js';
import {Link  }  from'react-router-dom';
import TrackCard from '../Cards/AlbumCard005.js';
import DownloadZip from './DownloadZip.js';
import ShareLink from '../ShareLink.js';
import ViewImageModal from '../viewImageModal.js';
import LoadingSkeleton from '../LoadingSkeletons/AlbumDetails.js';

const MoreFromAlbum = (props) => {

    const[page, setPage] = useState(1) 


   




    //Fetch Album Details
    const[data,setData] = useState(null)
    const[request,setRequest] = useState(null)
    const {data:FetchedData,isLoading, error, hasmore} = useFetch(request)

    const send_request = ()=>{
        setRequest({
            "album_id": props.data.song.album_id,
            "sort":"newest",
            "page": page,
        })
    }
    useEffect(()=>{
        if(props.data){
            send_request()
        }
    },[props.data.song.album_id])


    useEffect(()=>{
        if(FetchedData && FetchedData.total_results>0){
            setData(FetchedData)
           
        }
    },[FetchedData])

 
    const[isMenuOpened,setisMenuOpened] = useState(false)
 
    
    const [isViewingImage,setIsViewingImage] = useState(false);


    return (
        <>
       {data &&(
        <>
        <div className="albumdetails"> 
 
        
         <div className="htitle"><div className="lft"><p>ALBUM DETAILS</p></div></div>
            <div className="albumactions">
                <img src={data.results[0].album.artcover.length>1?ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/arts/smaller/?file_name="+data.results[0].album.artcover:"/assets/images/defaultsongart.jpg"} onClick={()=>setIsViewingImage(true)}/>
                <div className="btns">
                    <Button onClick={()=>setisMenuOpened(true)} variant="contained" color="primary" >Share Link</Button>
                    <DownloadZip album_id={props.data.song.album_id}/>
                   
                </div>
            </div>
            <div className="songdetails">
           
                <div className="par">
                    <p>Album Title:</p>
                    <p>{data.results[0].album.title}</p>
                </div>
                <div className="par">
                    <p>Genre:</p>
                    <p>{data.results[0].album.genre}</p>
                </div>
                <div className="par">
                    <p>Tracks:</p>
                    <p>{data.results[0].album.songs}</p>
                </div>

                <div className="par">
                    <p>Publisher:</p>
                    <p> <Link className="active" to={'/profile/'+data.results[0].user.user_id}>{data.results[0].user.artist_name}</Link></p>
                </div>
                <div className="par">
                    <p>Stats:</p>
                    <p className=""> Plays:  {data.results[0].album.plays} • Likes: {data.results[0].album.likes} • Downloads: {data.results[0].album.downloads}</p>
                </div>
                <div className="par">
                    <p>Date Released:</p>
                    <p>{data.results[0].album.date_added.substring(0, 16)}</p>
                </div>
                
                {(data.results[0].album.description.length>1) &&(
                <>
                    <div className="par">
                        <p>Description:</p>
                    </div>
                    <p className="descr">{data.results[0].album.description}</p>
                </>
                )}
            </div>
            <div className="moretracks">
            
            {data.results[0].songs.map((songs,index)=>( 
                    <TrackCard key={index} data={songs} Data={data.results[0].songs} index={index}/>
                ))
            }
            </div>
        </div>
        <ViewImageModal isViewingImage={isViewingImage} setIsViewingImage={setIsViewingImage} data={data} type="album_artcover"/>
        {(isMenuOpened && data) &&(
            <ShareLink setisMenuOpened={setisMenuOpened} data={data} type="Album"/>
        )}
        </>
        
       )}
       {isLoading &&(
            <div className="albumdetails"> 
                <div className="htitle"><div className="lft"><p>ALBUM DETAILS</p></div></div>
                <LoadingSkeleton/>
            </div>
        )}
       {(error=="network_err") &&(
         <div className="albumdetails"> 
            <div className="htitle"><div className="lft"><p>ALBUM DETAILS</p></div></div>
                <div className="no_data_msg">
                    <div className="container">
                        <div className="icon">
                            <img src="/assets/images/no_album_results.svg" />
                        </div>
                        <p>Check your internet connectivity</p> 
                        <Button onClick={()=>send_request()}>
                            Retry
                        </Button>
                    </div>
                </div>
            </div>
        )} 
        {(error=="no_results") &&(
            <div className="albumdetails"> 
                <div className="htitle"><div className="lft"><p>ALBUM DETAILS</p></div></div>
                <div className="no_data_msg">
                    <div className="container">
                        <div className="icon">
                            <img src="/assets/images/no_album_results.svg" />
                        </div>
                        <p>No results, link might broken or is been removed</p> 
                        <Button onClick={()=>send_request()}>
                            Retry
                        </Button>
                    </div>

                </div>
            </div>
        )}
        
        
       
            
      
    </>
    )
}

export default MoreFromAlbum
