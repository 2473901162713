import React from 'react'
const LoadingSkeleton = () => {
    return (
    <>
     <div className="albumcard-skeleton anime-skeleton">
        <div className="tp">

        </div>
        <div className="bt">
            <p></p>
            <p></p>
        </div>

    </div>
      
            
    </>
    )
}

export default LoadingSkeleton
