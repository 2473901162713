import {useState, useEffect, useContext} from 'react'
import { UserStoreContext } from '../../Contexts/UserStoreContext';
import Axios from 'axios';
const useFetch = (confirm,request) => {
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null); 
    

    const {is_logged_in, user_store} = useContext(UserStoreContext)
    




    useEffect(()=>{ 

        setIsLoading(true)
        if(is_logged_in && confirm){
        
            

            setTimeout(()=>{
                const url = '/geniuxmusic/api/delete_notifications.php';
                let cancel
                Axios({
                    method: 'post',
                    url: url,
                    data: {
                        confirm: 'yes',
                        myuserid: is_logged_in?user_store.user_id:"",
                        mytoken: is_logged_in?user_store.user_token:"",
                    },
                    cancelToken: new Axios.CancelToken(c => cancel = c),

                })
                
                .then(response=>{
                    setError(null)
                    setData(null)
                    setIsLoading(false)
                    if(response.data.response && response.data.response==="credentials_not_provided"){
                        setError('credentials_not_provided')
                    }else if(response.data.response && response.data.response==="user_not_found"){
                        setError('user_not_found')
                    }else if(response.data.response && response.data.response==="not_confirmed"){
                        setError('not_confirmed')
                    }else if(response.data.response && response.data.response==="failed_to_query"){
                        setError('failed_to_query')
                    }else if(response.data.response && response.data.response==="success"){
                        setData(response.data)
                        
                    }else{
                        setError('unknown_err');   
                    }
            
                
                })
                .catch(err=>{
                    setIsLoading(false)
                    setError('network_err');   
                })
                return () => cancel()
            },[3000])
        }else{
            setIsLoading(false)
        }
    
    
    },[is_logged_in,request])
 
    return {data, isLoading, error}
}

export default useFetch
