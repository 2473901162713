import {useState, useEffect, useContext} from 'react'
import Axios from 'axios';
import {useNavigate} from 'react-router-dom';
import { UserStoreContext } from '../Contexts/UserStoreContext';
import ENVIROMENTS from '../Environment/Configuration.json';

const useFetch = (request) => {
    const navigate = useNavigate()
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null);
    const {set_is_logged_in, set_user_store} = useContext(UserStoreContext)
    
    useEffect(()=>{ 

        setIsLoading(true)
        setError(null)
        if(request){

    
            const url = ENVIROMENTS.baseUrl + '/geniuxmusic/api/registration.php';
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: {
                    interest: request.interest,
                    fullname: request.fullname,
                    email: request.email,
                    password: request.password,
                },
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                setIsLoading(false)
                if(response.data.length>0 && response.data[0].user_id){
                    // localStorage.setItem('user_store', JSON.stringify(...response.data))
                    set_is_logged_in(true)
                    set_user_store(...response.data)
                    setData(...response.data)

                    navigate('/profile/'+response.data[0].user_id)

                }else{
                    if(response.data.response && response.data.response==="full_name_empty"){
                        setError('full_name_empty');  
                    }else if(response.data.response && response.data.response==="fullname_too_long"){
                        setError('fullname_too_long');  
                    }else if(response.data.response && response.data.response==="email_address_empty"){
                        setError('email_address_empty');  
                    }else if(response.data.response && response.data.response==="invalid_email"){
                        setError('invalid_email');  
                    }else if(response.data.response && response.data.response==="password_empty"){
                        setError('password_empty');  
                    }else if(response.data.response && response.data.response==="password_is_short"){
                        setError('password_is_short')
                    }else if(response.data.response && response.data.response==="email_taken"){
                        setError('email_taken');  
                    }else{
                        setError('unknown_error');  
                    }

                }
                
           
               
            })
            .catch(err=>{
                if(!Axios.isCancel(err)){
                    setError('network_err');
                    setIsLoading(false)
                }  
            })
            return () => cancel()
        }else{
            setIsLoading(false)
        }
    
    
    },[request])

    return {data, isLoading, error}
}

export default useFetch
