import React, {Component} from "react";
import {useState,useEffect, useContext, useRef, useCallback} from 'react';
import AlbumCard from '../Cards/card14.js';
import useFetch from '../APIs/FetchAlbums.js';
import CardSkeleton from '../LoadingSkeletons/Card13.js';
import { UserStoreContext } from '../Contexts/UserStoreContext';
const Albums = () => {

    const {is_logged_in, user_store} = useContext(UserStoreContext);


    const [page,setPage] = useState(1);

    const [data, setData] = useState(null);
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData && FetchedData.total_results>0){
            if(data && data.length>0){
              setData([...data, ...FetchedData.results])
            }else{
                setData(FetchedData.results)
            }
        }
    },[FetchedData])


    const send_request =()=>{
        setRequest({
            "user_id":is_logged_in?user_store.user_id:"",
            "song_id":"",
            "search_string":"",
            "genre":"",
            "sort":"newest",
            "page":page,
        })
    }



    useEffect(()=>{
        send_request()
    },[page])

    useEffect(()=>{
        setData(null)
        setPage(1)
        send_request()
    },[is_logged_in])

    const LoadMore = ()=>{
        const currpage = page;
        setPage(currpage+1)
    }
  
    const observer = useRef()
    const lastBookElementRef = useCallback(node=>{
  
        if(observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries=>{
            if(entries[0].isIntersecting){
  
              if(FetchedData && FetchedData.results.length>0){
                LoadMore()
              }
                
            }
        })
        if(node) observer.current.observe(node)
       
    },[FetchedData])

    return (
        <>
            {data &&(
            <>

                <div className="htitle">
                    <div className="lft"><p>ALBUMS ({FetchedData && (FetchedData.total_results)})</p></div>
                </div>
                {data.map((song,index)=>(
                    <AlbumCard index={index} data={song}/>
                ))}
                <div ref={lastBookElementRef}></div>
            </>
            )}
            {(isLoading && !data)&& (
                <>
                    <div className="htitle">
                        <div className="lft"><p>ALBUMS</p></div>
                    </div>
                    <CardSkeleton/>
                    <CardSkeleton/>
                    <CardSkeleton/>
                    <CardSkeleton/>
                    <CardSkeleton/>
                    <CardSkeleton/>
                </>
            )}
             {(isLoading && data)&& (
                <>
                    <CardSkeleton/>
                    <CardSkeleton/>
                    <CardSkeleton/>
                    <CardSkeleton/>
                    <CardSkeleton/>
                    <CardSkeleton/>
                </>
            )}
        </>
    )
}

export default Albums