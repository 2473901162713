import React, {Component} from "react";
import {useState, useEffect, useContext} from 'react';
import { UserStoreContext } from '../Contexts/UserStoreContext';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import useFetch from '../APIs/SendCommentReply.js';
import NoInternet from '../Dialogs/NoInternet.js';
import LogginDialog from '../Dialogs/LogginDialog.js';
const CommentBoxReply = (props) => {
    const {is_logged_in, user_store} = useContext(UserStoreContext)



    const [message, setMessage] = useState('');
    const [showLogIn, setShowLogIn] = useState(false);
  
    const [isEmptyMessage, setIsEmptyMessage] = useState(null);


    const onhandle_message = (e) =>{
  
        setMessage(e.target.value)
     
  
    }


    const [data, setData] = useState(null);
    const [request,setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)
  
    useEffect(()=>{
      if(FetchedData && FetchedData.length>0){
        props.setData(null)
        props.setPage(1)
        props.send_request()
        setMessage('')
      }
  
    },[FetchedData])


    const send_request=()=>{
        setRequest({
            "song_id": props.song_id,
            "comment_id": props.comment_id,
            "message": message
        })
    }

    const onhandle_submit= (e) =>{
        e.preventDefault()
        if(is_logged_in){
            if(message===''){
             
                setIsEmptyMessage(true)
                setTimeout(function(){
                    setIsEmptyMessage(false) 
                },1000);
            
            }else{
                send_request()
                
            }
        }else{
         setShowLogIn(true)
        }
        
    }

      
 
    return (
        <>
 

       
        <div className="CommentBox">
            <form noValidate autoComplete="off">


                <div className="lft">
                    {is_logged_in?    
                        user_store.user_avatar.length>2?
                        <img className="ppimg" src={"/geniuxmusic/data/avatars/"+user_store.user_avatar}/>
                        :
                        <div className="ppimg">{user_store.artist_name.substring(0, 1)}</div>  
                    :
                        <div className="ppimg">ME</div>            
                    }
                    <textarea placeholder="Type Comment" className={isEmptyMessage?'active':''} value={message} onChange={onhandle_message}></textarea>
                
                </div>
                <div className="rgt">
                    <Button variant="contained" color="primary" onClick={onhandle_submit}>{error?"Failed, Retry":"Comment"} {isLoading && <CircularProgress/>}</Button>
                </div>
            
            </form>
        </div>
        {/* {Error &&(
            <NoInternet setError={setError} handleError={handleError}/>
        )} */}
        {showLogIn &&(
          <LogginDialog setShowLogIn={setShowLogIn}/>
        )}
         
        </>
    )
}

export default CommentBoxReply
