import React from 'react'
const RecommendedTrack = () => {
    return (
        <div className="card005-skeleton anime-skeleton">
            <div className="lft">
                <p></p>
                <div>
                    <p></p>
                    <p></p>
                    <p></p>
                </div>
            </div>
            <div className="rgt">
                <div></div>
            </div>
        </div>
    )
}

export default RecommendedTrack
