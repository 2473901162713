import React, {Component} from "react";
import {useState,useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import CommentCard from './CommentCard.js';
import FetchCommentReplies from './FetchCommentReplies.js';
import useFetch from '../APIs/FetchComments.js';
import LoadingSkeleton from '../LoadingSkeletons/Comments.js';
import NoInternet from '../Dialogs/NoInternet.js';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
const CommentReplies = (props) => {
    const navigate = useNavigate()
  
    const [page,setPage] = useState(1);

    const [data, setData] = useState(null);
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData && FetchedData.total_results>0){
                setData(FetchedData.results[0])
        }
        
    },[FetchedData])


    const send_request =()=>{
        setRequest({
            "user_id":"",
            "song_id":props.song_id,
            "comment_id": props.comment_id,
            "search_string":"",
            "sort":"newest",
            "page":1,
        })
    }



    useEffect(()=>{
        send_request()
    },[page])

   

    //Handle Error Onclick
    const handleError = ()=>{
        send_request()
    }
    const[showNoInternet,setShowInternet] = useState(false)
    useEffect(()=>{
        if(error){
            setShowInternet(true)
        }
    },[error])

    const closeModal = ()=>{
        setShowInternet(false);
    }


    return (
        <>
        <Modal
                open={true}
                onClose={()=>navigate("/playing/"+props.song_id)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
            
                    <div className="commentReply fadefx">
                        <IconButton onClick={()=>navigate("/playing/"+props.song_id)} aria-label="close" className="closebtn"> 
                            <KeyboardArrowDownIcon />
                        </IconButton>
                        <div className="contain">
                            {data &&
                                <>
                                    <CommentCard data = {data} />  
                                    <div className="title"><p>REPLIES ({FetchedData.results[0].total_replies})</p></div> 
                                    <FetchCommentReplies song_id={props.song_id} comment_id = {props.comment_id} send_request={send_request}/>
                                </>
                            }
                            
                            {isLoading && (
                                <LoadingSkeleton/>
                            )}
                                    
                            {/* Feedbacks */}
                            {(!data && error=="network_err") &&(
                                <div className="no_data_msg">
                                    <div className="container">
                                        <div className="icon">
                                            <img src="/assets/images/no_comments_found.svg" />
                                        </div>
                                        <p>Check your internet connectivity</p> 
                                        <Button onClick={()=>send_request()}>
                                            Retry
                                        </Button>
                                    </div>

                                </div>
                            )}  

                            {(FetchedData && FetchedData.total_results<1) &&(
                                <>
                                    <div className="no_data_msg">
                                        <div className="container">
                                            <div className="icon">
                                                <img src="/assets/images/no_comments_found.svg" />
                                            </div>
                                            <p>Coment not found or has been removed</p>
                                            <Button onClick={()=>send_request()}>
                                                Retry
                                            </Button> 
                                        </div>
                                    </div>
                                </> 
                                
                            )}   
                            {(data && showNoInternet)&&(
                                <NoInternet closeModal={closeModal} handleError={handleError}/>
                            )}
                        

                            
                        </div>
                    </div>
            
          
          </Modal>
          
          
  
            
        </>
    )
}

export default CommentReplies
