import React, {Component} from "react";
import {useState, useEffect, useContext} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { UserStoreContext } from '../Contexts/UserStoreContext';
import {useNavigate} from 'react-router-dom'; 
import CircularProgress from '@mui/material/CircularProgress';
import useLogin from '../APIs/FetchLogin.js';
import { Link } from "react-router-dom";
const Login = () => {
  
    //Check Login Credentials
    const navigate = useNavigate()
    const {is_logged_in, user_store} = useContext(UserStoreContext)
    const [username,setUsername] = useState('');
    const [password,setPassword]= useState('');
    const [myerror,setMyError]= useState(null); 
  
    useEffect(()=>{
      if(is_logged_in){
         navigate('/')
      }
    },[])
  
    const onhandle_username = (e) =>{
      setUsername(e.target.value)
    }
    const onhandle_password = (e) =>{
      setPassword(e.target.value)
    }
    const [credentials, setCredentials] = useState({
      'username':'',
      'password':''
    })

    const [Error,setError] = useState(null)
    const [request,setRequest] = useState(false)
    const {data:FetchedData,isLoading, error} = useLogin(credentials,request)
  

   
    
  
    const onhandle_submit= (e) =>{
      e.preventDefault()
      if(username===""){
        document.getElementById('username_input').focus()
      }else if(password===""){
        document.getElementById('password_input').focus()
      }else{
        setRequest(!request)
        setCredentials({
          'username':username,
          'password':password
        })
      }
      
  
    }

    const [showpass, setShowPass] = useState(false);

    return (
        <>
       
            <form className="myform" onSubmit={onhandle_submit}>
            <TextField variant="filled" inputProps={{autoComplete: 'new-password'}} value={username} label="Email or Username" id="username_input" onChange={onhandle_username}/>
            <div className="passwordfield">
              <TextField variant="filled" inputProps={{autoComplete: 'new-password'}} label="Password" type={showpass?"text":"password"} value={password} id="password_input" onChange={onhandle_password}/>
              {password!=="" &&(
                <div className="icon" onClick={()=>setShowPass(!showpass)}>
                    {showpass?"HIDE":"SHOW"}
                </div>   
              )}
            </div>
            
                <div className="myerr">  
                 
                  {(error==="password_incorrect" && !isLoading) &&(
                      <p>Password is incorrect.</p>
                  )}
                  {(error==="username_not_found" && !isLoading) &&(
                      <p>Email address is not registered.</p>
                  )}
                  {(error==="network_err"  && !isLoading) &&(
                      <p>No internet.</p>
                  )}
                  {(error==="unknown_error"  && !isLoading) &&(
                      <p>Something went wrong.</p>
                  )}
                  {(isLoading && !error) && <CircularProgress/>}
                  
                  
                  {/* <Button onClick={()=> navigate('/forgot-password')} endIcon={<ErrorIcon/>} >
                      Lost Password?
                  </Button>  */}
                </div>  
            
                
        
            
            <div className="btns">
                <Button variant="contained" color="primary"  type="submit" value={password} >LOGIN </Button>
            </div>
            <p className="forgotpass"><Link to="/forgot-password">Forgot Password</Link></p>
        </form>
        </>
    )
}

export default Login
