import React, {Component} from "react";
import {useState,useEffect} from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import ImageCrop from './ImageCrop.js';
import Button from '@mui/material/Button';
import {Link, useNavigate  }  from'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ErrorIcon from '@mui/icons-material/Error';
import useFetch from '../APIs/UploadSong.js';
import UploadingProgress from './UploadingProgress.js';
import AddLyrics from '../Components/AddLyrics.js';
import NavigationMenuToggle from '../Navigation/NavigationMenuToggle.js'

export default function UploadSingle(props) {
  useEffect(()=>{
      window.scrollTo(0, 0)
      document.body.style.overflow = "unset"
      document.title = "Upload Music - Song";
  },[])
  const navigate = useNavigate()
  
  const [FetchGenres] = useState([{
      "id": "16",
      "orderId": "1",
      "genre": "Hiphop"
  },
  {
      "id": "17",
      "orderId": "2",
      "genre": "House"
  },
  {
      "id": "24",
      "orderId": "3",
      "genre": "RNB"
  },
  {
      "id": "3",
      "orderId": "4",
      "genre": "Classical"
  },
  {
      "id": "39",
      "orderId": "5",
      "genre": "Instrumentals"
  },
  {
      "id": "38",
      "orderId": "6",
      "genre": "Others"
  }])




  //Song Fields
  const [isAddingLyrics, setIsAddingLyrics] = useState(false);

  
  const [lyrics, setLyrics] = useState('');
  const [song_title, setTitle] = useState('');
  const [genre, setGenre] = useState('');
  const [description, setDescription] = useState('');

  //Validate Fields
  const [myError, setMyError] = useState(null);

  //Error From API
  const [Error,setError] = useState(null)

  //Song MP3 File
  const [songfile, setSongFile] = useState(null);
  const handleFile =(e)=>{
    setSongFile(null)
    setMyError(null)
    if(e.target.files[0]){
      if(e.target.files[0].size>100000000){
        setMyError('file_is_huge');
      }else if(e.target.files[0].type!=="audio/mpeg"){
        setMyError('file_not_allowed');
      }else{
        setSongFile(e.target.files[0]);
      
      }
      
    }else{
      setMyError('file_not_uploaded');
    }
    e.target.value = "";
    
  }

  useEffect(()=>{
    if(songfile){
      setTitle(songfile.name.replace(/\.[^/.]+$/, ""))
    }
  },[songfile])

  //Song Art Cover
  const [artcover, setArtCover] = useState(null);

  function handleSongTile(e){
    if(e.target.value.length<=90){
        setTitle(e.target.value)
    }
    
  
  }
  function handleSongDescription(e){
    if(e.target.value.length<=500){
      setDescription(e.target.value)
    }
  }



  const [data, setData] = useState(null);
  const [request,setRequest] = useState(null)
  const [UploadProgressValue,setUploadProgressValue] = useState(null)
  const {data:FetchedData,isLoading, error,UploadPercentage} = useFetch(request)

  useEffect(()=>{
   
    setError(error)
   

  },[error])

  useEffect(() => {
    if(FetchedData){
      // Completed
   
    }
  }, [FetchedData])


  useEffect(() => {
    if(UploadPercentage){
      setUploadProgressValue(UploadPercentage)
    }
  }, [UploadPercentage])

  const sendrequest = () =>{
    setData(null)
    setUploadProgressValue(null)
    setRequest({
      'song_title':song_title,
      'genre':genre,
      'description':description,
      'lyrics': lyrics,
      'songfile':songfile,
      'artcover':artcover,
    })
  }
  const onhandle_submit= (e) =>{
    e.preventDefault()
    setMyError(null);
    if(!songfile){
      setMyError('file_not_attached');
      window.scrollTo({top: 0, behavior: 'smooth'});
    }else if(song_title===""){
      document.getElementById('song_title').focus()
    }else if(genre===""){
      setMyError('genre_not_selected');
      window.scrollTo({top: 0, behavior: 'smooth'});
    }else{
      sendrequest()
    }
    

  }





  const saveLyrics=()=>{
   
  }
  return (
    <>
      {props.innerWidth<992&&(
        <div className="navbar">
            <div className="wrapper">
                <div className="lft">
                    <IconButton className="icon" onClick={()=>navigate('/uploads/upload')}>
                        <ArrowBackIcon />
                    </IconButton> 
                    <div className="titles">
                        <p>UPLOAD SONG</p>
                    </div>
                
                </div>
                <div className="rgt">
                    <NavigationMenuToggle/>
                </div>
            </div>
            
        </div>
      )}
     

      <div className="mycontainer">
        {/* My  path */}
        {props.innerWidth>992 &&(
          <div className="mypath">
              <div className="wrapper">
                <div className="lft">
                  {window.history.length>1?
                    <Button onClick={()=>navigate(-1)}>
                      <KeyboardBackspaceIcon/>
                    </Button>  
                    :
                    <Button disabled="true">
                        <KeyboardBackspaceIcon/>
                    </Button>  
                  }
                </div>
                <div className="rgt">
                  <p>
                    <Link to="/uploads/upload" className="active">Upload Music</Link>
                    <Link to="/uploads/upload/single" className="">/ Single</Link>
                  </p>
                </div>
            </div>
          </div>
        )}
        <div className="wrapper">
          <div className="uploadmusic">
            
              
                <form onSubmit={onhandle_submit} className="myform">
                <div className="row">
                    <div className="col-12 col-lg-7">
                      <div className="uploadcover">
                        <div className="imgcrop">
                          <ImageCrop data = {data} setArtCover={setArtCover}/>
                        </div>
                        <div className="titles">
                            <p>Choose</p>
                            <p>Insert image of your song art cover</p>
                        </div>
                      </div>   
                      {!songfile && myError==="file_not_attached"?<p className="mylabel error">File is required <div className="icon"><ErrorIcon/></div></p>:""}
                      {myError==="file_not_allowed"?<p className="mylabel error">Only MP3s allowed. <div className="icon error"><ErrorIcon/></div></p>:""}
                      {myError==="file_is_huge"?<p className="mylabel error">File size is limited to 100MB <div className="icon"><ErrorIcon/></div></p>:""}
                      {myError==="file_not_uploaded"?<p className="mylabel error">Try uploading different file <div className="icon"><ErrorIcon/></div></p>:""}
              
                      <label htmlFor="UploadInput" className={(!songfile && myError==="file_not_attached") || (myError==="file_not_allowed" )?"upload-file active":"upload-file"}>
                        <div className="lft">
                            <p>Upload MP3</p>
                        </div>
                        <div className="rgt">
                          <p>
                            {songfile?songfile.name:"Click to upload (Audio/MP3)"}
                          </p>
                        </div>
                        <input type="file" accept=".mp3,audio/*" id="UploadInput" onChange={handleFile}/>
                      </label>
                      <div className="fields">
                          
                          <TextField variant="filled" onChange={handleSongTile} id="song_title" label="Song Title"  value={song_title.slice(0,90)}/>
                          {myError==="genre_not_selected"?<p className="mylabel error">Select Genre <div className="icon"><ErrorIcon/></div></p>:
                          <p className="mylabel">Select Genre </p>
                          }
                          <div className="selectoptions">
                            {FetchGenres.map((data,index)=>(
                                <p key={index} onClick={()=>setGenre(data.genre)} className={genre===data.genre?"active":""}>{data.genre}</p>
                            ))}
                          </div>
                          <TextField variant="filled" label="Description (Optional)" value={description} multiline rows={4} onChange={handleSongDescription}/>
                          
                          <div className="addlyrics">
                              <div className="lft">
                                <p className="">Lyrics(Optional)</p>
                                <p className="">
                                {lyrics!=="" &&(<>Added</>)}
                                </p>
                              
                              </div>

                              
                              
                              <div  className="rgt">
                                <Button onClick={()=>setIsAddingLyrics(true)}>
                                  { lyrics!==""?
                                  <>Modify Lyrics</>
                                  :
                                  <>Add Lyrics</>
                                  }
                                  </Button>
                                </div>
                          </div>
                          <div className="myerr">  
                          
                            {(error==="song_title_empty" && !isLoading) &&(
                                <p>Song Title Required</p>
                            )}
                            {(error==="song_title_too_long" && !isLoading) &&(
                                <p>Song Title Too Long</p>
                            )}
                            {(error==="user_not_found" && !isLoading) &&(
                                <p>Something went wrong, try to logout and login again</p>
                            )}
                            {(error==="credentials_not_provided" && !isLoading) &&(
                                <p>Something went wrong, try to logout and login again</p>
                            )}
                            {(error==="song_file_not_attached" && !isLoading) &&(
                                <p>No file attached</p>
                            )}
                            {(error==="song_file_format_error" && !isLoading) &&(
                                <p>Attached file format is not allowed</p>
                            )}
                            {(error==="song_file_error_occured" && !isLoading) &&(
                                <p>File attached is corrupted, try to upload different file</p>
                            )}
                            {(error==="song_file_file_is_huge" && !isLoading) &&(
                                <p>Attached file is huge, Max size allowed is 200MB</p>
                            )}
                            {(error==="failed_to_save_file" && !isLoading) &&(
                                <p>Something went wrong: 004, contact developers</p>
                            )}
                            {(error==="failed_to_query" && !isLoading) &&(
                                <p>Something went wrong: 007, contact developers</p>
                            )}
                            {(error==="unknown_error" && !isLoading) &&(
                                <p>Something went wrong, contact developers</p>
                            )}
                            {(error==="network_err" && !isLoading) &&(
                                <p>Check your network connectivity</p>
                            )}
                                  
                                
                        </div>  
                          
                          <div className="btns">
                            <Button  type="submit" className="defaultBTN">UPLOAD SONG</Button>
                          </div> 
                          
                      </div>
                    </div>
                    <div className="col-12 col-lg-5">
                        <div className="rightmenu">
                              <div className="content-container">
                                  <div className="htitle">
                                      <div className="lft"><p>YOUR MUSIC, MATERIALS AND INFORMATION</p></div>
                                  </div>
                                  <p>
                                  When you upload your Recordings through our Services, you are asked to submit Metadata as well as cover artwork for use on the Stores. You submit the Recordings, cover artwork and any other information and material (jointly “Material”) at your own expense and in formats required for use on the Stores.
                                  <br/><br/>
                                  You are fully responsible for everything you submit to us. If we find it unsuitable, we reserve the right to, in our sole discretion, remove the information and/or prevent you from using our Services and/or any or all Stores.
                                  <br/><br/>
                                  You submit the Recordings to us in a pre-agreed format and for a pre-agreed release date. The release date will be locked and you may not change it.
                                  <br/><br/>
                                  </p>
                                  <div className="htitle">
                                      <div className="lft"><p>Grands of rights</p></div>
                                  </div>
                                  <p>
                                  You grant us all necessary consents and rights, without limitation, to sell and make use of your recordings for digital downloads, interactive and non-interactive streaming, cloud services and streaming-on-demand services. This grant of rights does not, however, constitute a transfer of ownership.
                                  <br/>
                                  <br/>
                                  <b>By using our Services you grant us, during the Term and throughout the Territory,</b><br/><br/>
                                    The non-exclusive right to use your className, photographs, likenesses, cover artwork, biographical and other information attributable to you, which you have submitted to Geniux Music.<br/><br/>
                                    The right for Geniux Music to sublicense or otherwise transfer the above rights to any and all Stores.<br/><br/>
                                    The right for Geniux Music to perform Metadata corrections (where necessary).<br/><br/>
                                    The right for Geniux Music to synchronize and authorize others to synchronize your Recordings with visual images, to combine portions of your Recordings with still or moving images or as a live stream.<br/>
                                  </p>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
              
          </div>
        </div>
      </div>
      {isAddingLyrics &&(
          <AddLyrics lyrics={lyrics} setLyrics={setLyrics} song_title={song_title} setIsAddingLyrics={setIsAddingLyrics} saveLyrics={()=>saveLyrics()} innerWidth={props.innerWidth}/>
      )}
 
      {isLoading && !error?
          <UploadingProgress UploadProgressValue = {UploadProgressValue} artcover={artcover}/>
        :
        ""
      }
   
 
    </>
  );
}

