import React, {Component} from "react";
import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import useFetch from '../APIs/FetchFollowers.js';
import UserCard from '../Cards/UserCard.js';
import LoadingSkeleton from '../LoadingSkeletons/UserCard03.js';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import { Navigation } from "swiper/modules";
const Followers = (props) => {

    //userId,songId,search_string,genre,sort,page,
    
    const [data, setData] = useState(null);
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData){
            if(FetchedData.total_results>0){
                setData(FetchedData)
            }
         
        }
    },[FetchedData])


    const send_request =()=>{
        setData(null)
        setRequest({
            "user_id":props.user_id,
            "type":"followers",
            "page":1,
        })
    }



    useEffect(()=>{
        send_request()
    },[])

    return (
    <>
   

    {isLoading &&(
        <>
            <div className="htitle">
                <div className="lft"><p>FOLLOWERS</p></div>
            </div>
            {props.innerWidth<992 &&(
                <div className="vertical-container">
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                </div>
            )}
            {props.innerWidth>992 &&(
                <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1600: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                    }}
                    className="mySwiper"
                >
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                    
                </Swiper>           
            )}
        </>
    )}
     {data &&(
        <>
            <div className="htitle">
                <div className="lft"><p>FOLLOWERS</p></div>
                {data.total_results>10 &&(
                 <div className="rgt"><p><Link to={'/profile/followers/'+props.user_id}>See more</Link></p></div>   
                )}
                
                
            </div>
            {props.innerWidth<992 &&(
                <div className="vertical-container">
                    {data.results.map((user,index)=>{
                            return <UserCard key={index} data = {user} />
                        }
                    )}
                </div>
            )}
            {props.innerWidth>992 &&(
                <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1600: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                    }}
                    className="mySwiper"
                >
                    {data.results.map((user,index)=>{
                            return <SwiperSlide key={index}><UserCard  data = {user} /></SwiperSlide>
                        }
                    )}
                    
                </Swiper>           
            )}
        </>
    )}
    </>  
    )
}

export default Followers
