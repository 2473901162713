import React, {Component} from "react";
import {useState, useEffect, useContext} from 'react';
import NavigationMenuToggle from '../Navigation/NavigationMenuToggle.js'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import {Link, useNavigate  }  from'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import { UserStoreContext } from '../Contexts/UserStoreContext';
import Albums from './Albums.js';
import Songs from './Songs.js';
const Uploads = (props) => {

   
    const navigate = useNavigate()
    const {is_logged_in, user_store} = useContext(UserStoreContext)


    return (
        <>{is_logged_in &&(
            <>

            
                {props.innerWidth<992 &&(
                    <div className="navbar">
                        <div className="wrapper">
                            <div className="lft">
                                <IconButton className="icon" onClick={()=>navigate(-1)}>
                                    <ArrowBackIcon />
                                </IconButton> 
                                <div className="titles">
                                    <p>MANAGE UPLOADS</p>
                                </div>
                            
                            </div>
                            <div className="rgt">
                                <NavigationMenuToggle/>
                            </div>
                        </div>
                    </div>
                )}
                <div className="mycontainer">
                    {props.innerWidth>992 &&(
                        <div className="mypath">
                            <div className="wrapper">
                                <div className="lft">
                                {window.history.length>1?
                                    <Button onClick={()=>navigate(-1)}>
                                    <KeyboardBackspaceIcon/>
                                    </Button>  
                                    :
                                    <Button onClick={()=>navigate(-1)} disabled="true">
                                        <KeyboardBackspaceIcon/>
                                    </Button>  
                                }
                                </div>
                                <div className="rgt">
                                    <p>
                                        <Link to="/settings" className="active">{is_logged_in?user_store.artist_name:"Account"}</Link>
                                        <Link to="/uploads" className="">/ Manage Uploads</Link>
                                    </p>
                                    <Link to="/uploads/upload">
                                        <IconButton className="icon">
                                            <FileUploadRoundedIcon />
                                        </IconButton>  
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="manageuploads">
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <Songs user_id={user_store.user_id}/>
                                <br/><br/>
                            </div>
                            <div className="col-12 col-lg-6">
                                <Albums user_id={user_store.user_id}/>
                            </div>
                        </div>
                    
                    </div>   
                    </div>
                
                </div>
            </>
        ) }
        </>
    )
}

export default Uploads
