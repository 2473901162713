import {useEffect} from 'react'
import {useNavigate, useSearchParams}  from'react-router-dom'; 
const SongPostId = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const postId = searchParams.get("postId");

    useEffect(()=>{
        navigate("/playing/"+postId, { replace: true })
    },[postId])
}

export default SongPostId