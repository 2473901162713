import {React,useEffect,useState} from 'react'
import TrackCard from '../Cards/card005.js';
import {Link} from 'react-router-dom';
import useFetch from '../APIs/FetchSongs.js';
import LoadingSkeleton from '../LoadingSkeletons/card005.js';
const TopSongs = (props) => {
 
    //userId,songId,search_string,genre,sort,page,
    
    const [data, setData] = useState(null);
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData && FetchedData.total_results>0){
            setData(FetchedData)
        }
    },[FetchedData])


    const send_request =()=>{
        setData(null)
        setRequest({
            "user_id":"",
            "song_id":"",
            "search_string":props.filters.search_string,
            "genre":props.filters.genre,
            "sort":"played",
            "type":"",
            "page":"",
        })
    }



    useEffect(()=>{
        send_request()
    },[props.filters])

  return (
    <>
        
        {data &&(
            <>
            
                <div className="col-12 col-lg-7">
                    <section>
                        <div className="htitle">
                            <div className="lft"><p>{props.filters.search_string!==""?<>POPULAR SONGS ({data.total_results})</>:<>TOP 3 PLAYED SONGS</>} <span className="platform">GENIUX MUSIC</span></p></div>

                            {data.total_results > 3 &&(
                                <div className="rgt"><p><Link to={props.filters.search_string!=''?"/browse?group=songs&search="+props.filters.search_string+"&sort=played":"/browse?group=songs&sort=played"}>See more</Link></p></div>
                            )}
                            
                        </div>
                        {data.results.slice(0,3).map((song,index)=>( 
                            <TrackCard key={index} data={song} Data={data.results} index={index}/>
                        ))}
                    </section>
                    <br/>
                </div>
            </>
        )}
        {isLoading &&(
            <>
                 <div className="col-12 col-lg-7">
                    <section>
                        <div className="htitle">
                            <div className="lft"><p>{props.filters.search_string!==""?<>POPULAR SONGS</>:<>TOP 3 PLAYED SONGS</>} <span className="platform">GENIUX MUSIC</span></p></div>
                            <div className="rgt"><p><Link to={props.filters.search_string!=''?"/browse?group=songs&search="+props.filters.search_string+"&sort=played":"/browse?group=songs&sort=played"}>See more</Link></p></div>
                        </div>
                        <LoadingSkeleton/>
                        <LoadingSkeleton/>
                        <LoadingSkeleton/>   
                    </section>
                    <br/>
                 </div>
               
            </>
            
        )}
                
    </>
  )
}

export default TopSongs