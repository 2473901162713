import React, {Component} from "react";
import {useState, useEffect, useContext} from 'react';

import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import { UserStoreContext } from '../Contexts/UserStoreContext';
import RecoverPasswordStepOne from './RecoverPasswordStepOne.js';
import RecoverPasswordStepTwo from './RecoverPasswordStepTwo.js';
const Settings = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const username_param = searchParams.get("username");
    const v_pin_param = searchParams.get("v_pin");

    const navigate = useNavigate();
    const[username, setusername] = useState('');

    const[stepOne, setSetOne] = useState(true);
    const[stepTwo, setSetTwo] = useState(false);


    const {is_logged_in, user_store} = useContext(UserStoreContext)
    useEffect(()=>{
      if(is_logged_in){
         navigate('/')
      }
    },[])


    return (
        <>
       
        
            
            <div className="recoverpassword">
               
                {username_param && v_pin_param?(
                    <>
                        <div className="htitle">
                            <div className="lft"><p>Create New Password</p></div>
                        </div>
                        <RecoverPasswordStepTwo username={username} username_param={username_param} setusername={setusername} v_pin_param={v_pin_param}/>
                    </>
                ):
                <>
                    <div className="htitle">
                        <div className="lft"><p>Recover Password</p></div>
                    </div>
                    <RecoverPasswordStepOne username={username} setSetOne={setSetOne} setSetTwo={setSetTwo} setusername={setusername}/>
                </>
                    
                }
                <p className="forgotpass"><Link to="/login">Login/register an account</Link></p>
            </div>  
             
        </>
    )
}

export default Settings
