import React from 'react'

const Card007 = () => {
  return (
    
    <div className="card007-skeleton anime-skeleton">
        <div className="lft">
        <div className="circle">

        </div>
        <div className="titles">
        <p></p>
            <p></p>
        </div>
        
        </div>
        <div className="rgt">
            <p></p>
        </div>
    </div>
  )
}

export default Card007