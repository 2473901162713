import React, {Component} from "react";
import {useEffect} from 'react';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DoneIcon from '@mui/icons-material/Done';
import {Link} from 'react-router-dom';
export default function AddLyrics(props) {
useEffect(()=>{
    if(props.song_title.length>0){
        document.title = "You adding lyrics for: "+props.song_title;
    }else{
        document.title = "Add Lyrics";
    }
},[])

  document.body.style.overflow = "hidden"

  function handleLyrics(e){
    if(e.target.value.length<=20000){
      props.setLyrics(e.target.value)
    }
  }

  
  const minimize = ()=>{
    
      document.body.style.overflow = "unset"
      props.setIsAddingLyrics(false)
    
    
  }
  return (
    <div className="addlyricscontainer">
        {props.innerWidth<992 &&(
        <div className="navbar">
            <div className="wrapper">
                <div className="lft">
                    <div className="titles">
                        <p>Add Lyrics</p>
                        {props.song_title.length<40?
                            <p>{props.song_title}</p>
                            :
                            <p>{props.song_title.substring(0, 40) + "..."}</p>
                        }
                    </div>
                
                </div>
                <div className="rgt">
                  <IconButton className="icon"  onClick={()=>minimize()}>
                      <CheckIcon />
                  </IconButton>
                </div>
            </div>
            
        </div>  
        )}
        

        <div className='mycontainer'>
        {props.innerWidth>992 &&(
            <div className="mypath">
                <div className="wrapper">
                  <div className="lft">
                      <Button onClick={()=>minimize()}>
                        <KeyboardBackspaceIcon/>
                      </Button>  
                   
                  </div>
                  <div className="rgt">
                    <p>
                      <Link to="" className="active">
                        Adding Lyrics 
                      </Link>
                    
                      <Link to="" >{props.song_title.length<40?props.song_title:props.song_title.substring(0, 40) + "..."}</Link>
                    </p>
                    <IconButton className="icon" onClick={()=>minimize()}>
                        <DoneIcon />
                    </IconButton>  
                  </div>
              </div>
            </div>
          )}
          <div className="txtarea">
            <form className="myform">
                <textarea placeholder="Start typing here" value={props.lyrics} onChange={handleLyrics}/>
            </form>
          </div>
          
        </div>
   
        
   

 
    </div>
  );
}

