import React, {Component} from "react";
import {useState, useEffect,useContext} from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {PlayContext} from '../Contexts/PlayContext.js'
import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';


export default function BasicModal(props) {
    const {PlayData,setPlayData} = useContext(PlayContext)

    const navigate = useNavigate()

    const [open, setOpen] = useState(false);

  
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
            setOpen(false)
            props.setisDialogOpened(false)

    }
    const goto = () => {
         navigate('/profile/'+props.myuserid)
    }

    useEffect(()=>{
        handleOpen()
    },[])

    useEffect(() => {
        if(PlayData===props.songId){
            setPlayData(null)
        }
    }, [props.songId])



  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
            <div className="myModal fadefx">
                <div className="container">
                    <div className="errIcon">
                        <CheckCircleIcon/>
                    </div>
                    <div className="errbody">
                        <p>Your song has been deleted successfully</p>
                        <Button onClick={()=>navigate(-1)}  className="btn">Okay</Button>
                        <Button onClick={()=>handleClose()} className="btn closebtn">Close</Button>
                    </div>
                </div>
            </div>
         
      </Modal>
  );
}



