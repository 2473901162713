import ENVIROMENTS from '../Environment/Configuration.json';
import React, {Component} from "react";
import {useState, useEffect,useContext} from 'react'
import RssFeedIcon from '@mui/icons-material/RssFeed';
import CommentIcon from '@mui/icons-material/Comment';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import GetAppIcon from '@mui/icons-material/GetApp';
import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TimeAgo from 'timeago-react';
import useFetch from '../APIs/UpdateNotificationStatus.js';
import ValidateUser from '../UserCredentials/ValidateUser';
export default function BasicModal(props) {

    const [open, setOpen] = useState(false);

  
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        props.setisMenuOpened(false)

    }


    const [status, setStatus] = useState(null)
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error,hasmore} = useFetch(request)


    useEffect(()=>{
        handleOpen()
        if(props.data){
            setRequest({
                "notification_id": props.data.id
            })
        }
    },[props.data])
  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="myModal fadefx">
            {FetchedData &&(<ValidateUser/>)}
            <div className="container">
                <div className="errIcon">
                    <NotificationsIcon/>
                </div>
                <div className="errbody">
                    {/* {props.data.type==="songreleased"?
                        <div className="artcover">
                            <Link to={"/playing/"+props.data.song_id+"/"}><img src={props.data.artcover.length>1?ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/arts/medium/?file_name="+props.data.artcover:"/assets/images/defaultsongart.jpg"}/></Link>
                    </div> :
                    props.data.type==="albumreleased"?
                    <div className="artcover">
                        <Link to={"/playing/album/"+props.data.album_id+"/"}><img src={props.data.artcover.length>1?ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/arts/medium/?file_name="+props.data.artcover:"/assets/images/defaultsongart.jpg"}/></Link>
                    </div> :
                    <div className="user_avatar">
                        <Link to={"/profile/"+props.data.from_user_id+"/"}>
                            {props.data.user_avatar.length>2?
                                <img alt="User" src={ENVIROMENTS.baseUrl + "/geniuxmusic/api/media/avatars/smaller/?file_name="+props.data.user_avatar}/>
                            :
                                <p>{props.data.artist_name.substring(0, 1)}</p>
                            }
                        </Link>
                    </div> 
                    } */}
                    <p>
                        <Link to={"/profile/"+props.data.from_user_id}>{props.data.artist_name}</Link> {props.data.message}
                        {
                            props.data.type==="followed"?
                                <RssFeedIcon/>
                            : props.data.type==="commentedsong"?
                                <CommentIcon/>
                            :props.data.type==="songliked"?
                                <FavoriteIcon/>
                            :props.data.type==="songdisliked"?
                                <ThumbDownAltIcon/>
                            :props.data.type==="commentliked"?
                                <ThumbUpIcon/>
                            : props.data.type==="commentdisliked"?
                                <ThumbDownAltIcon/>
                            : props.data.type==="likedcommentreply"?
                                <ThumbUpIcon/>
                            : props.data.type==="dislikedcommentreply"?
                                <ThumbDownAltIcon/>
                            :props.data.type==="commentreplied"?
                                <ModeCommentIcon/>
                            :props.data.type==="songreleased"?
                                <MusicNoteIcon/>
                            :props.data.type==="songdownloaded"?
                                <GetAppIcon/>
                            :props.data.type==="albumdownloaded"?
                                <GetAppIcon/>
                            :props.data.type==="albumreleased"?
                                <MusicNoteIcon/>
                            :""
                        }
                        <br/><br/>
                        <TimeAgo datetime={props.data.date_added} />
                    </p>
                    <br/>

                    {
                        props.data.type==="followed"?
                        <Link to={"/profile/"+props.data.from_user_id}><Button  className="btn">Go To Profile</Button></Link>
                        : props.data.type==="commentedsong"?
                        <Link to={"/playing/"+props.data.song_id}><Button  className="btn">Go To Song</Button></Link>
                        :props.data.type==="songliked"?
                        <Link to={"/playing/"+props.data.song_id}><Button  className="btn">Go To Song</Button></Link>
                        :props.data.type==="songdisliked"?
                        <Link to={"/playing/"+props.data.song_id}><Button  className="btn">Go To Song</Button></Link>
                        :props.data.type==="commentliked"?
                        <Link to={"/playing/"+props.data.song_id+"/?comment_id="+props.data.comment_id}><Button  className="btn">View Comment</Button></Link>
                        : props.data.type==="commentdisliked"?
                        <Link to={"/playing/"+props.data.song_id+"/?comment_id="+props.data.comment_id}><Button  className="btn">View Comment</Button></Link>
                        : props.data.type==="likedcommentreply"?
                        <Link to={"/playing/"+props.data.song_id+"/?comment_id="+props.data.commented_id}><Button  className="btn">View Comment</Button></Link>
                        : props.data.type==="dislikedcommentreply"?
                        <Link to={"/playing/"+props.data.song_id+"/?comment_id="+props.data.commented_id}><Button  className="btn">View Comment</Button></Link>
                        :props.data.type==="commentreplied"?
                        <Link to={"/playing/"+props.data.song_id+"/?comment_id="+props.data.commented_id}><Button  className="btn">View Comment</Button></Link>
                        :props.data.type==="songreleased"?
                        <Link to={"/playing/"+props.data.song_id}><Button  className="btn">Go To Song</Button></Link>
                        :props.data.type==="songdownloaded"?
                        <Link to={"/playing/"+props.data.song_id}><Button  className="btn">Go To Song</Button></Link>
                        :props.data.type==="albumdownloaded"?
                        <Link to={"/playing/album/"+props.data.album_id}><Button  className="btn">Go To Album</Button></Link>
                        :props.data.type==="albumreleased"?
                        <Link to={"/playing/album/"+props.data.album_id}><Button  className="btn">Go To Album</Button></Link>
                        :""
                    }
                    
                    <Button onClick={()=>handleClose()} className="btn closebtn">Close</Button>
                </div>
            </div>
        </div>
         
      </Modal>
  );
}



