import React, { useEffect,useContext } from 'react'
import {useNavigate} from 'react-router-dom'; 
import CircularProgress from '@mui/material/CircularProgress';
import { UserStoreContext } from '../Contexts/UserStoreContext';
const Logout = () => {
    const navigate = useNavigate();
    const {set_user_store, set_is_logged_in} = useContext(UserStoreContext)


    useEffect(()=>{
            //Clear Local Storage Cookies
            localStorage.clear();


            // Clear UserStore
            set_is_logged_in(false)
            set_user_store(null)
            setTimeout(()=>{
                navigate('/login',{ replace: true })
            },[3000]);
        
    },[])


    
    return (
        <div className="wrapper">
            <div className="logging_out">
                <div>
                    <CircularProgress/>
                    <p>Thanks for using our platform, see you again soon.</p>
                </div>
            </div>
        </div>
        
    )
}

export default Logout
