import {useState, useEffect, useContext} from 'react'
import { UserStoreContext } from '../Contexts/UserStoreContext';
import Axios from 'axios';
import ENVIROMENTS from '../Environment/Configuration.json';

const useFetch = (post_request) => {
    
    const [data, setData] = useState(null);
    const[isLoading, setIsLoading] = useState(true);
    const[error, setError] = useState(null); 
    

  

    const {is_logged_in, set_is_logged_in,set_user_store, user_store} = useContext(UserStoreContext)
    




    useEffect(()=>{ 
        setError(null)
        setIsLoading(true)
        if(is_logged_in && post_request){
            const url = ENVIROMENTS.baseUrl + '/geniuxmusic/api/update_song.php';
            var formData = new FormData()

            if(post_request.artcover){
                formData.append("artcover", post_request.artcover.originFileObj)
            }
            formData.append('songId', post_request.song_id)
            formData.append('song_title', post_request.song_title)
            formData.append('album_title', post_request.album_title)
            formData.append('genre', post_request.genre)
            formData.append('description', post_request.description)
            formData.append('lyrics', post_request.lyrics)
            formData.append('myuserid', user_store.user_id)
            formData.append('mytoken', user_store.user_token)
 
            let cancel
            Axios({
                method: 'post',
                url: url,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                
                cancelToken: new Axios.CancelToken(c => cancel = c),

            })
            
            .then(response=>{
                if(response.data.length>0 && response.data[0].song_id){
                    setData(response.data)
                    setIsLoading(false)
                    setError(null);  

               
                }else{
                
                    if(response.data.response){
                        if(response.data.response==="song_not_found"){
                            setError(response.data.response); 
                        }else if(response.data.response==="song_title_empty"){
                            setError(response.data.response); 
                        }else if(response.data.response==="art_file_is_huge"){
                            setError(response.data.response); 
                        }else if(response.data.response==="art_file_error_occured"){
                            setError(response.data.response); 
                        }else if(response.data.response==="art_file_format_error"){
                            setError(response.data.response); 
                        }else if(response.data.response==="user_not_found" || response.data.response==="credentials_not_provided"){
                            setError('not_logged_in'); 
                            set_is_logged_in(false)
                            set_user_store(null)
                        }else{
                            setError('unknown_err'); 
                        }
                       
                    }else{
                        setError('unknown_err'); 
                    }
                
                    setData(null)
                    setIsLoading(false)
                }
           
               
            })
            .catch(err=>{
                if(!Axios.isCancel(err)){
                    setError('network_err');
                    setIsLoading(false)
                } 
            })
            return () => cancel()
        }else{
            setIsLoading(false)
        }
    
    
    },[is_logged_in,post_request])
 
    return {data, isLoading, error}
}

export default useFetch
