import React, {Component} from "react";
import {useState, useEffect} from 'react'
import Button from '@mui/material/Button';
import SignalCellularConnectedNoInternet1BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet1Bar';
import Modal from '@mui/material/Modal';


export default function BasicModal(props) {
    const [open, setOpen] = useState(false);

  
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        props.closeModal()

    }
    const handleError = () => {
  
            props.handleError()
       

    }

    useEffect(()=>{
        handleOpen()
    },[])



  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
            <div className="myModal fadefx">
                <div className="container">
                    <div className="errIcon">
                        <SignalCellularConnectedNoInternet1BarIcon/>
                    </div>
                    <div className="errbody">
                        <p>Poor network connection detected.<br/>
                            Please check your connectivity.</p>
                        <Button className="btn" onClick={()=>handleError()}>Retry</Button>
                        <Button className="btn closebtn" onClick={()=>handleClose()}>Close</Button>
                    </div>
                </div>
            </div>
         
      </Modal>
  );
}

