import React, {Component} from "react";
import {useState, useEffect} from 'react'
import SongCard from '../Cards/SongCard.js'
import useFetch from '../APIs/FetchSongs.js';
import LoadingSkeleton from '../LoadingSkeletons/Card05.js';
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper/modules";

const Songs = (props) => {

    const [data, setData] = useState(null);
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData && FetchedData.total_results>0){
            setData(FetchedData.results)
        }
    },[FetchedData])

    const send_request =()=>{
        setData(null)
        setRequest({
            "user_id":props.user_id,
            "song_id":"",
            "search_string":"",
            "genre":"",
            "sort":"newest",
            "type":"song",
            "page":"",
        })
    }



    useEffect(()=>{
        send_request()
    },[])

    return (
        <>
            {data &&(
                <>
                <div className="htitle">
                    <div className="lft"><p>LATEST SINGLES</p></div>
                    {data.length > 10 &&(
                        <div className="rgt"><p><Link to={"/profile/browse/"+props.user_id+"?group=songs&sort=newest"}>See more</Link></p></div>
                    )}
                
                </div>
            
                {props.innerWidth<992 &&(
                    <div className="vertical-container">
                         {data.map((song,index)=>{
                                return   <SongCard  key = {index} data = {song} index={index} Data={data}/> 
                            }
                        )}

                    </div>
                )}
                 {props.innerWidth>992 &&(
                   <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            992: {
                                slidesPerView: 5,
                                spaceBetween: 20,
                            },
                            1600: {
                                slidesPerView: 6,
                                spaceBetween: 30,
                            },
                        }}
                        className="mySwiper"
                    >
                        {data && (
                            data.map((song,index)=>{
                                return <SwiperSlide key = {index}><SongCard  data = {song} Data={data} index={index}/></SwiperSlide>
                            }
                        ))}
                       
                    </Swiper>           
                )}

                </>
            )}
            
            {(isLoading || error==="network_err") &&(
            <>
                    <div className="htitle">
                        <div className="lft"><p>LATEST RELEASES</p></div>
                    </div>
                    {props.innerWidth<992 &&(
                        <div className="vertical-container">
                            <LoadingSkeleton/>
                            <LoadingSkeleton/>
                            <LoadingSkeleton/>
                            <LoadingSkeleton/>
                            <LoadingSkeleton/>
                            <LoadingSkeleton/>
                        </div>
                    )}
                    {props.innerWidth>992 &&(
                        <Swiper
                            navigation={true}
                            modules={[Navigation]}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                992: {
                                    slidesPerView: 5,
                                    spaceBetween: 20,
                                },
                                1600: {
                                    slidesPerView: 6,
                                    spaceBetween: 30,
                                },
                            }}
                            className="mySwiper"
                        >
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                            
                        </Swiper>           
                    )}
                </>
            )}

    
        
        </>
    )
}

export default Songs
