
import React, {Component} from "react";
import {useState, useEffect,useContext} from 'react'
import useFetch from '../APIs/FetchAlbums.js';
import {PlayContext} from '../Contexts/PlayContext.js'
import LoadingSkeleton from '../LoadingSkeletons/AlbumCard.js';
import AlbumCard from '../Cards/AlbumCard.js';
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import { Navigation } from "swiper/modules";
const Albums = (props) => {

    const [data, setData] = useState(null)
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    const send_request =()=>{
        setData(null)
        setRequest({
            "search_string":props.filters.search_string,
            "genre": "",
            "album_id": "",
            "user_id": "",
            "genre": "",
            "sort": "played",
            "page":1,
        })
    }
  
    useEffect(()=>{
        send_request()
    },[props.filters])


    useEffect(()=>{
        if(FetchedData && FetchedData.total_results>0){
            setData(FetchedData)
        }
    },[FetchedData])



    return (
        <>
        {data &&(
        <>
         <section>
                <div className="row">
                    <div className="col-12 col-lg-12">

                
                        <div className="htitle">
                            <div className="lft"><p>POPULAR ALBUMS {props.filters.search_string!=="" &&(<>({data.total_results})</>)} <span className="platform">GENIUX MUSIC</span></p></div>
                            {data.total_results > 10 &&(
                                <div className="rgt"><p><Link to={props.filters.search_string!=''?"/browse?group=albums&search="+props.filters.search_string+"&sort=played":"/browse?group=albums&sort=played"}>See more</Link></p></div>
                            )}
                        </div>
                        {props.innerWidth<992 &&(
                            <div className="vertical-container">
                                {data.results.slice(0,10).map((album,index)=>( 
                                    <AlbumCard data={album} key={index}/>
                                ))}
                            </div>
                        )}
                        {props.innerWidth>992 &&(
                            <Swiper
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    992: {
                                        slidesPerView: 5,
                                        spaceBetween: 20,
                                    },
                                    1600: {
                                        slidesPerView: 7,
                                        spaceBetween: 30,
                                    },
                                }}
                                className="mySwiper"
                            >
                                    {data.results.slice(0,10).map((album,index)=>( 
                                        <SwiperSlide key={index}><AlbumCard data={album} key={index}/></SwiperSlide>
                                ))}
                                
                            </Swiper>           
                        )}        
                    </div>
                </div>
            </section>  
        </>
        )}
         {(isLoading) &&(
            <>
                <section>
                    <div className="row">
                        <div className="col-12 col-lg-12">

                            <div className="htitle">
                                <div className="lft"><p>POPULAR ALBUMS {props.filters.search_string!=="" &&(<>(0)</>)} <span className="platform">GENIUX MUSIC</span></p></div>
                                <div className="rgt"><p><Link to={props.filters.search_string!=''?"/browse?group=albums&search="+props.filters.search_string+"&sort=played":"/browse?group=albums&sort=played"}>See more</Link></p></div>
                            </div>
                            {props.innerWidth<992 &&(
                                <div className="vertical-container">
                                    <LoadingSkeleton/>
                                    <LoadingSkeleton/>
                                    <LoadingSkeleton/>
                                    <LoadingSkeleton/>
                                    <LoadingSkeleton/>
                                    <LoadingSkeleton/>
                                    <LoadingSkeleton/>
                                </div>
                            )}
                            {props.innerWidth>992 &&(
                                <Swiper
                                    navigation={true}
                                    modules={[Navigation]}
                                    breakpoints={{
                                        640: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        992: {
                                            slidesPerView: 5,
                                            spaceBetween: 20,
                                        },
                                        1600: {
                                            slidesPerView: 7,
                                            spaceBetween: 30,
                                        },
                                    }}
                                    className="mySwiper"
                                >
                                    <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                    <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                    <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                    <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                    <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                    <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                    <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                                    
                                </Swiper>           
                            )}
                        </div>
                    </div>
                </section>  
            </>
        )}
        </>
    )
}

export default Albums
