import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import React, {Component} from "react";
import {useState, useEffect} from 'react'
import Modal from '@mui/material/Modal';



export default function BasicModal(props) {
    const navigate = useNavigate()

    const [open, setOpen] = useState(false);

  
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        props.setisDialogOpened(false)
    }
    const goto = () => {
         navigate('/profile/'+props.user_store.user_id)

    }

    useEffect(()=>{
        handleOpen()
    },[])



  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
            <div className="myModal fadefx">
                <div className="container">
                    <div className="errIcon">
                        <PersonIcon/>
                    </div>
                    <div className="errbody">
                    <p><b>Your profile has been updated successfully</b><br/><br/>
                            {props.user_store.artist_name}</p><br/>
                        <Button onClick={()=>goto()} className="btn">Go To My Profile</Button>
                        <Button onClick={()=>handleClose()} className="btn closebtn">Close</Button>
                    </div>
                </div>
            </div>
         
      </Modal>
  );
}


