import {useState,useEffect, useContext} from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Link, useNavigate, useSearchParams, useParams}  from'react-router-dom';
import Button from '@mui/material/Button';
import Container from '../../../Components/Profile/FollowersContainer'
import {Helmet} from "react-helmet";
import useFetch from '../../../Components/APIs/FetchUsers.js'; 
import {ViewContext} from '../../../Components/Contexts/ViewContext.js';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import NavigationMenuToggle from '../../../Components/Navigation/NavigationMenuToggle.js';
import IsLoading from '../../../Components/isLoading.js';
export default function Followers ({props}) {
    const navigate = useNavigate();
    const {setViewUserData} = useContext(ViewContext)

    const[innerWidth,setinnerWidth] = useState(window.innerWidth);
    const [pagescroll, setPageScroll] = useState(0)
    useEffect(()=>{
        window.scrollTo(0, 0)
        window.onscroll = () => {
            setPageScroll(window.pageYOffset)
        }
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])

    const {user_id} = useParams();
    const group = "followers";

    const[data,setData] = useState(null)
    const [user_request, set_user_request] = useState(null)
    const {data:_user_fetched_data, isLoading:_user_is_loading, error:_user_fetch_error} = useFetch(user_request)

    useEffect(()=>{
        if(_user_fetched_data){
            setData(_user_fetched_data)
            if(_user_fetched_data.total_results>0){
                setViewUserData(
                    {
                        "id": _user_fetched_data.results[0].user_id,
                        "user_id":_user_fetched_data.results[0].user_id,
                        "artist_name": _user_fetched_data.results[0].artist_name,
                        "img_url": "",
                        "platform": "geniuxmusic"
                    }
                );
            }else{
                setViewUserData(null)
            }
          
        }
    },[_user_fetched_data])


    const send_user_request =()=>{
        set_user_request({
            "user_id":user_id,
            "search_string":"",
            "role":"",
            "sort":"topfollowed",
            "page":"",
        })
    }
    useEffect(()=>{
        send_user_request()
    },[user_id])
   

    return (
        <>
        {data && data.total_results>0?
        <>
       
            <Helmet>
                <title>{"Followers: "+data.results[0].artist_name}</title>
                <meta property="og:title" content={"Followers: "+data.results[0].artist_name}  />
                <meta name="title" content={"Followers: "+data.results[0].artist_name}/>
                <meta name="description" content={data.results[0].bio.length>1?data.results[0].bio.length<90? data.results[0].bio:data.results[0].bio.substring(0, 90) + "...":"Check out this profile on Geniux Music."}/>
                {data.results[0].user_avatar.length>2?
                <meta property="og:image" content={"/geniuxmusic/data/avatars/"+data.results[0].user_avatar}/>
                :
                <meta property="og:image" content="/assets/images/geniuxbanner.jpeg"/>
                }
                
            </Helmet>
            {innerWidth<992 &&(
                <div className="navbar">
                    <div className="wrapper">
                        <div className="lft">
                            <IconButton className="icon" onClick={()=>navigate(-1)}>
                                <ArrowBackIcon />
                            </IconButton> 
                        
                            <div className="titles">
                                <p>{data.results[0].artist_name}</p>
                                {pagescroll>80 &&(<p>{group==="followers"?<>Followers ({data.results[0].followers})</>:<>Following ({data.results[0].following})</>}</p>)}
                              
                            </div>
                        
            
                        </div>
                        <div className="rgt">
                            <NavigationMenuToggle/>
                        </div>
                    </div>
                </div>
            )}
          <div className="mycontainer">
            {innerWidth>992 &&(
            <div className="mypath">
                <div className="wrapper ">
                    <div className="lft">
                    {window.history.length>1?
                        <Button onClick={()=>navigate(-1)}>
                        <KeyboardBackspaceIcon/>
                        </Button>  
                        :
                        <Button onClick={()=>navigate(-1)} disabled="true">
                            <KeyboardBackspaceIcon/>
                        </Button>  
                    }
                    </div>
                    <div className="rgt">
                    <p>
                        <Link to="" className="active">{group==="followers"?"Followers":"Following"}</Link>
                        
                        <Link to="" className="">/ {data.results[0].artist_name}</Link>
                        
                        
                    </p>
                    </div>
                </div>
            </div>
            )}
            <div className="wrapper">
            
                {data.results[0].followers>0 &&(
                <Container user_id={data.results[0].user_id} group = {group}/>
                )}
                {data.results[0].followers<1 &&(
        
                    <div className="no_data_msg">
                        <div className="container">
                            <div className="icon">
                                <img src="/assets/images/no_user_found.svg" />
                            </div>
                            <p><Link to={"/profile/"+data.results[0].user_id}>{data.results[0].artist_name}</Link> don't have {group=="following"? "followings": group=="followers"?"followers": "results"}</p> 
                            <Button onClick={()=>navigate(-1)}>
                                Go back
                            </Button>
                        </div>
                    </div>
            
                )}
            </div>
      

           
             
    
        </div>
        </>
        :
        data && data.total_results<1?
        <>
        <Helmet>
            <title>User not found</title>
            <meta property="og:title" content="User not found"/>
            <meta name="title" content="User not found"/>
            <meta name="description" content="Link might be invalid or user has been removed" />
            <meta property="og:image" content="/assets/images/geniuxbanner.jpeg"/>
                
           
        </Helmet>
        {innerWidth<992 &&(
            <div className="navbar">
                <div className="wrapper">
                    <div className="lft">
                        <IconButton className="icon" onClick={()=>navigate(-1)}>
                            <ArrowBackIcon />
                        </IconButton> 
                    
                        <div className="titles">
                            <p>PROFILE</p>
                            {pagescroll> 80 &&(<p>{user_id}</p>)}
                        </div>
                    
        
                    </div>
                    <div className="rgt">
                        <NavigationMenuToggle/>
                    </div>
                </div>
            </div>
        )}
        
        <div className="mycontainer">
            {innerWidth>992 &&(
                <div className="mypath">
                    <div className="wrapper ">
                        <div className="lft">
                        {window.history.length>1?
                            <Button onClick={()=>navigate(-1)}>
                            <KeyboardBackspaceIcon/>
                            </Button>  
                            :
                            <Button onClick={()=>navigate(-1)} disabled="true">
                                <KeyboardBackspaceIcon/>
                            </Button>  
                        }
                        </div>
                        <div className="rgt">
                            <p>
                                <Link to="" className="active">{group==="followers"?"Followers":"Following"}</Link>
                                <Link to="" className="">/ {user_id}</Link>
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {(!_user_is_loading) &&(
                <div className='wrapper'>
                    <div className="no_data_msg">
                        <div className="container">
                            <div className="icon">
                                <img src="/assets/images/no_user_found.svg" />
                            </div>
                            <p>User <Link to={"/profile/"+user_id}>{user_id}</Link> is not found</p> 
                            <Button onClick={()=>send_user_request()}>
                                Retry
                            </Button>
                        </div>

                    </div>
                </div> 
            )}  
        </div>
        </>:
        _user_fetch_error==="network_err"?
        <>
        <Helmet>
            <title>No Internet</title>
            <meta property="og:title" content="No Internet"/>
            <meta name="title" content="No Internet"/>
            <meta name="description" content="Check your internet connectivity" />
            <meta property="og:image" content="/assets/images/geniuxbanner.jpeg"/>
                
           
        </Helmet>
        {innerWidth<992 &&(
            <div className="navbar">
                <div className="wrapper">
                    <div className="lft">
                        <IconButton className="icon" onClick={()=>navigate(-1)}>
                            <ArrowBackIcon />
                        </IconButton> 
                    
                        <div className="titles">
                            <p>PROFILE</p>
                            {pagescroll> 80 &&(<p>{user_id}</p>)}
                        </div>
                    
        
                    </div>
                    <div className="rgt">
                        <NavigationMenuToggle/>
                    </div>
                </div>
            </div>
        )}
        <div className="mycontainer">
            {innerWidth>992 &&(
                <div className="mypath">
                    <div className="wrapper ">
                        <div className="lft">
                        {window.history.length>1?
                            <Button onClick={()=>navigate(-1)}>
                            <KeyboardBackspaceIcon/>
                            </Button>  
                            :
                            <Button onClick={()=>navigate(-1)} disabled="true">
                                <KeyboardBackspaceIcon/>
                            </Button>  
                        }
                        </div>
                        <div className="rgt">
                            <p>
                                <Link to="" className="active">{group==="followers"?"Followers":"Following"}</Link>
                                <Link to="" className="">/ {user_id}</Link>
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {(!_user_is_loading) &&(
                <div className='wrapper'>
                    <div className="no_data_msg">
                        <div className="container">
                            <div className="icon">
                                <img src="/assets/images/no_user_found.svg" />
                            </div>
                            <p>Check your internet connectivity</p> 
                            <Button onClick={()=>send_user_request()}>
                                Retry
                            </Button>
                        </div>
                    </div>
                </div> 
            )}  
        </div>
        </>:
        _user_is_loading?
        <>
        {innerWidth<992 &&(
            <div className="navbar">
                <div className="wrapper">
                    <div className="lft">
                        <IconButton className="icon" onClick={()=>navigate(-1)}>
                            <ArrowBackIcon />
                        </IconButton> 
                    
                        <div className="titles">
                            <p>PROFILE</p>
                            {pagescroll> 80 &&(<p>{user_id}</p>)}
                        </div>
                    
        
                    </div>
                    <div className="rgt">
                        <NavigationMenuToggle/>
                    </div>
                </div>
            </div>
        )}
        <div className="mycontainer">
            {innerWidth>992 &&(
                <div className="mypath">
                    <div className="wrapper ">
                        <div className="lft">
                        {window.history.length>1?
                            <Button onClick={()=>navigate(-1)}>
                            <KeyboardBackspaceIcon/>
                            </Button>  
                            :
                            <Button onClick={()=>navigate(-1)} disabled="true">
                                <KeyboardBackspaceIcon/>
                            </Button>  
                        }
                        </div>
                        <div className="rgt">
                            <p>
                                <Link to="" className="active">{group==="followers"?"Followers":"Following"}</Link>
                                <Link to="" className="">/ {user_id}</Link>
                            </p>
                        </div>
                    </div>
                </div>
            )}
            
            <div className='wrapper'>
                <IsLoading/>
            </div> 
            
        </div>
        </>:
        <></>
        }
         
        </>
    )
}


