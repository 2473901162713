import {useState,useEffect} from 'react';
import {Helmet} from "react-helmet";
import SearchIcon from '@mui/icons-material/Search';
import {Link, useNavigate, useSearchParams}  from'react-router-dom'; 
import NavigationMenuToggle from '../../Components/Navigation/NavigationMenuToggle.js'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import TopResults from '../../Components/Browse/GeniuxMusicTopResults.js';

import GeniuxMusicPopularSongs from '../../Components/Browse/GeniuxMusicPopularSongs.js';
import GeniuxMusicArtists from '../../Components/Browse/GeniuxMusicTopArtists.js';
import SpotifyArtists from '../../Components/Browse/SpotifyArtists.js';
import GeniuxMusicSingles from '../../Components/Browse/GeniuxMusicSongs.js';
import SpotifySingles from '../../Components/Browse/SpotifySingles.js';
import GeniuxMusicAlbums from '../../Components/Browse/GeniuxMusicAlbums.js';

import MorePeople from '../../Components/Browse/Sub/Artists.js' 
import MoreSongs from '../../Components/Browse/Sub/Singles.js'
import MoreAlbums from '../../Components/Browse/Sub/Albums.js'

import MorePeopleSpotify from '../../Components/Browse/Sub/SpotifyArtists.js' 
import MoreSongsSpotify from '../../Components/Browse/Sub/SpotifySingles.js' 
import MoreAlbumsOnSpotify from '../../Components/Browse/Sub/SpotifyAlbums.js' 


import SpotifyAlbums from '../../Components/Browse/SpotifyAlbums.js'

import Filter from "../../Components/Browse/Filter.js"
const Container = (props) => {
    const navigate = useNavigate();

    const[innerWidth,setinnerWidth] = useState(window.innerWidth);
    const [pagescroll, setPageScroll] = useState(0);
    useEffect(()=>{
        window.scrollTo(0, 0)
        window.onscroll = () => {
            setPageScroll(window.pageYOffset)
        }
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])

 
    const [searchParams, setSearchParams] = useSearchParams();
    const group_param = searchParams.get("group")
    const search_parm = searchParams.get("search")
    const sort_param = searchParams.get("sort")
    const genre_param = searchParams.get("genre")
    const role_param = searchParams.get("role")





    const onhandle_search_string = (e) =>{
        if(e.target.value.length<50){
            const isGenre = genre_param?"&genre="+genre_param:"";
            const isSort =  sort_param?"&sort="+sort_param:"";
            const isRole =  role_param?"&role="+role_param.replace(/\s/g, "%20"):"";
            const isSearch = e.target.value?"&search="+e.target.value.replace(/\s/g, "%20"):"";

            if(group_param==="all" || group_param===""){
                navigate('/browse?group=all'+isSearch+isSort+isGenre+isRole, { replace: true })
            }else if(group_param==="people"){
                navigate('/browse?group=people'+isSearch+isSort+isGenre+isRole, { replace: true })
            }else if(group_param==="songs"){
                navigate('/browse?group=songs'+isSearch+isSort+isGenre+isRole, { replace: true })
            }else if(group_param==="albums"){
                navigate('/browse?group=albums'+isSearch+isSort+isGenre+isRole, { replace: true })
            }else if(group_param==="people-on-spotify"){
                navigate('/browse?group=people-on-spotify'+isSearch+isSort+isGenre+isRole, { replace: true })
            }else if(group_param==="singles-on-spotify"){
                navigate('/browse?group=singles-on-spotify'+isSearch+isSort+isGenre+isRole, { replace: true })
            }else if(group_param==="albums-on-spotify"){
                navigate('/browse?group=albums-on-spotify'+isSearch+isSort+isGenre+isRole, { replace: true })
            }else{
                navigate('/browse?group=all'+isSearch+isSort+isGenre+isRole, { replace: true })
            }   
        }
        
       
    }

        


    const [filters,setFilter] = useState({
        "search_string": search_parm?search_parm:"",
        "sort": sort_param?sort_param:"",
        "genre": genre_param?genre_param:"",
        "role": role_param?role_param:""
    });

    useEffect(()=>{
        setFilter({
            "search_string": search_parm?search_parm:"",
            "sort": sort_param?sort_param:"",
            "genre": genre_param?genre_param:"",
            "role": role_param?role_param:""
        })

        setTimeout(()=>{
            if(document.querySelector(".FilterPanel .filter .lft button.active")!== null){
                document.querySelector(".FilterPanel .filter .lft button.active").scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                }); 
            }
        },[1000])
  
      
    },[group_param,search_parm,sort_param,genre_param,role_param])
  

    const[isFilterOpened,  setFilterOpened] = useState(false)
  

    const removeQueryParam = (param)=>{
        if (searchParams.has(param)) {
            const token = searchParams.get(param);
            if (token) {
              searchParams.delete(param);
              setSearchParams(searchParams);
            }
        }
    }

    const goto=(d)=>{
        const isSort =  sort_param?"&sort="+sort_param:"";
        const isSearch = search_parm?"&search="+search_parm.replace(/\s/g, "%20"):"";
        const isGenre = genre_param?"&genre="+genre_param:"";
        const isRole = role_param?"&role="+role_param.replace(/\s/g, "%20"):"";



        if(d==="All"){
            return '/browse?group=all'+isSearch+isSort+isGenre+isRole;
        }else if(d==="People"){
            return '/browse?group=people'+isSearch+isSort+isGenre+isRole;
        }else if(d==="Singles"){
            return '/browse?group=songs'+isSearch+isSort+isGenre+isRole;
        }else if(d==="Albums"){
            return '/browse?group=albums'+isSearch+isSort+isGenre+isRole;
        }else if(d==="PeopleOnSpotify"){
            return '/browse?group=people-on-spotify'+isSearch+isSort+isGenre+isRole;
        }else if(d==="SpotifySingles"){
            return '/browse?group=singles-on-spotify'+isSearch+isSort+isGenre+isRole;
        }else if(d==="AlbumsOnSpotify"){
            return '/browse?group=albums-on-spotify'+isSearch+isSort+isGenre+isRole;
        }else{
            return '/browse?group=all'+isSearch+isSort+isGenre+isRole;
        }

    
    }

  return (
    <div>
        
        {innerWidth<992 &&(
                <div className="navbar">
                <div className="wrapper">
                    <div className="lft">
                        <IconButton className="icon" onClick={()=>navigate(-1)}>
                            <ArrowBackIcon />
                        </IconButton> 
                        <div className="titles">
                            <p>Browse </p>
                            {pagescroll> 80 &&(
                              <p>
                                {
                                    group_param==="all"?
                                    "All":
                                    group_param==="songs"?
                                    "Singles":
                                    group_param==="albums"?
                                    "Albums":
                                    group_param==="people"?
                                    "Artist":
                                    group_param==="people-on-spotify"?
                                    "Artists on spotify":
                                    group_param==="singles-on-spotify"?
                                    "Singles on spotify":
                                    group_param==="albums-on-spotify"?
                                    "Albums on spotify":
                                    "All"
                                }
                            </p>  
                            )}
                        </div>
                        
                    </div>
                    <div className="rgt">
                    {/* {is_logged_in &&
                        <IconButton className="icon" onClick={()=>goto('/notifications/settings')} aria-label="Notification Settings" color="inherit">
                            <SettingsIcon />
                        </IconButton>
                    
                    } */}
                    <NavigationMenuToggle/>
                
                    </div>  
                </div>
                
            </div>
        )}
            
            <div className="mycontainer">
                <div className="wrapper">
                    <div className="FilterPanel">
                            <div className="searchbar">
                                <SearchIcon/>
                                <input placeholder="Search something here"  value={search_parm?search_parm:""}  onChange={onhandle_search_string}/>
                            </div>
                   
                            <div className="filter">
                                <div className="lft">
                                    <Link to={goto("All")}>
                                        <Button color="secondary" className={(group_param=="all" || group_param=="")?"active":""}><p>All</p></Button>
                                    </Link>
                                    <div className="separator"></div>
                                    <Link to={goto("People")} >
                                        <Button color="secondary" className={group_param=="people"?"active":""}><p>Artists</p></Button>
                                    </Link>
                                    <Link to={goto("Singles")}>
                                        <Button color="secondary" className={group_param=="songs"?"active":""}><p>Singles</p></Button>   
                                    </Link>
                                    <Link to={goto("Albums")}>
                                        <Button color="secondary" className={group_param=="albums"?"active":""}><p>Albums</p></Button>
                                    </Link>
                                    <div className="separator"></div>
                                    <Link to={goto("PeopleOnSpotify")} >
                                        <Button color="secondary" className={group_param=="people-on-spotify"?"active":""}><p>Artists <span>Spotify</span></p></Button>
                                    </Link>
                                    <Link to={goto("SpotifySingles")} >
                                        <Button color="secondary" className={group_param=="singles-on-spotify"?"active":""}><p>Singles <span>Spotify</span></p></Button>
                                    </Link>
                                    <Link to={goto("AlbumsOnSpotify")} >
                                        <Button color="secondary" className={group_param=="albums-on-spotify"?"active":""}><p>Albums <span>Spotify</span></p></Button>
                                    </Link>
                                </div>
                                
                                <div className="rgt">

                                    {innerWidth<992?<IconButton color="secondary" className="" onClick={()=>setFilterOpened(true)}><MoreVertRoundedIcon/></IconButton>:
                                    <><div className="separator"></div><Button color="secondary" onClick={()=>setFilterOpened(true)}>Filter</Button></>}
                                    
                                </div>
                                
                            </div>
                        
                            <div className="keywords">
                                {filters.search_string!==""&&(
                                    <Button color="secondary"><p>SEARCH: {filters.search_string.length>15?filters.search_string.substring(0,15)+"...":filters.search_string}</p><CancelIcon onClick={()=>removeQueryParam('search')}/></Button>
                                )}
                                {filters.sort!==""&&(
                                    <Button color="secondary" ><p onClick={()=>setFilterOpened(true)}>SORT: {filters.sort==="played"?"Popular":filters.sort==="random"?"Shuffle":filters.sort}</p><CancelIcon onClick={()=>removeQueryParam('sort')}/></Button>
                                )}
                                {filters.genre!==""&&(
                                    <Button color="secondary"><p onClick={()=>setFilterOpened(true)}>GENRE: {filters.genre}</p><CancelIcon onClick={()=>removeQueryParam('genre')}/></Button>
                                )}
                                {filters.role!==""&&(
                                    <Button color="secondary"><p onClick={()=>setFilterOpened(true)}>ROLES: {filters.role}</p><CancelIcon onClick={()=>removeQueryParam('role')}/></Button>
                                )}
                            </div>
                        
                       
                    </div>
               
                    {group_param=="people"?
                    <>
                        <Helmet>
                            <title>Browse - People</title>
                            <meta property="og:title" content="Browse - People"/>
                            <meta name="title" content="Browse - People"/>
                            <meta property="og:image" content="/assets/images/geniux_music_logo.png" />
                        </Helmet>
                        <MorePeople filters={filters} innerWidth={innerWidth}/>
                    </>:
                    group_param=="songs"?
                    <>
                        <Helmet>
                            <title>Browse - Songs</title>
                            <meta property="og:title" content="Browse - Songs"/>
                            <meta name="title" content="Browse - Songs"/>
                            <meta property="og:image" content="/assets/images/geniux_music_logo.png" />
                        </Helmet>
                        <MoreSongs filters={filters} innerWidth={innerWidth}/>
                    </>:
                    group_param=="albums"?
                    <>
                        <Helmet>
                            <title>Browse - Albums</title>
                            <meta property="og:title" content="Browse - Albums"/>
                            <meta name="title" content="Browse - Albums"/>
                            <meta property="og:image" content="/assets/images/geniux_music_logo.png" />
                        </Helmet>
                        <MoreAlbums filters={filters} innerWidth={innerWidth}/>
                    </>:
                    group_param=="people-on-spotify"?
                    <>
                        <Helmet>
                            <title>Browse - Artists On Spotify</title>
                            <meta property="og:title" content="Browse - Artists On Spotify"/>
                            <meta name="title" content="Browse - Artists On Spotify"/>
                            <meta property="og:image" content="/assets/images/geniux_music_logo.png" />
                        </Helmet>
                        <MorePeopleSpotify filters={filters} innerWidth={innerWidth}/>
                    </>:
                    group_param=="singles-on-spotify"?
                    <>
                        <Helmet>
                            <title>Browse - Singles On Spotify</title>
                            <meta property="og:title" content="Browse - Singles On Spotify"/>
                            <meta name="title" content="Browse - Singles On Spotify"/>
                            <meta property="og:image" content="/assets/images/geniux_music_logo.png" />
                        </Helmet>
                        <MoreSongsSpotify filters={filters} innerWidth={innerWidth}/>
                    </>:
                    group_param=="albums-on-spotify"?
                    <>
                        <Helmet>
                            <title>Browse - Albums On Spotify</title>
                            <meta property="og:title" content="Browse - Albums On Spotify"/>
                            <meta name="title" content="Browse - Albums On Spotify"/>
                            <meta property="og:image" content="/assets/images/geniux_music_logo.png" />
                        </Helmet>
                        <MoreAlbumsOnSpotify filters={filters} innerWidth={innerWidth}/>
                    </>:<>
                        <Helmet>
                            <title>Browse</title>
                            <meta property="og:title" content="Browse"/>
                            <meta name="title" content="Browse"/>
                            <meta property="og:image" content="/assets/images/geniux_music_logo.png" />
                        </Helmet>
                        <div className="row">
                            <TopResults filters={filters} innerWidth={innerWidth}/>
                            <GeniuxMusicPopularSongs filters={filters} innerWidth={innerWidth}/>
                        </div>
                        <GeniuxMusicArtists filters={filters} innerWidth={innerWidth}/>
                        <GeniuxMusicSingles filters={filters} innerWidth={innerWidth}/>
                        <GeniuxMusicAlbums filters={filters} innerWidth={innerWidth}/>

                        <SpotifyArtists filters={filters} innerWidth={innerWidth}/>
                        <SpotifySingles filters={filters} innerWidth={innerWidth}/>
                        <SpotifyAlbums filters={filters} innerWidth={innerWidth}/>
                    </>
                        
                    }
                    
                    
                  
                </div>
            </div>
            {isFilterOpened && (
                <Filter setFilterOpened={setFilterOpened} filters = {filters} group_param={group_param} />
            )}
 
    </div>
  )
}

export default Container