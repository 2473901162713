import React, {Component} from "react";
import {useState} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import useRegister from '../APIs/Register.js';
const Register = () => {
    document.title = "Register";
    const fetchInterests = [
        {
            "id": "15",
            "interests": "Amateur"
        },
        {
            "id": "2",
            "interests": "Artist"
        },
        {
            "id": "7",
            "interests": "Beat Maker"
        },
        {
            "id": "8",
            "interests": "Composer"
        },
        {
            "id": "14",
            "interests": "Dancer"
        },
        {
            "id": "16",
            "interests": "Director"
        },
        {
            "id": "11",
            "interests": "Ghost Writer"
        },
        {
            "id": "13",
            "interests": "Instrumentalist"
        },
        {
            "id": "9",
            "interests": "Lyricist"
        },
        {
            "id": "18",
            "interests": "Manager"
        },
        {
            "id": "3",
            "interests": "Musician"
        },
        {
            "id": "1",
            "interests": "Producer"
        },
        {
            "id": "6",
            "interests": "Rapper"
        },
        {
            "id": "20",
            "interests": "Record Label"
        },
        {
            "id": "19",
            "interests": "Regular User"
        },
        {
            "id": "5",
            "interests": "Singer"
        },
        {
            "id": "12",
            "interests": "Song Writer"
        },
        {
            "id": "4",
            "interests": "Sound Engineer"
        },
        {
            "id": "17",
            "interests": "Trapper"
        },
        {
            "id": "10",
            "interests": "Writer"
        }
    ]

    const[interest, setInterest] = useState('')
    const[fullname, setFullName] = useState('')
    const[email, setEmail] = useState('')
    const[password, setPassword] = useState('')


    const onhandle_fullname = (e) =>{
        setFullName(e.target.value)
    }
    const onhandle_email = (e) =>{
        setEmail(e.target.value)
    }
    const onhandle_password = (e) =>{
        setPassword(e.target.value)
    }


    const[request, sendRequest] = useState(null)
    const {data,isLoading, error} = useRegister(request)
    


    const onhandle_submit= (e) =>{

        e.preventDefault()
        if(fullname===""){
          document.getElementById('fullname').focus()
        }else if(email===""){
          document.getElementById('email').focus()
        }else if(password===""){
            document.getElementById('password').focus()
        }else{
          sendRequest({
            'interest':interest,
            'fullname':fullname,
            'email':email,
            'password':password,

          })
        }


       
    
    }





    const [showpass, setShowPass] = useState(false);
    return (
        <>
             <form className="myform">
                <TextField variant="filled" id="fullname" value={fullname} label="Full Name" onChange={onhandle_fullname}/>

                
                <p className="mylabel">Select Interest</p>
                <div className="selectoptions">
                    {
                        fetchInterests.map((data,index)=>(
                            <p key={index} onClick={()=>setInterest(data.interests)} className={interest===data.interests?"active":""}>{data.interests}</p>
                        ))
                     
                    }
                    
                </div>
                <TextField variant="filled" id="email" value={email} label="Email" onChange={onhandle_email} />
                <div className="passwordfield">
                    <TextField variant="filled" id="password" value={password} type={showpass?"text":"password"}  label="Password" onChange={onhandle_password} />
                    {password!=="" &&(
                    <div className="icon" onClick={()=>setShowPass(!showpass)}>
                        {showpass?"HIDE":"SHOW"}
                    </div>   
                    )}
                </div>
                <div className="myerr">  
                    {(error==="full_name_empty" && !isLoading) &&(
                        <p>Full Name is required</p>
                    )}
                    {(error==="fullname_too_long" && !isLoading) &&(
                        <p>Full Name is too long</p>
                    )}
                     {(error==="email_address_empty" && !isLoading) &&(
                        <p>Email Address is required</p>
                    )}
                    {(error==="invalid_email" && !isLoading) &&(
                        <p>Ops email address is invalid, try different email</p>
                    )}
                    {(error==="email_taken" && !isLoading) &&(
                        <p>Email address is taken, please use different email</p>
                    )}
                    {(error==="password_empty" && !isLoading) &&(
                        <p>Password is required</p>
                    )}
                    {(error==="password_is_short" && !isLoading) &&(
                        <p>Password is short, minimum of 6 characters required</p>
                    )}
                    {(error==="unknown_error" && !isLoading) &&(
                        <p>Something is wrong, contact developers</p>
                    )}
                    {(error==="network_err" && !isLoading) &&(
                        <p>Check your network connectivity</p>
                    )}
                  
                  
                
                </div>  

                <div className="btns">
                    <Button variant="contained" color="primary" value="submit" onClick={onhandle_submit}>Create An Account {(isLoading) && <CircularProgress/>}</Button>
                </div>
            </form>

            {/* {Error &&(
                <NoInternet setError={setError} handleError={handleError}/>
            )} */}
        </>
    )
}

export default Register
