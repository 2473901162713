import React, {Component} from "react";
import {useState, useEffect} from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import useEditComment from './APIs/useEditComment.js';
import useDeleteComment from './APIs/DeleteComment.js';
import CircularProgress from '@mui/material/CircularProgress';
import {useNavigate, useSearchParams} from 'react-router-dom';
const EditComment = (props) => {
    const navigate = useNavigate();

    
    const [searchParams, setSearchParams] = useSearchParams();


    const[comment, setComment] = useState('');

    useEffect(() => {
       if(props.data){
            setComment(props.data.message)
       }
    }, [props.data])

    const onhandle_message = (e) =>{
        setComment(e.target.value)
    }

    const [isEmptyMessage, setIsEmptyMessage] = useState(null);


    const [data, setData] = useState(null);
    const [request,setRequest] = useState(false);
    const [updatecommentrequest, setUpdateCommentRequest] = useState(null);
    const {data:FetchedData,isLoading, error} = useEditComment(updatecommentrequest)

    useEffect(()=>{
        if(FetchedData){
            setData(FetchedData)
            props.setData(...FetchedData)
            setUpdateCommentRequest(null)
        }
    },[FetchedData])
    const assign_values = ()=>{
        if(props.data){
            setRequest(!request)
            
            setUpdateCommentRequest({
                'commentId':props.data.comment_id,
                'comment': comment,
                'songId':props.data.song_id,
                'request': request,
              })
        }

    }
    const onhandle_submit= (e) =>{
        e.preventDefault()
        if(comment.length<1){
            setIsEmptyMessage(true)
            setTimeout(function(){
                setIsEmptyMessage(false) 
            },1000);
        }else{
            assign_values()

        }
    
    }

     

    const [isDeleting, setIsDeleting] = useState(false);
    const [deleterequest, setSendDeleteRequest] = useState(null);
    const {data:fetch_deleted,isLoading:loading_deleting, error:error_deleting} = useDeleteComment(deleterequest)


    const removeQueryParam = (param)=>{
        if (searchParams.has(param)) {
            const token = searchParams.get(param);
            if (token) {
              searchParams.delete(param);
              setSearchParams(searchParams);
            }
        }
    }

    useEffect(()=>{
        if(fetch_deleted){
            setTimeout(()=>{
                setSendDeleteRequest(null)
                props.setData(null)
                removeQueryParam('comment_id')
            },[500])
           
        }
    },[fetch_deleted])
    const delete_comment=()=>{

        if(props.data){
            setRequest(!request)

            setSendDeleteRequest({
                'commentId':props.data.comment_id,
                'songId':props.data.song_id,
                'request': request,
              })
        }
    }

        

    
    return (
        <>

        
            <div className="editcomment">
                <div className="wrapper">
                    <div className="navmenu">
                        <div className="lft">
                            <IconButton onClick={()=>props.showIntialMenu()} color="secondary">
                                <ArrowBackIcon/>
                            </IconButton>
                            
                            <div className="title">
                                Edit Comment
                            </div>
                        </div>
                        <div className="rgt">
                            {isDeleting &&(
                                <div className="deletebtn" onClick={()=>delete_comment()}>
                                    {(loading_deleting && !error_deleting) && (
                                        <>Deleting <CircularProgress/></>
                                    )}
                                    {(!loading_deleting && !error_deleting && !fetch_deleted)&& (
                                        <>Delete Comment?</>
                                    )}
                                    {(error_deleting)&& (
                                        <>Failed, Retry</>
                                    )}
                                    {(fetch_deleted && !error_deleting && !loading_deleting)&& (
                                        <>Comment deleted</>
                                    )}
                                    
                                </div>
                            )}
                            {!isDeleting &&(
                                <IconButton onClick={()=>setIsDeleting(true)} color="secondary">
                                    <DeleteIcon/>
                                </IconButton>
                            )}

                            
                        </div>
                    </div>
                    <form className="myform" onSubmit={onhandle_submit}>
                        <textarea placeholder="Start typing here" value={comment} onChange={onhandle_message} className={isEmptyMessage?'active':''}/>
                        <div className="myerr">  
                            
                            
                            {error==="comment_id_not_specified"?<p>Comment Id not specified</p>:""}
                            {error==="song_not_specified"?<p>Song not specified</p>:""}
                            {error==="comment_empty"?<p>Comment is empty</p>:""}
                            {error==="credentials_not_provided"?<p>You are not logged in</p>:""}
                            {error==="user_not_found"?<p>You are not logged in </p>:""}
                            {error==="failed_to_update_comment"?<p>Something went wrong</p>:""}
                            {error==="comment_not_found"?<p>Comment not found or has been removed</p>:""}
                            {error==="unknown_error"?<p>Something went wrong</p>:""}
                            {error==="network_err"?<p>Check internet connectivity</p>:""}
                            {(!error && FetchedData && data && !isLoading) &&(<p className="noerr">Saved</p>)}
                        </div>
                        <div className="btns">
                            <Button  type="submit" className="defaultBTN" color="primary">Save Changes  {(isLoading && !error) && (<CircularProgress/>)}</Button>
                        </div> 
                    </form>
                </div>
                

             
                
              

            </div>
            
        </>
    )
}

export default EditComment
