import {useState,useEffect, useContext} from 'react'
import Container from '../../Components/ManageUploads/Container'
import {Helmet} from "react-helmet";
import { UserStoreContext } from '../../Components/Contexts/UserStoreContext';
import ValidateUser from '../../Components/UserCredentials/ValidateUser';
import {useNavigate} from "react-router-dom"; 
export default function Uploads () {
    const navigate = useNavigate();
    const[innerWidth,setinnerWidth] = useState(window.innerWidth)
    useEffect(()=>{
        const updateInnerWidth = () => {
            setinnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateInnerWidth);
        return () => window.removeEventListener("resize", updateInnerWidth)
    },[])

    const {is_logged_in} = useContext(UserStoreContext)
    useEffect(()=>{
        if(is_logged_in===true || is_logged_in===false){
            if(is_logged_in===false){
                navigate('/login')
            }
        }
    },[is_logged_in])
    return (
        <>
        <ValidateUser/>
        <Helmet>
            <title>Your uploads</title>
            <meta property="og:title" content="Your uploads"/>
            <meta name="title" content="Your uploads"/>
            <meta name="description" content="Manage your uploads"/>
            <meta property="og:image" content="/assets/images/geniux_music_logo.png" />
        </Helmet>
        <Container innerWidth={innerWidth} /> 
      
        </>
    )
}

