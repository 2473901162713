import React, {Component} from "react";
import {useState, useEffect} from 'react'
import UserCard from '../Cards/UserCard';
import useFetch from '../APIs/FetchUsers.js';
import LoadingSkeleton from '../LoadingSkeletons/UserCard03.js';
import { Swiper, SwiperSlide } from "swiper/react";
import {Link} from 'react-router-dom';
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import { Navigation } from "swiper/modules";

const RecommendedUsers = (props) => {

    //userId,songId,search_string,genre,sort,page,
    
    const [data, setData] = useState(null);
    const [request, setRequest] = useState(null)
    const {data:FetchedData,isLoading, error} = useFetch(request)

    useEffect(()=>{
        if(FetchedData){
            setData(FetchedData)
        }
    },[FetchedData])


    const send_request =()=>{
        setData(null)
        setRequest({
            "user_id":"",
            "search_string":"",
            "role":"",
            "sort":"random",
            "page":"",
        })
    }



    useEffect(()=>{
        send_request()
    },[])

    return (
    <>

      {(isLoading || error=="network_err") &&(
        <>
        <div className="col-12 col-lg-6">
            
        </div>
            <div className="htitle">
                <div className="lft"><p>Who you may like</p></div>
                <div className="rgt"><p><Link to="/browse?group=people&sort=topfollowed">See more</Link></p></div> 
            </div>
            
            {props.innerWidth<992 &&(
                <div className="vertical-container">
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                    <LoadingSkeleton/>
                </div>
            )}
            {props.innerWidth>992 &&(
                <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                        992: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        1200: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        
                        1600: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                    }}
                    className="mySwiper"
                >
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                     <SwiperSlide ><LoadingSkeleton/></SwiperSlide>
                </Swiper>           
            )}

        </>
        )}
        {data &&(
            <>
                <div className="htitle">
                    <div className="lft"><p>Who you may like</p></div>
                    {data.total_results>9 &&(
                       <div className="rgt"><p><Link to="/browse?group=people&sort=topfollowed">See more</Link></p></div> 
                    )}
                    
                </div>
                {props.innerWidth<992 &&(
                    <div className="vertical-container">
                        {data && (
                            data.results.map((users,index)=>{
                                return <UserCard key = {index} data = {users} />
                            }
                        ))}

                    </div>
                )}
                {props.innerWidth>992 &&(
                   <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        breakpoints={{
                            992: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1600: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                            },
                        }}
                        className="mySwiper"
                    >
                        {data && (
                            data.results.map((users,index)=>{
                                return <SwiperSlide key = {index}><UserCard  data = {users} /></SwiperSlide>
                            }
                        ))}
                       
                    </Swiper>           
                )}
            </>
        )}
    </>  
    )
}

export default RecommendedUsers
